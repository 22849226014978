<template>
  <div class="body">
    <div class="main_container">
      <training-header :appConfig="appConfig"></training-header>
      <div class="container-fluid" role="main" style="">
        <div class="text-sectiomn-wrap">
          <div class="row">
            <div class="col-sm-10 mx-auto">
              <div class="backgroundbg-color">
                <div class="heading-title-card">
                  <h2 class="text-white" style="font-weight: 600">
                    Generate Your Exam Id
                  </h2>
                </div>
                <div class="x_panel border-radius-0">
                  <div class="x_content position-relative">
                    <div class="dashboard-widget-content">
                      <div class="user-details-div row">
                        <!-- <p>Thank you for your intrest in your family planning Certification test. Please fill out all the below fields and generate your examination id.</p> -->
                        <!-- <p>Please fill out all the below fields and generate your examination id.</p> -->
                        <div class="col-12 mb-4">
                          <p class="my-2">
                            Thank you for your interest in the FP - LMIS
                            Pre-test/ Post-test Questionnaire.
                          </p>
                          <p class="my-2">
                            Please fill out all the below fields and generate
                            your examination id.
                          </p>
                        </div>
                        <div class="col-md-5">
                          <div class="card" style="border: none">
                            <div
                              class="card-header"
                              style="
                                font-size: 16px;
                                font-weight: 600;

                                background-color: transparent;
                                border-bottom: none;
                              "
                            >
                              Instructions
                            </div>
                            <div
                              class="card-body examInstBody pt-1"
                              v-html="facilitatorObj.course[0].examRules"
                              v-if="facilitatorObj && facilitatorObj.course"
                            >
                              <!-- <ul class="examInst pl-3">
                                <li>Make sure you have a good internet connection.</li>
                                <li>You will have 1.5 hours to complete your exam.</li>
                                <li>If you are taking the exam late in the day, it is recommended that you reboot your computer before beginning to free up memory resources from other programs on your computer.</li>
                              </ul> -->
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-md-7"
                          style="border-left: 1px solid rgba(0, 0, 0, 0.125)"
                        >
                          <form class="form-horizontal px-3">
                            <div class="form-group">
                              <div class="row">
                                <label
                                  class="control-label col-sm-2 font-weight-bold"
                                  for="name"
                                  >Name</label
                                >
                                <div class="col-sm-10">
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="name"
                                    name="name"
                                    v-model="name"
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="row">
                                <label
                                  class="control-label col-sm-2 font-weight-bold"
                                  for="designation"
                                  >Designation</label
                                >
                                <div class="col-sm-10">
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="designation"
                                    name="designation"
                                    v-model="designation"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="row">
                                <label
                                  class="control-label col-sm-2 font-weight-bold"
                                  for="country"
                                  >Country</label
                                >
                                <div class="col-sm-10">
                                  <select
                                    class="form-control"
                                    id="country"
                                    name="country"
                                    v-model="country"
                                  >
                                    <option
                                      v-for="c in countries"
                                      :key="c.Name"
                                    >
                                      {{ c.Name }}
                                    </option>
                                  </select>
                                  <!-- <input type="text" class="form-control" id="country" name="country" v-model="country"/> -->
                                </div>
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="row">
                                <label
                                  class="control-label col-sm-2 font-weight-bold"
                                  for="email"
                                  >Email</label
                                >
                                <div class="col-sm-10">
                                  <input
                                    type="email"
                                    class="form-control"
                                    id="email"
                                    name="email"
                                    v-model="email"
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <div class="col-sm-2"></div>
                        <div class="col-sm-10">
                          <a
                            href="javascript:void(0)"
                            class="btn btn-primary floating-submit-btn yellow-float-btn"
                            style="padding: 8px 40px"
                            @click="addUser()"
                            >Submit</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <training-footer :appConfig="appConfig"></training-footer>
      <training-faq :facilitatorObj="facilitatorObj"></training-faq>
      <!-- /footer content -->
    </div>
  </div>
</template>
<script>
import trainingHeader from "../components/trainingHeader";
import trainingFooter from "../components/trainingFooter";
import trainingFaq from "./trainingFaq";
import ApiServices from "../service/ApiServices";
export default {
  components: {
    trainingHeader,
    trainingFooter,
    trainingFaq,
    trainingFaq,
  },
  data() {
    return {
      facilitatorObj: {},
      appConfig: {},
      user: "",
      name: "",
      email: "",
      country: "India",
      designation: "",
      courseId: "",
      countries: [
        { Code: "AF", Name: "Afghanistan" },
        { Code: "AX", Name: "\u00c5land Islands" },
        { Code: "AL", Name: "Albania" },
        { Code: "DZ", Name: "Algeria" },
        { Code: "AS", Name: "American Samoa" },
        { Code: "AD", Name: "Andorra" },
        { Code: "AO", Name: "Angola" },
        { Code: "AI", Name: "Anguilla" },
        { Code: "AQ", Name: "Antarctica" },
        { Code: "AG", Name: "Antigua and Barbuda" },
        { Code: "AR", Name: "Argentina" },
        { Code: "AM", Name: "Armenia" },
        { Code: "AW", Name: "Aruba" },
        { Code: "AU", Name: "Australia" },
        { Code: "AT", Name: "Austria" },
        { Code: "AZ", Name: "Azerbaijan" },
        { Code: "BS", Name: "Bahamas" },
        { Code: "BH", Name: "Bahrain" },
        { Code: "BD", Name: "Bangladesh" },
        { Code: "BB", Name: "Barbados" },
        { Code: "BY", Name: "Belarus" },
        { Code: "BE", Name: "Belgium" },
        { Code: "BZ", Name: "Belize" },
        { Code: "BJ", Name: "Benin" },
        { Code: "BM", Name: "Bermuda" },
        { Code: "BT", Name: "Bhutan" },
        { Code: "BO", Name: "Bolivia, Plurinational State of" },
        { Code: "BQ", Name: "Bonaire, Sint Eustatius and Saba" },
        { Code: "BA", Name: "Bosnia and Herzegovina" },
        { Code: "BW", Name: "Botswana" },
        { Code: "BV", Name: "Bouvet Island" },
        { Code: "BR", Name: "Brazil" },
        { Code: "IO", Name: "British Indian Ocean Territory" },
        { Code: "BN", Name: "Brunei Darussalam" },
        { Code: "BG", Name: "Bulgaria" },
        { Code: "BF", Name: "Burkina Faso" },
        { Code: "BI", Name: "Burundi" },
        { Code: "KH", Name: "Cambodia" },
        { Code: "CM", Name: "Cameroon" },
        { Code: "CA", Name: "Canada" },
        { Code: "CV", Name: "Cape Verde" },
        { Code: "KY", Name: "Cayman Islands" },
        { Code: "CF", Name: "Central African Republic" },
        { Code: "TD", Name: "Chad" },
        { Code: "CL", Name: "Chile" },
        { Code: "CN", Name: "China" },
        { Code: "CX", Name: "Christmas Island" },
        { Code: "CC", Name: "Cocos (Keeling) Islands" },
        { Code: "CO", Name: "Colombia" },
        { Code: "KM", Name: "Comoros" },
        { Code: "CG", Name: "Congo" },
        { Code: "CD", Name: "Congo, the Democratic Republic of the" },
        { Code: "CK", Name: "Cook Islands" },
        { Code: "CR", Name: "Costa Rica" },
        { Code: "CI", Name: "C\u00f4te d'Ivoire" },
        { Code: "HR", Name: "Croatia" },
        { Code: "CU", Name: "Cuba" },
        { Code: "CW", Name: "Cura\u00e7ao" },
        { Code: "CY", Name: "Cyprus" },
        { Code: "CZ", Name: "Czech Republic" },
        { Code: "DK", Name: "Denmark" },
        { Code: "DJ", Name: "Djibouti" },
        { Code: "DM", Name: "Dominica" },
        { Code: "DO", Name: "Dominican Republic" },
        { Code: "EC", Name: "Ecuador" },
        { Code: "EG", Name: "Egypt" },
        { Code: "SV", Name: "El Salvador" },
        { Code: "GQ", Name: "Equatorial Guinea" },
        { Code: "ER", Name: "Eritrea" },
        { Code: "EE", Name: "Estonia" },
        { Code: "ET", Name: "Ethiopia" },
        { Code: "FK", Name: "Falkland Islands (Malvinas)" },
        { Code: "FO", Name: "Faroe Islands" },
        { Code: "FJ", Name: "Fiji" },
        { Code: "FI", Name: "Finland" },
        { Code: "FR", Name: "France" },
        { Code: "GF", Name: "French Guiana" },
        { Code: "PF", Name: "French Polynesia" },
        { Code: "TF", Name: "French Southern Territories" },
        { Code: "GA", Name: "Gabon" },
        { Code: "GM", Name: "Gambia" },
        { Code: "GE", Name: "Georgia" },
        { Code: "DE", Name: "Germany" },
        { Code: "GH", Name: "Ghana" },
        { Code: "GI", Name: "Gibraltar" },
        { Code: "GR", Name: "Greece" },
        { Code: "GL", Name: "Greenland" },
        { Code: "GD", Name: "Grenada" },
        { Code: "GP", Name: "Guadeloupe" },
        { Code: "GU", Name: "Guam" },
        { Code: "GT", Name: "Guatemala" },
        { Code: "GG", Name: "Guernsey" },
        { Code: "GN", Name: "Guinea" },
        { Code: "GW", Name: "Guinea-Bissau" },
        { Code: "GY", Name: "Guyana" },
        { Code: "HT", Name: "Haiti" },
        { Code: "HM", Name: "Heard Island and McDonald Islands" },
        { Code: "VA", Name: "Holy See (Vatican City State)" },
        { Code: "HN", Name: "Honduras" },
        { Code: "HK", Name: "Hong Kong" },
        { Code: "HU", Name: "Hungary" },
        { Code: "IS", Name: "Iceland" },
        { Code: "IN", Name: "India" },
        { Code: "ID", Name: "Indonesia" },
        { Code: "IR", Name: "Iran, Islamic Republic of" },
        { Code: "IQ", Name: "Iraq" },
        { Code: "IE", Name: "Ireland" },
        { Code: "IM", Name: "Isle of Man" },
        { Code: "IL", Name: "Israel" },
        { Code: "IT", Name: "Italy" },
        { Code: "JM", Name: "Jamaica" },
        { Code: "JP", Name: "Japan" },
        { Code: "JE", Name: "Jersey" },
        { Code: "JO", Name: "Jordan" },
        { Code: "KZ", Name: "Kazakhstan" },
        { Code: "KE", Name: "Kenya" },
        { Code: "KI", Name: "Kiribati" },
        { Code: "KP", Name: "Korea, Democratic People's Republic of" },
        { Code: "KR", Name: "Korea, Republic of" },
        { Code: "KW", Name: "Kuwait" },
        { Code: "KG", Name: "Kyrgyzstan" },
        { Code: "LA", Name: "Lao People's Democratic Republic" },
        { Code: "LV", Name: "Latvia" },
        { Code: "LB", Name: "Lebanon" },
        { Code: "LS", Name: "Lesotho" },
        { Code: "LR", Name: "Liberia" },
        { Code: "LY", Name: "Libya" },
        { Code: "LI", Name: "Liechtenstein" },
        { Code: "LT", Name: "Lithuania" },
        { Code: "LU", Name: "Luxembourg" },
        { Code: "MO", Name: "Macao" },
        { Code: "MK", Name: "Macedonia, the Former Yugoslav Republic of" },
        { Code: "MG", Name: "Madagascar" },
        { Code: "MW", Name: "Malawi" },
        { Code: "MY", Name: "Malaysia" },
        { Code: "MV", Name: "Maldives" },
        { Code: "ML", Name: "Mali" },
        { Code: "MT", Name: "Malta" },
        { Code: "MH", Name: "Marshall Islands" },
        { Code: "MQ", Name: "Martinique" },
        { Code: "MR", Name: "Mauritania" },
        { Code: "MU", Name: "Mauritius" },
        { Code: "YT", Name: "Mayotte" },
        { Code: "MX", Name: "Mexico" },
        { Code: "FM", Name: "Micronesia, Federated States of" },
        { Code: "MD", Name: "Moldova, Republic of" },
        { Code: "MC", Name: "Monaco" },
        { Code: "MN", Name: "Mongolia" },
        { Code: "ME", Name: "Montenegro" },
        { Code: "MS", Name: "Montserrat" },
        { Code: "MA", Name: "Morocco" },
        { Code: "MZ", Name: "Mozambique" },
        { Code: "MM", Name: "Myanmar" },
        { Code: "NA", Name: "Namibia" },
        { Code: "NR", Name: "Nauru" },
        { Code: "NP", Name: "Nepal" },
        { Code: "NL", Name: "Netherlands" },
        { Code: "NC", Name: "New Caledonia" },
        { Code: "NZ", Name: "New Zealand" },
        { Code: "NI", Name: "Nicaragua" },
        { Code: "NE", Name: "Niger" },
        { Code: "NG", Name: "Nigeria" },
        { Code: "NU", Name: "Niue" },
        { Code: "NF", Name: "Norfolk Island" },
        { Code: "MP", Name: "Northern Mariana Islands" },
        { Code: "NO", Name: "Norway" },
        { Code: "OM", Name: "Oman" },
        { Code: "PK", Name: "Pakistan" },
        { Code: "PW", Name: "Palau" },
        { Code: "PS", Name: "Palestine, State of" },
        { Code: "PA", Name: "Panama" },
        { Code: "PG", Name: "Papua New Guinea" },
        { Code: "PY", Name: "Paraguay" },
        { Code: "PE", Name: "Peru" },
        { Code: "PH", Name: "Philippines" },
        { Code: "PN", Name: "Pitcairn" },
        { Code: "PL", Name: "Poland" },
        { Code: "PT", Name: "Portugal" },
        { Code: "PR", Name: "Puerto Rico" },
        { Code: "QA", Name: "Qatar" },
        { Code: "RE", Name: "R\u00e9union" },
        { Code: "RO", Name: "Romania" },
        { Code: "RU", Name: "Russian Federation" },
        { Code: "RW", Name: "Rwanda" },
        { Code: "BL", Name: "Saint Barth\u00e9lemy" },
        { Code: "SH", Name: "Saint Helena, Ascension and Tristan da Cunha" },
        { Code: "KN", Name: "Saint Kitts and Nevis" },
        { Code: "LC", Name: "Saint Lucia" },
        { Code: "MF", Name: "Saint Martin (French part)" },
        { Code: "PM", Name: "Saint Pierre and Miquelon" },
        { Code: "VC", Name: "Saint Vincent and the Grenadines" },
        { Code: "WS", Name: "Samoa" },
        { Code: "SM", Name: "San Marino" },
        { Code: "ST", Name: "Sao Tome and Principe" },
        { Code: "SA", Name: "Saudi Arabia" },
        { Code: "SN", Name: "Senegal" },
        { Code: "RS", Name: "Serbia" },
        { Code: "SC", Name: "Seychelles" },
        { Code: "SL", Name: "Sierra Leone" },
        { Code: "SG", Name: "Singapore" },
        { Code: "SX", Name: "Sint Maarten (Dutch part)" },
        { Code: "SK", Name: "Slovakia" },
        { Code: "SI", Name: "Slovenia" },
        { Code: "SB", Name: "Solomon Islands" },
        { Code: "SO", Name: "Somalia" },
        { Code: "ZA", Name: "South Africa" },
        { Code: "GS", Name: "South Georgia and the South Sandwich Islands" },
        { Code: "SS", Name: "South Sudan" },
        { Code: "ES", Name: "Spain" },
        { Code: "LK", Name: "Sri Lanka" },
        { Code: "SD", Name: "Sudan" },
        { Code: "SR", Name: "Suriname" },
        { Code: "SJ", Name: "Svalbard and Jan Mayen" },
        { Code: "SZ", Name: "Swaziland" },
        { Code: "SE", Name: "Sweden" },
        { Code: "CH", Name: "Switzerland" },
        { Code: "SY", Name: "Syrian Arab Republic" },
        { Code: "TW", Name: "Taiwan, Province of China" },
        { Code: "TJ", Name: "Tajikistan" },
        { Code: "TZ", Name: "Tanzania, United Republic of" },
        { Code: "TH", Name: "Thailand" },
        { Code: "TL", Name: "Timor-Leste" },
        { Code: "TG", Name: "Togo" },
        { Code: "TK", Name: "Tokelau" },
        { Code: "TO", Name: "Tonga" },
        { Code: "TT", Name: "Trinidad and Tobago" },
        { Code: "TN", Name: "Tunisia" },
        { Code: "TR", Name: "Turkey" },
        { Code: "TM", Name: "Turkmenistan" },
        { Code: "TC", Name: "Turks and Caicos Islands" },
        { Code: "TV", Name: "Tuvalu" },
        { Code: "UG", Name: "Uganda" },
        { Code: "UA", Name: "Ukraine" },
        { Code: "AE", Name: "United Arab Emirates" },
        { Code: "GB", Name: "United Kingdom" },
        { Code: "US", Name: "United States" },
        { Code: "UM", Name: "United States Minor Outlying Islands" },
        { Code: "UY", Name: "Uruguay" },
        { Code: "UZ", Name: "Uzbekistan" },
        { Code: "VU", Name: "Vanuatu" },
        { Code: "VE", Name: "Venezuela, Bolivarian Republic of" },
        { Code: "VN", Name: "Viet Nam" },
        { Code: "VG", Name: "Virgin Islands, British" },
        { Code: "VI", Name: "Virgin Islands, U.S." },
        { Code: "WF", Name: "Wallis and Futuna" },
        { Code: "EH", Name: "Western Sahara" },
        { Code: "YE", Name: "Yemen" },
        { Code: "ZM", Name: "Zambia" },
        { Code: "ZW", Name: "Zimbabwe" },
      ],
    };
  },
  methods: {
    getUser() {
      this.$root.loading = true;
      ApiServices.RestGetCall("api/avenir/training/getTrainingUser", {}).then(
        (res) => {
          console.log(res);
          this.$root.loading = false;
          this.user = res.traingUser;
          this.name = res.traingUser.name;
          this.email = res.traingUser.email;
          this.designation = res.traingUser.designation;
          //this.numberOfAttempts = res.traingUser.numberOfAttempts
          this.numberOfAttempts = 0;
          //this.getCountries()
        }
      );
    },
    addUser() {
      if (this.facilitatorObj.course[0].examRetake < this.numberOfAttempts) {
        alert(
          "You have exceeded no. of attempts. Please contact the administrator."
        );
        // this.$router.push({name:"exam-id",params:{facilitatorObj:this.facilitatorObj,appConfig:this.appConfig}})
      } else {
        this.$root.loading = true;
        let obj = {
          email: this.email,
          courseId: this.courseId,
          numberOfAttempts: this.numberOfAttempts,
          country: this.country,
          designation: this.designation,
          name: this.name,
        };
        localStorage.setItem("userDetails", JSON.stringify(obj));
        //  this.$router.push("/question-list");
        this.$router.push({
          name: "question-list",
          params: {
            facilitatorObj: this.facilitatorObj,
            appConfig: this.appConfig,
          },
        });
      }
    },
  },
  mounted() {
    this.getUser();
    if (this.$route.params.facilitatorObj) {
      this.facilitatorObj = this.$route.params.facilitatorObj;
    } else {
      this.facilitatorObj = JSON.parse(localStorage.getItem("facilitatorObj"));
    }
    if (this.$route.params.appConfig) {
      this.appConfig = this.$route.params.appConfig;
    } else {
      this.appConfig = JSON.parse(localStorage.getItem("appConfig"));
    }
    this.courseId = this.facilitatorObj.course[0].courseId;
  },
};
</script>
<style scoped lang="scss">
.x_panel {
  background-color: #fff;
  border: 1px solid #ececec;
  padding: 0;
  position: relative;
}
.x_title {
  border-bottom: 0px solid #e6e9ed;
  padding: 15px 18px;
  background-color: #b0b0b0;
  color: #fff !important;
  border-radius: 7px 7px 0 0;
}
.x_content {
  padding: 25px 25px;
}
.button-div {
  display: flex;
  justify-content: center;
  margin-bottom: 4%;
}
.dashboard-widget-content {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 350px; */
}
.user-details-div {
  width: 1000px;
}
.user-details-div form {
  padding-left: 50px;
  padding-right: 50px;
}
.user-details-div p {
  font-size: 16px;
  text-align: center;
  margin-bottom: 5%;
  font-weight: 400;
}
.form-horizontal .control-label {
  padding-top: 8px;
  text-align: justify;
  font-size: 14px;
  font-weight: 300;
}
.right_col {
  height: calc(100vh - 130px) !important;
  justify-content: center;
  align-items: center;
  display: flex;
}
.examInstBody {
  /* height: calc(100vh - 430px);
      overflow: hidden;
      overflow-y: scroll; */
  ul {
    padding-left: 18px;
    li {
      padding-bottom: 8px;
    }
  }
}
.examInst li {
  font-size: 1.1em;
  line-height: 1.6;
}
.backgroundbg-color {
  box-shadow: 0 -40px 0px #ececec, -40px -40px 0px #ececec,
    40px -40px 0px #ececec;
  position: relative;
}
.heading-title-card {
  position: absolute;
  z-index: 1;
  top: -65px;
  background: #343434;
  padding: 2px 12px;
}
.floating-submit-btn {
  padding: 8px 40px;
  position: absolute;
  right: 0;
  bottom: -55px;
  z-index: 1;
}
.yellow-float-btn {
  width: 200px;
  display: flex;
  justify-content: flex-end;
  background: #ffc816;
  color: #343434;
  font-weight: 600;
  padding-right: 15px;
  border: 2px solid;
}
.text-sectiomn-wrap {
  height: 100%;
  min-height: calc(100vh - 225px);
  overflow: hidden;
  overflow-y: scroll;
  /* background: red; */
  padding-top: 7rem;
}
</style>
