<template>
  <div class="body">
    <div class="main_container">
      <training-header></training-header>

      <!-- page content -->
      <div class="right_col" role="main" style="margin-left: 2px;">
        <div class="row px-4 pt-4">
          <div class="col-md-12 col-sm-12 col-xs-12">
             <div class="x_panel step_panel" style="background: #393939 !important;border-radius: 10px 10px 0 0;">
              <div class="x_content" style="padding: 20px 25px;">
                <div class="process_steps">
                  <ul id="progress">
                    <li class="active"><span>Download</span></li>
                    <li class="active"><span>Customize</span></li>
                    <li class="active"><span>Complete</span></li>
                  </ul>
                  
                </div>
              </div>
            </div>
            <div class="x_panel">
              <div class="x_content">
                <div class="process_steps">
                  <ul class="steps">
                    <li class="active">
                      <div>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span>Download</span>
                      </div>
                    </li>
                    <li class="active">
                      <div>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span>Customize</span>
                      </div>
                    </li>
                    <li class="active">
                      <div>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span>Upload</span>
                      </div>
                    </li>
                    <li>
                      <div>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span>Complete</span>
                      </div>
                    </li>
                    
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="x_panel">
              <div class="x_title">
                <h2 style="font-weight: 600;">Upload Agenda</h2>
                
                <div class="clearfix"></div>
              </div>
              <div class="x_content">
                <div class="dashboard-widget-content">
                  <form
                    id="dataUploadfrm"
                    class="filefrm_upload form-horizontal"
                    style=" margin: 0 auto;"
                    enctype="multipart/form-data"
                    method="post"
                  >
                    <div class="col-xs-12 col-sm-12 col-md-12">
                      <div class="pull-right">
                        <button class="btn btn-default">
                          <i class="fa fa-bug" aria-hidden="true"></i> Report Issue
                        </button>
                      </div>
                      <br />
                      <div class="upload_section">
                        <span class="fa fa-cloud-upload" aria-hidden="true"></span>
                        <p
                          style="text-align: center;font-size: 18px;color: #888;margin: 2% auto 6%;"
                        >Drag & Drop a backup to Import it</p>
                        <div>
                          <span class="control-fileupload">
                            <!-- <label for="file">Choose a file :</label> -->
                            <input type="file" id="file" />
                          </span>
                        </div>
                        <div class="button-sect" style="margin-top: 3%;">
                          <button class="btn btn-primary">Upload</button>
                          <button class="btn btn-default">Reset</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div style="text-align: center; margin-bottom: 3%;margin-top: 1%; padding-bottom:5%;">
                  <a href="#/facilitator-course" class="btn-bottom" style="margin-right: 5%;">
                    <i
                      class="fa fa-angle-double-left"
                      aria-hidden="true"
                      style="padding-right: 1%;"
                    ></i> Previous
                  </a>

                  <a
                    href="#/training-agenda"
                    class="btn-bottom"
                    role="button"
                    style="color: #50abc9;"
                  >
                    Next
                    <i
                      class="fa fa-angle-double-right"
                      aria-hidden="true"
                      style="padding-left: 1%;"
                    ></i>
                  </a>

                  <!-- <a href="login.html" class="btn btn3d btn-cust1" role="button">Skip to Login</a> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /page content -->

      <!-- footer content -->
      <training-footer></training-footer>
      <!-- /footer content -->
    </div>
  </div>
</template>
<script>
import trainingHeader from '../components/trainingHeader';
import trainingFooter from '../components/trainingFooter';
export default {
    components: {
        trainingHeader, 
        trainingFooter
    },
}
</script>
<style scoped>
    .x_panel {
        background-color: #fff;
        border: 0px solid transparent;
        padding:0;
        border-radius: 7px;
    }
    .x_title {
        border-bottom: 0px solid #E6E9ED;
        padding: 15px 18px;
        background-color: #b0b0b0;
        color: #fff;
        border-radius: 7px 7px 0 0;
        position: relative;
    }
    .x_content {
        padding: 0 25px 6px;
    }
    .download-btn{
        position: absolute;
        right: 18px;
    }
</style>