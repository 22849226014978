import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import CreateAccount from "../views/CreateAccount";
import wizard from "../views/wizard";
import profile from "../views/profile";
import organisationUnit from "../views/organisationUnit";
import selectApp from "../views/selectApp";
import selectPlan from "../views/selectPlan";
import generateExamId from "../views/generateExamId";
import questionsList from "../views/questionsList";
import scoreBoard from "../views/scoreBoard";
import reviewExam from "../views/reviewExam";
import certificate from "../views/certificate";
import facilitator from "../views/facilitator";
import facilitatorPage from "../views/facilitatorPage";
import facilitatorMenu from "../views/facilitatorMenu";
import resourcesSession from "../views/resourcesSession";
import facilitatorCourse from "../views/facilitatorCourse";
import agendaDoc from "../views/agendaDoc";
import uploadAgenda from "../views/uploadAgenda";
import trainingAgenda from "../views/trainingAgenda";
import userRoles from "../views/userRoles";
import certificateStatus from "../views/certificateStatus";
import selectPlanNepal from "../views/selectPlanNepal";
import selectAdmin from "../views/selectAdmin";
import training_wizard from "../views/training_wizard";
import trainingFaq from "../views/trainingFaq";
import adminDatatable from "../views/adminDatatable";
import feedbackForm from "../views/feedbackForm";
import userRoleForm from "../views/userRoleForm";
import HomeNew from "../views/HomeNew.vue";
import HomeMod from "../views/homeMod.vue";
import videoCoursePage from "../views/videoCoursePage";
Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "homeMod",
		component: HomeMod,
		meta: { title: "Sign in - FP-LMIS E-Learning" },
	},
	{
		path: "/homeNew",
		name: "homeNew",
		component: HomeNew,
		meta: { title: "Sign in - FP-LMIS E-Learning" },
	},
	{
		path: "/create-account",
		name: "createaccount",
		component: HomeMod,
		meta: { title: "Create Account - FP-LMIS E-Learning" },
	},
	{
		path: "/activate",
		name: "activate",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		// component: () => import("../views/Activation.vue"),
		component: HomeMod,
		meta: { title: "Activate Account - FP-LMIS E-Learning" },
	},
	{
		path: "/wizard",
		name: "wizard",
		component: wizard,
		meta: { title: "Application Configuration - FP-LMIS E-Learning" },
	},
	{
		path: "/training-wizard",
		name: "training_wizard",
		component: training_wizard,
		meta: { title: "Application Configuration - FP-LMIS E-Learning" },
	},
	{
		path: "/profile",
		name: "profile",
		component: profile,
		meta: { title: "Profile - FP-LMIS E-Learning" },
	},
	{
		path: "/organisationUnit",
		name: "organisationUnit",
		component: organisationUnit,
		meta: { title: "Application Configuration - FP-LMIS E-Learning" },
	},
	{
		path: "/training-dashboard",
		name: "training-dashboard",
		component: selectApp,
		meta: { title: "Training Dashboard - FP-LMIS E-Learning" },
	},
	{
		path: "/select-module",
		name: "select-module",
		component: selectPlan,
		meta: { title: "Dashboard - FP-LMIS E-Learning" },
	},
	{
		path: "/select-admin",
		name: "select-admin",
		component: selectAdmin,
		meta: { title: "Application Configuration - FP-LMIS E-Learning" },
	},
	{
		path: "/select-plan-nepal",
		name: "select-plan-nepal",
		component: selectPlanNepal,
		meta: { title: "Application Configuration - FP-LMIS E-Learning" },
	},
	{
		path: "/exam-id",
		name: "exam-id",
		component: generateExamId,
		meta: { title: "Course Test - FP-LMIS E-Learning" },
	},
	{
		path: "/question-list",
		name: "question-list",
		component: questionsList,
		meta: { title: "Course Test - FP-LMIS E-Learning" },
	},
	{
		path: "/score-board",
		name: "score-board",
		component: scoreBoard,
		meta: { title: "Score Board - FP-LMIS E-Learning" },
	},
	{
		path: "/review-exam",
		name: "review-exam",
		component: reviewExam,
		meta: { title: "Review Course Test - FP-LMIS E-Learning" },
	},
	{
		path: "/certificate",
		name: "certificate",
		component: certificate,
		meta: { title: "Certificate - FP-LMIS E-Learning" },
	},
	{
		path: "/facilitator",
		name: "facilitator",
		component: facilitator,
		meta: { title: "Facilitator - FP-LMIS E-Learning" },
	},
	{
		path: "/facilitator-page",
		name: "facilitator-page",
		component: facilitatorPage,
		meta: { title: "Training Planning - FP-LMIS E-Learning" },
	},
	{
		path: "/course",
		name: "course",
		component: videoCoursePage,
		meta: { title: "Courses - FP-LMIS E-Learning" },
	},
	{
		path: "/facilitator-menu",
		name: "facilitator-menu",
		component: facilitatorMenu,
		meta: { title: "Facilitator Menu - FP-LMIS E-Learning" },
	},
	{
		path: "/resources-session",
		name: "resources-session",
		component: resourcesSession,
		meta: { title: "Resources Session - FP-LMIS E-Learning" },
	},
	{
		path: "/facilitator-course",
		name: "facilitator-course",
		component: facilitatorCourse,
		meta: { title: "Customize Course - FP-LMIS E-Learning" },
	},
	{
		path: "/agenda-doc",
		name: "agenda-doc",
		component: agendaDoc,
		meta: { title: "Agenda - FP-LMIS E-Learning" },
	},
	{
		path: "/upload-agenda",
		name: "upload-agenda",
		component: uploadAgenda,
		meta: { title: "Upload Agenda - FP-LMIS E-Learning" },
	},
	{
		path: "/training-agenda",
		name: "training-agenda",
		component: trainingAgenda,
		meta: { title: "Training Agenda - FP-LMIS E-Learning" },
	},
	{
		path: "/user-roles",
		name: "user-roles",
		component: userRoles,
		meta: { title: "User Roles - FP-LMIS E-Learning" },
	},
	{
		path: "/certificate-status",
		name: "certificate-status",
		component: certificateStatus,
		meta: { title: "Certification Status - FP-LMIS E-Learning" },
	},
	{
		path: "/training-faq",
		name: "training-faq",
		component: trainingFaq,
		meta: { title: "Application Configuration - FP-LMIS E-Learning" },
	},
	{
		path: "/manage-requests",
		name: "manage-requests",
		component: adminDatatable,
		meta: { title: "Manage Requests - FP-LMIS E-Learning" },
	},
	{
		path: "/feedback",
		name: "feedback",
		component: feedbackForm,
		meta: { title: "Application Configuration - FP-LMIS E-Learning" },
	},
	{
		path: "/manage_user_roles",
		name: "manage_user_roles",
		component: userRoleForm,
		meta: { title: "Manage User Roles - FP-LMIS E-Learning" },
	},
];

const router = new VueRouter({
	//mode: 'history',
	routes,
});

export default router;
