<template>
  <div>
    <div class="mdgridviewpage-bg">
      <div class="container-fluid mt-2">
        <div class="row">
          <div class="col-lg-12" style="padding: 0 !important">
            <div id="header">
              <nav class="navbar navbar-expand-lg navbar-light bg-light">
                <a class="nav-brand d-flex minText" href="#">
                  <img
                    src="../assets/img/fplims/logo_mohfw_1.png"
                    class="py-3"
                  />
                </a>

                <div
                  class="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul class="navbar-nav mr-auto">
                    <li class="nav-item active">
                      <a class="nav-link" href="#"></a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid px-5">
        <div
          class="row dashboardchart-container globalconfigcontainer loginForm"
        >
          <div class="col-lg-4 pt-5" style="">
            <h4 class="formTitle">E-Learning Portal For FP-LMIS <br>Ministry of Health & Family Welfare </br> Government of India</h4>
            <div class="card m-b-20px m-t-10px">
              <div class="collapse show" aria-labelledby="headingOne">
                <ValidationObserver v-slot="{ handleSubmit }">
                  <div class="card-body c-body px-0">
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group text-right">
                          <a
                            class="text-dark-yellow"
                            @click="GotoCreateAccount()"
                            style="
                              font-size: 14px;
                              border-bottom: 1px solid #595959;
                            "
                            >Request for Registration</a
                          >
                        </div>
                      </div>
                    </div>
                    <form
                      class="d-flex"
                      @submit.prevent="handleSubmit(onSubmit)"
                    >
                      <div class="row col-lg-12" style="margin: 0 auto">
                        <div class="mainform-holder w-100">
                          <div class="col-lg-12">
                            <div class="form-group row">
                              <label
                                for="inputContactEmail"
                                class="col-sm-3 col-form-label text-right"
                                >Email</label
                              >
                              <div class="col-sm-9">
                                <ValidationProvider
                                  name="E-mail"
                                  rules="required"
                                  v-slot="{ errors }"
                                >
                                  <input
                                    v-model="email"
                                    type="text"
                                    class="form-control bg-grey"
                                    id="inputContactEmail"
                                    placeholder=""
                                  />
                                  <span class="text-danger">{{
                                    errors[0]
                                  }}</span>
                                </ValidationProvider>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12">
                            <div class="form-group row mb-0">
                              <label
                                for="inputContactEmailPassword"
                                class="col-sm-3 col-form-label text-right"
                                >Password</label
                              >
                              <div class="col-sm-9">
                                <ValidationProvider
                                  name="Password"
                                  rules="required"
                                  v-slot="{ errors }"
                                >
                                  <div class="input-group">
                                    <input
                                      v-model="password"
                                      type="password"
                                      class="form-control bg-grey"
                                      id="inputContactEmailPassword"
                                      placeholder=""
                                    />
                                    <div class="input-group-append">
                                      <span
                                        @click="showPass"
                                        class="input-group-text fa bg-grey"
                                        v-bind:class="[
                                          passActive
                                            ? 'fa-eye'
                                            : 'fa-eye-slash',
                                        ]"
                                      ></span>
                                    </div>
                                  </div>
                                  <span class="text-danger">{{
                                    errors[0]
                                  }}</span>
                                </ValidationProvider>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12 mt-3">
                            <div class="row">
                              <div class="col-sm-12">
                                <div class="row">
                                  <div class="col-6 d-none">
                                    <div class="form-group row mb-0">
                                      <div class="col-sm-12 text-left m-t-20px">
                                        <form>
                                          <div class="form-check">
                                            <label
                                              class="form-check-label m-t-20px"
                                              for="check1"
                                            >
                                              <input
                                                type="checkbox"
                                                class="form-check-input"
                                                id="check1"
                                                name="option1"
                                                value="something"
                                              />
                                              Remember Me
                                            </label>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-6"></div>
                                  <div class="col-6">
                                    <div class="form-group row mb-0">
                                      <div class="col-sm-12 text-left">
                                        <button
                                          type="submit"
                                          class="btn red-button mx-0"
                                        >
                                          Login
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-3"></div>
                                  <div class="col-5 mt-2">
                                    <div class="form-group row mb-0">
                                      <div class="col-sm-12 text-left m-t-20px">
                                        <a
                                          class="text-dark-yellow"
                                          @click="goToActivateAccount"
                                          style="
                                            font-size: 14px;
                                            border-bottom: 1px solid #595959;
                                          "
                                          >Activate Account</a
                                        >
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-4 mt-2">
                                    <div
                                      class="form-group row mb-0 float-right"
                                    >
                                      <div class="col-sm-12 m-t-20px">
                                        <a
                                          class="text-dark-yellow"
                                          @click="
                                            $bvModal.show('forgotPasswordModal')
                                          "
                                          v-b-modal.modal-center
                                          style="
                                            font-size: 14px;
                                            border-bottom: 1px solid #595959;
                                          "
                                          >Forgot Password</a
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </ValidationObserver>
              </div>
            </div>
          </div>
          <div class="col-lg-2"></div>
          <div class="col-lg-6">
            <div>
              <img
                src="../assets/img/fplims/logo_hum_do.png"
                class="d-block m-auto"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="forgotPasswordModal" centered>
      <template v-slot:modal-title> Forgot Password </template>
      <template v-slot:default>
        <ValidationObserver ref="frmForgotPassword">
          <div class="col-lg-12">
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">* E-mail</label>
              <div class="col-sm-8">
                <ValidationProvider
                  name="Email"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <input
                    v-model="forgotEmail"
                    type="email"
                    class="form-control bg-grey"
                    placeholder="Enter Email"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </ValidationObserver>
      </template>
      <template v-slot:modal-footer>
        <button
          type="button"
          class="btn yellow-float-btn w-auto justify-content-center"
          @click="$bvModal.hide('forgotPasswordModal')"
        >
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary border-radius-0"
          @click="forgotPassword()"
          style="background: #ed2226; border: 2px solid #000"
        >
          Save
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import ApiServices from "../service/ApiServices";
import trainingHeader from "../components/trainingHeader";
import trainingFooter from "../components/trainingFooter";

extend("required", {
  ...required,
  message: "This field is required",
});

export default {
  name: "home",
  props: {},
  components: {
    trainingHeader,
    trainingFooter,
  },
  data() {
    return {
      passActive: true,
      email: "",
      password: "",
      forgotEmail: "",
      settings: settings,
      baseUrl: "http://localhost:8080/dhis/",
      dashboardUrl: "api/apps/Avenir-Generic/Avenir_Generic/index.html#/",
    };
  },
  methods: {
    showPass() {
      this.passActive = !this.passActive;
      let passwordField = document.querySelector("#inputContactEmailPassword");
      if (passwordField.getAttribute("type") === "password") {
        passwordField.setAttribute("type", "text");
      } else {
        passwordField.setAttribute("type", "password");
      }
    },
    GotoCreateAccount() {
      this.$router.push("/create-account");
    },
    goToActivateAccount() {
      this.$router.push("/activate");
    },
    onSubmit() {
      this.$root.loading = true;
      this.baseUrl = ApiServices.getBaseUrl();
      this.dashboardUrl = this.baseUrl + this.dashboardUrl;
      //let headers = {Authorization: 'Basic YWRtaW46ZGlzdHJpY3Q='};
      let authorizationToken =
        "Basic " + btoa(this.email + ":" + this.password);
      localStorage.setItem("authToken", authorizationToken);
      ApiServices.RestPostCall("api/avenir/login", {
        username: this.email,
        password: this.password,
      })
        .then((res) => {
          console.log(res);
          this.$root.loading = false;
          if (res.status.toLocaleLowerCase() == "success") {
            // ApiServices.RestGetCall('/api/avenir/training/getAppDetails',{'userId':this.email}).then(aveT => {
            //   console.log(aveT);
            // localStorage.setItem("avenirTraining",JSON.stringify(aveT));
            localStorage.setItem("userProfile", JSON.stringify(res));
            localStorage.setItem("loggedInUserId", res.useruid);
            //console.log(JSON.parse(localStorage.getItem("userProfile")));
            //this.$router.push("/wizard");
            if (settings.countryName.toLowerCase() === "nepal") {
              if (settings.userRole.includes(res.userrole)) {
                this.$router.push("/select-plan-nepal");
              } else {
                window.open(this.dashboardUrl, "_blank");
              }
            } else if (
              res.userroleid === "65042" ||
              res.userroleid === "64975"
            ) {
              this.$router.push("/training-dashboard");
            } else {
              this.$router.push("/select-module");
            }
            //  });
          } else {
            this.$swal({
              icon: "error",
              text: res.message,
            });
            // logout forcefully
            ApiServices.RestGetCall(
              "dhis-web-commons-security/logout.action",
              {}
            ).then((res) => {
              console.log(res);
            });
          }
        })
        .catch((error) => {
          this.$root.loading = false;
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            //console.log(error.response.headers);
            this.$swal({
              text: "incorrect username/password",
            });
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          // logout forcefully
          ApiServices.RestGetCall(
            "dhis-web-commons-security/logout.action",
            {}
          ).then((res) => {
            console.log(res);
          });
          //console.log(error.config);
        });
    },
    forgotPassword() {
      if (this.$refs.frmForgotPassword.validate()) {
        this.$root.loading = true;
        console.log(this.forgotEmail);
        //this.forgotEmail = "";
        ApiServices.RestPostCall("api/avenir/subscribe/password/forgot", {
          email: this.forgotEmail
        }).then((res) => {
          console.log(res);
          this.$swal({
              text: res.message
          })
          this.$bvModal.hide('forgotPasswordModal')
          this.$root.loading = false;
        })
        //this.$bvModal.hide('forgotPasswordModal')
      }
    },
  },
  created() {
    document.title = "Sign in - FP-LMIS E-Learning";
  }
};
</script>
<style scoped lang="scss">
.mdgridviewpage-bg {
  background-color: #ededed;
  background: url("../assets/img/fplims/login_bg.jpg") no-repeat;
  background-size: cover;
  background-position: top;
  min-height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

body.green-theme .mdgridviewpage-bg {
  background-color: #006600;
  background: url("../assets/img/green-bg.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  padding: 20px 40px;
}
.mainform-holder {
  /*background: #fff;
  -webkit-box-shadow: -2px 0px 5px 0px rgba(204, 204, 204, 1);
  -moz-box-shadow: -2px 0px 5px 0px rgba(204, 204, 204, 1);
  box-shadow: -2px 0px 5px 0px rgba(204, 204, 204, 1);
  border-radius: 10px;*/
  padding: 8px 5px;
}

.c-body {
  background-color: transparent;
}

.card {
  background-color: transparent;
  box-shadow: 0px 0px 0px 0px 4px rgb(186, 186, 186) !important;
  border-radius: 10px;
  border: 0px !important;
}
.form-control {
  background: #fff !important;
  color: #595959 !important;
  font-size: 14px !important;
  font-weight: 200;
  border: 1px solid #595959;
  border-radius: 6px !important;
}
a {
  cursor: pointer;
  color: rgb(121, 121, 121);
}
a:hover {
  text-decoration: none !important;
}
input:-internal-autofill-selected {
  background: #ddd !important;
  border-color: #f2f2f2 !important;
}
* {
  color: #4c4c4c;
}
label {
  font-weight: 200;
}
.card-header {
  border-bottom: 0px;
  border-radius: 10px 10px 0 0 !important;
  padding: 8px 12px !important;
}
.input-group {
  position: relative;
  margin-bottom: 0px;
}
.input-group-append {
  position: absolute;
  right: 4px;
  top: 4px;
  .input-group-text {
    background-color: transparent !important;
    border: transparent;
    z-index: 999;
    cursor: pointer;
    color: #7d7d7d;
    padding: 0.175rem 0.75rem;
  }
}
#inputContactEmailPassword {
  padding-right: 36px;
}
.globalconfigcontainer {
  display: flex;
  justify-content: center;
  // align-items: center;
  // height: 80vh;
}
.navbar {
  background: transparent !important;
}
.logoImage {
  display: flex;
  justify-content: center;
  position: absolute;
}
.logoImageDiv {
  position: relative;
  z-index: 9;
  top: -90px;
  padding: 10px 10px;
  left: 0;
  width: 175px;
  height: 80px;
  background-color: rgba(255, 255, 255, 0.78);
  border-top-left-radius: 110px;
  border-top-right-radius: 110px;
  /* border: 10px solid gray; */
  border-bottom: 0;
}
.logoImageDiv img {
  position: absolute;
  top: 26px;
  left: 36px;
}
.headerTitle {
  font-size: 2rem;
  height: 100%;
  display: flex;
  justify-content: center;
  // align-items: center;
}
.minText p {
  margin-bottom: 0;
  padding-top: 3%;
  font-size: 16px;
  margin-left: 0;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.minText p span {
  display: block;
  color: #000;
  font-size: 14px;
  font-weight: 500;
}
// #footer .c_div .c_logo{
//   width: 15%;
// }
footer {
  background-color: #f6f0f8;
}
.nav-brand:hover {
  text-decoration: none !important;
}
.loginForm {
  .formTitle {
    font-family: Montserrat-Black;
    font-size: 1.375rem;
    color: #42413c;
  }
  label {
    color: #42413c;
    font-weight: 400;
  }
}
</style>
