<template>
  <div id="header">
    <div class="container-fluid">
      <!-- First Navbar -->
      <div class="firstNav row" style="margin-right: -15px; margin-left: -15px">
        <ul
          class="nav col-6 pr-2 pl-4 py-2"
          v-if="this.$route.name == 'select-module' && isAdmin"
        >
          <li class="nav-item">
            <a class="nav-link" href="javascript:void(0)"
              ><i class="fa fa-home" aria-hidden="true"></i
            ></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="javascript:void(0)">
              <h4 class="formTitle mb-0">E-Learning Portal For FP-LMIS</h4>
            </a>
          </li>
        </ul>
        <ul
          class="nav col-6 pr-2 pl-4 py-2"
          v-else-if="this.$route.name == 'training-dashboard' && isAdmin"
        >
          <li class="nav-item">
            <a class="nav-link" href="#/select-module"
              ><i class="fa fa-home" aria-hidden="true"></i
            ></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#/select-module">
              <h4 class="formTitle mb-0">E-Learning Portal For FP-LMIS</h4>
            </a>
          </li>
        </ul>
        <ul class="nav col-6 pr-2 pl-4 py-2" v-else>
          <li class="nav-item">
            <a class="nav-link" href="#/training-dashboard"
              ><i class="fa fa-home" aria-hidden="true"></i
            ></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#/training-dashboard">
              <h4 class="formTitle mb-0">E-Learning Portal For FP-LMIS</h4>
            </a>
          </li>
        </ul>
        <ul class="col-6 d-flex justify-content-end nav pr-4 pl-2 py-2">
          <li class="nav-item" v-if="isAdminOptions">
            <a
              class="nav-link"
              href="#/manage-requests"
              v-b-tooltip.hover
              title="Manage Requests"
            >
              <i class="fa fa-file-text-o" aria-hidden="true"></i>
            </a>
          </li>
          <li class="nav-item" v-if="isAdminOptions">
            <a
              class="nav-link"
              href="#/manage_user_roles"
              v-b-tooltip.hover
              title="User Role"
            >
              <i class="fa fa-users" aria-hidden="true"></i>
            </a>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link" href="javascript:void(0)" v-b-tooltip.hover title="Preferences">
              <i class="fa fa-cog" aria-hidden="true"></i>
            </a>
          </li> -->
          <!-- <li class="nav-item">
            <a
              class="nav-link"
              href="javascript:void(0)"
              v-b-tooltip.hover
              title="Toggle Notifications Menu"
            >
              <i class="fa fa-bell" aria-hidden="true"></i>
            </a>
          </li> -->
          <li class="nav-item dropdown">
            <a
              class="dropdown-toggle nav-link"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              v-b-tooltip.hover
              title="User Settings"
            >
              <i class="fa fa-user" aria-hidden="true"></i>
            </a>
            <div class="dropdown-menu">
              <!-- <a
              class="dropdown-item"
              href="#/userrole-form"
            >
              <i class="fa fa-user pr-3" aria-hidden="true"></i>
              User Role
            </a> -->
              <a class="dropdown-item hide" href="javascript:void(0)" disabled
                ><i class="fa fa-pencil-square-o pr-3" aria-hidden="true"></i>
                Profile</a
              >
              <a class="dropdown-item" href="javascript:void(0)" @click="logout"
                ><i class="fa fa-sign-out pr-3" aria-hidden="true"></i>
                Logout</a
              >
            </div>
          </li>
        </ul>
      </div>
      <!-- first Navbar ends -->
      <!-- Second navbar -->
      <div class="secondNav">
        <nav
          class="navbar navbar-expand-md navbar-default landingpage-nav py-0"
        >
          <div class="col-4">
            <a
              class="nav-brand d-flex minText nav-link"
              href="javascript:void(0)"
              style="cursor: default;"
            >
              <img src="../assets/img/goi.png" class="img-fluid" />
            </a>
          </div>
          <div
            class="col-4"
            v-if="
              this.$route.name != 'select-module' &&
                this.$route.name != 'training-dashboard'
            "
          >
            <!-- <div class="middleHeading" v-if="this.$route.name != 'select-module' && this.$route.name != 'training-dashboard'">
              <h3 class="mainHeading">Harmonia Training Interface</h3>
              <p>A comprehensive information guide for the FP-LMIS</p>
            </div> -->
            <div
              class="middleHeading"
              v-if="
                this.$route.name == 'exam-id' ||
                  this.$route.name == 'question-list'
              "
            >
              <h3 class="mainHeading">Certified Solutions Expert Exam</h3>
            </div>
            <div
              class="middleHeading"
              v-else-if="this.$route.name == 'score-board'"
            >
              <h3 class="mainHeading">Certified Solutions Expert Exam</h3>
              <p
                class="btn btn-dark test-result-btn mainHeading text-white"
                style="font-size: 1.4rem; cursor: default;"
              >
                Test Result
              </p>
            </div>
            <div
              class="middleHeading"
              v-else-if="this.$route.name == 'certificate-status'"
            >
              <h3 class="mainHeading">Check Your Certification Status</h3>
            </div>
            <div class="middleHeading" v-else>
              <h3 class="mainHeading">Training Interface</h3>
              <p>A comprehensive information guide for the FP-LMIS</p>
            </div>
          </div>
          <div v-else class="col-4">
            <a
              class="nav-brand d-flex minText nav-link p-0"
              href="javascript:void(0)"
              style="cursor: default;"
            >
              <img
                src="../assets/img/logo2 (1).png"
                class="d-block m-auto img-fluid"
                style="width: 20%;"
              />
            </a>
          </div>
          <div
            class="collapse navbar-collapse navRight col-4"
            id="collapsibleNavbar"
            style="justify-content: flex-end"
            v-if="
              this.$route.name != 'home' &&
                this.$route.name != 'createaccount' &&
                this.$route.name != 'activate'
            "
          >
            <a
              class="nav-brand d-flex minText nav-link"
              href="javascript:void(0)"
              style="cursor: default;"
            >
              <img src="../assets/img/fplims/nhm_new.png" class="img-fluid" />
            </a>
          </div>
        </nav>
      </div>
      <!-- Second navbar ends-->
    </div>
  </div>
</template>
<script>
import ApiServices from "../service/ApiServices";
import swal from "sweetalert2";
export default {
  props: ["appConfig"],
  data() {
    return {
      baseUrl: "http://localhost:8080/dhis/",
      dashboardUrl: "api/apps/Avenir-Generic/index.html#/",
      loggedInUserProfile: {},
      isAdmin: false,
      isAdminOptions: false,
    };
  },
  methods: {
    logout() {
      //dhis-web-commons-security/logout.action
      localStorage.removeItem("userProfile");
      localStorage.removeItem("loggedInUserId");
      ApiServices.RestGetCall("api/avenir/logout", {}).then((res) => {
        console.log(res);
      });
      //window.open('http://intraavenir.duredemos.com/service/dhis-web-commons-security/logout.action', '_blank');
      this.$router.push("/");
    },
    checkCourseTest() {
      let buttonClick = localStorage.getItem("responseClick");
      //console.log(buttonClick)
      if (buttonClick == "Next") {
        Swal.fire({
          //title: 'Are you sure, you want to quit?',
          text: "Are you sure, you want to quit?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
        }).then((result) => {
          if (result.value) {
            this.$router.push("/training-dashboard");
          }
        });
      } else {
        this.$router.push("/training-dashboard");
      }
    },
  },
  mounted() {
    this.baseUrl = ApiServices.getBaseUrl();
    this.dashboardUrl = this.baseUrl + this.dashboardUrl;
    this.loggedInUserProfile = JSON.parse(localStorage.getItem("userProfile"));

    if (
      this.loggedInUserProfile.userroleid == 65042 ||
      this.loggedInUserProfile.userroleid == 64975
    ) {
      this.isAdmin = false;
    } else {
      this.isAdmin = true;
    }
    if (this.loggedInUserProfile.userroleid == 65042) {
      this.isAdminOptions = false;
    } else {
      this.isAdminOptions = true;
    }
  },
};
</script>
<style scoped lang="scss">
.navRight i.fa {
  font-size: 1.4rem;
  padding-top: 10%;
}
.navRight .dropdown-item i.fa {
  font-size: 1rem;
}
.firstNav {
  .nav {
    background-color: #ffc816;
    border-bottom: 1px solid #383526;
    .formTitle {
      font-family: Montserrat-Black;
      font-size: 1rem;
      color: #42413c;
    }
    .nav-link {
      // font-size: 1.4rem;
      padding: 6px 12px;
      color: #42413c;
      .fa {
        font-size: 1.2rem;
      }
    }
    .dropdown-item {
      padding: 12px;
      font-size: 0.87rem;
    }
    .dropdown-menu {
      transform: translate3d(-114px, 32px, 0px) !important;
    }
  }
}
.minText p {
  margin-bottom: 0;
  padding-top: 3%;
  font-size: 16px;
  margin-left: 0;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.minText p span {
  display: block;
  color: #000;
  font-size: 14px;
  font-weight: 500;
}
.middleHeading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .mainHeading {
    font-family: "Montserrat-Black";
    font-size: 1.6rem;
    color: #42413c;
    text-transform: uppercase;
    text-align: center;
  }
  p {
  }
}
</style>
