<template>
  <div class="body">
    <div class="main_container">
      <training-header :appConfig="appConfig"></training-header>
      <!-- page content -->
      <div
        class="right_col"
        role="main"
        style="margin-left: 0;background-color: #fff;"
      >
        <br />
        <div class="row px-4 pt-3 pb-5">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="x_panel qestion_page">
              <div class="x_content">
                <div class="dashboard-widget-content">
                  <div class="row">
                    <div class="col-md-9">
                      <div class="c_num">
                        <!-- <img
                            id="certificate"
                            src="../assets/img/unfillcertificate.jpg"
                            style="display: block;margin: 0 auto;width: 800px;margin-bottom: 4%;"
                        /> -->
                        <!-- <p id="certificate_exam_id">
                          Certificate No. :
                          <span id="certificate_no">AD45678XR5678906</span>
                        </p> -->
                        <canvas
                          id="myCanvas"
                          width="800"
                          height="297"
                          style="border:1px solid #d3d3d3;"
                        ></canvas>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="row">
                        <div class="col-12">
                          <div
                            class="button-div"
                            style="padding-top: 3%; padding-bottom: 3%; margin-top: 50%;"
                          >
                            <button
                              class="btn btn-yellow text-dark"
                              @click="downloadCertificate"
                              style="margin-right: 2%; color: #fff; border: 2px solid;"
                            >
                              Download Certificate
                            </button>

                            <!-- <a href="#" class="btn" id="download" role="button" @click="downloadCertificate(this)" style="margin-right: 2%; background-color: #15797c; color: #fff;"
                            >Download Certificate</a> -->
                          </div>
                        </div>
                        <div
                          class="col-12"
                          style="display:flex; justify-content:center;"
                        >
                          <a
                            v-if="route == 'score-board'"
                            class="btn btn-headerbutton btn-yellow text-dark "
                            href="javascript:void(0)"
                            @click.prevent.stop="backTo('/score-board')"
                            style="padding: 6px 70px; margin-top: 4%; border: 2px solid;"
                            >Back</a
                          >
                          <a
                            v-else
                            class="btn btn-headerbutton text-dark btn-yellow"
                            href="javascript:void(0)"
                            @click.prevent.stop="backTo('/certificate-status')"
                            style="padding: 6px 70px; margin-top: 4%; border: 2px solid;"
                            >Back</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /page content -->

      <!-- footer content -->
      <training-footer :appConfig="appConfig"></training-footer>
      <!-- /footer content -->
    </div>
  </div>
</template>

<script>
import trainingHeader from "../components/trainingHeader";
import trainingFooter from "../components/trainingFooter";
import image from "../assets/img/unfillcertificate.jpg";
import moment from "moment";
import { jsPDF } from "jspdf";
export default {
  components: {
    trainingHeader,
    trainingFooter,
  },
  data() {
    return {
      from: "",
      route: localStorage.getItem("route-name"),
      appConfig: {},
    };
  },
  methods: {
    backTo(url) {
      this.$router.push(url);
    },
    createCertificate() {
      let routeName = localStorage.getItem("route-name");
      let examDetails = null,
        date = null;
      if (routeName == "score-board") {
        examDetails = JSON.parse(localStorage.getItem("score-board"));
        date = moment(examDetails.endTime.split(",")[0], "MM/DD/YYYY").format(
          "L"
        );
      } else {
        examDetails = JSON.parse(localStorage.getItem("certificate-data"));
        date = moment(examDetails.createdOn.split(" ")[0], "DD-MM-YYYY").format(
          "L"
        );
      }

      var canvas = document.getElementById("myCanvas"),
        ctx = canvas.getContext("2d");
      canvas.width = 900;
      canvas.height = 471;
      var background = new Image();
      background.src = image;

      background.onload = function() {
        ctx.drawImage(background, 0, 0);
        ctx.fillStyle = "#5a6268";
        ctx.font = "35px Lobster-Regular";
        ctx.fillText(examDetails.userName, 320, 280);
        ctx.font = "16px Lobster-Regular";
        ctx.fillText(date, 300, 390);
        ctx.font = "16px Lobster-Regular";
        ctx.fillText(examDetails.score, 535, 390);
        //ctx.fillText(examDetails.result.toUpperCase(), 450, 365);
        ctx.font = "12px Montserrat-SemiBold";
        ctx.fillText(examDetails.examId, 410, 435);
      };
    },
    downloadCertificate(el) {
      const { value: fruit } = Swal.fire({
        title: "Please Select a Format",
        input: "select",
        inputOptions: {
          pdf: "PDF",
          img: "Image",
        },
        showCancelButton: false,
        inputValidator: (value) => {
          if (value === "img") {
            var canvas = document.getElementById("myCanvas");
            var image = canvas
              .toDataURL("image/png")
              .replace("image/png", "image/octet-stream");
            var link = document.createElement("a");
            link.download = "certificate.png";
            link.href = image;
            link.click();
          } else {
            var canvas = document.getElementById("myCanvas");
            console.log(canvas);
            var img = canvas.toDataURL("image/png");

            const pdf = new jsPDF({
              orientation: "landscape",
              unit: "px",
            });
            pdf.addImage(img, 50, 70);
            pdf.save("certificate.pdf");
          }
        },
      });

      if (fruit) {
        Swal.fire(`You selected: ${fruit}`);
      }
    },
  },
  mounted() {
    this.createCertificate();
    if (this.$route.params.facilitatorObj) {
      this.facilitatorObj = this.$route.params.facilitatorObj;
    } else {
      this.facilitatorObj = JSON.parse(localStorage.getItem("facilitatorObj"));
    }
    if (this.$route.params.appConfig) {
      this.appConfig = this.$route.params.appConfig;
    } else {
      this.appConfig = JSON.parse(localStorage.getItem("appConfig"));
    }
  },
};
</script>
<style scoped>
.x_panel {
  background-color: #fff;
  border: 0px solid transparent;
  padding: 0;
  border-radius: 7px;
}
.x_title {
  border-bottom: 0px solid #e6e9ed;
  padding: 15px 18px;
  background-color: #b0b0b0;
  color: #fff;
  border-radius: 7px 7px 0 0;
}
.x_content {
  padding: 0 25px 6px;
}
.button-div {
  display: flex;
  justify-content: center;
  margin-bottom: 4%;
}
.backbtn {
  color: #d2b8d2;
  background-color: #813582 !important;
  border-color: #813582 !important;
}
.download_certificate_btn {
  color: #fff;
  background-color: #42b869;
  border-color: #42b869;
}
</style>
