import axios from "axios";
import $ from "jquery";
//let authToken = localStorage.getItem("authToken"); //'Basic ZG9ub3RkZWxldGV1c2VyOlNlY3JldEAxMjM=';//'Basic YWRtaW46U0BuZEIweEBkbWluQDE4Njc=';
/*if(localStorage.getItem("userProfile")){
  let loggedInUserProfile = JSON.parse(localStorage.getItem("userProfile"));
  authToken = loggedInUserProfile.authparam;
}*/
// let authToken = 'Basic YWJjMTBAbWFpbGluYXRvci5jb206U2VjcmV0QDEyMw==' //for nepal
let authToken = 'Basic YWRtaW46U2VjcmV0QDEyMw==' // for intraavenir

// let headers = { Authorization: authToken };//Basic YWRtaW46U0BuZEIweEBkbWluQDE4Njc=
//  var baseUrl = "https://uat.intraavenir.duredemos.com/service/"; //"http://localhost:8080/dhis/";
//var baseUrl = "https://fppnepal.duredemos.com/service/";

class ApiServices {
  constructor() {}
  getBaseUrl(){
    return `${settings.baseURL}`;
  }
  RestGetCall(url,inputObj, systemBasicAuth=null) {
    if(systemBasicAuth !== null) {
      if (localStorage.getItem("authToken")) {
          authToken = localStorage.getItem("authToken")
      }
    }
    // headers.Authorization == null ?  headers.Authorization =localStorage.getItem("authToken") : headers.Authorization;
    return axios
      .get(`${settings.baseURL}`+url, {
        headers: {Authorization:authToken},
        params: inputObj
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return error.response;
      });
  }
  RestPostCall(url, inputObj) {
    if(localStorage.getItem("authToken")) {
      authToken = localStorage.getItem("authToken")
    }
    // headers.Authorization == null ?  headers.Authorization =localStorage.getItem("authToken") : headers.Authorization;
    // console.log(headers.Authorization);
    return axios({
      method: "post",
      url: `${settings.baseURL}`+url,
      headers: {Authorization:authToken},
      data: inputObj
    }).then(response => {
      return response.data;
    })
    /*catch(error => {
      console.log(error);
      return error.response;
    });*/
  }

  RestPutCall(url, inputObj) {
    if(localStorage.getItem("authToken")) {
      authToken = localStorage.getItem("authToken")
    }
    // headers.Authorization == null ?  headers.Authorization =localStorage.getItem("authToken") : headers.Authorization;
    // console.log(headers.Authorization);
    return axios({
      method: "put",
      url: `${settings.baseURL}`+url,
      headers: {Authorization:authToken},
      data: inputObj
    }).then(response => {
      return response.data;
    })
    /*catch(error => {
      console.log(error);
      return error.response;
    });*/
  }

  RestDeleteCall(url, inputObj) {
    if (localStorage.getItem("authToken")) {
        authToken = localStorage.getItem("authToken")
    }
    // headers.Authorization == null ?  headers.Authorization =localStorage.getItem("authToken") : headers.Authorization;
    // console.log(headers.Authorization);
    return axios({
            method: "delete",
            url: `${settings.baseURL}` + url,
            headers: { Authorization: authToken },

        }).then(response => {
            return response.data;
        })
        /*catch(error => {
          console.log(error);
          return error.response;
        });*/
}

  RestPostMaltipart(url, inputObj) {
    if(localStorage.getItem("authToken")) {
      authToken = localStorage.getItem("authToken")
    }
    return axios({
      method: "post",
      url: `${settings.baseURL}`+url,
      headers: {'Content-Type': 'multipart/form-data',Authorization:authToken},
      data: inputObj
    }).then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response;
    });
  }

  theme(color) {
    if (color === '#2b2b2b') {
      $("#mainbody").removeClass("white-theme");
      $("#mainbody").removeClass("green-theme");
      $("#mainbody").removeClass("newGreen-theme");
      $("#mainbody").removeClass("newBlack-theme");
      $("#mainbody").addClass("black-theme");
    } else if (color === '#212832') {
      $("#mainbody").removeClass("white-theme");
      $("#mainbody").removeClass("green-theme");
      $("#mainbody").removeClass("black-theme");
      $("#mainbody").removeClass("newGreen-theme");
      $("#mainbody").addClass("newBlack-theme");
    } else if (color === '#006600') {
      $("#mainbody").removeClass("white-theme");
      $("#mainbody").removeClass("black-theme");
      $("#mainbody").addClass("green-theme");
      $("#mainbody").removeClass("newBlack-theme");
      $("#mainbody").removeClass("newGreen-theme");
      //$("#mainbody").addClass("newGreen-theme");
    } else if (color === '#25984b') {
      $("#mainbody").removeClass("white-theme");
      $("#mainbody").removeClass("black-theme");
      $("#mainbody").removeClass("newBlack-theme");
      $("#mainbody").removeClass("green-theme");
      //$("#mainbody").addClass("green-theme");
      $("#mainbody").addClass("newGreen-theme");
    }else if (color === '#e5e5e5') {
      $("#mainbody").removeClass("green-theme");
      $("#mainbody").removeClass("newGreen-theme");
      $("#mainbody").removeClass("black-theme");
      $("#mainbody").removeClass("newBlack-theme");
      $("#mainbody").addClass("white-theme");
    } else {
      $("#mainbody").removeClass("white-theme");
      $("#mainbody").removeClass("green-theme");
      $("#mainbody").removeClass("black-theme");
      $("#mainbody").removeClass("newBlack-theme");
      $("#mainbody").removeClass("newGreen-theme");
    }
  }
}

export default new ApiServices();
