<template>
  <div>
    <div class="mdgridviewpage-bg">
      <training-header :appConfig="configObj"></training-header>
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12" style="padding: 0 !important"></div>
        </div>
      </div>
      <!-- <div class="row" style="display:block; width:100%">
            <div class="col-12">
                <a href="#" @click="logout" class="nav-link float-right mb-4" type="button" role="button" style="font-size:16px; color:#000;"><i class="fa fa-sign-out" aria-hidden="true"></i> Logout</a>
            </div>
        </div> -->
      <div class="container-fluid body maindivWrap">
        <div class="row">
          <div class="col-lg-12" style="margin: 0 auto">
            <div class="card">
              <div class="card-body p-5">
                <div class="row mx-2">
                  <div
                    class="col-sm-4"
                    style="
                      padding-left: 15px !important;
                      padding-right: 15px !important;
                    "
                  >
                    <h4 class="top_text" style="font-weight: 500">
                      Welcome {{ userProfile.firstname }}
                    </h4>
                    <div class="paragraph py-3">
                      <p class="" style="">
                        The Ministry of Health & Family Welfare (MoHFW),
                        Government of India is committed to provide accessible,
                        affordable, accountable and quality healthcare services
                        responsive to the needs of the people.
                      </p>
                      <p class="" style="">
                        Provision of essential drugs, vaccines and commodities
                        including contraceptives is essential to ensure access
                        to comprehensive primary health through the public
                        health system.
                      </p>
                    </div>
                  </div>
                  <div
                    class="col-sm-1"
                    style="
                      padding-left: 15px !important;
                      padding-right: 15px !important;
                    "
                  ></div>
                  <div
                    class="col-md-7"
                    style="
                      padding-left: 15px !important;
                      padding-right: 15px !important;
                    "
                  >
                    <button class="btn btn-yellow border-radius-0">
                      Please select an option
                    </button>
                    <div class="row">
                      <div
                        class="col-md-12"
                        style="
                          padding-left: 10px !important;
                          padding-right: 15px !important;
                        "
                      >
                        <div class="flex-container2">
                          <!-- <a class="btn bg-light-red btn-div" href="#/training-dashboard">
                                        <div class="inside_content">
                                            <span class="fa fa-address-book-o text-white"></span>
                                            <p class="text-white">Avenir Training</p>
                                        </div>
                                    </a> -->
                          <router-link
                            :to="{
                              name: 'training-dashboard',
                              params: {
                                frmModel: frmModel,
                                configObj: configObj,
                              },
                            }"
                            class="btn btn-div border-radius-0"
                            v-bind:class="{ disabled: isDisable }"
                          >
                            <div class="inside_content">
                              <div class="inside_img_div">
                                <img
                                  src="../assets/img/fplims/e-learning-icon.png"
                                  class="d-block my-2"
                                  style="width: 75px"
                                />
                              </div>
                              <p class="my-2">E-learning Module</p>
                            </div>
                          </router-link>
                          <a
                            v-show="this.userProfile.userrole === 'Superuser'"
                            class="btn bg-light-green btn-div admin_mod border-radius-0"
                            href="#/training-wizard"
                            style="margin-left: 30px"
                          >
                            <div class="inside_content">
                              <div class="inside_img_div">
                                <img
                                  src="../assets/img/fplims/ico_admin.png"
                                  class="d-block my-2"
                                />
                              </div>
                              <p class="my-2">Admin Module</p>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <training-footer :appConfig="configObj"></training-footer>
  </div>
</template>
<script>
import trainingHeader from "../components/trainingHeader";
import trainingFooter from "../components/trainingFooter";
import ApiServices from "../service/ApiServices";
export default {
  components: {
    trainingHeader,
    trainingFooter,
  },
  data() {
    return {
      userProfile: "",
      settings: settings,
      frmModel: {},
      configObj: {},
      isDisable: true,
    };
  },
  methods: {
    logout() {
      //dhis-web-commons-security/logout.action
      localStorage.removeItem("userProfile");
      localStorage.removeItem("loggedInUserId");
      ApiServices.RestGetCall(
        "dhis-web-commons-security/logout.action",
        {}
      ).then((res) => {
        console.log(res);
      });
      //window.open('http://intraavenir.duredemos.com/service/dhis-web-commons-security/logout.action', '_blank');
      this.$router.push("/");
    },
  },
  mounted() {
    this.$root.loading = true;
    this.userProfile = JSON.parse(localStorage.getItem("userProfile"));
    ApiServices.RestGetCall("/api/avenir/training/getAppDetails", {
      userId: this.userProfile.username,
    }).then((aveT) => {
      console.log(aveT);
      this.avenirTraining = aveT;
      if (
        this.avenirTraining.trainingApp &&
        this.avenirTraining.config &&
        this.avenirTraining.trainingApp != "No training app available"
      ) {
        this.frmModel = this.avenirTraining.trainingApp;
        console.log(this.frmModel);
        if (this.frmModel.course.length > 0)
          this.course = this.frmModel.course[0];

        this.configObj = this.avenirTraining.config;
        localStorage.setItem("appConfig", JSON.stringify(this.configObj));
        localStorage.setItem("facilitatorObj", JSON.stringify(this.frmModel));
        this.configObj.color = "#212832";
        this.isDisable = false;
      }
      this.$root.loading = false;
    });
  },
};
</script>
<style scoped>
.mdgridviewpage-bg {
  background-color: #ededed;
  background: url("../assets/img/fplmis_bg.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  /* padding: 20px 40px; */
}
.maindivWrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: flex-start;
  /* height: calc(100vh - 250px);
  overflow: hidden;
  overflow-y: scroll; */
  padding-top: 3%;
}
.flex-container2 {
  display: flex;
  /* justify-content: center;
  align-items: center; */
  margin-top: 2%;
}

.flex-container2 > .btn-div {
  color: white;
  width: 200px;
  height: 150px;
  background: #eeeeee !important;
  border: 4px solid #eeeeee !important;
}
.flex-container2 > .btn-div:hover {
  background-color: #eeeeee !important;
  border: 4px solid #ffc816 !important;
}
.btn-success:hover {
  color: #fff;
  background-color: #449d44 !important;
  border-color: #398439 !important;
}
.inside_content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.inside_img_div {
  height: 80px;
  overflow: hidden;
}
.inside_content span {
  font-size: 26px;
  padding-top: 2px;
}

.inside_content p {
  color: #42413c;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
}
/* .body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
} */

.btn {
  transition: all 0.5s;
}
.btn:hover {
  background-color: #ababab !important;
  border-color: #ababab !important;
}
.card-body {
  /* background-color: rgba(255, 255, 255, 0.78) !important ; */
  padding-top: 10px !important;
  padding-bottom: 60px !important;
  /* border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px; */
  /* border-top-left-radius: 10px;
        border-top-right-radius: 10px; */
  /* box-shadow: 0px 0px 3px rgba(53,53,53,0.26); */
  border-radius: 8px;
}

.card {
  background-color: transparent;
  box-shadow: 0px 0px 0px 0px 4px rgb(186, 186, 186) !important;
  border-radius: 10px;
  border: 0px !important;
}
.card-header {
  border-bottom: 0px;
  border-radius: 10px 10px 0 0 !important;
  padding: 8px 12px !important;
}
footer {
  background-color: #f6f0f8;
}
.navbar {
  background: #edd0f5 !important;
}
.headerTitle {
  font-size: 2rem;
  height: 100%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}
.minText p {
  margin-bottom: 0;
  padding-top: 5%;
  font-size: 12px;
  margin-left: 0;
  color: #000;
}
.minText p span {
  display: block;
  color: #000;
}
.rightSideMenu {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.rightSideMenu ul {
  list-style: none;
}
.rightSideMenu ul li i.fa {
  font-size: 1.3rem;
}
.dropdown-menu {
  font-size: 14px;
}
.dropdown-menu .fa {
  font-size: 1rem;
}
.rightSideMenu .btn:hover {
  background-color: transparent !important;
  border-color: transparent !important;
}
.e_learn_module {
  background: linear-gradient(
    90deg,
    rgba(14, 36, 151, 1) 48%,
    rgba(68, 88, 196, 1) 77%
  ) !important;
}
.admin_mod {
  background: linear-gradient(
    90deg,
    rgba(179, 133, 10, 1) 48%,
    rgba(234, 188, 35, 1) 77%
  );
}
.e_learn_module:hover,
.admin_mod:hover {
  background-color: #ababab !important;
  border-color: #ababab !important;
}
.btn-yellow:hover {
  background-color: #fec919 !important;
  border-color: #fec919 !important;
  color: #ec2029 !important;
  font-weight: 600 !important;
}
</style>
