var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.configObj)?_c('div',{staticClass:"body"},[_c('div',{staticClass:"main_container main_full_div",style:({
      backgroundImage: ("url(" + (_vm.configObj.landingBgImg) + ")")
    })},[_c('training-header',{attrs:{"appConfig":_vm.configObj}}),_c('div',{class:_vm.getClass()},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"card bg-transparent border-0"},[_c('div',{staticClass:"card-body px-5 py-5"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4",staticStyle:{"padding-left":"15px !important","padding-right":"15px !important"}},[_c('div',{},[_c('h4',{staticClass:"top_text",staticStyle:{"font-weight":"500"}},[_vm._v(" Welcome "+_vm._s(_vm.loggedInUserProfile.firstname)+" ")]),_c('div',{staticClass:"paragraph py-3"},[_c('p',[_vm._v(_vm._s(_vm.configObj.contentHeading))]),_c('p',[_vm._v(_vm._s(_vm.configObj.contentDescription))])])])]),_c('div',{staticClass:"col-sm-1",staticStyle:{"padding-left":"15px !important","padding-right":"15px !important"}}),_c('div',{staticClass:"col-sm-7",staticStyle:{"padding-left":"15px !important","padding-right":"15px !important"}},[_c('button',{staticClass:"btn btn-yellow border-radius-0"},[_vm._v(" Please select an option ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 mb-2",staticStyle:{"padding-left":"10px !important","padding-right":"15px !important"}},[_c('div',{staticClass:"flex-container2"},[(_vm.isTrainer)?_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(
                        this.loggedInUserProfile.userrole !=
                        'AvenirLearnerGroup'
                      ),expression:"\n                        this.loggedInUserProfile.userrole !=\n                        'AvenirLearnerGroup'\n                      "}],staticClass:"btn btn-div border-radius-0",attrs:{"to":{
                        name: 'facilitator-page',
                        params: {
                          facilitatorObj: _vm.frmModel,
                          appConfig: _vm.configObj,
                        },
                      }}},[_c('div',{staticClass:"inside_content"},[_c('div',{staticClass:"inside_img_div"},[_c('img',{staticClass:"d-block my-2",attrs:{"src":require("../assets/img/fplims/ico_training_planning.png")}})]),_c('p',{staticClass:"my-2"},[_vm._v("Training Planning")])])]):_vm._e(),_c('router-link',{staticClass:"btn btn-div border-radius-0 courseDiv",staticStyle:{"margin-left":"30px"},attrs:{"to":{
                        name: 'course',
                        params: {
                          facilitatorObj: _vm.frmModel,
                          appConfig: _vm.configObj,
                        },
                      }}},[_c('div',{staticClass:"inside_content"},[_c('div',{staticClass:"inside_img_div"},[_c('img',{staticClass:"d-block my-2",attrs:{"src":require("../assets/img/fplims/ico_courses.png")}})]),_c('p',{staticClass:"my-2"},[_vm._v("Courses")])])])],1),_c('div',{staticClass:"flex-container2"},[_c('router-link',{staticClass:"btn btn-div border-radius-0",attrs:{"to":{
                        name: 'exam-id',
                        params: {
                          facilitatorObj: _vm.frmModel,
                          appConfig: _vm.configObj,
                        },
                      }}},[_c('div',{staticClass:"inside_content"},[_c('div',{staticClass:"inside_img_div"},[_c('img',{staticClass:"d-block my-2",attrs:{"src":require("../assets/img/fplims/ico_course_test.png")}})]),_c('p',{staticClass:"my-2"},[_vm._v("Course Test")])])]),_c('router-link',{staticClass:"btn btn-div border-radius-0",staticStyle:{"margin-left":"30px"},attrs:{"to":{
                        name: 'certificate-status',
                        params: {
                          facilitatorObj: _vm.frmModel,
                          appConfig: _vm.configObj,
                        },
                      }}},[_c('div',{staticClass:"inside_content"},[_c('div',{staticClass:"inside_img_div"},[_c('img',{staticClass:"d-block my-2",attrs:{"src":require("../assets/img/fplims/ico_certifiate.png")}})]),_c('p',{staticClass:"my-2"},[_vm._v("Certificate")])])])],1)])])])])])])])]),_c('training-footer',{attrs:{"appConfig":_vm.configObj}}),_c('training-faq',{attrs:{"facilitatorObj":_vm.frmModel}})],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }