<template>
  <div class="body">
    <div class="main_container">
      <training-header :appConfig="configObj"></training-header>
      <div>
        <nav class="navbar navbar-expand-sm bg-light px-0">
          <ul class="navbar-nav justify-content-end w-100">
            <li class="nav-item">
              <a class="nav-link" href="#">
                <form>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search..."
                      v-model="searchName"
                      style="border-left: 0"
                    />
                  </div>
                </form>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">
                <form class="form-inline" action="/action_page.php">
                  <label for="" class="mr-sm-2">Show:</label>
                  <select class="form-control" id="sel1" v-model="perPage">
                    <option>10</option>
                    <option>50</option>
                    <option>100</option>
                    <option>500</option>
                  </select>
                </form>
              </a>
            </li>
          </ul>
        </nav>
        <div class="container pb-5">
          <div class="row">
            <div class="col-sm-12">
              <div class="listingTable mb-4">
                <div class="row mx-0">
                  <div class="col-12 px-0">
                    <div class="row mb-3">
                      <div class="col-6">
                        <h5 class="section_title mt-3">User Role</h5>
                      </div>
                      <div class="col-6">
                        <button
                          class="btn yellow-float-btn float-right addTemp_btn mt-3 mr-0 justify-content-center w-auto border-radius-0"
                          data-toggle="collapse"
                          href="#add_indicator_type"
                          @click="edittableforflage()"
                        >
                          <span class="border_bottom_grey">
                            <i class="fa fa-plus" aria-hidden="true"></i> Add
                            User Role
                          </span>
                        </button>
                      </div>
                    </div>
                    <div class="card tableCard border-0">
                      <div class="card-body p-0">
                        <table
                          class="table table-bordered table-hover mb-0 bg-white shadow-sm"
                        >
                          <thead>
                            <tr class="row mx-0">
                              <th class="col-1" style="">No.</th>
                              <th class="col-5" style="">Display Name</th>
                              <th class="col-5" style="">Description</th>
                              <th class="col-1" style="">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              class="row mx-0"
                              v-for="(item, index) in typeList"
                            >
                              <td class="col-1">{{ index + 1 }}</td>
                              <td class="col-5">{{ item.displayName }}</td>
                              <td class="col-5">{{ item.description }}</td>
                              <td class="col-1">
                                <div class="iconGroup">
                                  <i
                                    class="fa fa-pencil-square-o cursor-pointer mr-3"
                                    @click="
                                      editTableData(indicatortablevalue[index])
                                    "
                                    aria-hidden="true"
                                    v-b-tooltip.hover
                                    title="Edit"
                                    data-toggle="collapse"
                                    href="#add_indicator_type"
                                  >
                                  </i>
                                  <i
                                    class="fa fa-trash cursor-pointer"
                                    @click="
                                      deletetabledata(
                                        indicatortablevalue[index]
                                      )
                                    "
                                    aria-hidden="true"
                                    v-b-tooltip.hover
                                    title="Delete"
                                  >
                                  </i>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="mt-3" v-if="typeList.length > 0">
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="allTypeListLen"
                        :per-page="perPage"
                        first-text="First"
                        prev-text="Prev"
                        next-text="Next"
                        last-text="Last"
                        align="center"
                      ></b-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="wizardCard mb-4 collapse" id="add_indicator_type">
                <div class="card shadow-sm">
                  <div class="card-header font-weight-bold bg-dark text-white" style="font-size:1rem;">Add User Role</div>
                  <div class="card-body">
                    <div class="row mx-0 mb-2">
                      <div class="col-sm-12 mb-1">
                        <div class="form-group row mx-0">
                          <label class="col-sm-3 col-form-label"
                            >User Role Name</label
                          >
                          <div class="col-sm-9">
                            <input
                              type="text"
                              class="form-control"
                              v-model="userRoleName"
                              id="indicatorTypeName"
                              placeholder
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mx-0 mb-2">
                      <div class="col-sm-12 mb-1">
                        <div class="form-group row mx-0">
                          <label for="" class="col-sm-3 col-form-label"
                            >Description</label
                          >
                          <div class="col-sm-9">
                            <input
                              type="text"
                              class="form-control"
                              v-model="userDiscription"
                              id="indicatorTypeName"
                              placeholder
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mx-0 mb-2">
                      <div class="justify-content-end d-flex w-100">
                        <button
                          class="btn yellow-float-btn justify-content-center w-auto"
                          v-if="updatetableflag == false"
                          @click="userRoletype(userRoleName, userDiscription)"
                        >
                          Save
                        </button>
                        <button
                          class="btn btn-success text-white"
                          v-if="updatetableflagfor1entry == true"
                          @click="
                            userRoletypeUpdate(userRoleName, userDiscription)
                          "
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <training-footer :appConfig="configObj"></training-footer>
    </div>
  </div>
</template>
<script>
import ApiServices from "../service/ApiServices";
import trainingHeader from "../components/trainingHeader";
import trainingFooter from "../components/trainingFooter";
export default {
  data() {
    return {
      userProfile: "",
      configObj: {},
      userRoleName: "",
      userDiscription: "",
      updatetableflag: false,
      updatetableflagfor1entry: false,
      allTypeListLen: "",
      userTableValue: [],
      perPage: 10,
      currentPage: 1,
      searchName: "",
      editabledata: [],
      indicatortablevalue: [],
    };
  },
  computed: {
    typeList() {
      let filteredApps = this.indicatortablevalue.filter((a) => {
        //console.log(a)
        a.displayName = a.displayName.toString();
        return (
          (a.displayName &&
            a.displayName
              .toLowerCase()
              .includes(this.searchName.toLowerCase())) ||
          (a.displayName &&
            a.displayName.includes(this.searchName.toLowerCase()))
        );
      });

      // console.log("filteredApps",filteredApps)
      this.allTypeListLen = filteredApps.length;
      return filteredApps.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
  },
  components: {
    trainingHeader,
    trainingFooter,
  },
  methods: {
    edittableforflage() {
      this.updatetableflag = false;
      this.updatetableflagfor1entry = false;
      this.userRoleName = "";
      this.userDiscription = "";
    },
    userRoletype(name, description) {
      console.log(name, description);
      ApiServices.RestPostCall("api/userRoles", {
        name: name,
        description: description,
      }).then((res) => {
        console.log(res);
        this.$router.go();
      });
    },
    userRoletypeUpdate(name, description) {
      console.log(name, description);
      console.log(this.editabledata);
      ApiServices.RestPutCall("api/userRoles/" + this.editabledata.id, {
        name: name,
        description: description,
      }).then((res) => {
        console.log(res);
        this.$router.go();
      });
    },
    editTableData(data) {
      console.log(data);
      this.updatetableflagfor1entry = true;
      this.updatetableflag = true;
      this.userRoleName = data.displayName;
      this.userDiscription = data.description;
      this.editabledata = data;
    },
    deletetabledata(data) {
      console.log(data);
      console.log(data.id);
      this.$root.loading = true
      ApiServices.RestDeleteCall("api/userRoles/" + data.id).then((res) => {
        console.log(res);
        this.getUserRoles()
      }).catch((res) => {
        this.$root.loading = false
        // console.log("res",res)
        this.$swal({
          text: "Could not delete due to association with another object"
        })
      });
    },
    getUserRoles() {
      ApiServices.RestGetCall(
        "api/userRoles?fields=displayName%2Cid%2Caccess%2Cuser%5BdisplayName%2Cid%5D%2CpublicAccess%2CuserGroupAccesses%2Cdescription&pageSize=50&page=1&order=name%3Aasc",
        {}
      ).then((response) => {
        console.log(response);
        this.indicatortablevalue = response.userRoles;
        this.$root.loading = false
      });
    }
  },
  mounted() {
    this.userProfile = JSON.parse(localStorage.getItem("userProfile"));
    this.getUserRoles()
    if (this.$route.params.configObj) {
      this.configObj = this.$route.params.configObj;
    } else {
      this.configObj = JSON.parse(localStorage.getItem("appConfig"));
    }
  },
};
</script>
<style lang="scss" scoped>
.navbar {
  background: #3c3b37 !important;
  border-color: #3c3b37;
}
.table thead th {
  vertical-align: bottom;
  /* border-bottom: 2px solid #dee2e6; */
  position: sticky;
  top: 0;
  z-index: 5;
  background: #363636;
  color: #fff;
}
.table td,
.table th {
  font-size: 0.9rem;
}
.iconGroup{
	.fa{
		font-size: 1.2rem;
	}
}
</style>
