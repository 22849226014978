<template>
  <div class="body">
    <div class="main_container videoCoursePage">
      <!-- <training-header></training-header> -->
      <training-header :appConfig="appConfig"></training-header>
      <!-- page content -->
      <section class="video-section">
        <div class="container-fluid">
          <div class="parent-wrap">
            <div class="row">
              <!-- video screen section start-->
              <div class="col-9 video-wrap">
                <div class="row pt-2">
                  <div class="col-12 px-0">
                    <div class="video-player bg-dark" v-if="!isQuestions">
                      <div>
                        <docPreview v-if="docFile" :doc="docFile"></docPreview>
                      </div>
                    </div>
                    <div class="dashboard-widget-content answerDivSect" v-else>
                      <question
                        :questionObj="questionObj"
                        @answer="getAnswer"
                        :index="index"
                        :quesLen="quesLen"
                      ></question>
                      <div
                        class="position-relative row px-4"
                        v-if="!isNextResource && !isExamActivate"
                      >
                        <div class="progressbar">
                          <div
                            class="progressbar text-center"
                            style="background-color: #ffc816; margin: 0; color: white;"
                            :style="{ width: progress + '%' }"
                          >
                            {{ progress }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="button-div m-0 floating-submit-btn"
                      style="padding-top: 0; padding-bottom: 0"
                    >
                      <a
                        href="#"
                        class="btn btn-primary yellow-float-btn"
                        role="button"
                        v-if="index + 1 == questionArr.length"
                        @click.prevent.stop="submitResponse"
                        style="padding: 6px 40px"
                        >Submit</a
                      >
                      <a
                        href="#"
                        class="btn yellow-float-btn"
                        role="button"
                        v-else
                        @click.prevent.stop="getQuestion"
                        style="padding: 6px 40px"
                        >Next</a
                      >
                    </div>
                    <div
                      class="text-right pt-4"
                      v-if="isNextResource && facilitatorObj.sessions.length"
                    >
                      <a
                        href="#"
                        class="btn btn-primary"
                        role="button"
                        @click.prevent.stop="nextResource"
                        style="padding: 6px 40px"
                      >
                        Next
                      </a>
                    </div>
                    <div class="text-right pt-4" v-if="isExamActivate">
                      <a
                        href="#"
                        class="btn btn-primary"
                        role="button"
                        @click.prevent.stop="examActivate"
                        style="padding: 6px 40px"
                      >
                        Course Test
                      </a>
                    </div>
                    <div class="video-sec-tabs">
                      <b-card no-body>
                        <b-tabs pills card>
                          <b-tab title="Overview" active v-if="contentData"
                            ><b-card-text>
                              <div class="row">
                                <div class="col-3">
                                  <img
                                    src="../assets/img/fplims/logo_mohfw_1.png"
                                    class="img-fluid"
                                  />
                                </div>
                                <div class="col-5">
                                  <div v-if="contentData">
                                    <h2
                                      class=""
                                      style="
                                        font-size: 1.4rem;
                                        font-weight: 600;
                                      "
                                    >
                                      About This Course
                                    </h2>
                                    <p v-html="contentData"></p>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <div class="d-flex justify-content-end">
                                    <img
                                      src="../assets/img/fplims/dfc.png"
                                      class="img-fluid"
                                    />
                                    <img
                                      src="../assets/img/fplims/nhm_new.png"
                                      class="d-block img-fluid ml-2"
                                    />
                                  </div>
                                </div>
                              </div> </b-card-text
                          ></b-tab>
                          <b-tab title="Feedback"
                            ><b-card-text>
                              <div class="row">
                                <div class="col-6">
                                  <div class="form-group">
                                    <label
                                      class="font-weight-bold"
                                      for="comment"
                                      >Describe Your Feedback</label
                                    >
                                    <textarea
                                      class="form-control"
                                      rows="3"
                                      id="comment"
                                      v-model="feedbackText"
                                    ></textarea>
                                  </div>
                                </div>
                                <div class="col-6">
                                  <div class="form-group">
                                    <label
                                      class="font-weight-bold d-block"
                                      for="comment"
                                      >What kind of feedback your
                                      submitting?</label
                                    >

                                    <div class="form-check-inline">
                                      <label class="form-check-label">
                                        <input
                                          type="radio"
                                          class="form-check-input"
                                          name="optradio"
                                          value="Comments"
                                          v-model="feedbackType"
                                        />Comments
                                        <!-- @click="feebackType('Comments')" -->
                                      </label>
                                    </div>
                                    <div class="form-check-inline">
                                      <label class="form-check-label">
                                        <input
                                          type="radio"
                                          class="form-check-input"
                                          name="optradio"
                                          value="Suggestions"
                                          v-model="feedbackType"
                                        />Suggestions
                                        <!-- @click="feebackType('Suggestions')" -->
                                      </label>
                                    </div>
                                    <div
                                      class="form-check-inline disabled hide"
                                    >
                                      <label class="form-check-label">
                                        <input
                                          type="radio"
                                          class="form-check-input"
                                          name="optradio"
                                          value="Questions"
                                          v-model="feedbackType"
                                          disabled
                                        />Questions
                                        <!-- @click="feebackType('Questions')" -->
                                      </label>
                                    </div>
                                  </div>

                                  <button
                                    class="btn yellow-float-btn my-3 justify-content-center w-auto"
                                    :disabled="feedbackText === ''"
                                    @click.prevent.stop="callApi"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                              <div class="row mt-4">
                                <div
                                  class="col-12 small text-right cursor-pointer mb-3"
                                  @click.prevent.stop="
                                    showFeedback = !showFeedback
                                  "
                                >
                                  {{ showFeedback ? "Hide" : "Show" }} Feedbacks
                                </div>
                                <div class="col-12" v-if="showFeedback">
                                  <div class="card tableCard border-0">
                                    <div class="card-body p-0">
                                      <table
                                        class="table table-bordered table-hover mb-0 bg-white shadow-sm"
                                      >
                                        <thead>
                                          <tr>
                                            <th>Feedback ID</th>
                                            <th>Email ID</th>
                                            <th>Feedback Type</th>
                                            <th>Feedback</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr
                                            v-for="(feedback,
                                            index) in feedbacksList"
                                          >
                                            <td>{{ feedback.feedbackId }}</td>
                                            <td>{{ feedback.emailId }}</td>
                                            <td>{{ feedback.feedbackType }}</td>
                                            <td>{{ feedback.feedback }}</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </b-card-text></b-tab
                          >
                        </b-tabs>
                      </b-card>
                    </div>
                  </div>
                  <div class="col-12"></div>
                </div>
              </div>
              <div class="col-3 wrap px-0">
                <div class="playlist-wrap">
                  <div class="course-listing">
                    <ul
                      class="nav flex-column"
                      v-if="facilitatorObj.sessions.length"
                    >
                      <template v-for="(session, i) in facilitatorObj.sessions">
                        <div
                          class="nav-item main-title-nav"
                          :key="i"
                          v-if="session.category.length > 0"
                        >
                          <a class="nav-link active" href="#">
                            <h4 class="course-title my-2">
                              {{ session.session }}
                            </h4>
                          </a>
                        </div>
                        <li
                          class="nav-item"
                          :key="session.sessionId"
                          v-if="session.category.length > 0"
                        >
                          <a
                            class="nav-link px-0 py-0"
                            href="javascript:void(0)"
                          >
                            <div class="accordion" id="accordionExample">
                              <div
                                class="card mb-0"
                                v-for="(category, j) in session.category"
                                :key="category.categoryId"
                                v-if="category.category"
                              >
                                <div
                                  class="card-head px-3 py-3"
                                  :id="'headingOne' + category.categoryId"
                                  style="border-bottom: 1px solid #e9e9eb"
                                >
                                  <h2
                                    class="mb-0 mt-0 category-title"
                                    data-toggle="collapse"
                                    :data-target="
                                      '#collapse' + category.categoryId
                                    "
                                    :aria-expanded="j === currentCat"
                                    :aria-controls="
                                      'collapse' + category.categoryId
                                    "
                                  >
                                    {{ category.category }}
                                  </h2>

                                  <!-- <p class="mb-0"><small>26 mins left</small></p> -->
                                </div>

                                <div
                                  :id="'collapse' + category.categoryId"
                                  class="collapse"
                                  :aria-labelledby="
                                    'headingOne' + category.categoryId
                                  "
                                  data-parent="#accordionExample"
                                  :class="
                                    category.resourse.filter(
                                      (o) => o.resourseId == resId
                                    ).length > 0
                                      ? 'show'
                                      : ''
                                  "
                                >
                                  <div
                                    class="card-body py-0 bg-white"
                                    v-if="category.resourse.length > 0"
                                  >
                                    <ul class="nav flex-column sub-course-nav">
                                      <template
                                        v-for="(resourse,
                                        k) in category.resourse"
                                      >
                                        <li
                                          class="nav-item"
                                          :key="resourse.resourseId"
                                          v-if="resourse.header"
                                          :class="{
                                            active:
                                              j === currentCat &&
                                              k === currentResourse,
                                          }"
                                        >
                                          <a
                                            class="nav-link"
                                            href="#"
                                            @click.prevent.stop="
                                              viewResource(resourse, j, k)
                                            "
                                          >
                                            <p
                                              class="cursor-pointer mb-1 resourse_header text-truncate"
                                              v-b-tooltip.hover
                                              :title="resourse.header"
                                            >
                                              {{ k + 1 + ". "
                                              }}{{ resourse.header }}
                                            </p>
                                            <p
                                              class="mb-0 sign-symbol"
                                              v-if="
                                                resourse.downloadLink.includes(
                                                  'youtube'
                                                ) ||
                                                  resourse.downloadLink.includes(
                                                    'youtu'
                                                  )
                                              "
                                            >
                                              <small
                                                ><i
                                                  class="fa fa-youtube-play mr-1"
                                                  aria-hidden="true"
                                                ></i>
                                                Video</small
                                              >
                                            </p>
                                            <p
                                              class="mb-0 sign-symbol"
                                              v-else-if="
                                                resourse.downloadLink.includes(
                                                  'ppt'
                                                ) ||
                                                  resourse.downloadLink.includes(
                                                    'pptx'
                                                  )
                                              "
                                            >
                                              <small
                                                ><i
                                                  class="fa fa-file mr-1"
                                                  aria-hidden="true"
                                                ></i>
                                                PPT Document</small
                                              >
                                            </p>
                                            <p
                                              class="mb-0 sign-symbol"
                                              v-else-if="
                                                resourse.downloadLink.includes(
                                                  'doc'
                                                ) ||
                                                  resourse.downloadLink.includes(
                                                    'docx'
                                                  )
                                              "
                                            >
                                              <small
                                                ><i
                                                  class="fa fa-file-word-o mr-1"
                                                  aria-hidden="true"
                                                ></i>
                                                Word Document</small
                                              >
                                            </p>
                                          </a>
                                        </li>
                                      </template>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                        </li>
                      </template>
                    </ul>
                    <div class="text-center pt-4" v-else>
                      No Courses Assigned. <br />
                      Please contact your Administrator.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- playlist section close-->
          </div>
        </div>
      </section>
      <!-- /page content -->
      <training-faq :facilitatorObj="facilitatorObj"></training-faq>
    </div>
  </div>
</template>
<script>
import trainingHeader from "../components/trainingHeader";
import trainingFooter from "../components/trainingFooter";
import trainingFaq from "./trainingFaq";
import vcarousel from "carousel";
import vueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import docPreview from "./docPreview";
import ApiServices from "../service/ApiServices";
import question from "./question";
export default {
  components: {
    trainingHeader,
    trainingFooter,
    trainingFaq,
    vcarousel,
    vueSlickCarousel,
    docPreview,
    resId: "",
    question,
  },
  data() {
    return {
      show: true,
      docPreviewflag: false,
      contentData: "",
      docFile: "",
      facilitatorObj: {},
      appConfig: {},
      slide: 0,
      sliding: null,
      facilitatorObj: {},
      appConfig: {},
      currentOffset: 0,
      windowSize: 0,
      paginationFactor: 310,
      settings: {
        arrows: true,
        dots: true,
        dotsClass: "slick-dots custom-dot-class",
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 5000,
        autoplaySpeed: 2000,
        swipeToSlide: true,
        userProfile: "",
        feedbackType: "",
        feedbackText: "",
      },
      feedbackType: "Comments",
      feedbackText: "",
      resCountArr: [],
      qaList: [],
      feedbacksList: [],
      showFeedback: false,
      isQuestions: false,
      questionArr: [],
      quesLen: [],
      index: 0,
      questionObj: null,
      answer: null,
      answerArr: [],
      startTime: new Date().toLocaleString(),
      endTime: "",
      isNextResource: true,
      allCats: 0,
      allResOfCats: 0,
      currentCat: 0,
      currentResourse: 0,
      isExamActivate: false,
    };
  },
  mounted() {
    localStorage.setItem("isFromCourse", false);
    if (this.$route.params.facilitatorObj) {
      this.facilitatorObj = this.$route.params.facilitatorObj;
    } else {
      this.facilitatorObj = JSON.parse(localStorage.getItem("facilitatorObj"));
    }
    if (this.$route.params.appConfig) {
      this.appConfig = this.$route.params.appConfig;
    } else {
      this.appConfig = JSON.parse(localStorage.getItem("appConfig"));
    }
    // console.log(this.facilitatorObj);
    this.getQAList();
    let userProfile = JSON.parse(localStorage.getItem("userProfile"));
    // console.log("userProfile", userProfile)
    let filteredSessions = this.facilitatorObj.sessions.filter((s) =>
      s.userRoles.find(
        (r) =>
          r.displayName.toLowerCase() === userProfile.userrole.toLowerCase()
      )
    );
    // console.log("filteredSessions", filteredSessions)
    this.facilitatorObj.sessions = filteredSessions;
    if (this.facilitatorObj.sessions.length) {
      let resource = this.facilitatorObj?.sessions[0]?.category[this.currentCat]
        ?.resourse[this.currentResourse];
      let content = this.facilitatorObj?.sessions[0]?.category[this.currentCat]
        ?.resourse[this.currentResourse].content;
      this.resId = this.facilitatorObj?.sessions[0]?.category[
        this.currentCat
      ]?.resourse[this.currentResourse].resourseId;
      let link = this.facilitatorObj?.sessions[0]?.category[this.currentCat]
        ?.resourse[this.currentResourse].downloadLink;
      this.allCats = this.facilitatorObj?.sessions[0]?.category.length;
      this.allResOfCats = this.facilitatorObj?.sessions[0]?.category[
        this.currentCat
      ]?.resourse.length;
      // console.log("this.allCats",this.allCats)
      // console.log("this.allResOfCats",this.allResOfCats)
      // console.log("resource",resource)
      if (resource.resourceType === "questions") {
        this.isQuestions = true;
        this.isNextResource = false;
        this.index = 0;
        this.questionArr = [];
        this.answerArr = [];
        this.quesLen = [];
        this.questionObj = null;
        this.questionArr = resource.questions;
        resource.questions.forEach((q) => {
          this.answerArr.push({ questionId: q.questionId, optionId: null });
        });

        for (let i in this.questionArr) {
          this.quesLen.push(Number(i) + 1);
        }
        // console.log(this.questionArr, this.quesLen);
        this.questionObj = this.questionArr[this.index];
        // console.log("this.questionObj", this.questionObj)
      } else {
        this.isNextResource = true;
        this.isExamActivate = false;
        this.isQuestions = false;
        if (link.includes("youtube")) {
          if (link.includes("watch?v")) {
            // console.log("scsdvcsd");
            link = link.replace("watch?v=", "embed/");
          }
        } else if (link.includes("youtu")) {
          link = link.replace("youtu.be", "www.youtube.com/embed/");
        }
        // console.log(link);
        this.docFile = link;
      }
      this.contentData = content;
      let count = 0;
      this.facilitatorObj.sessions.forEach((session) => {
        if (session.category.length > 0) {
          session.category.forEach((cat) => {
            if (cat.resourse.length > 0) {
              cat.resourse.forEach((res, i) => {
                count++;
                this.resCountArr.push(res.resourseId);
              });
            }
          });
        }
      });
      // console.log("resCountArr", this.resCountArr);
    }
  },
  computed: {
    progress() {
      // console.log(this.index/this.quesLen.length*100)
      return (this.index / this.quesLen.length) * 100;
    },
  },
  methods: {
    examActivate() {
      localStorage.setItem("isFromCourse", true);
      this.$router.push("/exam-id");
    },
    getQAList() {
      ApiServices.RestGetCall("api/avenir/training/getFeedbacks").then(
        (response) => {
          // console.log("Q&A",response);
          if (response.feedbacks !== "No records found") {
            let feedbacks = JSON.parse(response.feedbacks);
            this.feedbacksList = feedbacks.filter(
              (f) => f.appId === this.facilitatorObj.appId
            );
            // console.log("feedbacksList",this.feedbacksList)
          }
        }
      );
    },
    callApi() {
      this.$root.loading = true;
      this.userProfile = JSON.parse(localStorage.getItem("userProfile"));
      // console.log(this.userProfile.username)
      // console.log(this.feedbackType)
      // console.log(this.feedbackText)
      // console.log(this.facilitatorObj.appId)
      ApiServices.RestPostCall("api/avenir/training/sendFeedback", {
        feedbackType: this.feedbackType,
        feedback: this.feedbackText,
        emailId: this.userProfile.username,
        appId: this.facilitatorObj.appId,
      }).then((aveT) => {
        this.$root.loading = false;
        this.feedbackText = "";
        this.feedbackType = "Comments";
        this.$swal({
          text: "Feedback saved successfully!",
        });
        this.getQAList();
        console.log(aveT);
      });
    },
    feebackType(value) {
      this.feedbackType = value;
      // console.log(this.feedbackType);
    },
    showNext() {
      // console.log("carousel");
      this.$refs.carousel.next();
    },
    showprev() {
      this.$refs.carousel.prev();
    },
    nextResource() {
      // console.log("this.currentResourse", this.currentResourse)
      // console.log("this.allResOfCats", this.allResOfCats)
      // console.log("this.allResOfCats - 1", this.allResOfCats - 1)
      if (this.currentResourse < this.allResOfCats - 1) {
        let resource = this.facilitatorObj?.sessions[0]?.category[
          this.currentCat
        ]?.resourse[this.currentResourse + 1];
        this.viewResource(resource, this.currentCat, this.currentResourse + 1);
      } else {
        // console.log("this.currentCat", this.currentCat)
        // console.log("this.allCats", this.allCats)
        // console.log("this.allCats - 1", this.allCats - 1)
        if (this.currentCat < this.allCats - 1) {
          this.currentResourse = 0;
          let resource = this.facilitatorObj?.sessions[0]?.category[
            this.currentCat + 1
          ]?.resourse[this.currentResourse];
          let catIndex = this.currentCat + 1;
          this.viewResource(resource, catIndex, this.currentResourse);
          if (catIndex === this.allCats) {
            // console.log("in")
            this.isNextResource = false;
          }
        } else {
          this.isNextResource = false;
          if (this.currentResourse === this.allResOfCats - 1) {
            this.isExamActivate = true;
          }
        }
      }
    },
    viewResource(resource, catIndex, resIndex) {
      // console.log("catIndex",catIndex)
      // console.log("resIndex",resIndex)
      // console.log("resource", resource)
      // console.log("questions", resource.questions)
      let id = resource.resourseId,
        link = resource.downloadLink,
        content = resource.content;
      this.currentCat = catIndex;
      this.currentResourse = resIndex;
      this.allResOfCats = this.facilitatorObj?.sessions[0]?.category[
        this.currentCat
      ]?.resourse.length;
      this.resId = id;
      // console.log("this.resId", this.resId)
      if (resource.resourceType === "questions") {
        this.isNextResource = false;
        this.isQuestions = true;
        this.index = 0;
        this.questionArr = [];
        this.answerArr = [];
        this.quesLen = [];
        this.questionObj = null;
        this.questionArr = resource.questions;
        resource.questions.forEach((q) => {
          this.answerArr.push({ questionId: q.questionId, optionId: null });
        });

        for (let i in this.questionArr) {
          this.quesLen.push(Number(i) + 1);
        }
        // console.log(this.questionArr, this.quesLen);
        this.questionObj = this.questionArr[this.index];
      } else {
        this.isNextResource = true;
        this.isExamActivate = false;
        this.isQuestions = false;
        if (link.includes("youtube")) {
          if (link.includes("watch?v")) {
            // console.log("scsdvcsd");
            link = link.replace("watch?v=", "embed/");
          }
        } else if (link.includes("youtu")) {
          link = link.replace("youtu.be", "www.youtube.com/embed/");
        }
        // console.log(link);
        this.docFile = link;
      }
      this.contentData = content;
    },
    getAnswer(value) {
      // console.log(value);
      this.answer = value;
      let objIndex = this.answerArr.findIndex(
        (obj) => obj.questionId == value.questionId
      );
      if (objIndex != -1) {
        this.answerArr[objIndex].optionId = value.optionId;
      } else {
        this.answerArr.push(value);
      }
    },
    getQuestion() {
      this.index++;
      this.questionObj = this.questionArr[this.index];
      localStorage.setItem("responseClick", "Next");
    },
    submitResponse() {
      // console.log("this.questionArr", this.questionArr)
      // console.log("this.answerArr", this.answerArr)
      // console.log("username", this.facilitatorObj.username)
      let data = this.answerArr.map((a) => {
        return {
          ...a,
          userId: this.facilitatorObj.username,
        };
      });
      // savePrePostQuestions
      ApiServices.RestPostCall(
        "api/avenir/training/savePrePostQuestions",
        data
      ).then((response) => {
        // console.log("response",response);
        if (
          this.currentCat === this.allCats - 1 &&
          this.currentResourse === this.allResOfCats - 1
        ) {
          this.isExamActivate = true;
          this.isNextResource = false;
        } else {
          this.isNextResource = true;
        }
        this.$swal({
          text: "Data saved successfully!",
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.sub-course-nav .nav-item.active {
  background-color: #cecece !important;
}
.videoCoursePage {
  background-image: none;
  background-color: #fff;
  height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
}
.accordion .card {
  border: none;
  margin-bottom: 20px;
  background: transparent;
}
// .accordion .card h2 {
//   background: url(https://cdn0.iconfinder.com/data/icons/arrows-android-l-lollipop-icon-pack/24/expand2-256.png)
//     no-repeat calc(100% - 10px) center;
//   background-size: 15px;
//   cursor: pointer;
//   font-size: 18px;
// }
// .accordion .card h2.collapsed {
//   background-image: url(https://cdn0.iconfinder.com/data/icons/entypo/91/arrow56-512.png);
// }

.accordion .card h2[aria-expanded="true"]:after {
  content: "\f106";
  font-family: "FontAwesome";
  font-weight: 900;
  float: right;
}

.accordion .card h2[aria-expanded="false"]:after {
  content: "\f107";
  font-family: "FontAwesome";
  font-weight: 900;
  float: right;
}

.accordion .card-body {
  padding-left: 0;
  padding-right: 0;
}
.video-player {
  // padding-bottom: 45%;
  height: 450px;
  border: 1px solid #e9e9eb;
}
.wrap {
  border-left: 1px solid #e9e9eb;
  .playlist-wrap {
    .course-listing {
      .nav {
        .nav-item {
          background-color: #f7f8fa;
          .section-title {
            font-family: Montserrat-SemiBold;
            font-size: 1rem;
            color: #42413c;
          }
        }
        .main-title-nav {
          background: #fff;
          border-bottom: 1px solid #e9e9eb;
        }
      }
    }
  }
}
.category-title {
  font-family: Montserrat-Medium;
  font-size: 0.9rem !important;
  color: #42413c;
}
.course-title {
  font-family: Montserrat-SemiBold;
  font-size: 1.15rem;
  color: #42413c;
}
.sub-course-nav {
  .nav-item {
    background-color: #fff !important;
    .custom-control-label {
      font-size: 0.83rem;
      color: #000;
      font-weight: 500;
    }
  }
}
.resource-title {
  font-size: 0.89rem;
}
.sign-symbol {
  color: #989898;
}
.resourse_header {
  font-size: 0.9rem;
  font-weight: 500;
  width: 300px;
}
.progressbar {
  width: 100%;
  height: 5px;
  background-color: #eee;
  margin: 1em auto;
  transition: width 500ms;
}
</style>
