<template>
  <div class="body">
    <div class="main_container">
      <training-header :appConfig="appConfig"></training-header>

      <!-- page content -->
      <div class="container-fluid" role="main" style="">
        <div class="text-sectiomn-wrap">
          <div class="row">
            <div class="col-sm-10 mx-auto">
              <div class="backgroundbg-color">
                <div class="row">
                  <div class="col-2 px-0">
                    <div class="row mb-3">
                      <div class="col-md-12 col-sm-12 col-xs-12">
                        <div
                          class="flex-container certification_status flex-column"
                        >
                          <div class="middle-div">
                            <div class="heading-title-card">
                              <img
                                src="../assets/img/unfillcertificate.jpg"
                                alt
                                style="
                                  width: 200px;
                                  display: block;
                                  margin: 0 auto;
                                "
                              />
                            </div>
                            <hr class="hr-line" />
                          </div>

                          <div>
                            <p class="text-left pr-3">
                              The kinds of birth control that work the best to
                              prevent pregnancy are the implant and IUDs —
                              they're also the most convenient to use, and the
                              most foolproof. Other birth control methods, like
                              the pill, ring, patch, and shot, are also really
                              good at preventing pregnancy if you use them
                              perfectly.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-10 px-0">
                    <div class="x_panel">
                      <div class="x_content position-relative">
                        <div class="dashboard-widget-content pt-0">
                          <div class="row">
                            <div class="col-12">
                              <div class="tableFixHead">
                                <table class="table table-bordered mb-0">
                                  <thead>
                                    <tr
                                      style="background: #363636; color: #fff"
                                    >
                                      <th scope="col">Sr. No.</th>
                                      <th scope="col">Examination Id</th>
                                      <th scope="col">Date Of Exam</th>
                                      <th scope="col">Score</th>
                                      <th scope="col">Status</th>
                                      <th scope="col">Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody v-if="examList && examList.length">
                                    <template v-for="(list, i) in examList">
                                      <tr :key="i">
                                        <td scope="row">{{ i + 1 }}</td>
                                        <td>{{ list.examId }}</td>
                                        <td>
                                          {{ list.createdOn }}
                                        </td>
                                        <td>{{ list.score }}</td>
                                        <td v-if="list.result == 'passed'">
                                          Pass
                                        </td>
                                        <td v-else>Fail</td>
                                        <td class="d-flex">
                                          <button
                                            @click="getCertificate(list)"
                                            v-if="list.result == 'passed'"
                                            type="button"
                                            class="align-items-center border-radius-0 btn btn-sm float-none justify-content-center w-auto yellow-float-btn"
                                          >
                                            View Certificate
                                          </button>
                                          <a
                                            ><button
                                              @click="retakeExam"
                                              type="button"
                                              class="btn float-none w-auto text-white border-radius-0 btn-sm font-weight-bold"
                                              style="
                                                background: #ed2226;
                                                border: 2px solid #000;
                                              "
                                            >
                                              Retake Exam
                                            </button></a
                                          >
                                          <!-- <button type="button" class="btn btn-danger"><i class="far fa-trash-alt"></i></button> -->
                                        </td>
                                      </tr>
                                    </template>
                                  </tbody>
                                  <tbody v-else>
                                    <tr>
                                      <td colspan="6">No Certificate Found</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /page content -->

      <!-- footer content -->
      <training-footer :appConfig="appConfig"></training-footer>
      <training-faq :facilitatorObj="facilitatorObj"></training-faq>
      <!-- /footer content -->
    </div>
  </div>
</template>
<script>
import trainingHeader from "../components/trainingHeader";
import trainingFooter from "../components/trainingFooter";
import trainingFaq from "./trainingFaq";
import ApiServices from "../service/ApiServices";
export default {
  data() {
    return {
      examList: null,
      appConfig: {},
      facilitatorObj: null,
    };
  },
  components: {
    trainingHeader,
    trainingFooter,
    trainingFaq,
  },
  methods: {
    getExamList() {
      this.$root.loading = true;
      ApiServices.RestPostCall("api/avenir/training/getUserExamList", {}).then(
        (res) => {
          this.$root.loading = false;
          this.examList = res.examDetails.reverse();
        }
      );
    },
    getCertificate(list) {
      localStorage.setItem("certificate-data", JSON.stringify(list));
      this.$router.push("/certificate");
    },
    retakeExam() {
      this.$router.push("/exam-id");
    },
  },
  mounted() {
    localStorage.setItem("route-name", this.$route.name);
    this.getExamList();
    if (this.$route.params.facilitatorObj) {
      this.facilitatorObj = this.$route.params.facilitatorObj;
    } else {
      this.facilitatorObj = JSON.parse(localStorage.getItem("facilitatorObj"));
    }
    if (this.$route.params.appConfig) {
      this.appConfig = this.$route.params.appConfig;
    } else {
      this.appConfig = JSON.parse(localStorage.getItem("appConfig"));
    }
  },
};
</script>
<style lang="scss" scoped>
.flex-container {
  // height: auto !important;
  background: #ececec;
  height: 595px !important;
  justify-content: flex-start;
  align-items: flex-start;
}
// .tableFixHead          { overflow-y: auto; height: 400px; }
// .tableFixHead thead th { position: sticky; top: 0; }

// /* Just common table stuff. Really. */
// table  { border-collapse: collapse; width: 100%; }
// th, td { padding: 8px 16px; }
// th     {  }
.table thead th {
  font-size: 1.3em;
  font-weight: 500;
}
.table td {
  font-size: 14px;
}
.action_btn {
  font-size: 12px;
}
.right_col {
  display: flex;
  justify-content: center;
  align-items: center;
}
.x_panel {
  background-color: #fff;
  border: 0px solid #ececec;
  padding: 0;
  position: relative;
}
.x_title {
  border-bottom: 0px solid #e6e9ed;
  padding: 15px 18px;
  background-color: #b0b0b0;
  color: #fff;
  border-radius: 7px 7px 0 0;
}
.x_content {
  padding: 8px 0px 8px;
}
.right_col {
  justify-content: center;
  align-items: center;
  display: flex;
}
.text-sectiomn-wrap {
  height: 100%;
  min-height: calc(100vh - 225px);
  overflow: hidden;
  overflow-y: scroll;
  /* background: red; */
  padding-top: 7rem;
}
.backgroundbg-color {
  // background: #ececec;
}
.heading-title-card {
  position: relative;
  z-index: 1;
  top: 0px;
  left: -43px;
  background: transparent;
  padding: 0;
  filter: drop-shadow(6px 6px 1px #c6c6c6);
}
.table thead th {
  vertical-align: bottom;
  /* border-bottom: 2px solid #dee2e6; */
  position: sticky;
  top: 0;
  z-index: 5;
  background: #363636;
  color: #fff;
}
.hr-line {
  border: 1px solid #fff;
  position: relative;
  left: -25px;
  margin: 30px 0;
}
</style>
