<template>
  <div class="body">
    <div class="main_container">
      <training-header :appConfig="appConfig"></training-header>

      <div class="container-fluid" role="main" style="">
        <div class="text-sectiomn-wrap">
          <div class="row">
            <div class="col-sm-10 mx-auto">
              <div class="backgroundbg-color">
                <div class="heading-title-card">
                  <h2 class="text-white" style="font-weight: 600">
                    {{ facilitatorObj.roleHeader }}
                  </h2>
                </div>
                <div class="x_panel">
                  <div class="x_content position-relative">
                    <div
                      class="dashboard-widget-content"
                      v-html="facilitatorObj.roleContent"
                    ></div>

                    <div
                      class="floating-submit-btn w-100"
                      style="text-align: center"
                    >
                      <div class="d-flex justify-content-between">
                        <router-link
                          :to="{
                            name: 'facilitator-page',
                            params: {
                              facilitatorObj: facilitatorObj,
                              appConfig: appConfig,
                            },
                          }"
                          class=""
                          style="margin-right: 5%"
                        >
                          <a class="btn yellow-float-btn justify-content-start">
                            Previous
                          </a>
                        </router-link>

                        <router-link
                          :to="{
                            name: 'facilitator-course',
                            params: {
                              facilitatorObj: facilitatorObj,
                              appConfig: appConfig,
                            },
                          }"
                          class=""
                          style="color: #813582"
                        >
                          <a class="btn yellow-float-btn"> Next </a>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <training-footer :appConfig="appConfig"></training-footer>
      <training-faq :facilitatorObj="facilitatorObj"></training-faq>
    </div>
  </div>
</template>
<script>
import trainingHeader from "../components/trainingHeader";
import trainingFooter from "../components/trainingFooter";
import trainingFaq from "./trainingFaq";
export default {
  components: {
    trainingHeader,
    trainingFooter,
    trainingFaq,
  },
  data() {
    return {
      facilitatorObj: {},
      appConfig: {},
    };
  },
  mounted() {
    if (this.$route.params.facilitatorObj) {
      this.facilitatorObj = this.$route.params.facilitatorObj;
    }
    if (this.$route.params.appConfig) {
      this.appConfig = this.$route.params.appConfig;
    }
  },
};
</script>
<style scoped>
.x_panel {
  background-color: #fff;
  border: 1px solid #ececec;
  padding: 0;
  position: relative;
}
.x_title {
  border-bottom: 0px solid #e6e9ed;
  padding: 15px 18px;
  background-color: #b0b0b0;
  color: #fff;
  border-radius: 7px 7px 0 0;
}
.x_content {
  padding: 25px 25px 40px;
}
.right_col {
  justify-content: center;
  align-items: center;
  display: flex;
}
.text-sectiomn-wrap {
  height: 100%;
  min-height: calc(100vh - 225px);
  overflow: hidden;
  overflow-y: scroll;
  /* background: red; */
  padding-top: 7rem;
}
.floating-submit-btn {
  bottom: -35px;
}
</style>
