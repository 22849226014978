<template>
  <div class="body" v-if="configObj">
    <div
      class="main_container main_full_div"
      :style="{
        backgroundImage: `url(${configObj.landingBgImg})`
      }"
    >
      <training-header :appConfig="configObj"></training-header>
      <div v-bind:class="getClass()">
        <div class="container-fluid">
        <!-- page content -->
        <div class="card bg-transparent border-0">
          <div class="card-body px-5 py-5">
            <div class="row">
              <div
                class="col-sm-4"
                style="
                  padding-left: 15px !important;
                  padding-right: 15px !important;
                "
              >
                <div class="">
                  <h4 class="top_text" style="font-weight: 500">
                    Welcome {{ loggedInUserProfile.firstname }}
                  </h4>
                  <div class="paragraph py-3">
                    <p>{{ configObj.contentHeading }}</p>
                    <p>{{ configObj.contentDescription }}</p>
                  </div>
                </div>
              </div>
              <div
                class="col-sm-1"
                style="
                  padding-left: 15px !important;
                  padding-right: 15px !important;
                "
              ></div>
              <div
                class="col-sm-7"
                style="
                  padding-left: 15px !important;
                  padding-right: 15px !important;
                "
              >
                <button class="btn btn-yellow border-radius-0">
                  Please select an option
                </button>
                <div class="row">
                  <div
                    class="col-sm-12 mb-2"
                    style="
                      padding-left: 10px !important;
                      padding-right: 15px !important;
                    "
                  >
                    <!-- <a href="#/facilitator-page" class="btn btn-secondary">  </a> -->
                    <div class="flex-container2">
                      <router-link
                        v-if="isTrainer"
                        :to="{
                          name: 'facilitator-page',
                          params: {
                            facilitatorObj: frmModel,
                            appConfig: configObj,
                          },
                        }"
                        class="btn btn-div border-radius-0"
                        v-show="
                          this.loggedInUserProfile.userrole !=
                          'AvenirLearnerGroup'
                        "
                      >
                        <div class="inside_content">
                          <div class="inside_img_div">
                            <img
                              src="../assets/img/fplims/ico_training_planning.png"
                              class="d-block my-2"
                              style=""
                            />
                          </div>
                          <p class="my-2">Training Planning</p>
                        </div>
                      </router-link>
                      <router-link
                        :to="{
                          name: 'course',
                          params: {
                            facilitatorObj: frmModel,
                            appConfig: configObj,
                          },
                        }"
                        class="btn btn-div border-radius-0 courseDiv"
                        style="margin-left: 30px"
                      >
                        <div class="inside_content">
                          <div class="inside_img_div">
                            <img
                              src="../assets/img/fplims/ico_courses.png"
                              class="d-block my-2"
                              style=""
                            />
                          </div>
                          <p class="my-2">Courses</p>
                        </div>
                        <!-- <span
                          class="fa fa-folder-open pull-left"
                          style="font-size: 20px; padding-right: 15px"
                        ></span
                        >training material -->
                      </router-link>
                    </div>
                    <!-- <a href="https://avenirtraining.duredemos.com/facilatorpage.html" class="btn btn-secondary"> <span class="fa fa-puzzle-piece pull-left" style="font-size: 20px;padding-right: 15px; color: #65f67f;"></span>training planning </a>
            <a href="https://avenirtraining.duredemos.com/training_agenda_session.html" class="btn btn-secondary"><span class="fa fa-folder-open pull-left" style="font-size: 20px;padding-right: 15px; color: #e641ff;"></span>training material</a> -->
                    <div class="flex-container2">
                      <router-link
                        :to="{
                          name: 'exam-id',
                          params: {
                            facilitatorObj: frmModel,
                            appConfig: configObj,
                          },
                        }"
                        class="btn btn-div border-radius-0"
                      >
                        <div class="inside_content">
                          <div class="inside_img_div">
                            <img
                              src="../assets/img/fplims/ico_course_test.png"
                              class="d-block my-2"
                              style=""
                            />
                          </div>
                          <p class="my-2">Course Test</p>
                        </div>
                        <!-- <span
                          class="fa fa-file-text-o pull-left"
                          style="font-size: 20px; padding-right: 15px"
                        ></span
                        >Course Test -->
                      </router-link>
                      <router-link
                        :to="{
                          name: 'certificate-status',
                          params: {
                            facilitatorObj: frmModel,
                            appConfig: configObj,
                          },
                        }"
                        class="btn btn-div border-radius-0"
                        style="margin-left: 30px"
                      >
                        <div class="inside_content">
                          <div class="inside_img_div">
                            <img
                              src="../assets/img/fplims/ico_certifiate.png"
                              class="d-block my-2"
                              style=""
                            />
                          </div>
                          <p class="my-2">Certificate</p>
                        </div>
                        <!-- <span
                          class="fa fa-certificate pull-left"
                          style="font-size: 20px; padding-right: 15px"
                        ></span
                        >Certificate -->
                      </router-link>
                      <!-- <router-link :to="{ name: 'feedback',  params: { facilitatorObj: frmModel}}" class="color-white text-decoration-none text-info">
              <span class="fa fa-list-alt pull-left" style="font-size: 20px;padding-right: 15px;"></span>Feedback Form
            </router-link> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <!-- /page content -->

      <!-- footer content -->
      <training-footer :appConfig="configObj"></training-footer>
      <training-faq :facilitatorObj="frmModel"></training-faq>

      <!-- /footer content -->
    </div>
  </div>
</template>

<script>
import trainingHeader from "../components/trainingHeader";
import trainingFooter from "../components/trainingFooter";
import trainingFaq from "./trainingFaq";
import ApiServices from "../service/ApiServices";

export default {
  components: {
    trainingHeader,
    trainingFooter,
    trainingFaq,
  },
  data() {
    return {
      dashboardUrl: "api/apps/Avenir-Generic/index.html#/",
      frmModel: {},
      configObj: null,
      loggedInUserProfile: {},
      isTrainer: true,
    };
  },
  methods: {
    getClass(){
      // console.log(this.loggedInUserProfile.userrole)
      if (this.loggedInUserProfile.userroleid == "65042") {
        return "marginClass";
      }
    },
    getAppDetails() {

    }
  },
  mounted() {
    this.$root.loading = true;
    this.baseUrl = ApiServices.getBaseUrl();
    this.dashboardUrl = this.baseUrl + this.dashboardUrl;
    //this.$root.loading = true;
    if (localStorage.getItem("userProfile")) {
      this.loggedInUserProfile = JSON.parse(localStorage.getItem("userProfile"));
    }
    if (this.$route.params.frmModel) {
      this.frmModel = this.$route.params.frmModel;
    } else {
      this.frmModel = localStorage.getItem("facilitatorObj") ? JSON.parse(localStorage.getItem("facilitatorObj")) : null;
    }
    if (this.$route.params.configObj) {
      this.configObj = this.$route.params.configObj;
    } else {
      this.configObj = localStorage.getItem("appConfig") ? JSON.parse(localStorage.getItem("appConfig")) : null;
    }

    if (!this.configObj) {
       ApiServices.RestGetCall('/api/avenir/training/getAppDetails',{'userId': this.loggedInUserProfile.username}).then(aveT => {
         console.log(aveT);
         this.avenirTraining=aveT
         if(this.avenirTraining.trainingApp && this.avenirTraining.config && this.avenirTraining.trainingApp !="No training app available"){
           console.log("inif hii");
           this.frmModel = this.avenirTraining.trainingApp;
           console.log(this.frmModel);
           if(this.frmModel.course.length > 0)
           this.course = this.frmModel.course[0];

           this.configObj = this.avenirTraining.config
           this.configObj.color = "#212832"
           localStorage.setItem("appConfig", JSON.stringify(this.configObj));
           localStorage.setItem("facilitatorObj", JSON.stringify(this.frmModel));
           this.$root.loading = false;
         }
       });
       this.avenirTraining = JSON.parse(localStorage.getItem("avenirTraining"));
    } else {
      this.$root.loading = false;
    }
    if (this.loggedInUserProfile.userroleid === "65042") {
      this.isTrainer = false;
    }
  },

};
</script>
<style lang="scss" scoped>
.btn-yellow:hover {
  background-color: #fec919 !important;
  border-color: #fec919 !important;
  color: #ec2029 !important;
  font-weight: 600 !important;
}
.flex-container2 {
  display: flex;
  /* justify-content: center;
  align-items: center; */
  margin-top: 2%;
}

.flex-container2 > .btn-div {
  color: white;
  width: 200px;
  height: 150px;
  background: #eeeeee !important;
  border: 4px solid #eeeeee !important;
}
.flex-container2 > .btn-div:hover {
  background-color: #eeeeee !important;
  border: 4px solid #ffc816 !important;
}
.inside_content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.inside_img_div {
  // height: 80px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inside_content span {
  font-size: 26px;
  padding-top: 2px;
}

.inside_content p {
  color: #42413c;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
}
.marginClass .courseDiv {
  margin-left: 0px !important;
}
</style>
