<template>
  <div class="body">
    <div class="main_container">
      <training-header :appConfig="configObj"></training-header>
      <div>
        <nav class="navbar navbar-expand-sm bg-light px-0">
          <ul class="navbar-nav justify-content-end w-100">
            <li class="nav-item">
              <a class="nav-link" href="#">
                <form>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search..."
                      v-model="searchName"
                      style="border-left: 0"
                    />
                  </div>
                </form>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">
                <form class="form-inline" action="/action_page.php">
                  <label for="" class="mr-sm-2">Show:</label>
                  <select class="form-control" id="sel1" v-model="perPage">
                    <option>10</option>
                    <option>50</option>
                    <option>100</option>
                    <option>500</option>
                  </select>
                </form>
              </a>
            </li>
          </ul>
        </nav>
        <div class="container pb-5">
          <div class="row">
            <div class="col-sm-12">
              <div class="listingTable mb-4">
                <div class="row mx-0">
                  <div class="col-12 px-0">
                    <div class="row mb-3">
                      <div class="col-12">
                        <h5 class="section_title mt-3">Manage Request</h5>
                      </div>
                      <!-- <div class="col-6">
                        <button
                          class="btn yellow-float-btn float-right addTemp_btn mt-3 mr-0 justify-content-center w-auto border-radius-0"
                          data-toggle="collapse"
                          href="#add_indicator_type"
                          @click="edittableforflage()"
                        >
                          <span class="border_bottom_grey">
                            <i class="fa fa-plus" aria-hidden="true"></i> Add
                            User Role
                          </span>
                        </button>
                      </div> -->
                    </div>
                    <div class="card tableCard border-0 bg-transparent">
                      <div class="card-body p-0">
                        <table
                          class="table table-bordered m-0 bg-white"
                          id="app-datatable"
                        >
                          <thead>
                            <tr
                              style="
                                background: rgb(129, 53, 130);
                                color: rgb(255, 255, 255);
                              "
                            >
                              <th>Name</th>
                              <th>Email Id</th>
                              <!-- <th>Status</th> -->
                              <th>Approved By</th>
                              <th>Registration Date</th>
                              <th>User Role</th>
                              <th>Manage Requests</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="data in typeList">
                              <td>{{ data.firstName }} {{ data.lastName }}</td>
                              <td>{{ data.email }}</td>
                              <!-- <td>{{ data.status }}</td> -->
                              <td>{{ data.approvedBy }}</td>
                              <td>{{ data.registrationDate }}</td>
                              <td>{{ data.userRole }}</td>
                              <td class="">
                                <div class="d-flex">
                                  <select class="form-control" v-model="data.status">
                                    <option value="New" disabled>Please select</option>
                                    <option value="Approved">Approve</option>
                                    <option value="Rejected">Reject</option>
                                  </select>
                                  <input
                                    type="submit"
                                    class="btn yellow-float-btn btn-sm ml-2 w-auto justify-content-center"
                                    :disabled="data.status === 'New'"
                                    @click="manageRequests(data.status, data.email, data.userRole)"
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div class="mt-3" v-if="typeList.length > 0"></div>
                      </div>
                    </div>
                    <div class="mt-3" v-if="typeList.length > 0">
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="allTypeListLen"
                        :per-page="perPage"
                        first-text="First"
                        prev-text="Prev"
                        next-text="Next"
                        last-text="Last"
                        align="center"
                      ></b-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-sm-12">
              <div class="wizardCard mb-4 collapse" id="add_indicator_type">
                <div class="card shadow-sm">
                  <div
                    class="card-header font-weight-bold bg-dark text-white"
                    style="font-size: 1rem"
                  >
                    Add User Role
                  </div>
                  <div class="card-body">
                    <div class="row mx-0 mb-2">
                      <div class="col-sm-12 mb-1">
                        <div class="form-group row mx-0">
                          <label class="col-sm-3 col-form-label"
                            >User Role Name</label
                          >
                          <div class="col-sm-9">
                            <input
                              type="text"
                              class="form-control"
                              v-model="userRoleName"
                              id="indicatorTypeName"
                              placeholder
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mx-0 mb-2">
                      <div class="col-sm-12 mb-1">
                        <div class="form-group row mx-0">
                          <label for="" class="col-sm-3 col-form-label"
                            >Description</label
                          >
                          <div class="col-sm-9">
                            <input
                              type="text"
                              class="form-control"
                              v-model="userDiscription"
                              id="indicatorTypeName"
                              placeholder
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mx-0 mb-2">
                      <div class="justify-content-end d-flex w-100">
                        <button
                          class="btn yellow-float-btn justify-content-center w-auto"
                          v-if="updatetableflag == false"
                          @click="userRoletype(userRoleName, userDiscription)"
                        >
                          Save
                        </button>
                        <button
                          class="btn btn-success text-white"
                          v-if="updatetableflagfor1entry == true"
                          @click="
                            userRoletypeUpdate(userRoleName, userDiscription)
                          "
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <training-footer :appConfig="configObj"></training-footer>
    </div>
  </div>
</template>
    <script>
import ApiServices from "../service/ApiServices";
import trainingHeader from "../components/trainingHeader";
import trainingFooter from "../components/trainingFooter";
export default {
  data() {
    return {
      userProfile: "",
      data: "",
      configObj: {},
      perPage: 10,
      searchName: "",
      indicatortablevalue: [],
      allTypeListLen: "",
      currentPage: 1,
      status: "",
    };
  },
  computed: {
    typeList() {
      let filteredApps = this.indicatortablevalue.filter((a) => {
        a.firstName = a.firstName.toString();
        return (
          (a.firstName && a.firstName.toLowerCase().includes(this.searchName.toLowerCase())) ||
          (a.lastName && a.lastName.toLowerCase().includes(this.searchName.toLowerCase())) ||
          (a.email && a.email.toLowerCase().includes(this.searchName.toLowerCase())) ||
          (a.status && a.status.toLowerCase().includes(this.searchName.toLowerCase())) ||
          (a.approvedBy && a.approvedBy.toLowerCase().includes(this.searchName.toLowerCase())) ||
          (a.registrationDate && a.registrationDate.toString().toLowerCase().includes(this.searchName.toLowerCase())) ||
          (a.userRole && a.userRole.toLowerCase().includes(this.searchName.toLowerCase()))
        );
      });

      // console.log("filteredApps",filteredApps)
      this.allTypeListLen = filteredApps.length;
      return filteredApps.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
  },
  components: {
    trainingHeader,
    trainingFooter,
  },
  methods: {
    getTypeList() {
      let filteredApps = this.indicatortablevalue.filter((a) => {
        //console.log(a)
        a.firstName = a.firstName.toString();
        return (
          (a.firstName &&
            a.firstName
              .toLowerCase()
              .includes(this.searchName.toLowerCase())) ||
          (a.firstName && a.firstName.includes(this.searchName.toLowerCase()))
        );
      });

      // console.log("filteredApps",filteredApps)
      this.allTypeListLen = filteredApps.length;
      return filteredApps.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
    manageRequests(status, email, userRole) {
      this.$root.loading = true
      let request = {
        email: email,
        userRole: userRole,
        orgUid: "IqnG05geR2W",
        appIds: "64691",
        status: status
      }
      ApiServices.RestPostCall("api/avenir/register/manageRequest", request).then((response) => {
        if (status == "Rejected") {
          this.$swal({
            text: "Your request has been rejected by the adminstrator.",
          });
        } else {
          this.$swal({
            text: response.message,
          });
        }
        this.status = "";
        this.getUserRequest();
        this.getTypeList();
        console.log(response);
      }).catch(() => {
        this.$root.loading = false
      })
    },
    getUserRequest() {
      ApiServices.RestGetCall("api/avenir/register/getUserRequests", {}).then(
        (response) => {
          console.log(response);
          this.indicatortablevalue = response.requests;
          console.log(this.data);
          this.$root.loading = false
        }
      );
    },
  },
  mounted() {
    this.userProfile = JSON.parse(localStorage.getItem("userProfile"));
    if (this.$route.params.configObj) {
      this.configObj = this.$route.params.configObj;
    } else {
      this.configObj = JSON.parse(localStorage.getItem("appConfig"));
    }
    this.getUserRequest();
  },
};
</script>
<style lang="scss" scoped>
.navbar {
  background: #3c3b37 !important;
  border-color: #3c3b37;
}
.table thead th {
  vertical-align: bottom;
  /* border-bottom: 2px solid #dee2e6; */
  position: sticky;
  top: 0;
  z-index: 5;
  background: #363636;
  color: #fff;
}
.table td,
.table th {
  font-size: 0.9rem;
}
</style>
