<template>
  <div class="body">
    <div class="main_container">
      <training-header :appConfig="appConfig"></training-header>

      <!-- page content -->
      <div class="container-fluid" role="main">
        <div class="text-sectiomn-wrap">
          <div class="row px-4 pt-4">
            <div class="col-md-10 col-sm-10 col-xs-10 mx-auto">
              <div class="backgroundbg-color">
                <div class="heading-title-card">
                  <h2 class="examid mb-0" style="font-weight: 600">
                    EXAM ID NO: {{ examDetails.examId }}
                  </h2>
                </div>
                <div class="x_panel qestion_page">
                  <!-- <div class="x_title">
                    <h2 class="col-6">Certified Solutions Expert Exam</h2>
                    <h2 class="examid col-6 text-right">
                      EXAM ID NO: {{ examDetails.examId }}
                    </h2>

                    <div class="clearfix"></div>
                  </div> -->
                  <div class="x_content position-relative">
                    <div class="dashboard-widget-content">
                      <div class="row">
                        <div
                          class="col-md-12"
                          style="
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          "
                        >
                          <!-- <img src="images/score.PNG" style="display: block; margin: 0 auto;"> -->
                          <div class="scorboard-width">
                            <!-- <h2 style="font-size: 3em; text-align: center">
                              <span
                                v-if="examDetails.result == 'passed'"
                                style="color: #42b869"
                                >Congratulations!
                              </span>
                              <span v-else>Sorry! </span>
                              <span style="color: #fb6b59"
                                >You {{ examDetails.result }}.</span
                              >
                            </h2> -->
                            <!-- <p
                              style="
                                color: #ababab;
                                font-weight: 400;
                                padding-left: 4px;
                                font-size: 1.3em;
                                line-height: 3;
                                text-align: center;
                              "
                            >
                              You can review your quiz choices or continue to
                              the your certificate
                            </p> -->
                            <div class="row mb-2">
                              <div class="col-md-12">
                                <!-- <div class="progress blue">
                                  <span class="progress-left">
                                    <span class="progress-bar"></span>
                                  </span>
                                  <span class="progress-right">
                                    <span class="progress-bar"></span>
                                  </span>
                                  <div class="progress-value">{{examDetails.score}}%</div>
                                </div> -->
                                <div
                                  class="d-flex justify-content-center rchart"
                                >
                                  <highcharts
                                    v-if="chart"
                                    :options="chart"
                                    ref="inputCharts"
                                    style="height: 200px; width: 200px"
                                  ></highcharts>
                                  <div class="score">
                                    {{ examDetails.score }}%
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div
                                  class="d-flex justify-content-center"
                                  style="padding-top: 2%"
                                >
                                  <!-- <div>
                                    <h4 class="text-status">
                                      {{ examDetails.resultStatus }}
                                    </h4>
                                  </div>
                                  <br /> -->
                                  <div class="icon-rank">
                                    <span
                                      v-if="examDetails.resultStatus == 'Poor'"
                                    >
                                      <img
                                        src="../assets/img/poor6.png"
                                        class="img-responsive iconfaces"
                                      />
                                      <!-- <i>Poor</i> -->
                                    </span>
                                    <span v-else>
                                      <img
                                        src="../assets/img/poor.png"
                                        class="img-responsive iconfaces"
                                      />
                                      <!-- <i>Poor</i> -->
                                    </span>
                                    <span
                                      v-if="examDetails.resultStatus == 'Fair'"
                                    >
                                      <img
                                        src="../assets/img/fairgreen.png"
                                        class="img-responsive iconfaces"
                                      />
                                      <!-- <i>Fair</i> -->
                                    </span>
                                    <span v-else>
                                      <img
                                        src="../assets/img/fairG.png"
                                        class="img-responsive iconfaces"
                                      />
                                      <!-- <i>Fair</i> -->
                                    </span>
                                    <span
                                      v-if="examDetails.resultStatus == 'Good'"
                                    >
                                      <img
                                        src="../assets/img/goodgreen.png"
                                        class="img-responsive iconfaces"
                                      />
                                      <!-- <i>Good</i> -->
                                    </span>
                                    <span v-else>
                                      <img
                                        src="../assets/img/googG.png"
                                        class="img-responsive iconfaces"
                                      />
                                      <!-- <i>Good</i> -->
                                    </span>
                                    <span
                                      v-if="
                                        examDetails.resultStatus == 'Excellent'
                                      "
                                    >
                                      <img
                                        src="../assets/img/excellentGreen.png"
                                        class="img-responsive iconfaces"
                                      />
                                      <!-- <i>Excellent</i> -->
                                    </span>
                                    <span v-else>
                                      <img
                                        src="../assets/img/excellentG.png"
                                        class="img-responsive iconfaces"
                                      />
                                      <!-- <i>Excellent</i> -->
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div class="row mt-4">
                        <div class="col-sm-12">
                          <p
                            style="
                              color: #42413c;
                              font-weight: 500;
                              padding-left: 4px;
                              font-size: 1rem;

                              text-align: center;
                            "
                          >
                            You may want to take the test again, or review your
                            answers.
                          </p>
                          <p
                            style="
                              color: #42413c;
                              font-weight: 400;
                              padding-left: 4px;
                              font-size: 0.9rem;

                              text-align: center;
                            "
                          >
                            However, if you may also wish to proceed to the
                            certificate.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="floating-submit-btn w-100" style="">
                          <div class="d-flex justify-content-between">
                            <router-link
                              v-if="this.facilitatorObj && this.facilitatorObj.course && this.facilitatorObj.course[0].examRetake >=
                                noofattemptvalue
                              "
                              :to="{
                                name: 'exam-id',
                                params: {
                                  facilitatorObj: facilitatorObj,
                                  appConfig: appConfig,
                                },
                              }"
                              class=""
                              style=""
                            >
                              <a
                                class="btn yellow-float-btn justify-content-center"
                                >Retake Exam</a
                              >
                            </router-link>
                            <router-link
                              v-if="backToCourse"
                              :to="{
                                name: 'course'
                              }"
                              class=""
                              style=""
                            >
                              <a
                                class="btn yellow-float-btn justify-content-center"
                                >Back to Courses</a
                              >
                            </router-link>
                            <router-link
                              :to="{
                                name: 'review-exam',
                                params: {
                                  facilitatorObj: facilitatorObj,
                                  appConfig: appConfig,
                                },
                              }"
                              class=""
                              style=""
                            >
                              <a
                                class="btn yellow-float-btn justify-content-center"
                                >Review Answer</a
                              >
                            </router-link>

                            <router-link
                              v-if="examDetails.result == 'passed'"
                              :to="{
                                name: 'certificate',
                                params: {
                                  facilitatorObj: facilitatorObj,
                                  appConfig: appConfig,
                                },
                              }"
                              class=""
                            >
                              <a
                                class="btn yellow-float-btn justify-content-center"
                                >Get Certificate</a
                              >
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /page content -->

      <!-- footer content -->
      <training-footer :appConfig="appConfig"></training-footer>
      <training-faq :facilitatorObj="facilitatorObj"></training-faq>
      <!-- /footer content -->
    </div>
  </div>
</template>
<script>
import trainingHeader from "../components/trainingHeader";
import trainingFooter from "../components/trainingFooter";
import trainingFaq from "./trainingFaq";
import Highcharts from "highcharts";
import { Chart } from "highcharts-vue";
export default {
  components: {
    trainingHeader,
    trainingFooter,
    trainingFaq,
    highcharts: Chart,
  },
  data() {
    return {
      // examDetails: this.$route.params.data
      examDetails: JSON.parse(localStorage.getItem("score-board")),
      chart: null,
      noofattemptvalue: null,
      facilitatorObj: {},
      appConfig: {},
    };
  },
  computed: {
    backToCourse() {
      return localStorage.getItem("isFromCourse")
    }
  },
  methods: {
    drawChart() {
      let chart = {
        chart: {
          renderTo: "container",
          type: "pie",
        },
        credits: {
          enabled: false,
        },
        exporting: {
          enabled: false,
        },
        title: {
          text: "",
        },
        colors: ["#23d14a", "#ccc"],
        plotOptions: {
          series: {
            enableMouseTracking: false,
          },
          pie: {
            borderColor: "#fff",
            innerSize: "70%",
            dataLabels: {
              enabled: false,
              format: "",
              style: {
                color:
                  (Highcharts.theme && Highcharts.theme.contrastTextColor) ||
                  "black",
              },
            },
          },
        },
        tooltip: {
          enabled: false,
          pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
        },
        series: [
          {
            name: "Source",
            data: [
              ["Score", this.examDetails.score],
              //   ['Call centre', 31],
              //   ['Mela', 9],
              ["Empty value", 100 - this.examDetails.score],
            ],
          },
        ],
      };
      this.chart = chart;
    },
  },
  mounted() {
    this.drawChart();
    localStorage.setItem("route-name", this.$route.name);
    if (this.$route.params.facilitatorObj) {
      this.facilitatorObj = this.$route.params.facilitatorObj;
    } else {
      this.facilitatorObj = JSON.parse(localStorage.getItem("facilitatorObj"));
    }
    if (this.$route.params.appConfig) {
      this.appConfig = this.$route.params.appConfig;
    } else {
      this.appConfig = JSON.parse(localStorage.getItem("appConfig"));
    }
    if (
      this.facilitatorObj.course[0].poorFrom <= this.examDetails.score &&
      this.facilitatorObj.course[0].poorTo > this.examDetails.score
    ) {
      this.examDetails.resultStatus == "Poor";
    } else if (
      this.facilitatorObj.course[0].fairFrom <= this.examDetails.score &&
      this.facilitatorObj.course[0].fairTo > this.examDetails.score
    ) {
      this.examDetails.resultStatus == "Fair";
    } else if (
      this.facilitatorObj.course[0].goodFrom <= this.examDetails.score &&
      this.facilitatorObj.course[0].goodTo > this.examDetails.score
    ) {
      this.examDetails.resultStatus == "Good";
    } else if (
      this.facilitatorObj.course[0].excellentFrom <= this.examDetails.score
    ) {
      this.examDetails.resultStatus == "Excellent";
    }

    let obj = JSON.parse(localStorage.getItem("userDetails"));
    this.noofattemptvalue = obj.numberOfAttempts * 1 + 1;
  },
};
</script>
<style scoped lang="scss">
.x_panel {
  background-color: #fff;
  border: 1px solid #ececec;
  padding: 0;
  position: relative;
}
.x_title {
  border-bottom: 0px solid #e6e9ed;
  padding: 15px 18px;
  background-color: #b0b0b0;
  color: #fff;
  border-radius: 7px 7px 0 0;
}
.x_content {
  padding: 25px 25px 40px;
}
.button-div {
  display: flex;
  justify-content: center;
  margin-bottom: 4%;
}
.x_content h4 {
  font-size: 3em;
  font-weight: 500;
  color: #5bd282;
}
.rchart {
  position: relative;
}
.score {
  /* position: absolute;
    top: 38%; */
  font-size: 25px;
  font-family: "Montserrat-SemiBold";
  /* left: 30%; */
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.highcharts-container {
  height: 200px !important;
  width: 200px !important;
}
.heading-title-card {
  position: absolute;
  z-index: 1;
  top: -40px;
  background: transparent;
  padding: 2px 12px 2px 0;
  color: #343434 !important;
  h2 {
    font-size: 0.9rem;
    color: #343434 !important;
  }
}
</style>
