<template>
  <div class="body">
    <div class="main_container">
      <training-header :appConfig="appConfig"></training-header>

      <!-- page content -->
      <div class="container-fluid" role="main" style="margin-left: 2px">
        <!--            CUSTOM PROGESS INDICATOR - START    -->
        <div class="text-sectiomn-wrap">
          <div class="row px-4 pt-4">
            <!-- <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="x_panel step_panel" style="background: #393939 !important;border-radius: 10px">
                <div class="x_content" style="padding: 20px 25px;">
                  <div class="process_steps">
                    <ul id="progress">
                      <li class="active"><span>Download</span></li>
                      <li class="active"><span>Customize</span></li>
                      <li><span>Complete</span></li>
                    </ul>

                  </div>
                </div>
              </div>
            </div> -->

            <div class="col-md-10 col-sm-10 col-xs-10 mx-auto">
              <div class="backgroundbg-color">
                <div class="heading-title-card">
                  <h2 class="text-white" style="font-weight: 600">
                    Facilitator, please customize your course to your local
                    needs
                  </h2>
                </div>
                <div class="x_panel">
                  <div class="x_content position-relative">
                    <div class="dashboard-widget-content">
                      <table
                        class="table table-bordered"
                        style="font-size: 14px; font-weight: 600"
                      >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>
                              Develop your agenda using the following resources
                            </th>
                            <th>Select</th>
                            <th>Type of session</th>
                            <th>Recommended duration</th>
                            <th>Your selection of duration</th>
                          </tr>
                        </thead>
                        <tbody v-if="facilitatorObj.agenda">
                          <template
                            v-for="(agenda, i) in facilitatorObj.agenda"
                          >
                            <tr :key="i">
                              <td>{{ i + 1 }}</td>
                              <td v-html="agenda.agenda"></td>
                              <td style="text-align: center">
                                <input
                                  type="checkbox"
                                  :value="agenda.masterAgendaId"
                                  :id="agenda.masterAgendaId"
                                  v-model="agendaSelected"
                                  @change="
                                    getSelected(agenda.masterAgendaId, $event)
                                  "
                                />
                              </td>
                              <td v-html="goSearch(agenda.typeOfSession)"></td>
                              <td>{{ agenda.recommendedDuration }}</td>
                              <td>
                                <select
                                  name="basiccontime"
                                  class="form-control"
                                  @change="getDuration(agenda.masterAgendaId, $event)"
                                  :disabled="!agendaSelected.includes(agenda.masterAgendaId)"
                                  v-model="selectedDuration[agenda.masterAgendaId]"
                                >
                                  <!-- <option disabled selected>Select minutes</option> -->
                                  <option
                                    :value="d"
                                    v-for="d in duration"
                                    :key="d"
                                  >
                                    {{ d }} min
                                  </option>
                                </select>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>

                      <div
                        class="floating-submit-btn w-100"
                        style="text-align: center"
                      >
                        <div class="d-flex justify-content-between">
                          <router-link
                            :to="{
                              name: 'facilitator',
                              params: {
                                facilitatorObj: facilitatorObj,
                                appConfig: appConfig,
                              },
                            }"
                            class=""
                            style="margin-right: 5%"
                          >
                            <a
                              href="javascript:void(0)"
                              class="btn yellow-float-btn justify-content-start"
                            >
                              Previous
                            </a>
                          </router-link>

                          <a
                            href="javascript:void(0)"
                            class="btn yellow-float-btn"
                            role="button"
                            @click.prevent.stop="checkAgenda"
                          >
                            Next
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /page content -->

      <!-- footer content -->
      <training-footer :appConfig="appConfig"></training-footer>
      <training-faq :facilitatorObj="facilitatorObj"></training-faq>
      <!-- /footer content -->
    </div>
  </div>
</template>
<script>
import trainingHeader from "../components/trainingHeader";
import trainingFooter from "../components/trainingFooter";
import trainingFaq from "./trainingFaq";
import ApiServices from "../service/ApiServices";
import swal from "sweetalert2";
export default {
  components: {
    trainingHeader,
    trainingFooter,
    trainingFaq,
  },
  data() {
    return {
      facilitatorObj: {},
      appConfig: {},
      duration: [15, 30, 45, 60, 75, 90, 105, 120],
      masterAgenda: null,
      agendaSelected: [],
      selectedAgendas: [],
      selectedAgendasArr: [],
      durationSelected: 15,
      obj: null,
      selectedDuration: {}
    };
  },
  methods: {
    goSearch(sessId) {
      var sessname = this.facilitatorObj.sessions.filter((obj) => {
        return obj.sessionId == sessId;
      });
      if (sessname.length) return sessname[0].session;
      else return "Daidatic Session";
    },
    getUserAgenda() {
      this.$root.loading = true;
      ApiServices.RestPostCall("api/avenir/training/getMasterAgenda", {}).then(
        (res) => {
          this.$root.loading = false;
          this.masterAgenda = res.masterAgenda;
          localStorage.setItem(
            "masterAgenda",
            JSON.stringify(this.masterAgenda)
          );
        }
      );
    },
    getSelected(id, e) {
      // console.log(e.target.checked)
      // console.log("agendaSelected",this.agendaSelected)
      if (e.target.checked) {
        let duration = this.selectedDuration[id] ? this.selectedDuration[id] : 15
        this.selectedDuration[id] = 15
        this.obj = { masterAgendaId: id, duration: duration, selected: true };
        this.selectedAgendas.push(this.obj);
      } else {
        this.selectedAgendas = this.selectedAgendas.filter(function (el) {
          return el.masterAgendaId != id;
        });
        this.selectedDuration[id] = null
      }
      // console.log("this.selectedAgendas",this.selectedAgendas)
      localStorage.setItem(
        "selectedAgendas",
        JSON.stringify(this.selectedAgendas)
      );
    },
    getDuration(id, e) {
      // console.log(e.target.value, event.target.id, this.agendaSelected)
      this.durationSelected = e.target.value;
      let duration = e.target.value,
        obj = {},
        selectedArr = [];
      this.obj = {
        masterAgendaId: id,
        duration: this.durationSelected,
        selected: true,
      };

      let objIndex = this.selectedAgendas.findIndex(
        (obj) => obj.masterAgendaId == id
      );
      if (objIndex != -1) {
        this.selectedAgendas[objIndex].duration = this.durationSelected;
      }
      localStorage.setItem(
        "selectedAgendas",
        JSON.stringify(this.selectedAgendas)
      );
    },
    checkAgenda() {
      if (this.selectedAgendas.length > 0) {
        //  this.$router.push("/agenda-doc");
        this.$router.push({
          name: "agenda-doc",
          params: {
            facilitatorObj: this.facilitatorObj,
            appConfig: this.appConfig,
          },
        });
      } else {
        Swal.fire({
          text: "Please select atleast one course!",
          icon: "warning",
          confirmButtonColor: "#ed2226",
          cancelButtonColor: "#d33",
        });
      }
    },
  },
  mounted() {
    //this.getUserAgenda()
    if (this.$route.params.facilitatorObj) {
      this.facilitatorObj = this.$route.params.facilitatorObj;
    } else {
      this.facilitatorObj = JSON.parse(localStorage.getItem("facilitatorObj"));
    }
    console.log("this.facilitatorObj", this.facilitatorObj)
    if (this.$route.params.appConfig) {
      this.appConfig = this.$route.params.appConfig;
    } else {
      this.appConfig = JSON.parse(localStorage.getItem("appConfig"));
    }
  },
};
</script>
<style scoped>
.x_panel {
  background-color: #fff;
  border: 1px solid #ececec;
  padding: 0;
  position: relative;
}
.x_title {
  border-bottom: 0px solid #e6e9ed;
  padding: 15px 18px;
  background-color: #b0b0b0;
  color: #fff;
  border-radius: 7px 7px 0 0;
}
.x_content {
  padding: 25px 25px 40px;
}
.table-bordered td {
  font-weight: 400;
}
.text-sectiomn-wrap {
  height: 100%;
  min-height: calc(100vh - 225px);
  overflow: hidden;
  overflow-y: scroll;
  /* background: red; */
  padding-top: 7rem;
}
.floating-submit-btn {
  bottom: -35px;
}
.table thead th {
  vertical-align: bottom;
  /* border-bottom: 2px solid #dee2e6; */
  position: sticky;
  top: 0;
  z-index: 5;
  background: #363636;
  color: #fff;
}
.form-control {
  font-size: 0.8rem;
}
.dashboard-widget-content {
  padding-top: 0;
}
.dashboard-widget-content p {
  margin-bottom: 0;
}
</style>
