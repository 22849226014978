<template>
    <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6 page-main-title">
            <div class="row">
              <div class="col-lg-2 text-center col-md-2" v-if="userProfile.userrole == 'AvenirAppAdmin'">
                <a v-if="settings.countryName.toLowerCase() === 'nepal'" href="#/wizard" class="f-s-1-75rem"><i class="fa fa-long-arrow-left color-white pt-3" aria-hidden="true"></i></a>
                <a v-else href="#/wizard" class="f-s-1-75rem"><i class="fa fa-long-arrow-left color-white pt-3" aria-hidden="true"></i></a>
              </div>
              <div class="col-lg-2 text-center col-md-2" v-else>
                <a v-if="settings.countryName.toLowerCase() === 'nepal'" href="#/select-plan-nepal" class="f-s-1-75rem"><i class="fa fa-long-arrow-left color-white pt-3" aria-hidden="true"></i></a>
                <a v-else href="#/select-module" class="f-s-1-75rem"><i class="fa fa-long-arrow-left color-white pt-3" aria-hidden="true"></i></a>
              </div>
              <div class="col-lg-10 col-md-10">
                <h3 class="color-white f-w-100"><span class="border-bottom-white text-uppercase">Organisation Unit Management</span></h3>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
             <ul class="navbar-nav ml-auto mt-2 mt-lg-0 topnavbarlist">
          <li class="nav-item dropdown" v-if="userProfile.userrole == 'AvenirAppAdmin'">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="fa fa-sliders"></i> Setup
            </a>
            <div class="dropdown-menu innerdropdownmenu" aria-labelledby="navbarDropdown" >
              <a class="dropdown-item" href="#/wizard">Application Setup</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#/organisationUnit">Location GML</a>
              <!-- <div class="dropdown-divider"></div> -->
              <!-- <a class="dropdown-item" href="#">Module Selection</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Data Upload</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Content Management</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">User Management</a> -->
            </div>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="fa fa-laptop"></i> Web Link
            </a>
            <div class="dropdown-menu innerdropdownmenu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" :data-link="dashboardUrl" @click="copyToClipboard">Copy Link</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" target="_blank" :href="dashboardUrl">Go to Dashboard</a>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="fa fa-user"></i> User
            </a>
            <div class="dropdown-menu innerdropdownmenu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" href="#/profile">Profile</a>
              
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" @click="logout">Log out</a>
              
            </div>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link pointer-event-none" @click="logout"><i class="fa fa-user"></i> Log out</a>
          </li> -->
          <li class="nav-item">
            <a class="nav-link pointer-event-none" href="#"><i class="fa fa-question-circle"></i> Help</a>
          </li>
          <!-- <li class="nav-item">
                <a class="nav-link" href="#/bookmark-chart"><i class="fa fa-bookmark"></i></a>
              </li> -->
          <li class="nav-item dropdown d-none">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Dropdown
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Something else here</a>
            </div>
          </li>
        </ul>
          </div>
        </div>
      </div>
    
</template>
<script>
import ApiServices from '../../service/ApiServices';
export default {
  data(){
    return{
      baseUrl: "http://localhost:8080/dhis/",
      dashboardUrl: "api/apps/Avenir-Generic/index.html#/",
      settings: settings,
      userProfile: ''
    }
  },
  methods:{
    copyToClipboard(e){
      //console.log(e.target.getAttribute('data-link'));
      var dummy = document.createElement('input'),
      text = e.target.getAttribute('data-link');
      document.body.appendChild(dummy);
      dummy.value = text;
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);
    },
    logout(){
      //dhis-web-commons-security/logout.action
      localStorage.removeItem("userProfile");
      localStorage.removeItem("loggedInUserId");
      ApiServices.RestGetCall('dhis-web-commons-security/logout.action',{}).then(res=>{
        console.log(res);
      })
      //window.open('http://intraavenir.duredemos.com/service/dhis-web-commons-security/logout.action', '_blank');
      this.$router.push("/");
    }
  },
  mounted(){
    this.baseUrl = ApiServices.getBaseUrl();
    this.dashboardUrl = this.baseUrl+this.dashboardUrl;
    this.userProfile = JSON.parse(localStorage.getItem("userProfile"));
    //console.log(this.dashboardUrl)
  }
}
</script>