<template>
  <div>
    <!-- <button @click="back">Back to Resource Library</button> -->
    <div v-if="doc.includes('youtube')">
      <iframe width="100%" height="450px" :src="doc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <div v-else-if="doc.includes('ppt') || doc.includes('pptx') || doc.includes('doc') || doc.includes('docx') || doc.includes('xls') || doc.includes('xlsx')">
      <VueDocPreview :url="doc" type="office" :height="450"/>
    </div>
    <div class="document-preview-btn" v-else>
      <a class="btn yellow-float-btn w-auto" :href="doc" target="_blank"> View File <i class="fa fa-eye ml-1 my-1" aria-hidden="true"></i></a>
    </div>
    
  </div>
</template>
 
<script>
import VueDocPreview from 'vue-doc-preview'
export default {
  components: {
    VueDocPreview
  },
  props: ['doc'],
  data(){
    console.log(this.doc)
    
    return {
        docPreview :'https://fplmislearning.duredemos.com/trainingResourceFiles/file_example_PPT_250kB.ppt'
    }
  },
  methods:{
    
  },
  mounted(){
    
  }
}
</script>