<template>
  <div class="body">
    <div class="main_container">
      <!-- <training-header></training-header> -->
      <training-header :appConfig="appConfig"></training-header>
      <!-- page content -->
      <div class="container-fluid pb-5" role="main">
        <div class="row">
          <div class="col-md-6 col-sm-6 col-xs-12" v-if="facilitatorObj && facilitatorObj.introPhotos && facilitatorObj.introPhotos.length">
            <div
              id="shape_circle_Shw"
              v-if="facilitatorObj.introPhotos.length == 1"
            >
              <img
                :src="facilitatorObj.introPhotos[0].photoContent"
                class="imgHarmonia"
                width="350px"
                height="350px"
                style="margin-left: 99px"
              />
              <!-- src="../assets/img/puzzlepage.png" -->
              <!-- <img src="images/el.gif" height="200px" id="gifTest" style="opacity: 0"> -->
            </div>
            <div class="m-auto sliderDiv" v-else>
              <!-- <button @click="showNext">show me the next</button>
              <button @click="showprev">show me the showprev</button> -->
              <vueSlickCarousel
                v-bind="settings"
                ref="carousel"
                v-if="facilitatorObj.introPhotos.length > 0"
              >
                <template v-for="(item, index) in facilitatorObj.introPhotos">
                  <div>
                    <img :src="item.photoContent" />
                  </div>
                </template>

                <template #prevArrow="arrowOption">
                  <div class="custom-arrow">
                    {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
                  </div>
                </template>
                /*...*/
              </vueSlickCarousel>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="x_panel facilatorPage pr-5" style="">
              <div v-html="facilitatorObj.introContent"></div>
              <div class="mt-5">
                <router-link
                  :to="{
                    name: 'facilitator',
                    params: {
                      facilitatorObj: facilitatorObj,
                      appConfig: appConfig,
                    },
                  }"
                  class="btn btn-yellow border-radius-0"
                  role="button"
                >
                  {{ facilitatorObj.introLinkText }}
                  <i
                    class="fa fa-angle-double-right"
                    style="padding-left: 2px"
                  ></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /page content -->

      <!-- footer content -->
      <training-footer :appConfig="appConfig"></training-footer>
      <training-faq :facilitatorObj="facilitatorObj"></training-faq>
      <!-- /footer content -->
    </div>
  </div>
</template>
<script>
import trainingHeader from "../components/trainingHeader";
import trainingFooter from "../components/trainingFooter";
import trainingFaq from "./trainingFaq";
import vcarousel from "carousel";
import vueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  components: {
    trainingHeader,
    trainingFooter,
    trainingFaq,
    vcarousel,
    vueSlickCarousel,
  },
  data() {
    return {
      facilitatorObj: {},
      appConfig: {},
      slide: 0,
      sliding: null,
      currentOffset: 0,
      windowSize: 0,
      paginationFactor: 310,
      settings: {
        arrows: true,
        dots: true,
        dotsClass: "slick-dots custom-dot-class",
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        swipeToSlide: true,
      },
    };
  },
  mounted() {
    if (this.$route.params.facilitatorObj) {
      this.facilitatorObj = this.$route.params.facilitatorObj;
    } else {
      this.facilitatorObj = JSON.parse(localStorage.getItem("facilitatorObj"));
    }
    if (this.$route.params.appConfig) {
      this.appConfig = this.$route.params.appConfig;
    } else {
      this.appConfig = JSON.parse(localStorage.getItem("appConfig"));
    }
  },
  computed: {},
  methods: {
    showNext() {
      console.log("carousel");
      this.$refs.carousel.next();
    },
    showprev() {
      this.$refs.carousel.prev();
    },
  },
};
</script>
<style scoped>
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*        =========== NAV BAR CENTRE =============*/

.navbar-toggle {
  z-index: 3;
}

.navbar-brand > a {
  color: #ffffff !important;
}

/*            ====================================================================
*/

/*            NEW ANIMATION CSS*/

html,
body {
  height: 100%;
}

body {
  margin: 0;
  background: linear-gradient(#eeeeee, #cccccc);
  overflow: hidden;
}

.selector {
  position: absolute;
  left: 28%;
  top: 50%;
  width: 140px;
  height: 140px;
  margin-top: -70px;
  margin-left: -70px;
}

.selector,
.selector button {
  font-family: "Oswald", sans-serif;
  font-weight: 300;
}

.selector button {
  margin-left: -29px;
  margin-top: -36px;
  position: relative;
  width: 150%;
  height: 150%;
  padding: 10px;
  background: #428bca;
  border-radius: 50%;
  border: 0;
  color: white;
  font-size: 20px;
  cursor: pointer;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.1s;
}

.selector button:hover {
  background: #3071a9;
}

.selector button:focus {
  outline: none;
}

.selector ul {
  position: absolute;
  list-style: none;
  padding: 0;
  margin: 0;
  top: -20px;
  right: -20px;
  bottom: -20px;
  left: -20px;
}

.selector li {
  position: absolute;
  width: 0;
  height: 100%;
  margin: 0 50%;
  -webkit-transform: rotate(-360deg);
  transition: all 3s ease-in-out;
}

.selector li input {
  display: none;
}

.selector li input + label {
  position: absolute;
  left: 50%;
  bottom: 100%;
  width: 0;
  height: 0;
  line-height: 1px;
  margin-left: 0;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  font-size: 1px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: none;
  transition: all 3s ease-in-out, color 0.1s, background 0.1s;
}

.selector li input + label:hover {
  background: #f0f0f0;
}

.selector li input:checked + label {
  background: #5cb85c;
  color: white;
}

.selector li input:checked + label:hover {
  background: #449d44;
}

.selector.open li input + label {
  width: 190px;
  height: 190px;
  line-height: 177px;
  margin-left: -40px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
  font-size: 18px;
  font-weight: 600;
  color: white;
}
.right_col {
  justify-content: center;
  align-items: center;
  display: flex;
}

body {
  background: #f8f8f8;
  color: #2c3e50;
  font-family: "Source Sans Pro", sans-serif;
}

.card-carousel-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0 40px;
  color: #666a73;
}

.card-carousel {
  display: flex;
  justify-content: center;
  width: 500px;
}
.card-carousel--overflow-container {
  overflow: hidden;
}
.card-carousel--nav__left,
.card-carousel--nav__right {
  display: inline-block;
  width: 15px;
  height: 15px;
  padding: 10px;
  box-sizing: border-box;
  border-top: 2px solid #757575;
  border-right: 2px solid #757575;
  cursor: pointer;
  margin: 0 20px;
  transition: transform 150ms linear;
}
.card-carousel--nav__left[disabled],
.card-carousel--nav__right[disabled] {
  opacity: 0.2;
  border-color: black;
}
.card-carousel--nav__left {
  transform: rotate(-135deg);
}
.card-carousel--nav__left:active {
  transform: rotate(-135deg) scale(0.9);
}
.card-carousel--nav__right {
  transform: rotate(45deg);
}
.card-carousel--nav__right:active {
  transform: rotate(45deg) scale(0.9);
}

.card-carousel-cards {
  display: flex;
  transition: transform 150ms ease-out;
  transform: translatex(0px);
}
.card-carousel-cards .card-carousel--card {
  margin: 0 10px;
  cursor: pointer;
  box-shadow: 0 4px 15px 0 rgba(40, 44, 53, 0.06),
    0 2px 2px 0 rgba(40, 44, 53, 0.08);
  /* background-color: #fff; */
  background: linear-gradient(
    90deg,
    rgba(116, 18, 106, 1) 0%,
    rgba(196, 68, 157, 1) 100%
  );
  border-radius: 10px;
  z-index: 3;
  margin-bottom: 2px;
}
.card-carousel-cards .card-carousel--card:first-child {
  margin-left: 0;
}
.card-carousel-cards .card-carousel--card:last-child {
  margin-right: 0;
}
.card-carousel-cards .card-carousel--card img {
  vertical-align: bottom;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  transition: opacity 150ms linear;
  user-select: none;
}
.card-carousel-cards .card-carousel--card img:hover {
  opacity: 0.5;
}
.card-carousel-cards .card-carousel--card--footer {
  border-top: 0;
  padding: 7px 15px;
}
.card-carousel-cards .card-carousel--card--footer p {
  padding: 3px 0;
  margin: 0;
  margin-bottom: 2px;
  font-size: 19px;
  font-weight: 500;
  color: #2c3e50;
  user-select: none;
}
.card-carousel-cards .card-carousel--card--footer p.tag {
  font-size: 11px;
  font-weight: 300;
  padding: 4px;
  background: rgba(40, 44, 53, 0.06);
  display: inline-block;
  position: relative;
  margin-left: 4px;
  color: #666a73;
}
.card-carousel-cards .card-carousel--card--footer p.tag:before {
  content: "";
  float: left;
  position: absolute;
  top: 0;
  left: -12px;
  width: 0;
  height: 0;
  border-color: transparent rgba(40, 44, 53, 0.06) transparent transparent;
  border-style: solid;
  border-width: 8px 12px 12px 0;
}
.card-carousel-cards .card-carousel--card--footer p.tag.secondary {
  margin-left: 0;
  border-left: 1.45px dashed white;
}
.card-carousel-cards .card-carousel--card--footer p.tag.secondary:before {
  display: none !important;
}
.card-carousel-cards .card-carousel--card--footer p.tag:after {
  content: "";
  position: absolute;
  top: 8px;
  left: -3px;
  float: left;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background: white;
  box-shadow: -0px -0px 0px #004977;
}

h1 {
  font-size: 3.6em;
  font-weight: 100;
  text-align: center;
  margin-bottom: 0;
  color: #42b883;
}
.right_col {
  overflow: hidden;
  overflow-y: scroll;
  padding-bottom: 80px;
}
.sliderDiv {
  width: 500px;
  margin-top: 8% !important;
}
.btn-yellow:hover {
  background-color: #fec919 !important;
  border-color: #fec919 !important;
  color: #ec2029 !important;
  font-weight: 600 !important;
}
</style>
