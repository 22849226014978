<template>
  <div class="body">
    <div class="main_container">
      <training-header :appConfig = "appConfig"></training-header>
      <div class="right_col" role="main" style="margin-left: 0;">
        <br />
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="x_panel qestion_page">
              <div class="x_title">
                <h2>Certified Solutions Expert Exam</h2>
                <h2 class="examid float-right">EXAM ID NO: {{examId}}</h2>
                <div class="circle-wrap hide">
                  <div class="circlediv">
                    <h3>
                      0%
                      <br />
                      <span>Score</span>
                    </h3>
                  </div>
                </div>
                <div class="clearfix"></div>
              </div>
              <div class="x_content" style="margin-top: 3%;">
                <div class="dashboard-widget-content">
                  <question v-if="questionObj" :questionObj="questionObj" :quesLen="quesLen" :review="true" :index="index"></question>
                  <div class="row">
                    <!-- <div class="col-md-6">
                      <div class="question_number_container">
                        <template v-for="(item, i) in quesLen" >
                          <div :class="{ 'active': i === index }" :key="i">{{item}}</div>
                        </template>
                      </div>
                    </div> -->
                    <div class="progressbar">
                      <div class="progressbar text-center" 
                          style="background-color: #ffc816; margin: 0; color: white;"
                          :style="{width: progress + '%'}">
                        {{ progress }} 
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="button-div" style="padding-top: 5%; padding-bottom: 5%;">
                        <a href="#" class="btn btn-primary" role="button" @click="back" v-if="index+1 == questionArr.length" style="padding: 6px 40px;">Back to Scoreboard</a>
                        <a href="#" class="btn btn-secondary" role="button" v-else @click="getQuestion" style="padding: 6px 40px;">Next</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /page content -->

      <!-- footer content -->
      <training-footer :appConfig = "appConfig"></training-footer>
      <!-- /footer content -->
    </div>
  </div>
</template>
<script>
import trainingHeader from "../components/trainingHeader";
import trainingFooter from "../components/trainingFooter";
import question from "./question";
import ApiServices from '../service/ApiServices';
export default {
  components: {
    trainingHeader,
    trainingFooter,
    question
  },
  computed:{
    progress(){
      console.log(this.index/this.quesLen.length*100)
      return this.index/this.quesLen.length*100
    }
  },
  data(){
      return {
         examId: null,
         questionArr: [],
         quesLen: [],
         index: 0,
         questionObj: null,
         answer: null,
         answerArr: [],
        appConfig:{},
        facilitatorObj:{},
        courseId:'',
      }
  },
  methods:{
      back(){
         // this.$router.push("/score-board");
         console.log(this.facilitatorObj)
          this.$router.push({name:"score-board",params:{facilitatorObj:this.facilitatorObj,appConfig:this.appConfig}})
      },
      getQuestion(){
        this.index++
        this.questionObj = this.questionArr[this.index]
        let objIndex = this.questionObj.options.findIndex((obj => obj.selected == true || obj.selected == false));
        let attempted = false
        this.questionObj.options.forEach((obj => {
          if(obj.selected == true || obj.selected == false){
            attempted = true
          }
        }));
        this.questionObj['attempted'] = attempted

        this.questionObj.options.forEach(element => {
            if(element.correctAnswer && element.selected){
                element['styleClass'] = 'active'
            }else if(element.correctAnswer && element.selected == undefined){
                element['styleClass'] = 'active'
            }else if(element.correctAnswer && !element.selected){
                element['styleClass'] = 'wrong'
            }else if(!element.correctAnswer && element.selected){
                element['styleClass'] = 'wrong'
            }
        });
        //console.log(this.questionObj)
      },
      getQuestionList(){
        this.$root.loading = true;
        let resId = JSON.parse(localStorage.getItem("score-board")).responseId;
            console.log(this.courseId)
        let obj = {responseId: resId,courseId:this.courseId}
        ApiServices.RestPostCall('api/avenir/training/reviewExamResult',obj).then(res => {
          console.log(res)
            this.$root.loading = false;
            this.examId = res.examDetails.examId
            this.questionArr = res.examDetails.questionResponses
            this.questionObj = this.questionArr[this.index]
            let attempted = false
            this.questionObj.options.forEach((obj => {
              if(obj.selected == true || obj.selected == false){
                attempted = true
              }
            }));
            this.questionObj['attempted'] = attempted
            this.questionObj.options.forEach(element => {
                if(element.correctAnswer && element.selected){
                    element['styleClass'] = 'active'
                }else if(element.correctAnswer && element.selected == undefined){
                    element['styleClass'] = 'active'
                }else if(element.correctAnswer && !element.selected){
                    element['styleClass'] = 'wrong'
                }else if(!element.correctAnswer && element.selected){
                    element['styleClass'] = 'wrong'
                }
            });
           //console.log(this.questionObj)
            for(let i in this.questionArr){
              this.quesLen.push(Number(i)+1)
            }
        }).catch((error) => {
          this.$root.loading = false;
            console.log(error)
        })
      }
  },
  mounted(){
    

    if(this.$route.params.facilitatorObj){
      this.facilitatorObj = this.$route.params.facilitatorObj
    }
    else{
      this.facilitatorObj =JSON.parse(localStorage.getItem("facilitatorObj"));
    }
    if(this.$route.params.appConfig){
      this.appConfig = this.$route.params.appConfig
    }else{
      this.appConfig =JSON.parse(localStorage.getItem("appConfig"));
    }

    console.log(this.facilitatorObj)
    this.courseId = this.facilitatorObj.course[0].courseId;
    console.log(this.courseId)
    this.getQuestionList()
  }
};
</script>
<style scoped>
.progressbar {
    width: 100%;
    height: 5px;
    background-color: #eee;
    margin: 1em auto;
    transition: width 500ms;
}
.x_panel {
  background-color: #fff;
  border: 0px solid transparent;
  padding: 0;
  border-radius: 7px;
}
.x_title {
  border-bottom: 0px solid #e6e9ed;
  padding: 15px 18px;
  background-color: #b0b0b0;
  color: #fff;
  border-radius: 7px 7px 0 0;
}
.x_content {
  padding: 0 25px 6px;
}
.button-div {
  display: flex;
  justify-content: center;
  margin-bottom: 4%;
}
</style>
