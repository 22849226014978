<template>
  <div class="body">
    <div class="main_container">
      <training-header :appConfig="appConfig"></training-header>
      <div class="container-fluid" role="main" style="">
        <div class="text-sectiomn-wrap">
          <div class="row pt-2">
            <div class="col-sm-10 mx-auto">
              <div class="backgroundbg-color">
                <div class="heading-title-card" v-if="traingUser">
                  <h2 class="" style="font-weight: 600">
                    EXAM ID NO: {{ traingUser.examDetails.examId }}
                  </h2>
                </div>
                <div
                  class="x_panel qestion_page position-relative"
                  v-if="traingUser"
                >
                  <div class="x_content" style="">
                    <div class="dashboard-widget-content answerDivSect">
                      <question
                        v-if="questionObj"
                        :questionObj="questionObj"
                        @answer="getAnswer"
                        :index="index"
                        :quesLen="quesLen"
                      ></question>
                      <div class="row px-4">
                        <!-- <div class="col-md-12">
                          <div class="question_number_container">
                            <template v-for="(item, i) in quesLen">
                              <div :class="{ active: i === index }" :key="i">
                                <span>{{ item }}</span>
                              </div>
                            </template>
                          </div>
                        </div> -->
                        <div class="progressbar">
                          <div
                            class="progressbar text-center"
                            style="background-color: #ffc816; margin: 0; color: white;"
                            :style="{ width: progress + '%' }"
                          >
                            {{ progress }}
                          </div>
                        </div>

                        <!-- <div class="col-md-6"></div> -->
                        <!-- <div class="col-md-12"> -->

                        <!-- </div> -->
                      </div>
                      <div class="d-flex justify-content-between">
                        <div>
                          <router-link
                            :to="{
                              name: 'course',
                            }"
                            class=""
                            style=""
                          >
                            <a class="btn yellow-float-btn justify-content-center"
                              >Back to Courses</a
                            >
                          </router-link>
                        </div>
                        <div
                          class="button-div m-0 floating-submit-btn"
                          style="padding-top: 0; padding-bottom: 0"
                        >
                          <a
                            href="#"
                            class="btn btn-primary yellow-float-btn"
                            role="button"
                            v-if="index + 1 == questionArr.length"
                            @click.prevent.stop="submitResponse"
                            style="padding: 6px 40px"
                            >Submit</a
                          >
                          <a
                            href="#"
                            class="btn yellow-float-btn"
                            role="button"
                            v-else
                            @click.prevent.stop="getQuestion"
                            style="padding: 6px 40px"
                            >Next</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /page content -->

      <!-- footer content -->
      <training-footer :appConfig="appConfig"></training-footer>
      <!-- /footer content -->
    </div>
  </div>
</template>
<script>
import trainingHeader from "../components/trainingHeader";
import trainingFooter from "../components/trainingFooter";
import question from "./question";
import ApiServices from "../service/ApiServices";
import swal from "sweetalert2";
window.Swal = swal;
export default {
  components: {
    trainingHeader,
    trainingFooter,
    question,
  },
  data() {
    return {
      facilitatorObj: {},
      appConfig: {},
      qData: [],
      traingUser: null,
      questionArr: [],
      quesLen: [],
      index: 0,
      questionObj: null,
      answer: null,
      answerArr: [],
      startTime: new Date().toLocaleString(),
      endTime: "",
    };
  },
  computed: {
    progress() {
      console.log((this.index / this.quesLen.length) * 100);
      return (this.index / this.quesLen.length) * 100;
    },
  },
  methods: {
    submitResponse() {
      localStorage.setItem("responseClick", "Submit");
      this.$root.loading = true;
      this.endTime = new Date().toLocaleString();
      let obj = {};
      obj.id = this.traingUser.id;
      obj.email = this.traingUser.email;
      obj.courseId = this.facilitatorObj.course[0].courseId;
      obj.examDetails = {
        responseId: this.traingUser.examDetails.responseId,
        examId: this.traingUser.examDetails.examId,
        userId: this.traingUser.examDetails.userId,
        score: 0,
        userResponses: this.answerArr,
        endTime: this.endTime,
        startTime: this.startTime,
      };
      let checkArr = [];
      this.answerArr.forEach((key) => {
        if (key.optionId != null) {
          checkArr.push(key.optionId);
        }
      });
      console.log(checkArr);
      if (checkArr.length > 0) {
        ApiServices.RestPostCall(
          "api/avenir/training/saveCourseExamResult",
          obj
        )
          .then((res) => {
            this.$root.loading = false;
            localStorage.setItem(
              "score-board",
              JSON.stringify(res.examDetails)
            );
            //this.$router.push("/score-board");
            this.$router.push({
              name: "score-board",
              params: {
                facilitatorObj: this.facilitatorObj,
                appConfig: this.appConfig,
              },
            });
          })
          .catch((error) => {
            this.$root.loading = false;
            console.log(error);
          });
      } else {
        this.$root.loading = false;
        let temp = this;
        //alert('Please attemp atleast one question')
        //Swal.fire('Any fool can use a computer')
        Swal.fire({
          text: "Please attemp atleast one question!",
          icon: "warning",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
        }).then(function() {
          temp.index = 0;
          window.location = "#/question-list";
        });
      }
    },
    getAnswer(value) {
      console.log(value);
      this.answer = value;
      let objIndex = this.answerArr.findIndex(
        (obj) => obj.questionId == value.questionId
      );
      if (objIndex != -1) {
        this.answerArr[objIndex].optionId = value.optionId;
      } else {
        this.answerArr.push(value);
      }
    },
    getQuestion() {
      this.index++;
      this.questionObj = this.questionArr[this.index];
      localStorage.setItem("responseClick", "Next");
    },
    getPrevQuestion() {
      this.index--;
      this.questionObj = this.questionArr[this.index];
      localStorage.setItem("responseClick", "Back");
    },
    getQuestionList() {
      this.$root.loading = true;
      let obj = JSON.parse(localStorage.getItem("userDetails"));
      ApiServices.RestPostCall("api/avenir/training/addTrainingUser", obj)
        .then((res) => {
          this.$root.loading = false;
          this.traingUser = res.traingUser;
          // this.traingUser.examDetails.questionTypes.forEach(key =>{
          //   key.masterQuestions.forEach(q =>{
          //     let question = {}
          //     question.id = q.questionId
          //     question.question = q.question
          //     question.options = q.options
          //     if(q.options != undefined){
          //       this.questionArr.push(question)
          //       this.answerArr.push({questionId: q.questionId, optionId: null})
          //     }
          //   })
          // })
          this.facilitatorObj = this.$route.params.facilitatorObj;
          this.qData = this.facilitatorObj.course[0].questions;
          this.qData.forEach((q) => {
            let question = {};
            question.id = q.questionId;
            question.question = q.question;
            question.options = q.options;
            question.uploadImageContent = q.uploadImageContent;
            if (q.options != undefined) {
              this.questionArr.push(question);
              this.answerArr.push({ questionId: q.questionId, optionId: null });
            }
          });
          for (let i in this.questionArr) {
            this.quesLen.push(Number(i) + 1);
          }
          console.log(this.questionArr, this.quesLen);
          this.questionObj = this.questionArr[this.index];

          console.log(this.answerArr);
        })
        .catch((error) => {
          this.$root.loading = false;
          console.log(error);
        });
    },
  },
  mounted() {
    this.getQuestionList();
    if (this.$route.params.facilitatorObj) {
      this.facilitatorObj = this.$route.params.facilitatorObj;
    } else {
      this.facilitatorObj = JSON.parse(localStorage.getItem("facilitatorObj"));
    }
    if (this.$route.params.appConfig) {
      this.appConfig = this.$route.params.appConfig;
    } else {
      this.appConfig = JSON.parse(localStorage.getItem("appConfig"));
    }
  },
};
</script>
<style scoped lang="scss">
.main_container {
  height: 100vh;
}
.progressbar {
  width: 100%;
  height: 5px;
  background-color: #eee;
  margin: 1em auto;
  transition: width 500ms;
}
.x_panel {
  background-color: #fff;
  border: 0px solid transparent;
  padding: 0;
  border-radius: 7px;
}
.x_title {
  border-bottom: 0px solid #e6e9ed;
  padding: 15px 18px;
  background-color: #b0b0b0;
  color: #fff;
  border-radius: 7px 7px 0 0;
}
.x_content {
  padding: 25px 25px 40px;
}
.button-div {
  display: flex;
  justify-content: center;
  margin-bottom: 4%;
}
.active {
  border: 2px solid #3471ce;
  color: #3471ce !important;
}
.nextBtn {
  color: #d2b8d2;
  background-color: #813582 !important;
  border-color: #813582 !important;
}
.nextBtn:hover {
  color: #fff;
}
.right_col {
  min-height: 100vh !important;
  padding-bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.heading-title-card {
  position: absolute;
  z-index: 1;
  top: -40px;
  background: transparent;
  padding: 2px 12px 2px 0;
  color: #343434 !important;
  h2 {
    font-size: 0.9rem;
    color: #343434 !important;
  }
}
// .floating-submit-btn{
//       padding: 8px 40px;
//     position: absolute;
//     right: 0;
//     bottom: -25px;
//     z-index: 1;
// }
</style>
