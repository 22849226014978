<template>
  <div class="">
    <loader :is-visible="$root.loading"></loader>
    <router-view />
    <div
      class="floating-chat-icon hide"
      data-toggle="modal"
      data-target="#faqModal"
    >
      <div class="floatingIcon">
        <span
          class="fa fa-question-circle-o"
          aria-hidden="true"
          v-b-tooltip.hover
          title="FAQ"
        ></span>
      </div>
    </div>

    <div class="modal fade" id="faqModal">
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">Frequently Asked Questions</h4>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <!-- Modal body -->
          <div class="modal-body">
            <div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import excel from "vue-excel-export";
// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// import "bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
// import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(excel);
// Vue.use(BootstrapVue)
// Vue.use(IconsPlugin)
export default {};
</script>
<style lang="scss">
#app {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 0px;
}
#faqModal {
  .modal-header {
    background-color: #ffc816;
    .modal-title {
      font-family: Montserrat-Black;
      font-size: 1.2rem;
      color: #42413c;
      margin-bottom: 0;
    }
  }
  .close {
    // color: #fff;
    text-decoration: none;
    font-size: 2.3rem;
  }
}
</style>
