<template>
    <div v-bind:class="getClass()">
        <div class="mdgridviewpage-bg">
            <HeaderOrganisation></HeaderOrganisation>
            <!-- Page Content -->
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12"></div>
                </div>
            </div>
            <div class="container-fluid m-t-10px">
                <div class="row dashboardchart-container globalconfigcontainer">
                    <div class="col-lg-8" style="margin:0 auto;"></div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card mainCard mt-3">
                            <div class="card-body p-0">
                                <div class="d-flex">
                                    <ul id="tabs" class="nav nav-tabs col-3 pr-0 border-0" role="tablist">
                                        <li class="nav-item">
                                            <a id="tab-A" href="#pane-A" class="nav-link active pl-4 py-4" data-toggle="tab" role="tab"><i class="fa fa-user mr-3 pt-1" aria-hidden="true"></i> GML Upload</a>
                                        </li>
                                        <!-- <li class="nav-item">
                      <a id="tab-B" href="#pane-B" class="nav-link pl-4 py-4" data-toggle="tab" role="tab"><i class="fa fa-unlock-alt mr-3 pt-1" aria-hidden="true"></i> Change Password</a>
                    </li> -->
                                        <!-- <li class="nav-item">
                      <a id="tab-C" href="#pane-C" class="nav-link pl-4 py-4" data-toggle="tab" role="tab"><i class="fa fa-eye mr-3 pt-1" aria-hidden="true"></i> View Profile</a>
                    </li> -->
                                    </ul>
                                    <div id="content" class="tab-content col-9 m-2" role="tablist">
                                        <div id="pane-A" class="card tab-pane fade show active insidecard border-0" role="tabpanel" aria-labelledby="tab-A">
                                            <div class="userProfile">
                                                <div class="card-header mx-5 mb-3 pt-3 pr-0 d-none" style="background-color: transparent;border: 0;">
                                                    <div class="row">
                                                        <div class="col-8"></div>
                                                        <div class="col-4">
                                                            <form>
                                                                <div class="input-group mb-3">
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"><i class="fa fa-search searchIcon" aria-hidden="true"></i></span>
                                                                    </div>
                                                                    <input type="text" class="form-control" placeholder="Search" id="usr" name="username">
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-body mx-0 mb-3 pt-0 mt-3">
                                                    <!-- <div v-for="item in pageOfItems" :key="item.id"> -->
                                                    <v-client-table :columns="columns" :data="orgUnitItem" v-model="datatabelvalue" :options="options" @row-click='changeName'>
                                                         
                                                       <span slot="uri" slot-scope="{ row }" @click="myEdit(row)" ><i class="fa fa-edit" aria-hidden="true"></i></span>
                                                       <span slot="uri1" slot-scope="{ row }" @click.prevent.stop="myView(row)" ><i class="fa fa-eye" aria-hidden="true"></i></span>
                                                       <span slot="uri2" slot-scope="{ row }" @click.prevent.stop="geojsondelete(row)" ><i class="fa fa-trash" aria-hidden="true"></i></span>
                                                       

                                                       
                                                        <div slot="name" slot-scope="{row, update, setEditing, isEditing, revertValue}">
                                                            <span type="text" v-model="row.name">{{row.name}}</span>
                                                        </div>
                                                    </v-client-table>
                                                    <!-- <table class="table table-bordered">
                                                        <thead>
                                                            <tr class="row">
                                                                <th class="col-md-1">No</th>
                                                                <th class="col-md-3">Organisation Unit <a class="float-right sortIcon"><img src="../assets/img/sort.svg" style="width:12px;"></a></th>
                                                                <th class="col-md-3">Location Level <a class="float-right sortIcon"><img src="../assets/img/sort.svg" style="width:12px;"></a></th>
                                                                <th class="col-md-2">Geometry <a class="float-right sortIcon"><img src="../assets/img/sort.svg" style="width:12px;"></a></th>
                                                                <th class="col-md-3">Funtions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody v-if="orgUnitItem && orgUnitItem.length">
                                                            <template v-for="(list, i) in orgUnitItem">
                                                                <tr class="row" :key="i">
                                                                    <td class="col-md-1" scope="row">{{i+1}}</td>
                                                                    <td class="col-md-3">{{list.name}}</td>
                                                                    <td class="col-md-3">{{list.level}}</td>
                                                                    <td class="col-md-2">{{list.geometryStatus}}</td>
                                                                    <td class="col-md-3">
                                                                        <div class="d-flex functionBtnWrap">
                                                                            <a class="functionBtn" href="#" @click="myEdit(list)"><i class="fa fa-pencil" aria-hidden="true"></i></a>
                                                                            <a class="functionBtn" href="#" @click.prevent.stop="myView(list)"><i class="fa fa-eye" aria-hidden="true"></i></a>
                                                                            <a class="functionBtn" href="#" @click.prevent.stop="geojsondelete(list)"><i class="fa fa-trash mr-3 pt-1" aria-hidden="true"></i></a>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </template>
                                                        </tbody>
                                                    </table> -->
                                                    <!-- </div> -->
                                                </div>
                                                <div class="card-footer d-none" style="background-color: transparent;">
                                                    <div class="mt-3">
                                                        <!-- <div class="paginaionWrap">
                              <div class="paginaionDiv">
                                <jw-pagination :items="bookmarks" @changePage="onChangePage" :labels="customLabels" />
                              </div>
                            </div> -->
                                                       <!--  <div class="paginaionWrap-ou">
                                                            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" first-number last-number first-text="First" prev-text="Prev" next-text="Next" last-text="Last" align="center">
                                                            </b-pagination>
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- The Modal -->
            <b-modal id="myModalEdit" centered ref="modal-edit">
                <div class="row d-none">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="sel1">Select Type</label>
                            <div class="select-wrapper">
                                <select class="form-control" id="sel1">
                                    <option>POINT</option>
                                    <option>LINESTRING</option>
                                    <option>POLYGON</option>
                                    <option>MULTIPOINT</option>
                                    <option>MULTILINESTRING</option>
                                    <option>MULTIPOLYGON</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12" style="display:none">
                        <p class="d-flex mb-0" for="orgLabel">
                            <span class="btn pl-0">
                                Organisation Unit
                            </span>
                            <span class="btn">-</span>
                            <span class="btn">
                                India
                            </span>
                            <span class="btn">-</span>
                            <span class="btn">
                                Level 3
                            </span>
                        </p>
                        <hr class="mt-0">
                    </div>
                    <div class="col-12">
                        <label class="mb-2">Upload the shape file</label>
                    </div>
                    <div class="col-12" style="display:none">
                        <div class="input-group mb-3">
                            <div class="custom-file">
                                <input type="file" class="custom-file-input" id="inputGroupFile01">
                                <label class="custom-file-label" for="inputGroupFile01">Choose file</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-0">
                    <div class="col-12">
                        <div class="form-group">
                            <!-- <label for="comment">Comment:</label> -->
                            <ValidationProvider name="orgLabel" rules="required" v-slot="{ errors }">
                                <textarea class="form-control" rows="5" v-model="userDetails.geoCordinates" placeholder="Your Geojson coordinates" id="comment" name="text" style="height: 202px;"></textarea>
                                <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <span class="text-danger" v-if="userDetails.error">{{ userDetails.error }}</span>
                        <button type="submit" class="btn btn-primary" @click.prevent.stop="update" :disabled="!userDetails.geoCordinates">Update</button>
                    </div>
                </div>
            </b-modal>
            <b-modal id="myModalView" centered ref="modal-view">
                <div class="row">
                    <div class="col-12">
                        <div ref="mapObj1" id="imap1" style="border: 1px solid #ebebeb; height:300px;">
                            <l-map ref="mapObj" style="height: 100%; width: 100%" :zoom="zoom" :maxZoom="24" :center="center" :options="{zoomControl: false}">
                                <l-tile-layer :url="url"></l-tile-layer>
                                <l-control-zoom position="topright"></l-control-zoom>
                                <l-geo-json :geojson="geojson" :mapInstance="mapInstance"></l-geo-json>
                            </l-map>
                        </div>
                    </div>
                </div>
            </b-modal>
            <!-- The Modal -->
            <modals-container />
        </div>
    </div>
</template>
<script>
// import "vue-form-wizard/dist/vue-form-wizard.min.css";
import ApiServices from '../service/ApiServices';

import HeaderOrganisation from '../components/layout/HeaderOrganisation';
import moment from 'moment';
import swal from 'sweetalert2';
import 'leaflet/dist/leaflet.css';
import { LMap, LControlZoom, LControl, LControlLayers, LTileLayer, LMarker, LPopup, LGeoJson } from 'vue2-leaflet';
import { geoJSON, latLng, Icon, icon } from 'leaflet';
import LeafletGeometric from 'polylabel'
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';
import Vuetables from 'vue-tables-2';

window.Swal = swal;
export default {
    components: {
        HeaderOrganisation,
        LMap,
        LControlZoom,
        LControl,
        LControlLayers,
        LTileLayer,
        LMarker,
        LPopup,
        LGeoJson,
        Vuetables,
        LeafletGeometric
    },
    data() {
        const now = new Date()
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
        // 15th two months prior
        const minDate = new Date(today)
        minDate.setYear(1920)
        minDate.setDate(1)
        // 15th in two months
        const maxDate = new Date(today)
        maxDate.setMonth(maxDate.getMonth())
        maxDate.setDate(15)
        return {
            userDetails: {

                geoCordinates: "",
                id: "",
                uid: "",
                level: "",
                error: ""
            },
            currentPage: 1,
            perPage: 5,
            disabled: 0,
            min: minDate,
            max: maxDate,
            loggedInUserProfile: null,
            frmModel: { color: "#212832", username: "" },


            orgUnitItem: null,
            url: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
            zoom: 6,
            center: ['', ''],
            geojson: null,
            mapInstance: null,
            columns: ['no','name', 'code', 'uri','uri1','uri2'],
            datatabelvalue: [],
            options: {
                headings: {
                    no: 'No',
                    name: 'Organisation Unit',
                    code: 'Location Level',
                    uri: 'Edit',
                    uri1: 'View',
                    uri2: 'Delete',

                },
                onRowClick: function(row) {
                   console.log(row)
                },
          sortable: ['no','name', 'code'],
          filterable: ['no','name', 'code']
            }



        }
    },
    created() {

    },
    computed: {
        rows() {
            return this.orgUnitItem && this.orgUnitItem.length ? this.orgUnitItem.length : 0;
        }
    },
    methods: {
      changeName({row}){
        console.log(row)
      },


        myEdit(data,i) {
            console.log(data)
            console.log(i)
            this.userDetails.id = data.id;
            this.userDetails.uid = data.uid;
            this.userDetails.level = data.level
            this.$refs['modal-edit'].show()
        },
        geojsondelete(data){
          console.log(data)

          this.$root.loading = true;
            

            var param = {}
            
            //param.coordinates = [];

            //param.type = data.geometryAsJson.type
            param.id = data.id;
            param.uid = data.uid;
            param.level = data.level;

            var url = "api/avenir/data/updateOrgGML";
            console.log(param)
            ApiServices.RestPostCall(url, param).then(res => {
                console.log(res)
                 if (res.status == "Success") {
                   alert("Shape file Updated successfully");

                 }else{
                  alert("Shape file failed");
                 }
               this.$root.loading = false;

            })
        },

      

        myView(data) {
            console.log(data)
            var mapCentroid = " "
            this.mapInstance = null;
            this.geojson = data.geometryAsJson
            this.center = [data.latitude, data.longitude]
            this.$refs['modal-view'].show()

         
           
            console.log(this.center)
             
        //    var centeriod = polylabel(data.geometryAsJson, 1.0);
        //    console.log(centeriod)
            setTimeout(() => {
            //  console.log(this.$refs.mapObj)
                //this.$refs.mapObj.invalidateSize()
                window.dispatchEvent(new Event('resize'))

            }, 300);

        },

        getClass() {
            // if(settings.countryName.toLowerCase() === 'nepal'){
            //   return 'newBlack-theme'
            // }else{
            //   return 'newBlue-theme'
            // }
            if (this.frmModel.color === '#212832') {
                return 'newBlack-theme'
            } else if (this.frmModel.color === '#66e093') {
                return 'newBlue-theme'
            } else if (this.frmModel.color === '#25984b') {
                return 'newGreen-theme'
            }
        },
        getorgunits() {
            this.$root.loading = true;

            ApiServices.RestGetCall('api/avenir/data/getOrgUnitGMLs', {}).then(Response => {
                this.$root.loading = false;
                this.orgUnitItem = Response.orgList;

                console.log(this.orgUnitItem)

                var datatabelvaluetemp = [];
                
                for(var i in this.orgUnitItem){
                  var param = {}
                  console.log(this.orgUnitItem[i])
                  param.no = Number(i) +1;
                  param.name = this.orgUnitItem[i].name;
                  param.code = this.orgUnitItem[i].level;
                  param.id = this.orgUnitItem[i].id;
                  param.uid = this.orgUnitItem[i].uid;

                  if(this.orgUnitItem[i].geometryStatus){
                    param.geometryAsJson = this.orgUnitItem[i].geometryAsJson;
                    param.latitude = this.orgUnitItem[i].latitude;
                    param.longitude = this.orgUnitItem[i].longitude;
                  }
                  datatabelvaluetemp.push(param)
                }
                console.log(datatabelvaluetemp)

                this.datatabelvalue = datatabelvaluetemp

                // if(Response.birthday != undefined){
                //   bDate = moment(Response.birthday).format('MM/DD/YYYY')
                // }

            });
        },
        update() {
            this.$root.loading = true;
            let obj = {}
            obj.id = this.userDetails.id
            obj.uid = this.userDetails.uid
            obj.level = this.userDetails.level;

            console.log(typeof JSON.stringify(this.userDetails.geoCordinates))

            if(typeof this.userDetails.geoCordinates !== "string"){
            obj.coordinates = this.userDetails.geoCordinates
            }else if(typeof this.userDetails.geoCordinates == "string"){
              console.log(1)
               this.$root.loading = false;
              
             
            }
            
            obj.name = this.userDetails.name
            obj.geometryStatus = this.userDetails.geometryStatus
            console.log(this.userDetails.geoCordinates)

            var param = {}
            var tempValueofText = JSON.parse(this.userDetails.geoCordinates)
            console.log(tempValueofText)
            param.coordinates = JSON.stringify(tempValueofText.coordinates);

            param.type = tempValueofText.type
            param.id = this.userDetails.id;
            param.uid = this.userDetails.uid;
            param.level = this.userDetails.level;

            var url = "api/avenir/data/updateOrgGML";
            console.log(param)
            ApiServices.RestPostCall(url, param).then(res => {
                console.log(res)
                if (res.status == "Success") {
                    this.$root.loading = false;
                    this.$refs['modal-edit'].hide()
                    this.getorgunits()
                    this.userDetails.geoCordinates = ""
                     alert("Shape file Updated successfully");

                } else {
                    this.$root.loading = false;
                    this.userDetails.error = "Check Shape file"
                    alert("Check Shape file");
                }

            })
            /* if (this.userDetails.geoCordinates != undefined) {
                 ApiServices.RestPostCall('api/avenir/data/updateOrgGML', obj).then(Response => {

                     this.$root.loading = false;
                     localStorage.setItem("score-board", JSON.stringify(res.userDetails));
                     this.$router.push("/score-board");
                     console.log(Response)
                 }).then(
                     Swal.fire({
                         text: "User details updated successfully!",
                         icon: 'warning',
                         confirmButtonColor: '#3085d6',
                         cancelButtonColor: '#d33'
                     })
                 )
             } else {
                 this.$root.loading = false;
                 Swal.fire({
                     text: "Please fill all the mandatory fields!",
                     icon: 'warning',
                     confirmButtonColor: '#3085d6',
                     cancelButtonColor: '#d33'
                 })
             }
             */
        },

    },
    mounted() {

        this.getorgunits()

        // if(localStorage.getItem("userProfile")){
        //   this.loggedInUserProfile = JSON.parse(localStorage.getItem("userProfile"));
        //   if(this.loggedInUserProfile.config){
        //     this.frmModel = this.loggedInUserProfile.config;
        //     this.frmModel.color = this.frmModel.color;
        //     this.frmModel.username = this.loggedInUserProfile.username;
        //   }
        // }
    }
};
</script>
<style lang="scss" scoped>
.mdgridviewpage-bg {
    // background-color: #38a1c1;
    background-color: #3471CE;
    // background: url("../assets/img/blue-bg.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    padding: 20px 40px;
}

body.green-theme .mdgridviewpage-bg {
    background-color: #006600;
    background: url("../assets/img/green-bg.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    padding: 20px 40px;
}

.mainform-holder {
    background: #fff;
    -webkit-box-shadow: -2px 0px 5px 0px rgba(204, 204, 204, 1);
    -moz-box-shadow: -2px 0px 5px 0px rgba(204, 204, 204, 1);
    box-shadow: -2px 0px 5px 0px rgba(204, 204, 204, 1);
    border-radius: 10px;
    padding: 60px;
}

.homepage-bg {
    /*background: url("../assets/img/home/Avenir-health-home.jpg") no-repeat;*/
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../assets/img/home/avenirbg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.socialicons-container {
    position: absolute;
}

/*.config-icon-container {
  width: 100px;
  height: auto;
}*/

.modRadio .config-icon-container {
    width: 100px;
    height: 100px;
}

#benchmarking .tabs .card-header {
    background-color: rgba(0, 0, 0, 0.03)
}

#sidebar-wrapper {
    position: relative
}

.modRadio.colorSelect {
    margin-bottom: 30px;
}

.overlay {
    position: absolute;
    top: 48px;
    left: 0;
    height: calc(100% - 126px);
    width: 100%;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);
}

.edit-links {
    z-index: 14;
    position: relative;
    color: #fff;
    font-size: 14px;
}

.img-preview img {
    width: 180px !important;
    margin-top: 20px;
}

.headingText {
    background: transparent;
    border: none;
    font-size: 2.5rem;
    font-weight: 100;
    font-color: #FFFFFF;
    padding: 0px;
}

.landing-desc {
    background: transparent;
    color: #fff;
    border: none;
    width: 100%;
}

.col-form-label {
    font-size: 14px;
}

.vue-form-wizard .wizard-btn {
    font-weight: 400;
}

.card {
    background-color: #f4f4f4;
}

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-55,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
    padding-right: 15px !important;
    padding-left: 15px !important;
}

select {
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    appearance: none;
    -moz-appearance: none !important;


}

.select-wrapper {
    position: relative;
}

.select-wrapper:after {
    font-family: FontAwesome;
    content: '\f0d7';
    font-size: 16px;
    position: absolute;
    top: 8px;
    right: 14px;
    color: #676767;
    pointer-events: none;
}

.bg-light-purple {
    background-color: #3662a8 !important;
}

.nav-tabs {
    display: none;
    height: 80vh;
    background: #373a3f !important;
}

.mainCard {
    height: 80vh;
    border: 0;
}

.insidecard {
    height: 75vh;
    overflow: hidden;
    // overflow-y: scroll;
}

.nav-tabs .nav-item {
    margin-bottom: 0;
}

.nav-tabs>li>a {
    font-size: 1.3em;
    color: #f4f4f4;
}

label {
    margin-bottom: 0;
    font-size: 14px;
    // padding-top: 14px;
    color: #4c4c4c;
    font-weight: 400;
}

.btn {
    font-size: 14px;
}

input[type="date"] {
    position: relative;
    padding: 10px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    z-index: 1;
}

input[type="date"]:before {
    color: transparent;
    background: none;
    display: block;
    font-family: 'FontAwesome';
    content: '\f073';
    /* This is the calendar icon in FontAwesome */
    width: 15px;
    height: 20px;
    position: absolute;
    top: 10px;
    right: 10px;
    color: #c2c3c3;
    cursor: pointer;
}

.newGreen-theme {
    .nav-tabs {
        background-color: #1a4728 !important;
    }

    .nav-tabs .nav-link.active {
        background-color: #fff;
    }

    .card {
        background-color: #fff;
    }

    .nav-tabs .nav-link.active:hover {
        background-color: #fff;
    }
}

@media (min-width: 768px) {
    .nav-tabs {
        display: flex;
        flex-flow: column nowrap;
    }

    .nav-tabs {
        border-bottom: none;
        border-right: 1px solid #ddd;
        display: flex;
    }

    .nav-tabs {
        margin: 0 0px 0 0;
    }

    // .nav-tabs .nav-item + .nav-item {
    //   margin-top: 0.25rem;
    // }
    .nav-tabs .nav-link {
        transition: border-color 0.125s ease-in;
        white-space: nowrap;
    }

    .nav-tabs .nav-link:hover {
        background-color: #d4d4d4;
        border-color: transparent;
        border-radius: 0;
        color: #4c4c4c !important;
        border-left: 6px solid #fe5868;
    }

    .nav-tabs .nav-link.active {

        border-radius: 0;
        background-color: #f4f4f4;
        border: 0;
        border-left: 6px solid #fe5868;
        font-weight: 500;
    }

    // .nav-tabs #tab-A:hover{
    //   border-left: 6px solid #80b853;
    // }
    // .nav-tabs #tab-A.active{
    //   border-left: 6px solid #80b853;
    // }
    .nav-tabs .nav-link.active:hover {
        background-color: #f4f4f4;

        // border-color: #0275d8 #fff #0275d8 #0275d8;

    }


}

@media (max-width: 767px) {
    .tab-pane {
        display: block !important;
        opacity: 1;
    }
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #c2c3c3;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

// .formInputs::after
// {
//   font-family: 'FontAwesome';
//   content: "\f007";
//   color:red;
//   position: relative;
//   left: -5px;

// }
.formInputs {
    position: relative;
}

// .formInputs:after {

//   font-family: FontAwesome;
//   content: '\f040';
//   font-size: 12px;
//   position: absolute;
//   top: 13px;
//   right: 10px;
//   color: #676767;
//   pointer-events: none;
//   opacity: .6;

// }
.editIcon {
    font-size: 12px;
    position: absolute;
    top: 13px;
    right: 10px;
    color: #676767;
    opacity: .6;
    display: none;

}

.form-control:hover~.editIcon {

    display: block;
}

.form-control:disabled~.editIcon {
    opacity: 0;
}

.select-wrapper .formInputs .editIcon {
    right: 35px;
}

.dobField .editIcon {
    top: 15px;
    right: 45px;
}

.form-control {
    font-size: 14px;
}

.table-bordered {
    border: 1px solid transparent;
}

.functionBtnWrap {
    justify-content: space-between;
}

.functionBtnWrap .fa {
    opacity: .6;
    font-size: 16px;

}

.functionBtnWrap .fa:hover {
    opacity: 1;
    color: #373a3f;
    font-size: 14px;
}

.input-group {
    position: relative;
    margin-bottom: 0px;

    .form-control {
        border: 1px solid #ebebeb;
        border-left: 0;
        border-radius: 0 50px 50px 0;
    }
}

.input-group-text {
    background-color: #fff !important;
    border: 1px solid #ebebeb;
    border-right: 0;
    border-radius: 50px;
}


.input-group .form-control {
    width: auto;
}


::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #ababab;
    font-size: 13px;
    font-weight: 200;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: #ababab;
    font-size: 13px;
    font-weight: 200;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: #ababab;
    font-size: 13px;
    font-weight: 200;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: #ababab;
    font-size: 13px;
    font-weight: 200;
}

.searchIcon {
    color: #ababab;
}

.table thead tr {
    position: sticky;
    top: 0px;
    background-color: #f4f4f4;
    z-index: 99;
}

.table thead th {

    font-size: 14px;
    font-weight: 400;
    text-align: center;
}

.table tbody td {
    text-align: center;
    font-weight: 200;
    border: 0;
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
}

.close {
    position: absolute;
    z-index: 999;
    top: -45px;
    right: -50px;
    opacity: 1;
    color: #fff;
    font-size: 3.5em;
    font-weight: 100;
}

.close:focus {
    border: 0;
    outline: none;
    box-shadow: none;
}

// #pane-A .card-body {
//     overflow: hidden;
//     overflow-y: scroll;
//     height: calc(75vh - 180px);
// }

.modalEditIcon {}

.glyphicon.glyphicon-sort{
  color:red !important;
}
</style>