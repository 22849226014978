<template>
  <div>
  <!-- <training-header></training-header> -->
    <div class="mdgridviewpage-bg newbgdata">

      <!-- <div class="container-fluid">

        <div class="row">
          <div class="col-lg-12"></div>
        </div>
      </div> -->
      <div class="container-fluid m-t-10px">
        <div class="row dashboardchart-container globalconfigcontainer">
          <div class="col-lg-8" style="">
            <div class="card m-b-20px m-t-20px">
              <!--<div
                class="card-header text-decoration-none p-l-16px p-12px"
                id="headingOne"
              >
                Sign In / Create an Account
              </div>-->
              <div class="collapse show" aria-labelledby="headingOne">
                <ValidationObserver v-slot="{ handleSubmit }">

                    <div class="card-header d-none" style="background-color:rgba(221, 221, 221, 0.91) !important;">
                      <div class="row d-flex">
                        <div class="col-md-8 mx-auto">
                          <div class="row">
                            <div class="col-sm-4">
                                <img v-if="settings.countryName.toLowerCase() === 'nepal' " src="../assets/img/Nepal_logo.png" class="imgFit float-right" style="padding-top: 3%; height: 65px;">
                                <img v-else src="../assets/img/logo_front_new.png" class="imgFit float-right pr-3" style="padding-top: 3%;">
                            </div>
                            <div class="col-sm-8 perfect-center float-left" style="justify-content: left;">
                              <h4 style="margin-bottom: 0;font-weight:400;"><span style="font-weight:600;">Family Planning</span> Dashboard</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-body c-body" >

                      <form class="d-flex" @submit.prevent="handleSubmit(onSubmit)" >

                        <div class="row col-lg-8" style="margin: 0 auto;">
                          <!-- <h4
                            class="inner-heading text-center pt-2 pb-1 w-100"
                            style="font-weight: 400; margin-bottom:0px;"
                          >
                            Welcome
                          </h4> -->
                          <div class="mainform-holder w-100">
                            <div class="col-lg-12">
                              <!-- <p class="text-center" style="font-size: 16px;font-weight: 300;margin-bottom: 3%;">Sign in manually</p> -->
                              <div class="form-group row">
                                <label
                                  for="inputContactEmail"
                                  class="col-sm-4 col-form-label"
                                  >E-mail</label
                                >
                                <div class="col-sm-8">
                                  <ValidationProvider name="E-mail" rules="required" v-slot="{ errors }">
                                    <input
                                      v-model="email"
                                      type="text"
                                      class="form-control bg-grey"
                                      id="inputContactEmail"
                                      placeholder=""
                                    />
                                    <span class="text-danger">{{ errors[0] }}</span>
                                  </ValidationProvider>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-12">
                              <div class="form-group row">
                                <label
                                  for="inputContactEmailPassword"
                                  class="col-sm-4 col-form-label"
                                  >Password</label
                                >
                                <div class="col-sm-8">

                                    <ValidationProvider name="Password" rules="required" v-slot="{ errors }">
                                      <div class="input-group">
                                        <input
                                          v-model="password"
                                          type="password"
                                          class="form-control bg-grey"
                                          id="inputContactEmailPassword"
                                          placeholder=""
                                        />
                                        <div class="input-group-append">
                                          <span @click="showPass" class="input-group-text fa fa-eye bg-grey" ></span>
                                        </div>
                                      </div>
                                      <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>

                                </div>
                              </div>
                            </div>
                            <div class="col-lg-12 mt-3">
                              <!-- <div class="row">
                                <div class="col-lg-12"> -->
                                  <div class="row">
                                    <div class="col-sm-4">

                                    </div>
                                    <div class="col-sm-8">
                                      <div class="row">
                                        <div class="col-6 d-none">
                                          <div class="form-group row mb-0">
                                            <div class="col-sm-12 text-left m-t-20px">
                                              <form>
                                                <div class="form-check">
                                                  <label class="form-check-label m-t-20px" for="check1">
                                                    <input type="checkbox" class="form-check-input" id="check1" name="option1" value="something"> Remember Me
                                                  </label>
                                                </div>
                                              </form>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-6">
                                          <div class="form-group row mb-0">
                                            <div class="col-sm-12 m-t-10px">
                                              <!-- <a
                                              class="btn btn-primary black-btn color-white"
                                              @click="GotoCreateAccount()"
                                              >Create an Account</a> -->
                                              <a class="text-light-red " @click="GotoCreateAccount()" style="font-size:14px;">Register Now</a>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-6">
                                          <div class="form-group row mb-0">
                                            <div class="col-sm-12 text-left">
                                              <button type="submit" class="btn btn-primary black-btn color-white" style="padding: 6px 35px; float: right; margin-right:0;">
                                              Sign In
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-6 mt-2">
                                          <div class="form-group row mb-0 ">
                                            <div class="col-sm-12 text-left m-t-20px" style="border-right: 1px solid #797979">
                                              <a
                                              class=""
                                              @click="goToActivateAccount"
                                              style="font-size: 14px; color: #797979;"
                                              >Activate Account</a>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-6 mt-2">
                                          <div class="form-group row mb-0 float-right">
                                            <div class="col-sm-12 m-t-20px" >
                                              <a class="" @click="$bvModal.show('forgotPasswordModal')"
                                              style="font-size: 14px; color: #797979;"
                                              >Forgot Password ?</a>
                                            </div>
                                          </div>
                                        </div>


                                      </div>
                                    </div>
                                  </div>
                                <!-- </div>
                              </div> -->
                            </div>
                            <!-- <div class="col-lg-12">
                              <div class="row">
                                <div class="col-lg-12">
                                  <div class="row">
                                    <div class="col-lg-12">
                                      <div class="form-group row mb-0">
                                        <div class="col-sm-6 text-left m-t-20px">
                                          <a
                                            class="color-black"
                                            @click="$bvModal.show('forgotPasswordModal')"
                                            style="text-decoration: underline;font-size: 14px;"
                                          >Forgot Password</a>
                                        </div>
                                        <div class="col-sm-6 text-left m-t-20px">
                                          <a
                                            class="color-black"
                                            @click="goToActivateAccount"
                                            style="text-decoration: underline;font-size: 14px;"
                                          >Activate Account</a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> -->
                          </div>
                        </div>
                      </form>
                    </div>

                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <training-footer></training-footer> -->
    <b-modal id="forgotPasswordModal">
      <template v-slot:modal-title>
        Forgot Password
      </template>
      <template v-slot:default>
        <ValidationObserver ref='frmForgotPassword'>
          <div class="col-lg-12">
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">* E-mail</label>
              <div class="col-sm-8">
                <ValidationProvider name="Email" rules="required" v-slot="{ errors }">
                  <input
                    v-model="forgotEmail"
                    type="email"
                    class="form-control bg-grey"
                    placeholder="Enter Email"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </ValidationObserver>
      </template>
      <template v-slot:modal-footer>
        <button type="button" class="btn btn-secondary" @click="$bvModal.hide('forgotPasswordModal')">Close</button>
        <button type="button" class="btn btn-primary" @click="forgotPassword()">Save</button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { extend } from 'vee-validate';
import { required} from 'vee-validate/dist/rules';
import ApiServices from '../service/ApiServices';
import trainingHeader from '../components/trainingHeader';
import trainingFooter from '../components/trainingFooter';

extend('required', {
  ...required,
  message: 'This field is required'
});

export default {
  name: "home",
  props: {},
  components: {
        trainingHeader,
        trainingFooter
    },
  data() {
    return {
      email:'',
      password: '',
      forgotEmail:'',
      settings: settings,
      baseUrl: "http://localhost:8080/dhis/",
      dashboardUrl: "api/apps/Avenir-Generic/Avenir_Generic/index.html#/"
    };
  },
  methods: {
    showPass() {
      let passwordField = document.querySelector('#inputContactEmailPassword')
      if (passwordField.getAttribute('type') === 'password') {
        passwordField.setAttribute('type', 'text')
      }else
      {
        passwordField.setAttribute('type', 'password')
      }
    },
    GotoCreateAccount() {
      this.$router.push("/create-account");
    },
    goToActivateAccount(){
      this.$router.push("/activate");
    },
    onSubmit() {
      this.$root.loading = true;
      this.baseUrl = ApiServices.getBaseUrl();
      this.dashboardUrl = this.baseUrl+this.dashboardUrl;
      //let headers = {Authorization: 'Basic YWRtaW46ZGlzdHJpY3Q='};
      let authorizationToken = "Basic "+ btoa(this.email + ":"+this.password);
      localStorage.setItem("authToken",authorizationToken);
      ApiServices.RestPostCall('api/avenir/login',{"username":this.email,"password":this.password}).then(res => {
        console.log(res)
        this.$root.loading = false;
        if(res.status.toLocaleLowerCase() == "success"){
          localStorage.setItem("userProfile",JSON.stringify(res));
          localStorage.setItem("loggedInUserId",res.useruid);
          //console.log(JSON.parse(localStorage.getItem("userProfile")));
          //this.$router.push("/wizard");
          if(settings.countryName.toLowerCase() === 'nepal'){
            if(settings.userRole.includes(res.userrole)){
              this.$router.push("/select-plan-nepal");
            }else{
              window.open(this.dashboardUrl, '_blank');
            }

          }
          else{
            this.$router.push("/select-module");
          }

        }else{
          this.$swal({
            icon: 'error',
            text: res.message,
          });
          // logout forcefully
          ApiServices.RestGetCall('dhis-web-commons-security/logout.action',{}).then(res=>{
            console.log(res);
          })
        }
      }).catch((error) => {
          this.$root.loading = false;
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            //console.log(error.response.headers);
            this.$swal({
                text: "incorrect username/password"
            });
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          // logout forcefully
          ApiServices.RestGetCall('dhis-web-commons-security/logout.action',{}).then(res=>{
            console.log(res);
          })
          //console.log(error.config);
      });
    },
    forgotPassword() {
      if (this.$refs.frmForgotPassword.validate()) {
        this.$root.loading = true;
        console.log(this.forgotEmail);
        //this.forgotEmail = "";
        ApiServices.RestPostCall("api/avenir/subscribe/password/forgot", {
          email: this.forgotEmail
        }).then((res) => {
          console.log(res);
          this.$swal({
              text: res.message
          })
          this.$bvModal.hide('forgotPasswordModal')
          this.$root.loading = false;
        })
        //this.$bvModal.hide('forgotPasswordModal')
      }
    }
  }
};
</script>
<style scoped lang="scss">
.mdgridviewpage-bg {
  background-color: #38a1c1;
  background: url("../assets/img/sign_bg1.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
    padding: 20px 40px;
    display: flex;

    justify-content: center;
    align-items: center;
}

body.green-theme .mdgridviewpage-bg {
  background-color: #006600;
  background: url("../assets/img/green-bg.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  padding: 20px 40px;
}
.mainform-holder {
  /*background: #fff;
  -webkit-box-shadow: -2px 0px 5px 0px rgba(204, 204, 204, 1);
  -moz-box-shadow: -2px 0px 5px 0px rgba(204, 204, 204, 1);
  box-shadow: -2px 0px 5px 0px rgba(204, 204, 204, 1);
  border-radius: 10px;*/
  padding: 8px 5px;
}

.c-body {
  //background-color: rgba(255, 255, 255, 0.78) ;
  padding-top: 10px !important;
  padding-bottom: 30px !important;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  /* border-top-left-radius: 10px;
  border-top-right-radius: 10px; */
  /* box-shadow: 0px 0px 3px rgba(53,53,53,0.26); */
}


.card{
  background-color:transparent;
  box-shadow: 0px 0px 0px 0px 4px rgb(186, 186, 186) !important;
        border-radius: 10px;
        border: 0px !important;
}
.form-control{
  background: #efeeee !important;
    border-color: #f2f2f2;
    color: #171a22 !important;
    font-size: 14px !important;
    font-weight: 200;
    border: 1px solid #e9e9e9;
}
a{
  cursor: pointer;
  color: rgb(121, 121, 121);
}
a:hover{
  text-decoration:underline !important;
}
input:-internal-autofill-selected {
  background: #ddd !important;
    border-color: #f2f2f2 !important;
}
*{
  color: #4c4c4c;
}
label{
  font-weight: 200;
}
.card-header{
        border-bottom: 0px;
        border-radius: 10px 10px 0 0 !important;
        padding:8px 12px !important;
}
.input-group{
  position: relative;
  margin-bottom: 0px;
}
.input-group-append{
  position: absolute;
  right: 4px;
  top: 4px;
  .input-group-text{
    background-color: transparent !important;
    border: transparent;
    z-index: 999;
    cursor: pointer;
    color: #a3a3a3;
    padding: .175rem .75rem;
  }
}
#inputContactEmailPassword{
    padding-right:36px;
  }
</style>
