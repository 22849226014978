<template>
    <div id="header">
        <!-- <nav class="navbar navbar-default d-none" role="navigation">
            <div class="navbar-header">
                
                <a class="navbar-brand customfontstylegeneva" href="#/select-module">
                    <img src="../assets/img/logo_front.png"> 
                </a>
            </div>
            <div class="navbar-collapse collapse">
                <ul class="nav navbar-nav navbar-left">
                    <li><a href="" class="customfontstylegeneva" style="cursor: default;"> <span class="title-text">Family Planning Dashboard</span></a></li>
                    <li><a href=""></a></li>
                </ul>
                <ul class="nav navbar-nav navbar-right">
                    
                    <li>
                        <a href="http://avenirtraining.duredemos.com/service/dhis-web-commons/security/login.action" class="btn btn-headerbutton" type="button" role="button" style="color: #fff; background-color: #1d5288; border-color: #1d5288; padding: 0 12px;margin-top: 13%;">Skip to login</a> 
                    </li>
                    
                </ul>
            </div>
        </nav> -->
        <nav class="navbar navbar-expand-md navbar-default landingpage-nav" v-if="this.$route.name == 'training-dashboard' || this.$route.name == 'select-module'">
            <a class="navbar-brand customfontstylegeneva" href="#/select-module" >
                <img src="../assets/img/logo_front.png" class="imgFit"> 
            </a>
            
            <a href="#" class="customfontstylegeneva pl-4" style="cursor: default;"> <span class="title-text " style="color:#000 !important;">Family Planning Dashboard</span></a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                <span class="navbar-toggler-icon"></span>
            </button>
            
            <div class="collapse navbar-collapse" id="collapsibleNavbar" style="justify-content: flex-end;" v-if="this.$route.name != 'home' && this.$route.name != 'createaccount' && this.$route.name != 'activate'">
                <ul class="navbar-nav" v-if="this.$route.name == 'select-module'">
                    <li class="nav-item">
                        <a href="#" @click="logout" class="nav-link btn btn-headerbutton" type="button" role="button" style="padding: 0px 12px;margin-top: 4%;width: 130px; color:#000 !important;"><i class="fa fa-sign-out" aria-hidden="true"></i> Logout</a> 
                    </li>
                </ul>
                <ul class="navbar-nav" v-else>
                    <li class="nav-item">
                        <a href="#" @click="logout" class="nav-link btn btn-headerbutton text-dark" type="button" role="button" style="padding: 0px 12px;margin-top: 4%;width: 130px; color:#000 !important;"><i class="fa fa-sign-out" aria-hidden="true"></i> Logout</a> 
                    </li>
                    <!-- <li class="nav-item">
                        <a href="http://avenirtraining.duredemos.com/service/dhis-web-commons/security/login.action" class="nav-link btn btn-headerbutton" target="_blank" type="button" role="button" style="color: rgb(255, 255, 255);background-color: rgb(29, 82, 136);border-color: rgb(29, 82, 136);padding: 0px 12px;margin-top: 4%;width: 130px;">Harmonia</a> 
                    </li> -->
                    
                </ul>
            </div>  
        </nav>
        <nav class="navbar navbar-expand-md navbar-default insidepage-nav" v-else>
            <a @click="checkCourseTest" class="navbar-brand customfontstylegeneva">
                <i aria-hidden="true" class="fa fa-home color-white"></i>
            </a>
            <a href="#" class="customfontstylegeneva pl-4" style="cursor: default;"> <span class="title-text text-white">Family Planning Dashboard</span></a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                <span class="navbar-toggler-icon"></span>
            </button>
            
            <div class="collapse navbar-collapse" id="collapsibleNavbar" style="justify-content: flex-end;" v-if="this.$route.name != 'home' && this.$route.name != 'createaccount' && this.$route.name != 'activate'">
                <ul class="navbar-nav" v-if="this.$route.name == 'select-module'">
                    <li class="nav-item">
                        <a href="#" @click="logout" class="nav-link btn btn-headerbutton" type="button" role="button" style="padding: 0px 12px;margin-top: 4%;width: 130px;"><i class="fa fa-sign-out" aria-hidden="true"></i> Logout</a> 
                    </li>
                </ul>
                <ul class="navbar-nav" v-else>
                    <li class="nav-item">
                        <a href="#" @click="logout" class="nav-link btn btn-headerbutton" type="button" role="button" style="padding: 0px 12px;margin-top: 4%;width: 130px;"><i class="fa fa-sign-out" aria-hidden="true"></i> Logout</a> 
                    </li>
                    <!-- <li class="nav-item">
                        <a href="http://avenirtraining.duredemos.com/service/dhis-web-commons/security/login.action" class="nav-link btn btn-headerbutton" target="_blank" type="button" role="button" style="color: rgb(255, 255, 255);background-color: rgb(29, 82, 136);border-color: rgb(29, 82, 136);padding: 0px 12px;margin-top: 4%;width: 130px;">Harmonia</a> 
                    </li> -->
                    
                </ul>
            </div>  
        </nav>
    </div>
</template>
<script>
import ApiServices from '../service/ApiServices';
import swal from 'sweetalert2';
export default {
  data(){
    return{
      baseUrl: "http://localhost:8080/dhis/",
      dashboardUrl: "api/apps/Avenir-Generic/index.html#/"
    }
  },
  methods:{
    logout(){
      //dhis-web-commons-security/logout.action
      localStorage.removeItem("userProfile");
      localStorage.removeItem("loggedInUserId");
      ApiServices.RestGetCall('api/avenir/logout',{}).then(res=>{
        console.log(res);
      })
      //window.open('http://intraavenir.duredemos.com/service/dhis-web-commons-security/logout.action', '_blank');
      this.$router.push("/");
    },
    checkCourseTest(){
        let buttonClick = localStorage.getItem("responseClick");
        //console.log(buttonClick)
        if(buttonClick == 'Next'){
            Swal.fire({
                //title: 'Are you sure, you want to quit?',
                text: 'Are you sure, you want to quit?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33'
            }).then((result) => {
               if(result.value){
                  this.$router.push("/training-dashboard"); 
               }
            })
        }else{
            this.$router.push("/training-dashboard");
        }
        
    }
  },
  mounted(){
    this.baseUrl = ApiServices.getBaseUrl();
    this.dashboardUrl = this.baseUrl+this.dashboardUrl;
    //console.log(this.dashboardUrl)
  }
}
</script>
<style scoped>
    
</style>