<template>
  <div v-bind:class="getClass()">
    <div class="mdgridviewpage-bg">
      <HeaderProfile></HeaderProfile>
      <!-- Page Content -->
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12"></div>
        </div>
      </div>
      <div class="container-fluid m-t-10px">
        <div class="row dashboardchart-container globalconfigcontainer">
          <div class="col-lg-8" style="margin:0 auto;"></div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12">
            <div class="card mainCard mt-3">
              <div class="card-body p-0">
                <div class="d-flex">

                  <ul id="tabs" class="nav nav-tabs col-3 pr-0 border-0" role="tablist">
                    <li class="nav-item">
                      <a id="tab-A" href="#pane-A" class="nav-link active pl-4 py-4" data-toggle="tab" role="tab"><i class="fa fa-user mr-3 pt-1" aria-hidden="true"></i> User Profile</a>
                    </li>
                    <li class="nav-item">
                      <a id="tab-B" href="#pane-B" class="nav-link pl-4 py-4" data-toggle="tab" role="tab"><i class="fa fa-unlock-alt mr-3 pt-1" aria-hidden="true"></i> Change Password</a>
                    </li>
                    <!-- <li class="nav-item">
                      <a id="tab-C" href="#pane-C" class="nav-link pl-4 py-4" data-toggle="tab" role="tab"><i class="fa fa-eye mr-3 pt-1" aria-hidden="true"></i> View Profile</a>
                    </li> -->
                  </ul>

                  <div id="content" class="tab-content col-9 m-2" role="tablist">
                    <div id="pane-A" class="card tab-pane fade show active insidecard border-0" role="tabpanel" aria-labelledby="tab-A">
                      <div class="userProfile">
                        <div class="card-body mx-5 mb-3 pt-3">
                          <form id="userForm" ref="userForm">
                            <div class="form-group mx-4 mb-4 mt-0">
                              <div class="row mb-3">
                                <div class="col-3">
                                  <label for="fname" class="">First Name  <span class="text-danger">*</span></label>
                                </div>
                                <div class="col-5">
                                  <ValidationProvider name="Firstname" rules="required" v-slot="{ errors }">
                                    <div class="formInputs">
                                    <input type="text" class="form-control" placeholder="First" id="fname" v-model="userDetails.firstName" :disabled="disabled == 0">
                                    <span class="text-danger">{{ errors[0] }}</span>
                                    <i class="fa fa-pencil editIcon" aria-hidden="true"></i>
                                    </div>
                                  </ValidationProvider>
                                </div>
                                <div class="col-4">
                                  <ValidationProvider name="Last Name" rules="required" v-slot="{ errors }">
                                    <div class="formInputs">
                                    <input type="text" class="form-control" placeholder="Last" id="surname" v-model="userDetails.surname" :disabled="disabled == 0">
                                    <span class="text-danger">{{ errors[0] }}</span>
                                    <i class="fa fa-pencil editIcon" aria-hidden="true"></i>
                                    </div>
                                  </ValidationProvider>
                                </div>
                              </div>
                              <div class="row mb-3 d-none">
                                <div class="col-3">
                                  <label for="surname" class="">Surname  <span class="text-danger">*</span></label>
                                </div>
                                <div class="col-9">
                                  <ValidationProvider name="Last Name" rules="required" v-slot="{ errors }">
                                    <input type="text" class="form-control" placeholder="" id="surname" v-model="userDetails.surname" :disabled="disabled == 0">
                                    <span class="text-danger">{{ errors[0] }}</span>
                                  </ValidationProvider>
                                </div>
                              </div>
                              <div class="row mb-3">
                                <div class="col-3">
                                  <label for="email" class="">Email  <span class="text-danger">*</span></label>
                                </div>
                                <div class="col-9">
                                  <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                                    <input type="text" class="form-control" placeholder="" id="email" v-model="userDetails.email" readonly>
                                    <span class="text-danger">{{ errors[0] }}</span>
                                  </ValidationProvider>
                                </div>
                              </div>
                              <div class="row mb-3">
                                <div class="col-3">
                                  <label for="mblNo" class="">Mobile Number  <span class="text-danger">*</span></label>
                                </div>
                                <div class="col-9">
                                  <ValidationProvider name="Mobile" rules="required" v-slot="{ errors }">
                                    <div class="formInputs">
                                    <input type="text" class="form-control" placeholder="" id="mblNo" v-model="userDetails.phoneNumber" :disabled="disabled == 0">
                                    <span class="text-danger">{{ errors[0] }}</span>
                                    <i class="fa fa-pencil editIcon" aria-hidden="true"></i>
                                    </div>
                                  </ValidationProvider>
                                </div>
                              </div>
                              
                              <div class="row mb-3">
                                <div class="col-3">
                                  <label for="gender" class="">Gender  <span class="text-danger">*</span></label>
                                </div>
                                <div class="col-9">
                                  <div class="select-wrapper ">
                                    <ValidationProvider name="Gender" rules="required" v-slot="{ errors }">
                                      <div class="formInputs">
                                      <select class="form-control" id="gender" v-model="userDetails.gender" :disabled="disabled == 0">
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                        <option value="other">Other</option>
                                      </select>
                                      <span class="text-danger">{{ errors[0] }}</span>
                                      <i class="fa fa-pencil editIcon" aria-hidden="true"></i>
                                      </div>
                                    </ValidationProvider>
                                    <!-- <b-form-select v-model="selected" :options="options"></b-form-select> -->
                                  </div>
                                </div>
                              </div>
                              <div class="row mb-3">
                                <div class="col-3">
                                  <label for="dob" class="">Birthday  <span class="text-danger">*</span></label>
                                </div>
                                <div class="col-9">
                                  <ValidationProvider name="Date" rules="required" v-slot="{ errors }">
                                    <div class="formInputs dobField">
                                    <b-form-datepicker  :min="min" :max="max" v-model="userDetails.birthday" locale="en" :disabled="disabled == 0"></b-form-datepicker>
                                    <!-- <input type="date" class="form-control" placeholder="" id="dob" v-model="userDetails.birthday" :disabled="disabled == 0"> -->
                                    <span class="text-danger">{{ errors[0] }}</span>
                                    <i class="fa fa-pencil editIcon" aria-hidden="true"></i>
                                    </div>
                                  </ValidationProvider>
                                </div>
                              </div>
                              <div class="row mb-3">
                                <div class="col-3">
                                  <label for="nationality" class="">Nationality  <span class="text-danger">*</span></label>
                                </div>
                                <div class="col-9">
                                  <ValidationProvider name="nationality" rules="required" v-slot="{ errors }">
                                    <div class="formInputs">
                                    <input type="text" class="form-control" placeholder="" id="nationality" v-model="userDetails.nationality" :disabled="disabled == 0">
                                    <span class="text-danger">{{ errors[0] }}</span>
                                    <i class="fa fa-pencil editIcon" aria-hidden="true"></i>
                                    </div>
                                  </ValidationProvider>
                                </div>
                              </div> 
                              <div class="row mb-3">
                                <div class="col-3">
                                  <label for="jobTitle" class="">Job Title  <span class="text-danger">*</span></label>
                                </div>
                                <div class="col-9">
                                  <ValidationProvider name="nationality" rules="required" v-slot="{ errors }">
                                    <div class="formInputs">
                                    <input type="text" class="form-control" placeholder="" id="jobTitle" v-model="userDetails.jobTitle" :disabled="disabled == 0">
                                    <span class="text-danger">{{ errors[0] }}</span>
                                    <i class="fa fa-pencil editIcon" aria-hidden="true"></i>
                                    </div>
                                  </ValidationProvider>
                                </div>
                              </div>
                              <div class="row mb-3">
                                <div class="col-3">
                                  <label for="employer" class="">Employer </label>
                                </div>
                                <div class="col-9">
                                  <div class="formInputs">
                                  <input type="text" class="form-control" placeholder="" id="employer" v-model="userDetails.employer" :disabled="disabled == 0">
                                  <i class="fa fa-pencil editIcon" aria-hidden="true"></i>
                                  </div>
                                </div>
                              </div> 
                              <div class="row mb-3 ">
                                <div class="col-3">
                                  <label for="education" class="">Education </label>
                                </div>
                                <div class="col-9">
                                  <div class="formInputs">
                                  <input type="text" class="form-control" placeholder="" id="education" v-model="userDetails.education" :disabled="disabled == 0">
                                  <i class="fa fa-pencil editIcon" aria-hidden="true"></i>
                                  </div>
                                </div>
                              </div> 
                              <div class="row mb-3 d-none">
                                <div class="col-3">
                                  <label for="intrests" class="">Intrests  <span class="text-danger">*</span></label>
                                </div>
                                <div class="col-9">
                                  <div class="formInputs">
                                  <input type="text" class="form-control" placeholder="" id="intrests">
                                  <i class="fa fa-pencil editIcon" aria-hidden="true"></i>
                                  </div>
                                </div>
                              </div> 
                              <div class="row mb-3 d-none">
                                <div class="col-3">
                                  <label for="lang" class="">Languages  <span class="text-danger">*</span></label>
                                </div>
                                <div class="col-9">
                                  <div class="formInputs">
                                  <input type="text" class="form-control" placeholder="" id="lang">
                                  <i class="fa fa-pencil editIcon" aria-hidden="true"></i>
                                  </div>
                                </div>
                              </div> 
                              <div class="row my-5">
                                <div class="col-6">
                                  <button type="button" class="btn btn-danger" @click="editProfile">Edit Profile</button>
                                  <button type="button" class="btn btn-primary" @click="updateProfile" :disabled="disabled == 0">Update Profile</button>
                                </div>
                                <div class="col-6">
                                 
                                </div>
                              </div>
                            </div>
                            
                          </form>
                        </div>
                      </div>
                    </div>

                    <div id="pane-B" class="card tab-pane fade insidecard border-0" role="tabpanel" aria-labelledby="tab-B">
                      <div class="password_change">
                        <div class="card-body mx-5 my-3 ">
                          <form class="">
                            <div class="form-group m-4">
                              <div class="row mb-3">
                                <div class="col-3">
                                  <label for="uname" class="">Username  <span class="text-danger">*</span></label>
                                </div>
                                <div class="col-9">
                                  <input type="text" class="form-control" placeholder="" id="uname" autocomplete="new-nemail@DFHRFDRDF" v-model="frmModel.username" disabled>
                                </div>
                              </div>
                              <div class="row mb-3">
                                <div class="col-3">
                                  <label for="oldPassword" class="">Old Password  <span class="text-danger">*</span></label>
                                </div>
                                <div class="col-9">
                                  <input type="password" class="form-control" placeholder="" id="oldPassword" autocomplete="old-password" v-model="oldPassword">
                                </div>
                              </div>
                              <div class="row mb-3">
                                <div class="col-3">
                                  <label for="newPassword" class="">New Password  <span class="text-danger">*</span></label>
                                </div>
                                <div class="col-9">
                                  <input type="password" class="form-control" placeholder="" id="newPassword" autocomplete="new-paaaassword" v-model="newPassword">
                                </div>
                              </div>
                              <div class="row mb-3">
                                <div class="col-3">
                                  <label for="r_newPassword" class="">Repeat New Password  <span class="text-danger">*</span></label>
                                </div>
                                <div class="col-9">
                                  <input type="password" class="form-control" placeholder="" id="r_newPassword" autocomplete="new-rpassword" v-model="confirmPassword">
                                </div>
                              </div>
                              <div class="row my-5">
                                <div class="col-6">
                                  <button type="button" class="btn btn-primary" @click="updatePassword">Update Password</button>
                                </div>
                                <div class="col-6">
                                 
                                </div>
                              </div>
                            </div>
                            
                          </form>
                        </div>
                      </div>
                    </div>

                    
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <modals-container/>
    </div>
  </div>
</template>

<script>
  // import "vue-form-wizard/dist/vue-form-wizard.min.css";
  import ApiServices from '../service/ApiServices';
  
  import HeaderProfile from '../components/layout/HeaderProfile';
  import moment from 'moment';
  import swal from 'sweetalert2';
  window.Swal = swal;
  export default {
    components: {
        HeaderProfile 
    },
    data() {
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      // 15th two months prior
      const minDate = new Date(today)
      minDate.setYear(1920)
      minDate.setDate(1)
      // 15th in two months
      const maxDate = new Date(today)
      maxDate.setMonth(maxDate.getMonth())
      maxDate.setDate(15)
      return {
        userDetails: {
          //id: "",
          firstName:"",
          surname:"",
          phoneNumber:"",
          education: "",
          gender: null,
          nationality: "",
          jobTitle: "",
          employer:"",
          birthday: ""
        },
        disabled: 0,
        min: minDate,
        max: maxDate,
        loggedInUserProfile: null,
        frmModel: {color : "#212832", username: ""},
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      }
    },
    created() {
      
    },
    methods: {
      getClass(){
        // if(settings.countryName.toLowerCase() === 'nepal'){
        //   return 'newBlack-theme'
        // }else{
        //   return 'newBlue-theme'
        // }
        if(this.frmModel.color === '#212832'){
          return 'newBlack-theme'
        }else if(this.frmModel.color === '#66e093'){
          return 'newBlue-theme'
        }else if(this.frmModel.color === '#25984b'){
          return 'newGreen-theme'
        }
      },
      getprofile(){
        this.$root.loading = true;
        
        ApiServices.RestGetCall('api/me.json?fields=id,email,firstName,surname,gender,jobTitle,phoneNumber,birthday,employer,nationality,education').then(Response =>{
          let gender = '', bDate = ''
          console.log(Response)
          // if(Response.birthday != undefined){
          //   bDate = moment(Response.birthday).format('MM/DD/YYYY')
          // }
          this.userDetails = {
              //id:Response.id,
              birthday:Response.birthday,
              email: Response.email,
              firstName:Response.firstName,
              phoneNumber:Response.phoneNumber,
              education:Response.education,
              gender:Response.gender,
              nationality:Response.nationality,
              surname:Response.surname,
              jobTitle:Response.jobTitle,
              employer:Response.employer
            }
          console.log(this.userDetails)
          this.$root.loading = false;
        }).catch(error=>{
          this.$root.loading = false;
          this.$swal({
            text: error
          });
        });
      },
      editProfile(){
        this.disabled = 1
      },
      updateProfile(){
        this.$root.loading = true;
        console.log(this.userDetails)
        if(this.userDetails.birthday != undefined && this.userDetails.firstName != undefined &&
        this.userDetails.gender != undefined && this.userDetails.jobTitle != undefined && this.userDetails.nationality != undefined && 
        this.userDetails.phoneNumber != undefined && this.userDetails.surname != undefined){
          ApiServices.RestPutCall('api/me',this.userDetails).then(Response =>{
            this.disabled = 0
            this.$root.loading = false;
            console.log(Response)
          }).then(
            Swal.fire({
              text: "User details updated successfully!",
              icon: 'warning',
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33'
            })
          )
        }else{
          this.$root.loading = false;
          Swal.fire({
              text: "Please fill all the mandatory fields!",
              icon: 'warning',
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33'
            })
        }
        
      },
      updatePassword(){
        
        console.log(this.oldPassword, this.newPassword, this.confirmPassword)
        if(this.newPassword === this.confirmPassword){
          ApiServices.RestPutCall('api/29/me/changePassword',{oldPassword: this.oldPassword, newPassword: this.newPassword}).then(Response =>{
            console.log(Response)
            Swal.fire({
              text: "Password updated successfully!",
              icon: 'warning',
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33'
            })
            this.$root.loading = false;
          }).catch(error=>{
            this.$root.loading = false;
            Swal.fire({
                text: "Password must not be one of the previous 24 passwords",
                icon: 'warning',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33'
              })
          })
        }else{
          this.$root.loading = false;
           Swal.fire({
            text: "New Password and Confirm Password not matching",
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33'
          })
        }
        
      }
    },
    mounted() {
      this.getprofile()
      if(localStorage.getItem("userProfile")){
        this.loggedInUserProfile = JSON.parse(localStorage.getItem("userProfile"));
        if(this.loggedInUserProfile.config){
          this.frmModel = this.loggedInUserProfile.config;
          this.frmModel.color = this.frmModel.color;
          this.frmModel.username = this.loggedInUserProfile.username;
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
.mdgridviewpage-bg {
  // background-color: #38a1c1;
  background-color: #3471CE;
  // background: url("../assets/img/blue-bg.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  padding: 20px 40px;
}

body.green-theme .mdgridviewpage-bg {
  background-color: #006600;
  background: url("../assets/img/green-bg.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  padding: 20px 40px;
}

.mainform-holder {
  background: #fff;
  -webkit-box-shadow: -2px 0px 5px 0px rgba(204, 204, 204, 1);
  -moz-box-shadow: -2px 0px 5px 0px rgba(204, 204, 204, 1);
  box-shadow: -2px 0px 5px 0px rgba(204, 204, 204, 1);
  border-radius: 10px;
  padding: 60px;
}

.homepage-bg {
        /*background: url("../assets/img/home/Avenir-health-home.jpg") no-repeat;*/
        background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../assets/img/home/avenirbg.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    
    .socialicons-container {
        position: absolute;
    }
    /*.config-icon-container {
  width: 100px;
  height: auto;
}*/
    
    .modRadio .config-icon-container {
        width: 100px;
        height: 100px;
    }
    
    #benchmarking .tabs .card-header {
        background-color: rgba(0, 0, 0, 0.03)
    }
    
    #sidebar-wrapper {
        position: relative
    }
    
    .modRadio.colorSelect {
        margin-bottom: 30px;
    }
    
    .overlay {
        position: absolute;
        top: 48px;
        left: 0;
        height: calc(100% - 126px);
        width: 100%;
        z-index: 10;
        background-color: rgba(0, 0, 0, 0.5);
    }
    
    .edit-links {
        z-index: 14;
        position: relative;
        color: #fff;
        font-size: 14px;
    }
    .img-preview img {
        width: 180px !important;
        margin-top: 20px;
    }
    .headingText {
        background: transparent;
        border: none;
        font-size: 2.5rem;
        font-weight: 100;
        font-color: #FFFFFF;
        padding: 0px;
    }

    .landing-desc {
        background: transparent;
        color: #fff;
        border: none;
        width: 100%;
    }
.col-form-label{
  font-size: 14px;
}
.vue-form-wizard .wizard-btn{
  font-weight: 400;
}
.card{
  background-color: #f4f4f4;
}
.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-55, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9{
      padding-right: 15px !important;
    padding-left: 15px !important;
}
  select{
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
    -moz-appearance:none !important;
  
  
  }
  .select-wrapper {
    position: relative;	
  }
  
  .select-wrapper:after {
    font-family: FontAwesome;
      content: '\f0d7';
      font-size: 16px;
      position: absolute;
      top: 8px;
      right: 14px;
      color: #676767;
      pointer-events: none;
  }

  .bg-light-purple{
    background-color: #3662a8 !important;
  }
  .nav-tabs {
    display: none;
    height: 80vh;
    background: #373a3f !important;
  }
.mainCard{
  height: 80vh;
  border: 0;
}
.insidecard{
    height: 75vh;
    overflow: hidden;
    overflow-y: scroll;
}

.nav-tabs .nav-item{
  margin-bottom: 0;
}

.nav-tabs > li > a{
  font-size: 1.3em;
  color: #f4f4f4;
}
label{
  margin-bottom: 0;
  font-size: 15px;
  padding-top: 14px;
  color: #4c4c4c;
  font-weight: 400;
}
.btn{
  font-size: 14px;
}
input[type="date"] {
  position: relative;
  padding: 10px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: transparent;
  background: none;
  z-index: 1;
}

input[type="date"]:before {
  color: transparent;
  background: none;
  display: block;
  font-family: 'FontAwesome';
  content: '\f073';
  /* This is the calendar icon in FontAwesome */
  width: 15px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  color: #c2c3c3;
  cursor: pointer;
}

.newGreen-theme{
  .nav-tabs{
    background-color: #1a4728 !important;
  }
  .nav-tabs .nav-link.active{
    background-color: #fff;
  }
  .card{
    background-color: #fff;
  }
  .nav-tabs .nav-link.active:hover{
    background-color: #fff;
  }
}
@media (min-width: 768px) {
  .nav-tabs {
    display: flex;
    flex-flow: column nowrap;
  }
  .nav-tabs {
    border-bottom: none;
    border-right: 1px solid #ddd;
    display: flex;
  }
  .nav-tabs {
    margin: 0 0px 0 0;
  }
  // .nav-tabs .nav-item + .nav-item {
  //   margin-top: 0.25rem;
  // }
  .nav-tabs .nav-link {
    transition: border-color 0.125s ease-in;
    white-space: nowrap;
  }
  .nav-tabs .nav-link:hover {
    background-color: #d4d4d4;
    border-color: transparent;
    border-radius: 0;
    color: #4c4c4c !important;
    border-left: 6px solid #fe5868;
  }
  .nav-tabs .nav-link.active {

    border-radius: 0;
    background-color: #f4f4f4;
    border: 0;
    border-left: 6px solid #fe5868;
    font-weight: 500;
  }
  .nav-tabs #tab-A:hover{
    border-left: 6px solid #80b853;
  }
  .nav-tabs #tab-A.active{
    border-left: 6px solid #80b853;
  }
  .nav-tabs .nav-link.active:hover {
    background-color: #f4f4f4;
    
    // border-color: #0275d8 #fff #0275d8 #0275d8;
    
  }

  
}

@media (max-width: 767px) {
  .tab-pane {
    display: block !important;
    opacity: 1;
  }
}
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #c2c3c3;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
// .formInputs::after
// {
//   font-family: 'FontAwesome';
//   content: "\f007";
//   color:red;
//   position: relative;
//   left: -5px;
      
// }
.formInputs {
    position: relative;	
  }
  
  // .formInputs:after {
    
  //   font-family: FontAwesome;
  //   content: '\f040';
  //   font-size: 12px;
  //   position: absolute;
  //   top: 13px;
  //   right: 10px;
  //   color: #676767;
  //   pointer-events: none;
  //   opacity: .6;

  // }
.editIcon{
  font-size: 12px;
    position: absolute;
    top: 13px;
    right: 10px;
    color: #676767;
    opacity: .6;
  display: none;
  
}

.form-control:hover ~ .editIcon{
  
    display: block;
}
.form-control:disabled ~ .editIcon{
  opacity: 0;
}
.select-wrapper .formInputs .editIcon {
  right: 35px;
}
.dobField .editIcon{
  top : 15px;
  right: 45px;
}
.form-control{
  font-size: 14px;
}

</style>
