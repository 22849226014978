<template>
  <div>
    <div class="row">
      <div class="col-12" style="padding-right: 5px; padding-left: 5px">
        <!-- {{review}} {{question.attempted}} -->
        <div
          class="alert alert-danger"
          role="alert"
          v-if="review && question.attempted == false"
        >
          <b>Not Attempted!!</b>
        </div>
      </div>
    </div>
 
    <div class="row px-4" v-if="question && quesLen">
      <div class="col-6">
        
        <div class="question-text">
          <!-- <p v-if="review && question.attempted == false">Not Attempted!!</p> -->
          <div class="d-flex question-set-sect align-items-center">
            <div class="quest_no_div mr-3">
              <span class="btn btn-dark m-0 border-radius-0">{{
                index + 1
              }}</span>
              <span class="d-block text-center">of {{ quesLen.length }}</span>
            </div>
            <p class="font-weight-bold">{{ question.question }}</p>
            <!-- <span>{{ index + 1 }})</span> {{ question.question }} -->
          </div>
          <p class="quest-img-div mt-1">
            <img
              class="responsive"
              v-if="question.uploadImageContent"
              :src="question.uploadImageContent"
            />
          </p>
        </div>
        
      </div>

      <div class="col-md-6">
        <div class="mcqdiv mt-2">
          <div class="answerdiv px-4" v-if="!review">
            <div
              class="row"
              v-for="(option, i) in question.options"
              :key="option.optionId"
            >
              <div class="col-sm-2">
                <span class="ans_sr_no">{{ i + 1 }}</span>
              </div>
              <div class="col-sm-10">
                <p
                  class="ans_text mb-3"
                  :class="{ active: option.optionId == selected }"
                  @click="getAnswer(option.optionId, question.id)"
                  v-html="option.option"
                ></p>
              </div>
            </div>
          </div>
          <div class="answerdiv" v-else>
            <div
              class="row"
              v-for="(option, i) in question.options"
              :key="option.optionId"
            >
              <div class="col-sm-2">
                <span class="ans_sr_no">{{ i + 1 }}</span>
              </div>
              <div class="col-sm-10">
                <p
                  class="ans_text"
                  :class="option.styleClass"
                  v-html="option.option"
                ></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["questionObj", "review", "index", "quesLen"],
  data() {
    console.log(this.quesLen)
    return {
      question: this.questionObj,
      selected: undefined,
      active: {
        "background-color": "#50abc9",
        color: "#fff",
        border: "1px solid #50abc9",
      },
      wrong: {
        "background-color": "#f44336",
        color: "#fff",
        border: "1px solid #f44336",
      },
      isActive: false,
    };
  },
  methods: {
    getAnswer(oId, qId) {
      this.selected = oId;
      this.isActive = true;
      this.$emit("answer", { questionId: qId, optionId: oId });
    },
  },
  watch: {
    questionObj(newVal) {
      this.question = newVal;
    },
  },
};
</script>
<style scoped lang="scss">
.active {
  background-color: #a2f6a0;
  color: #42413c;
  border: 1px solid #a2f6a0;
}
.wrong {
  background-color: #fb6b59;
  color: #42413c;
  border: 1px solid #fb6b59;
}
.quest_no_div {
  span {
    font-family: Montserrat-SemiBold;
  }
}
.btn-dark {
  color: #fff;
  background-color: #42413c;
  border-color: #42413c;
  font-size: 1.4rem;
}
.quest-img-div {
  img {
    width: 510px;
    height: 200px;
    object-fit: contain;
  }
}
</style>
