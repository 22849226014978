import { render, staticRenderFns } from "./HomeNew.vue?vue&type=template&id=62f7abfd&scoped=true&"
import script from "./HomeNew.vue?vue&type=script&lang=js&"
export * from "./HomeNew.vue?vue&type=script&lang=js&"
import style0 from "./HomeNew.vue?vue&type=style&index=0&id=62f7abfd&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62f7abfd",
  null
  
)

export default component.exports