<template>
  <div class="body">
    <div class="main_container">
      <training-header :appConfig="appConfig"></training-header>

      <div class="right_col" role="main" style="margin-left: 2px">
        <br />

        <div class="row">
          <div class="col-lg-10 mx-auto">
            <div class="backgroundbg-color">
              <div class="heading-title-card">
                <h2 class="text-white" style="font-weight: 600">
                  {{ facilitatorObj.stepHeader }}
                </h2>
              </div>
              <div class="x_panel">
                <div class="x_content">
                  <div class="dashboard-widget-content">
                    <div class="row" style="height: 345px">
                      <div class="col-md-1"></div>
                      <div
                        class="col-md-2"
                        style="
                          border-top: 13px solid rgba(196, 68, 145, 1);
                          border-left: 13px solid rgba(196, 68, 145, 1);
                          bottom: -229px;
                          padding-top: 8px;
                          margin-right: 8px;
                          height: 105px;
                          background-color: rgb(196 68 145 / 27%);
                        "
                      >
                        <span class="spantextstyle">
                          <a>
                            <b>Step 1:</b> Click the download button and
                            download all relevant resources.
                          </a>
                        </span>
                      </div>

                      <div
                        class="col-md-2"
                        style="
                          border-top: 13px solid rgba(58, 79, 196, 1);
                          border-left: 13px solid rgba(58, 79, 196, 1);
                          bottom: -167px;
                          height: 105px;
                          padding-top: 8px;
                          margin-right: 8px;
                          background-color: rgb(58 79 196 / 23%);
                        "
                      >
                        <span class="spantextstyle">
                          <a>
                            <b>Step 2:</b> Now click the customise button and
                            tailor-make an agenda
                          </a>
                        </span>
                      </div>

                      <div
                        class="col-md-2"
                        style="
                          border-top: 13px solid rgba(227, 53, 31, 1);
                          border-left: 13px solid rgba(227, 53, 31, 1);
                          bottom: -101px;
                          height: 105px;
                          padding-top: 8px;
                          margin-right: 8px;
                          background-color: rgb(227 53 31 / 26%);
                        "
                      >
                        <span class="spantextstyle">
                          <a> <b>Step 3:</b> Download your agenda </a>
                        </span>
                      </div>

                      <div
                        class="col-md-2"
                        style="
                          border-top: 13px solid rgba(227, 183, 36, 1);
                          border-left: 13px solid rgba(227, 183, 36, 1);
                          bottom: -52px;
                          height: 105px;
                          padding-top: 8px;
                          margin-right: 8px;
                          background-color: rgb(227 183 36 / 40%);
                        "
                      >
                        <span class="spantextstyle">
                          <a>
                            <b>Step 4:</b> Click on the complete button and now
                            you are ready for conducting the training.
                          </a>
                        </span>
                      </div>

                      <div
                        class="col-md-2"
                        style="
                          border-top: 13px solid rgb(34 95 78);
                          border-left: 13px solid rgb(34 95 78);
                          height: 105px;
                          padding-top: 8px;
                          margin-right: 8px;
                          background-color: rgb(34 95 78 / 33%);
                        "
                      >
                        <span class="spantextstyle">
                          <a>
                            <b>Step 5:</b> Login to the FP-LMIS dashboard using
                            the shared credentials.
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    class="floating-submit-btn w-100"
                    style="text-align: center; margin-bottom: 3%"
                  >
                    <div class="d-flex justify-content-between">
                      <router-link
                        :to="{
                          name: 'facilitator',
                          params: {
                            facilitatorObj: facilitatorObj,
                            appConfig: appConfig,
                          },
                        }"
                        class=""
                      >
                        <a class="btn yellow-float-btn justify-content-start">
                          Previous</a
                        >
                      </router-link>

                      <router-link
                        :to="{
                          name: 'resources-session',
                          params: {
                            facilitatorObj: facilitatorObj,
                            appConfig: appConfig,
                          },
                        }"
                        class=""
                      >
                        <a class="btn yellow-float-btn"> Next </a>
                      </router-link>
                    </div>
                    <!-- <a href="login.html" class="btn btn-cust1 btn-md btn3d" role="button">Skip to Login</a> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /page content -->

      <!-- footer content -->
      <training-footer :appConfig="appConfig"></training-footer>
      <training-faq :facilitatorObj="facilitatorObj"></training-faq>
      <!-- /footer content -->
    </div>
  </div>
</template>
<script>
import trainingHeader from "../components/trainingHeader";
import trainingFooter from "../components/trainingFooter";
import trainingFaq from "./trainingFaq";
import vcarousel from "carousel";

// import {
//   mdbCarousel,
//   mdbBtn,
//   mdbCard,
//   mdbCardImage,
//   mdbCardText,
//   mdbCardTitle,
//   mdbCardBody
// } from "mdbvue";
export default {
  components: {
    // mdbCarousel,
    // mdbCard,mdbCardImage,mdbCardBody,mdbBtn,mdbCardText,mdbCardTitle,
    trainingHeader,
    trainingFooter,
    trainingFaq,
    vcarousel,
  },
  data() {
    return {
      slide: 0,
      sliding: null,
      facilitatorObj: {},
      appConfig: {},
      currentOffset: 0,
      windowSize: 3,
      paginationFactor: 220,
      items: [],
    };
  },
  mounted() {
    if (this.$route.params.facilitatorObj) {
      this.facilitatorObj = this.$route.params.facilitatorObj;
    } else {
      this.facilitatorObj = JSON.parse(localStorage.getItem("facilitatorObj"));
    }
    if (this.$route.params.appConfig) {
      this.appConfig = this.$route.params.appConfig;
    } else {
      this.appConfig = JSON.parse(localStorage.getItem("appConfig"));
    }
  },
  computed: {
    atEndOfList() {
      return (
        this.currentOffset <=
        this.paginationFactor *
          -1 *
          (this.facilitatorObj.steps.length - this.windowSize)
      );
    },
    atHeadOfList() {
      return this.currentOffset === 0;
    },
  },
  methods: {
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = true;
    },
    moveCarousel(direction) {
      // Find a more elegant way to express the :style. consider using props to make it truly generic
      if (direction === 1 && !this.atEndOfList) {
        this.currentOffset -= this.paginationFactor;
      } else if (direction === -1 && !this.atHeadOfList) {
        this.currentOffset += this.paginationFactor;
      }
    },
  },
};
</script>
<style scoped>
.carousel-indicators li {
  background-color: #771c1c;
}
.carousel-indicators .active {
  opacity: 1;
}
.x_panel {
  background-color: #fff;
  border: 1px solid #ececec;
  padding: 0;
  position: relative;
}
.x_title {
  border-bottom: 0px solid #e6e9ed;
  padding: 15px 18px;
  background-color: #b0b0b0;
  color: #fff !important;
  border-radius: 7px 7px 0 0;
}
.x_content {
  padding: 0 25px 6px;
}
.spantextstyle a {
  color: #000;
  font-weight: 400;
}
.right_col {
  justify-content: center;
  align-items: center;
  display: flex;
}

body {
  background: #f8f8f8;
  color: #2c3e50;
  font-family: "Source Sans Pro", sans-serif;
}

.card-carousel-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0 40px;
  color: #666a73;
}

.card-carousel {
  display: flex;
  justify-content: center;
  /* width: 200px; */
}
.card-carousel--overflow-container {
  overflow: hidden;
}
.card-carousel--nav__left,
.card-carousel--nav__right {
  display: inline-block;
  width: 15px;
  height: 15px;
  padding: 10px;
  box-sizing: border-box;
  border-top: 2px solid #42b883;
  border-right: 2px solid #42b883;
  cursor: pointer;
  margin: 0 20px;
  transition: transform 150ms linear;
}
.card-carousel--nav__left[disabled],
.card-carousel--nav__right[disabled] {
  opacity: 0.2;
  border-color: black;
}
.card-carousel--nav__left {
  transform: rotate(-135deg);
}
.card-carousel--nav__left:active {
  transform: rotate(-135deg) scale(0.9);
}
.card-carousel--nav__right {
  transform: rotate(45deg);
}
.card-carousel--nav__right:active {
  transform: rotate(45deg) scale(0.9);
}

.card-carousel-cards {
  display: flex;
  transition: transform 150ms ease-out;
  transform: translatex(0px);
}
.card-carousel-cards .card-carousel--card {
  margin: 0 10px;
  cursor: pointer;
  box-shadow: 0 4px 15px 0 rgba(40, 44, 53, 0.06),
    0 2px 2px 0 rgba(40, 44, 53, 0.08);
  background-color: #fff;
  border-radius: 4px;
  z-index: 3;
  margin-bottom: 2px;
}
.card-carousel-cards .card-carousel--card:first-child {
  margin-left: 0;
}
.card-carousel-cards .card-carousel--card:last-child {
  margin-right: 0;
}
.card-carousel-cards .card-carousel--card img {
  vertical-align: bottom;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  transition: opacity 150ms linear;
  user-select: none;
}
.card-carousel-cards .card-carousel--card img:hover {
  opacity: 0.5;
}
.card-carousel-cards .card-carousel--card--footer {
  border-top: 0;
  padding: 7px 15px;
}
.card-carousel-cards .card-carousel--card--footer p {
  padding: 3px 0;
  margin: 0;
  margin-bottom: 2px;
  font-size: 19px;
  font-weight: 500;
  color: #2c3e50;
  user-select: none;
}
.card-carousel-cards .card-carousel--card--footer p.tag {
  font-size: 11px;
  font-weight: 300;
  padding: 4px;
  background: rgba(40, 44, 53, 0.06);
  display: inline-block;
  position: relative;
  margin-left: 4px;
  color: #666a73;
}
.card-carousel-cards .card-carousel--card--footer p.tag:before {
  content: "";
  float: left;
  position: absolute;
  top: 0;
  left: -12px;
  width: 0;
  height: 0;
  border-color: transparent rgba(40, 44, 53, 0.06) transparent transparent;
  border-style: solid;
  border-width: 8px 12px 12px 0;
}
.card-carousel-cards .card-carousel--card--footer p.tag.secondary {
  margin-left: 0;
  border-left: 1.45px dashed white;
}
.card-carousel-cards .card-carousel--card--footer p.tag.secondary:before {
  display: none !important;
}
.card-carousel-cards .card-carousel--card--footer p.tag:after {
  content: "";
  position: absolute;
  top: 8px;
  left: -3px;
  float: left;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background: white;
  box-shadow: -0px -0px 0px #004977;
}

h1 {
  font-size: 3.6em;
  font-weight: 100;
  text-align: center;
  margin-bottom: 0;
  color: #42b883;
}
</style>
