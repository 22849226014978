<template>
  <div class="body">
    <div class="main_container">
      <training-header></training-header>
      <!-- page content -->
      <div class="container-fluid" role="main" style="margin-left: 2px;">
        <div class="text-sectiomn-wrap">
          <div class="row px-4 pt-4">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="x_panel" style="border-radius:10px;">
                
                
                <div class="x_content">
                  <div class="dashboard-widget-content">
                    <div class="row">
                      <!--                                        <div class="col-md-4">-->
                      <div class="col-md-12 col-sm-12 col-xs-12">
                        <div class="x_panel text-center" style="border: none;">
                          <div class="x_title">
                            <h1 class="text-center">
                              <input
                                type="checkbox"
                                name="chk_harmonia"
                                id="chk_harmonia"
                                class="chkUserSelectCls"
                                data-user="genevauser"
                                data-pass="Genevauser123"
                                style="margin: 15px 05px"
                              />HARMONIA
                              <!-- <input type="checkbox" name="chk_harmonia" id="chk_harmonia" class="chkUserSelectCls" data-user="genevauser" data-pass="Genevauser123" value="true"> -->
                            </h1>
                            <!--<label class="container">HARMONIA
                            <input type="checkbox" name="chk_harmonia" id="chk_harmonia" value="true"><span class="checkmark"></span></label>-->
                            <div class="clearfix"></div>
                          </div>
                          <div class="x_content">
                            <p>U: genevauser</p>
                            <!-- <br> -->
                            <p>P: Genevauser123</p>
                          </div>
                          <div class="row textstart px-2">
                            <div class="col-md-2 col-sm-2 col-xs-12">
                              <div class="x_panel orange-panel">
                                <div class="x_title">
                                  <input
                                    type="checkbox"
                                    name="chk_orange"
                                    id="chk_orange"
                                    class="chkUserSelectCls"
                                    data-user="sioprovince1"
                                    data-pass="Sioprovince1321"
                                    disabled="true"
                                  />
                                  <h2 class="text-center">Province 1</h2>
                                  <div class="clearfix"></div>
                                </div>
                                <div class="x_content">
                                  <p>U: sioprovince1</p>
                                  <!-- <br> -->
                                  <p>P: Sioprovince1321</p>
                                  <!-- start accordion -->
                                  <div
                                    class="accordion"
                                    id="accordion"
                                    role="tablist"
                                    aria-multiselectable="true"
                                  >
                                    <div class="panel">
                                      <input
                                        type="checkbox"
                                        name="chk_lotus"
                                        id="chk_lotus"
                                        class="chkUserSelectCls"
                                        data-user="diolotus"
                                        data-pass="Diolotus625"
                                        disabled="true"
                                      />
                                      <a
                                        class="panel-heading collapsed"
                                        role="tab"
                                        id="headingLotus"
                                        data-toggle="collapse"
                                        data-parent="#accordion"
                                        href="#collapseLotus"
                                        aria-expanded="true"
                                        aria-controls="collapseLotus"
                                      >
                                        <h4 class="panel-title">Lotus</h4>
                                      </a>
                                      <div
                                        id="collapseLotus"
                                        class="panel-collapse collapse"
                                        role="tabpanel"
                                        aria-labelledby="headingLotus"
                                      >
                                        <div class="panel-body">
                                          <p>U: diolotus</p>
                                          <!-- <br> -->
                                          <p>P: Diolotus625</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="panel">
                                      <input
                                        type="checkbox"
                                        name="chk_rose"
                                        id="chk_rose"
                                        class="chkUserSelectCls"
                                        data-user="diorose"
                                        data-pass="Diorose820"
                                        disabled="true"
                                      />
                                      <a
                                        class="panel-heading collapsed"
                                        role="tab"
                                        id="headingRose"
                                        data-toggle="collapse"
                                        data-parent="#accordion"
                                        href="#collapseRose"
                                        aria-expanded="false"
                                        aria-controls="collapseRose"
                                      >
                                        <h4 class="panel-title">Rose</h4>
                                      </a>
                                      <div
                                        id="collapseRose"
                                        class="panel-collapse collapse"
                                        role="tabpanel"
                                        aria-labelledby="headingRose"
                                      >
                                        <div class="panel-body">
                                          <p>U: diorose</p>
                                          <!-- <br> -->
                                          <p>P: Diorose820</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="panel">
                                      <input
                                        type="checkbox"
                                        name="chk_lily"
                                        id="chk_lily"
                                        class="chkUserSelectCls"
                                        data-user="diolily"
                                        data-pass="Diolily489"
                                        disabled="true"
                                      />
                                      <a
                                        class="panel-heading collapsed"
                                        role="tab"
                                        id="headingThree"
                                        data-toggle="collapse"
                                        data-parent="#accordion"
                                        href="#collapseThree"
                                        aria-expanded="false"
                                        aria-controls="collapseThree"
                                      >
                                        <h4 class="panel-title">Lily</h4>
                                      </a>
                                      <div
                                        id="collapseThree"
                                        class="panel-collapse collapse"
                                        role="tabpanel"
                                        aria-labelledby="headingThree"
                                      >
                                        <div class="panel-body">
                                          <p>U: diolily</p>
                                          <!-- <br> -->
                                          <p>P: Diolily489</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- end of accordion -->
                                </div>
                              </div>
                            </div>

                            <div class="col-md-2 col-sm-2 col-xs-12">
                              <div class="x_panel yellow-panel">
                                <div class="x_title">
                                  <input
                                    type="checkbox"
                                    name="chk_yellow"
                                    id="chk_yellow"
                                    class="chkUserSelectCls"
                                    data-user="sioprovince2"
                                    data-pass="Sioprovince2527"
                                  />
                                  <h2>
                                    Province 2
                                    <!-- <input type="checkbox" name="chk_prov2" id="chk_prov2" class="chkUserSelectCls" data-user="sioprovince2" data-pass="Sioprovince2527"> -->
                                  </h2>
                                  <!--
                                      <label class="container">Province 2
                                        <input type="checkbox" name="chk_prov2" id="chk_prov2"><span class="checkmark"></span>
                                      </label>
                                  -->
                                  <div class="clearfix"></div>
                                </div>
                                <div class="x_content">
                                  <p>U: sioprovince2</p>
                                  <!-- <br> -->
                                  <p>P: Sioprovince2527</p>
                                  <!-- start accordion -->
                                  <div
                                    class="accordion"
                                    id="accordion"
                                    role="tablist"
                                    aria-multiselectable="true"
                                  >
                                    <div class="panel">
                                      <input
                                        type="checkbox"
                                        name="chk_gold"
                                        id="chk_gold"
                                        class="chkUserSelectCls"
                                        data-user="diogold"
                                        data-pass="Diogold637"
                                      />
                                      <a
                                        class="panel-heading collapsed"
                                        role="tab"
                                        id="headingGold"
                                        data-toggle="collapse"
                                        data-parent="#accordion"
                                        href="#collapseGold"
                                        aria-expanded="true"
                                        aria-controls="collapseGold"
                                        data-user="diogold"
                                        data-pass="Diogold637"
                                      >
                                        <h4 class="panel-title">Gold</h4>
                                      </a>
                                      <!--
                                          <label class="container">Gold
                                                                                                  <input type="checkbox" name="chk_gold" id="chk_gold"><span class="checkmark"></span></label>
                                      -->
                                      <div
                                        id="collapseGold"
                                        class="panel-collapse collapse"
                                        role="tabpanel"
                                        aria-labelledby="headingGold"
                                      >
                                        <div class="panel-body">
                                          <p>U: diogold</p>
                                          <!-- <br> -->
                                          <p>P: Diogold637</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="panel">
                                      <input
                                        type="checkbox"
                                        name="chk_silver"
                                        id="chk_silver"
                                        class="chkUserSelectCls"
                                        data-user="diosilver"
                                        data-pass="Diosilver847"
                                      />
                                      <a
                                        class="panel-heading collapsed"
                                        role="tab"
                                        id="headingSilver"
                                        data-toggle="collapse"
                                        data-parent="#accordion"
                                        href="#collapseSilver"
                                        aria-expanded="false"
                                        aria-controls="collapseSilver"
                                        data-user="diosilver"
                                        data-pass="Diosilver847"
                                      >
                                        <h4 class="panel-title">Silver</h4>
                                      </a>
                                      <!--
                                          <label class="container">Silver
                                            <input type="checkbox" name="chk_silver" id="chk_silver" data-user="diosilver" data-pass="Diosilver847"><span class="checkmark"></span></label>
                                      -->
                                      <div
                                        id="collapseSilver"
                                        class="panel-collapse collapse"
                                        role="tabpanel"
                                        aria-labelledby="headingSilver"
                                      >
                                        <div class="panel-body">
                                          <p>U: diosilver</p>
                                          <!-- <br> -->
                                          <p>P: Diosilver847</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="panel">
                                      <input
                                        type="checkbox"
                                        name="chk_platinum"
                                        id="chk_platinum"
                                        class="chkUserSelectCls"
                                        data-user="dioplatinum"
                                        data-pass="Dioplatinum887"
                                      />
                                      <a
                                        class="panel-heading collapsed"
                                        role="tab"
                                        id="headingPlatinum"
                                        data-toggle="collapse"
                                        data-parent="#accordion"
                                        href="#collapsePlatinum"
                                        aria-expanded="false"
                                        aria-controls="collapsePlatinum"
                                        data-user="dioplatinum"
                                        data-pass="Dioplatinum887"
                                      >
                                        <h4 class="panel-title">Platinum</h4>
                                      </a>
                                      <!--
                                          <label class="container">Platinum
                                                                                                  <input type="checkbox" name="chk_platinum" id="chk_platinum"><span class="checkmark"></span></label>
                                      -->
                                      <div
                                        id="collapsePlatinum"
                                        class="panel-collapse collapse"
                                        role="tabpanel"
                                        aria-labelledby="headingPlatinum"
                                      >
                                        <div class="panel-body">
                                          <p>U: dioplatinum</p>
                                          <!-- <br> -->
                                          <p>P: Dioplatinum887</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- end of accordion -->
                                </div>
                              </div>
                            </div>
                            <div class="col-md-2 col-sm-2 col-xs-12">
                              <div class="x_panel green-panel">
                                <div class="x_title">
                                  <input
                                    type="checkbox"
                                    name="chk_green"
                                    id="chk_green"
                                    class="chkUserSelectCls"
                                    data-user="sioprovince3"
                                    data-pass="Sioprovince3598"
                                    disabled="true"
                                  />
                                  <h2>Province 3</h2>
                                  <div class="clearfix"></div>
                                </div>
                                <div class="x_content">
                                  <p>U: sioprovince3</p>
                                  <!-- <br> -->
                                  <p>P: Sioprovince3598</p>
                                  <!-- start accordion -->
                                  <div
                                    class="accordion"
                                    id="accordion"
                                    role="tablist"
                                    aria-multiselectable="true"
                                  >
                                    <div class="panel">
                                      <input
                                        type="checkbox"
                                        name="chk_cashewnut"
                                        id="chk_cashewnut"
                                        class="chkUserSelectCls"
                                        data-user="diocashewnut"
                                        data-pass="Diocashewnut231"
                                        disabled="true"
                                      />
                                      <a
                                        class="panel-heading collapsed"
                                        role="tab"
                                        id="headingCashewnut"
                                        data-toggle="collapse"
                                        data-parent="#accordion"
                                        href="#collapseCashewnut"
                                        aria-expanded="true"
                                        aria-controls="collapseCashewnut"
                                      >
                                        <h4 class="panel-title">Cashewnut</h4>
                                      </a>
                                      <div
                                        id="collapseCashewnut"
                                        class="panel-collapse collapse"
                                        role="tabpanel"
                                        aria-labelledby="headingCashewnut"
                                      >
                                        <div class="panel-body">
                                          <p>U: diocashewnut</p>
                                          <br />
                                          <p>P: Diocashewnut231</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="panel">
                                      <input
                                        type="checkbox"
                                        name="chk_chestnut"
                                        id="chk_chestnut"
                                        class="chkUserSelectCls"
                                        data-user="diochestnut"
                                        data-pass="Diochestnut629"
                                        disabled="true"
                                      />
                                      <a
                                        class="panel-heading collapsed"
                                        role="tab"
                                        id="headingChestnut"
                                        data-toggle="collapse"
                                        data-parent="#accordion"
                                        href="#collapseChestnut"
                                        aria-expanded="false"
                                        aria-controls="collapseChestnut"
                                      >
                                        <h4 class="panel-title">Chestnut</h4>
                                      </a>
                                      <div
                                        id="collapseChestnut"
                                        class="panel-collapse collapse"
                                        role="tabpanel"
                                        aria-labelledby="headingChestnut"
                                      >
                                        <div class="panel-body">
                                          <p>U: diochestnut</p>
                                          <!-- <br> -->
                                          <p>P: Diochestnut629</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="panel">
                                      <input
                                        type="checkbox"
                                        name="chk_walnut"
                                        id="chk_walnut"
                                        class="chkUserSelectCls"
                                        data-user="diowalnut"
                                        data-pass="Diowalnut735"
                                        disabled="true"
                                      />
                                      <a
                                        class="panel-heading collapsed"
                                        role="tab"
                                        id="headingWalnut"
                                        data-toggle="collapse"
                                        data-parent="#accordion"
                                        href="#collapseWalnut"
                                        aria-expanded="false"
                                        aria-controls="collapseWalnut"
                                      >
                                        <h4 class="panel-title">Walnut</h4>
                                      </a>
                                      <div
                                        id="collapseWalnut"
                                        class="panel-collapse collapse"
                                        role="tabpanel"
                                        aria-labelledby="headingWalnut"
                                      >
                                        <div class="panel-body">
                                          <p>U: diowalnut</p>
                                          <!-- <br> -->
                                          <p>P: Diowalnut735</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- end of accordion -->
                                </div>
                              </div>
                            </div>
                            <div class="col-md-2 col-sm-2 col-xs-12">
                              <div class="x_panel teal-panel">
                                <div class="x_title">
                                  <input
                                    type="checkbox"
                                    name="chk_teal"
                                    id="chk_teal"
                                    class="chkUserSelectCls"
                                    data-user="sioprovince4"
                                    data-pass="Sioprovince4524"
                                    disabled="true"
                                  />
                                  <h2>Province 4</h2>
                                  <div class="clearfix"></div>
                                </div>
                                <div class="x_content">
                                  <p>U: sioprovince4</p>
                                  <!-- <br> -->
                                  <p>P: Sioprovince4524</p>
                                  <!-- start accordion -->
                                  <div
                                    class="accordion"
                                    id="accordion"
                                    role="tablist"
                                    aria-multiselectable="true"
                                  >
                                    <div class="panel">
                                      <input
                                        type="checkbox"
                                        name="chk_pepper"
                                        id="chk_pepper"
                                        class="chkUserSelectCls"
                                        data-user="diopepper"
                                        data-pass="Diopepper534"
                                        disabled="true"
                                      />
                                      <a
                                        class="panel-heading collapsed"
                                        role="tab"
                                        id="headingPepper"
                                        data-toggle="collapse"
                                        data-parent="#accordion"
                                        href="#collapsePepper"
                                        aria-expanded="true"
                                        aria-controls="collapsePepper"
                                      >
                                        <h4 class="panel-title">Pepper</h4>
                                      </a>
                                      <div
                                        id="collapsePepper"
                                        class="panel-collapse collapse"
                                        role="tabpanel"
                                        aria-labelledby="headingPepper"
                                      >
                                        <div class="panel-body">
                                          <p>U: diopepper</p>
                                          <!-- <br> -->
                                          <p>P: Diopepper534</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="panel">
                                      <input
                                        type="checkbox"
                                        name="chk_cardamom"
                                        id="chk_cardamom"
                                        class="chkUserSelectCls"
                                        data-user="diocardammom"
                                        data-pass="Diocardammom1321"
                                        disabled="true"
                                      />
                                      <a
                                        class="panel-heading collapsed"
                                        role="tab"
                                        id="headingCardamom"
                                        data-toggle="collapse"
                                        data-parent="#accordion"
                                        href="#collapseCardamom"
                                        aria-expanded="false"
                                        aria-controls="collapseCardamom"
                                      >
                                        <h4 class="panel-title">Cardamom</h4>
                                      </a>
                                      <div
                                        id="collapseCardamom"
                                        class="panel-collapse collapse"
                                        role="tabpanel"
                                        aria-labelledby="headingCardamom"
                                      >
                                        <div class="panel-body">
                                          <p>U: diocardammom</p>
                                          <!-- <br> -->
                                          <p>P: Diocardammom1321</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="panel">
                                      <input
                                        type="checkbox"
                                        name="chk_cinnamon"
                                        id="chk_cinnamon"
                                        class="chkUserSelectCls"
                                        data-user="diocinnamon"
                                        data-pass="Diocinnamon244"
                                        disabled="true"
                                      />
                                      <a
                                        class="panel-heading collapsed"
                                        role="tab"
                                        id="headingCinnamon"
                                        data-toggle="collapse"
                                        data-parent="#accordion"
                                        href="#collapseCinnamon"
                                        aria-expanded="false"
                                        aria-controls="collapseCinnamon"
                                      >
                                        <h4 class="panel-title">Cinnamon</h4>
                                      </a>
                                      <div
                                        id="collapseCinnamon"
                                        class="panel-collapse collapse"
                                        role="tabpanel"
                                        aria-labelledby="headingCinnamon"
                                      >
                                        <div class="panel-body">
                                          <p>U: diocinnamon</p>
                                          <!-- <br> -->
                                          <p>P: Diocinnamon244</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- end of accordion -->
                                </div>
                              </div>
                            </div>
                            <div class="col-md-2 col-sm-2 col-xs-12">
                              <div class="x_panel blue-panel">
                                <div class="x_title">
                                  <input
                                    type="checkbox"
                                    name="chk_blue"
                                    id="chk_blue"
                                    class="chkUserSelectCls"
                                    data-user="sioprovince5"
                                    data-pass="Sioprovince5870"
                                    disabled="true"
                                  />
                                  <h2>Province 5</h2>
                                  <div class="clearfix"></div>
                                </div>
                                <div class="x_content">
                                  <p>U: sioprovince5</p>
                                  <!-- <br> -->
                                  <p>P: Sioprovince5870</p>
                                  <!-- start accordion -->
                                  <div
                                    class="accordion"
                                    id="accordion"
                                    role="tablist"
                                    aria-multiselectable="true"
                                  >
                                    <div class="panel">
                                      <input
                                        type="checkbox"
                                        name="chk_mercury"
                                        id="chk_mercury"
                                        class="chkUserSelectCls"
                                        data-user="diomercury"
                                        data-pass="Diomercury497"
                                        disabled="true"
                                      />
                                      <a
                                        class="panel-heading collapsed"
                                        role="tab"
                                        id="headingMercury"
                                        data-toggle="collapse"
                                        data-parent="#accordion"
                                        href="#collapseMercury"
                                        aria-expanded="true"
                                        aria-controls="collapseMercury"
                                      >
                                        <h4 class="panel-title">Mercury</h4>
                                      </a>
                                      <div
                                        id="collapseMercury"
                                        class="panel-collapse collapse"
                                        role="tabpanel"
                                        aria-labelledby="headingMercury"
                                      >
                                        <div class="panel-body">
                                          <p>U: diomercury</p>
                                          <!-- <br> -->
                                          <p>P: Diomercury497</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="panel">
                                      <input
                                        type="checkbox"
                                        name="chk_jupiter"
                                        id="chk_jupiter"
                                        class="chkUserSelectCls"
                                        data-user="diojupitor"
                                        data-pass="Diojupitor537"
                                        disabled="true"
                                      />
                                      <a
                                        class="panel-heading collapsed"
                                        role="tab"
                                        id="headingJupiter"
                                        data-toggle="collapse"
                                        data-parent="#accordion"
                                        href="#collapseJupiter"
                                        aria-expanded="false"
                                        aria-controls="collapseJupiter"
                                      >
                                        <h4 class="panel-title">Jupiter</h4>
                                      </a>
                                      <div
                                        id="collapseJupiter"
                                        class="panel-collapse collapse"
                                        role="tabpanel"
                                        aria-labelledby="headingJupiter"
                                      >
                                        <div class="panel-body">
                                          <p>U: diojupitor</p>
                                          <!-- <br> -->
                                          <p>P: Diojupitor537</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="panel">
                                      <input
                                        type="checkbox"
                                        name="chk_venus"
                                        id="chk_venus"
                                        class="chkUserSelectCls"
                                        data-user="diojupitor"
                                        data-pass="Diojupitor537"
                                        disabled="true"
                                      />
                                      <a
                                        class="panel-heading collapsed"
                                        role="tab"
                                        id="headingVenus"
                                        data-toggle="collapse"
                                        data-parent="#accordion"
                                        href="#collapseVenus"
                                        aria-expanded="false"
                                        aria-controls="collapseVenus"
                                      >
                                        <h4 class="panel-title">Venus</h4>
                                      </a>
                                      <div
                                        id="collapseVenus"
                                        class="panel-collapse collapse"
                                        role="tabpanel"
                                        aria-labelledby="headingVenus"
                                      >
                                        <div class="panel-body">
                                          <p>U: diojupitor</p>
                                          <!-- <br> -->
                                          <p>P: Diojupitor537</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- end of accordion -->
                                </div>
                              </div>
                            </div>
                            <div class="col-md-2 col-sm-2 col-xs-12">
                              <div class="x_panel red-panel">
                                <div class="x_title">
                                  <input
                                    type="checkbox"
                                    name="chk_red"
                                    id="chk_red"
                                    class="chkUserSelectCls"
                                    data-user="sioprovince6"
                                    data-pass="Sioprovince6461"
                                    disabled="true"
                                  />
                                  <h2>Province 6</h2>
                                  <div class="clearfix"></div>
                                </div>
                                <div class="x_content">
                                  <p>U: sioprovince6</p>
                                  <!-- <br> -->
                                  <p>P: Sioprovince6461</p>
                                  <!-- start accordion -->
                                  <div
                                    class="accordion"
                                    id="accordion"
                                    role="tablist"
                                    aria-multiselectable="true"
                                  >
                                    <div class="panel">
                                      <input
                                        type="checkbox"
                                        name="chk_linen"
                                        id="chk_linen"
                                        class="chkUserSelectCls"
                                        data-user="dioblue1"
                                        data-pass="Dioblue1436"
                                        disabled="true"
                                      />
                                      <a
                                        class="panel-heading collapsed"
                                        role="tab"
                                        id="headingBlue"
                                        data-toggle="collapse"
                                        data-parent="#accordion"
                                        href="#collapseBlue"
                                        aria-expanded="true"
                                        aria-controls="collapseBlue"
                                      >
                                        <h4 class="panel-title">Linen</h4>
                                      </a>
                                      <div
                                        id="collapseBlue"
                                        class="panel-collapse collapse"
                                        role="tabpanel"
                                        aria-labelledby="headingBlue"
                                      >
                                        <div class="panel-body">
                                          <p>U: dioblue1</p>
                                          <!-- <br> -->
                                          <p>P: Dioblue1436</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="panel">
                                      <input
                                        type="checkbox"
                                        name="chk_silk"
                                        id="chk_silk"
                                        class="chkUserSelectCls"
                                        data-user="diowhite1"
                                        data-pass="Diowhite1387"
                                        disabled="true"
                                      />
                                      <a
                                        class="panel-heading collapsed"
                                        role="tab"
                                        id="headingWhite"
                                        data-toggle="collapse"
                                        data-parent="#accordion"
                                        href="#collapseWhite"
                                        aria-expanded="false"
                                        aria-controls="collapseWhite"
                                      >
                                        <h4 class="panel-title">Silk</h4>
                                      </a>
                                      <div
                                        id="collapseWhite"
                                        class="panel-collapse collapse"
                                        role="tabpanel"
                                        aria-labelledby="headingWhite"
                                      >
                                        <div class="panel-body">
                                          <p>U: diowhite1</p>
                                          <!-- <br> -->
                                          <p>P: Diowhite1387</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="panel">
                                      <input
                                        type="checkbox"
                                        name="chk_velvet"
                                        id="chk_velvet"
                                        class="chkUserSelectCls"
                                        data-user="diogreen1"
                                        data-pass="Diogreen1248"
                                        disabled="true"
                                      />
                                      <a
                                        class="panel-heading collapsed"
                                        role="tab"
                                        id="headingGreen"
                                        data-toggle="collapse"
                                        data-parent="#accordion"
                                        href="#collapseGreen"
                                        aria-expanded="false"
                                        aria-controls="collapseGreen"
                                      >
                                        <h4 class="panel-title">Velvet</h4>
                                      </a>
                                      <div
                                        id="collapseGreen"
                                        class="panel-collapse collapse"
                                        role="tabpanel"
                                        aria-labelledby="headingGreen"
                                      >
                                        <div class="panel-body">
                                          <p>U: diogreen1</p>
                                          <!-- <br> -->
                                          <p>P: Diogreen1248</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- end of accordion -->
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--
                      <div style="display: flex;align-items: center;justify-content: center;">
                          <a href="facilitator.html" class="btn btn-warning" role="button">Upload</a>
                      </div>
                  -->
                </div>
                <div class="col-md-12" style="text-align: center; margin-bottom: 3%;margin-top: 1%; padding-bottom:5%;">
              <a href="#/agenda-doc" class="btn-bottom" style="margin-right: 5%;">
                <i class="fa fa-angle-double-left" aria-hidden="true" style="padding-right: 1%;"></i> Previous
              </a>
              <a
                href="http://avenirtraining.duredemos.com/service/dhis-web-commons/security/login.action"
                class="btn-bottom"
                role="button"
                style="color: #50abc9;"
                target="_blank"
              >
                Next
                <i class="fa fa-angle-double-right" aria-hidden="true" style="padding-left: 1%;"></i>
              </a>
              <!-- <a href="login.html" class="btn btn-cust1 btn-md btn3d" role="button">Skip to Login</a> -->
            </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      <!-- /page content -->
      <!-- footer content -->
      <training-footer></training-footer>
      <!-- /footer content -->
    </div>
  </div>
</template>
<script>
import trainingHeader from '../components/trainingHeader';
import trainingFooter from '../components/trainingFooter';
export default {
    components: {
        trainingHeader, 
        trainingFooter
    },
}
</script>
<style scoped>
    .x_panel {
        background-color: #fff !important;
        
    }
    input[type=checkbox], input[type=radio]{
        display: none !important;
    }
    .x_title {
        border-bottom: 0px solid #E6E9ED;
        padding: 15px 18px;
        background-color: #b0b0b0;
        color: #fff;
        border-radius: 7px 7px 0 0;
        position: relative;
    }
    .textstart .x_panel {
    border: 1px solid #b0b0b0;
    padding: 0px;
    border-radius: 10px;
}
.textstart .x_title h2{
  text-align: center !important;
  float:none;
}
    .textstart .x_content{
      padding-left: 8%;
      padding-right: 8%;
    }
    .textstart .x_content p{
      margin-bottom: 5px;
    }
    .x_content h4{
      margin-bottom: 0px;
    }
</style>