<template>
  <div id="footer">
    <footer>
      <div class="row align-items-center">
        <div class="col-md-6">
          <div class="social-icon">
            <ul>
              <li>
                <a :href="appConfig.socialtwitter" target="_blank"
                  ><span class="fa fa-twitter-square social-icon-fa text-white"></span
                ></a>
              </li>
              <!-- <li><a :href="appConfig.socialfacebook"><span class="fa fa-facebook-square social-icon-fa"></span></a></li> -->
              <li>
                <a :href="appConfig.socialyoutube"
                  ><span class="fa fa-youtube-play social-icon-fa text-white"></span
                ></a>
              </li>
              <li>
                <a :href="appConfig.socialinsta"
                  ><span class="fa fa-instagram social-icon-fa text-white"></span
                ></a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-6">
          <div class="c_div">
            <span class="">Powered by </span>

            <img
              src="../assets/img/fplims/dure_logo_lite1.png"
              class="img-responsive-sm c_logo"
              alt="Dure Technology"
            />
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  props: ["appConfig"],
  data() {
    return {
      settings: settings,
    };
  },
};
</script>
