<template>
  <div class="body">
    <div class="main_container">
      <training-header :appConfig="appConfig"></training-header>

      <!-- page content -->
      <div
      class="right_col resources_block_page"
      role="main"
      style="margin-left: 0;padding:0px 20px;"
      >
      <br />

      <div class="row px-4 pt-4">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <div class="x_panel step_panel" style="background: #393939 !important;border-radius: 10px 10px 0 0;">
            <div class="x_content" style="padding: 20px 25px;">
              <div class="process_steps">
                <ul id="progress">
                  <li class="active"><span>Download</span></li>
                  <li><span>Customize</span></li>
                  <li><span>Complete</span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row px-4">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <div class="x_panel resourceSessionPage">
            <div class="d-flex">
              <div class="">
                <div class="nav-side-menu">
                  <div class="brand"></div>
                  <i
                  class="fa fa-bars fa-2x toggle-btn"
                  data-toggle="collapse"
                  data-target="#menu-content"
                  ></i>

                  <div class="menu-list">
                    <ul id="menu-content" class="menu-content collapse out"  v-for="(item, index) in facilitatorObj.sessions">
                      <li
                      data-toggle="collapse"
                      :data-target="`#red${item.sessionId}`"
                      class="collapsed active"

                      >
                      <a href="#"> {{item.session}}<span class="arrow"></span></a>
                    </li>
                    <ul class="sub-menu collapse show" :id="`red${item.sessionId}`"  >
                      <template v-for="(item1, index1) in item.category">
                        <li  class=""><a :href="`#category${item1.categoryId}`" @click="showHideDive(index,index1,item1.categoryId)" >{{item1.category}}</a></li>
                      </template>
                    </ul>


                  </ul>
                </div>
              </div>
            </div>
            <!-- @click="showHideDive(index,index1,item1.categoryId)" -->
      <!-- <div class="" :key="index"  v-for="(item, index) in facilitatorObj.sessions"> -->
        <!-- <div class="resourcesDivWrap targetRes" :key="index1"  v-for="(item1, index1) in item.category"> -->
        <div class="targetRes">
        <template  v-for="(item, index) in facilitatorObj.sessions">
          <template   v-for="(item1, index1) in item.category">
          <div class="resourcesDivWrap" :class="{ 'active': index == 0 && index1 ==0 && showFirstDive }"  :id="`category${item1.categoryId}`" >
            <template  v-for="(item2, index2) in item1.resourse">
              <div class="col-sm-4"  >
                <div
                class="panel panel-default panel-card"
                style=""
                >
                <div
                class="panel-heading"
                style=""
                >
                <span>{{ item2.header}}</span>
              </div>
              <div class="panel-body" style="">
                <div class="panel-img">
                  <img
                  :src="item2.photoBlob"
                  class="img-responsive"
                  />
                </div>
                <div class="resourceBriefSect" v-html="item2.content"></div>
              </div>
              <div
              class="panel-footer"
              style=""
              >
              <div class="row">
                <a href="#" class="col-6" v-b-tooltip.hover title="View" style="border-right:1px solid #fff;"><i class="fa fa-eye text-white" aria-hidden="true"></i></a>
                <a :href="item2.downloadLink" class="col-6" v-b-tooltip.hover title="Download"><i class="fa fa-download text-white" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </template>
</template>
</div>
  <!-- </div>
</div> -->
</div>

</div>
<div class="x_content">
  <div style="text-align: center;padding-bottom:1%; padding-top:1%;">
    <router-link
    :to="{
      name: 'facilitator-menu',
      params: {
        facilitatorObj: facilitatorObj,
        appConfig: appConfig
      }
      }"
      class="btn-bottom"
      style="margin-right: 5%;"
      >
      <i
      class="fa fa-angle-double-left"
      aria-hidden="true"
      style="padding-right: 1%;"
      ></i>
      Previous
    </router-link>

    <router-link
    :to="{name: 'facilitator-course',params: {facilitatorObj: facilitatorObj,appConfig: appConfig}}"
      class="btn-bottom"
      style="color: #813582;">Next
      <i
      class="fa fa-angle-double-right"
      aria-hidden="true"
      style="padding-left: 1%;"
      ></i>
    </router-link>
    <!-- <a href="facilitator_menu.html" class="btn btn-danger btn-md btn3d" role="button">Next</a>
    <a href="login.html" class="btn btn-cust1 btn-md btn3d" role="button">Skip to Login</a>-->
  </div>
</div>
</div>
</div>
</div>
<!-- /page content -->

<!-- footer content -->
<training-footer :appConfig="appConfig"></training-footer>
<training-faq :facilitatorObj="facilitatorObj"></training-faq>
<!-- /footer content -->
</div>
</div>
</template>
<script>
import trainingHeader from "../components/trainingHeader";
import trainingFooter from "../components/trainingFooter";
import trainingFaq from './trainingFaq';
import ApiServices from "../service/ApiServices";
export default {
  components: {
    trainingHeader,
    trainingFooter,
    trainingFaq
  },
  data() {
    return {
      planningData: null,
      facilitatorObj: {},
      appConfig: {},
      showFirstDive:true
    };
  },
  methods: {
    showHideDive(seind,catind,catId){
      if(seind == 0 && catind == 0 )
      {
        this.showFirstDive = true
      }
      else {
        this.showFirstDive = false
      }
    },
    stop(){
      event.preventDefault();
    },
    getPlanningData() {
      this.$root.loading = true;
      ApiServices.RestPostCall("api/avenir/training/getPlanningData", {}).then(
        res => {
          console.log(res);
          this.planningData = res.masterAgenda;
          this.$root.loading = false;
        }
      );
    }
  },
  mounted() {
    //this.getPlanningData()
    //console.log(this.$refs['clickRes']);
    //this.$refs['my-modal']
    //this.$refs.clickRes.click();
    if(this.$route.params.facilitatorObj){
      this.facilitatorObj = this.$route.params.facilitatorObj
    }
    else{
      this.facilitatorObj =JSON.parse(localStorage.getItem("facilitatorObj"));
    }
    if(this.$route.params.appConfig){
      this.appConfig = this.$route.params.appConfig
    }else{
      this.appConfig =JSON.parse(localStorage.getItem("appConfig"));
    }
  }
};
</script>
<style scoped>
.x_panel {
  background-color: #fff !important;
  border: 0px solid transparent;
  padding: 0 !important;
  border-radius: 10px;
}
.x_title {
  border-bottom: 0px solid #e6e9ed;
  padding: 0 !important;
  background-color: #b0b0b0;
  color: #fff;
  border-radius: 10px 10px 0 0;
}
.x_content {
  padding: 0px 25px 6px;
}
.panel-img img {
  width: 100px;
  height: 100px;
  margin: 10px auto;
}
.dashboard-widget-content {
  height: 350px;
  overflow: hidden;
  overflow-y: scroll;
}
.right_col {
  padding-bottom: 80px !important;
}

/* side nav */

.nav-side-menu {
  overflow: auto;
  font-family: verdana;
  font-size: 12px;
  font-weight: 200;
  background-color: #717171;
  position: relative;
  top: 0px;
  width: 300px;
  height: calc(100vh - 245px);
  overflow: hidden;
  overflow-y: scroll;
  color: #ffff;
  padding-bottom: 5%;
  border-radius: 0px 0 0 10px;
  /* left: -5px; */
}
.nav-side-menu .brand {
  background-color: #23282e;
  line-height: 50px;
  display: block;
  text-align: center;
  font-size: 14px;
}
.nav-side-menu .toggle-btn {
  display: none;
}
.nav-side-menu ul,
.nav-side-menu li {
  list-style: none;
  padding: 0px;
  margin: 0px;
  line-height: 35px;
  cursor: pointer;

}
.nav-side-menu ul :not(collapsed) .arrow:before,
.nav-side-menu li :not(collapsed) .arrow:before {
  font-family: FontAwesome;
  content: "\f078";
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: middle;
  float: right;
}
.nav-side-menu ul .active,
.nav-side-menu li .active {
  border-left: 0px solid #d19b3d;
  background-color:#bebebe;
}
.nav-side-menu ul .sub-menu li.active,
.nav-side-menu li .sub-menu li.active {
  color:#fff;
}

.nav-side-menu ul .sub-menu li.active a,
.nav-side-menu li .sub-menu li.active a {
  color:#fff;
}
.nav-side-menu ul .sub-menu li,
.nav-side-menu li .sub-menu li {
  background-color: #ae98ad;
  border: none;
  line-height: 28px;
  border-bottom: 1px solid #ae98ad;
  margin-left: 0px;
}
.nav-side-menu ul .sub-menu li:hover,
.nav-side-menu li .sub-menu li:hover {
  background-color:#ae98ad;
}
/* .nav-side-menu ul .sub-menu li:before,
.nav-side-menu li .sub-menu li:before {
font-family: Fontawesome;
content: "\f105";
display: inline-block;
padding-left: 20px;
padding-right: 10px;
vertical-align: middle;
} */
.nav-side-menu li {
  padding-left: 0px;
  border-left: 0px solid #2e353d;
  /* border-bottom: 1px solid #c9c9c9; */
  padding: 10px 0 10px 0;
}
.nav-side-menu li a {
  text-decoration: none;
  color: #fff;
  font-weight: 500;
  padding-left: 5%;
  font-size: 1rem;
}
.nav-side-menu li a i {
  padding-left: 10px;
  width: 20px;
  padding-right: 20px;
}
.nav-side-menu li:hover {
  /* border-left: 3px solid #d19b3d; */
  background-color:#bebebe;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}
@media (max-width: 767px) {
  .nav-side-menu {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
  }
  .nav-side-menu .toggle-btn {
    display: block;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 10 !important;
    padding: 3px;
    background-color: #ffffff;
    color: #000;
    width: 40px;
    text-align: center;
  }
  .brand {
    text-align: left !important;
    font-size: 22px;
    padding-left: 20px;
    line-height: 50px !important;
  }
}
@media (min-width: 767px) {
  .nav-side-menu .menu-list .menu-content {
    display: block;
  }
}
/* body {
margin: 0px;
padding: 0px;
} */

.nav-side-menu ul .sub-menu ul .sub-line li.active,
.nav-side-menu li .sub-menu li .sub-line li.active {
  color: #d19b3d;
}

.nav-side-menu ul .sub-menu li .sub-line li.active a,
.nav-side-menu li .sub-menu li .sub-line li.active a {
  color: #d19b3d;
}
.nav-side-menu ul .sub-menu li .sub-line li,
.nav-side-menu li .sub-menu li .sub-line li {
  background-color: #181c20;
  border: none;
  line-height: 28px;
  border-bottom: 1px solid #23282e;
  margin-left: 0px;
}
.nav-side-menu ul .sub-menu li .sub-line li:hover,
.nav-side-menu li .sub-menu li .sub-line li:hover {
  background-color: #020203;
}
.nav-side-menu ul .sub-menu li .sub-line li:before,
.nav-side-menu li .sub-menu li .sub-line li:before {
  font-family: FontAwesome;
  content: "\f105";
  display: inline-block;
  padding-left: 100px;
  padding-right: 10px;
  vertical-align: middle;
}

.nav-side-menu .sub-menu li {
  padding-left: 20px;
  border-left: 3px solid #2e353d;
  border-bottom: 1px solid #23282e;
}
.nav-side-menu .sub-menu li a {
  text-decoration: none;
  color: #fff;
  font-size: .9rem;
}
.sub-menu li a i {
  padding-left: 10px;
  width: 20px;
  padding-right: 20px;
}
.nav-side-menu li .sub-menu li:hover {
  border-left: 3px solid #d19b3d;
  background-color: #4f5b69;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}
@media (max-width: 767px) {
  .nav-side-menu .sub-menu {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
  }
  .nav-side-menu .sub-menu .toggle-btn {
    display: block;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 10 !important;
    padding: 3px;
    background-color: #ffffff;
    color: #000;
    width: 40px;
    text-align: center;
  }

  .sub-line ul .sub-press li.active,
  .sub-line li .sub-press li.active {
    color: #d19b3d;
  }

  .sub-line ul .sub-press li.active a,
  .sub-line li .sub-press li.active a {
    color: #d19b3d;
  }
  .sub-line ul .sub-press li,
  .sub-line li .sub-press li {
    background-color: #181c20;
    border: none;
    line-height: 28px;
    border-bottom: 1px solid #23282e;
    margin-left: 0px;
  }
  .sub-line ul .sub-press li:hover,
  .sub-line li .sub-press li:hover {
    background-color: #020203;
  }
  .sub-line ul .sub-press li:before,
  .sub-line li .sub-press li:before {
    font-family: Arial;
    content: "\f105";
    display: inline-block;
    padding-left: 50px;
    padding-right: 10px;
    vertical-align: middle;
  }

  .sub-line li {
    padding-left: 20px;
    border-left: 3px solid #2e353d;
    border-bottom: 1px solid #23282e;
  }
  .sub-line li a {
    text-decoration: none;
    color: #e1ffff;
  }
  .sub-line li a i {
    padding-left: 50px;
    width: 20px;
    padding-right: 20px;
  }
  .sub-line li:hover {
    border-left: 3px solid #d19b3d;
    background-color: #4f5b69;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
  }
}
@media (max-width: 767px) {
  .sub-line {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
  }
  .sub-line .toggle-btn {
    display: block;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 10 !important;
    padding: 3px;
    background-color: #ffffff;
    color: #000;
    width: 40px;
    text-align: center;
  }
}
.resourcesDivWrap{
  height: calc(100vh - 245px);
  overflow: hidden;
  overflow-y: scroll;
  padding: 12px 0;
  margin-left: 1%;
  margin-left: 2%;
  margin-right: 2%;
}
.nav-side-menu::-webkit-scrollbar {
  width: .1em;
}

.nav-side-menu::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #fff;
  border-radius: 10px;
}

.nav-side-menu::-webkit-scrollbar-thumb {
  background-color: #f4f4f4;
  outline: 1px solid #f4f4f4;
}
.resourcesDivWrap .panel-card .panel-heading {
  background: linear-gradient(to right, #76156b, #eb5ddb);
  border-radius: 10px 10px 0 0;
      display: flex;
    justify-content: center;
    align-items: center;
}
.resourcesDivWrap .panel-card{
  background-color: #daced8;

}
.resourcesDivWrap .panel-footer{
  background: linear-gradient(to right, rgb(118, 21, 107), rgb(235, 93, 219));
  opacity: .9;
}
.resourceSessionPage{
  margin-top: -15px;
  border-radius: 0 0 10px 10px;
}
.sub-menu {
  /* height: calc(100vh - 777px); */
  height: 100%;
  max-height: 148px;
  overflow: hidden;
  overflow-y: scroll;
}
.resourcesDivWrap .panel-body .resourceBriefSect{
  width: auto;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.resourcesDivWrap .panel-heading span{
  width: auto;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.nav-side-menu .sub-menu::-webkit-scrollbar {
  width: .5em;
}

.nav-side-menu .sub-menu::-webkit-scrollbar-track {
  box-shadow: inset 0 3px 11px #ae98ad;;
  border-radius: 10px;
}
.nav-side-menu .sub-menu::-webkit-scrollbar-thumb {
  background-color: #837382;
  /* outline: 1px solid #fff; */
  border-radius: 10px;
}
/* .targetRes > div:first-child{
  display:block
} */
.targetRes > div {
  display:none;
}
.targetRes .active{
  display:block;
}

.targetRes > div:target{
  display:block;
}

</style>
