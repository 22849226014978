import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import $ from 'jquery'
require("@/assets/css/custom.scss");
require("@/assets/css/custom.min.css");
require("@/assets/css/mystyle.css");

// bootstrap
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue);
Vue.use(IconsPlugin)
//Sweet alert
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);
// Eagle Modal to show error details in table format on excel upload error alert
/*import {EagleModal} from 'vue-eagle-modal'
Vue.use(EagleModal);*/

import VModal from 'vue-js-modal'
//Vue.use(VModal)
Vue.use(VModal, { dynamic: true, dynamicDefaults: { clickToClose: false } })

//form validations start
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: {
    id: 'G-6N9CGC45NX'
  },
  appName: 'FPLMIS',
}, router);


//vue form wizard component
import VueFormWizard from 'vue-form-wizard'
Vue.use(VueFormWizard)

//own components
import loader from "./components/layout/Loader";
Vue.component("loader", loader);
import Header from "./components/layout/Header";
Vue.component("Header", Header);

import HeaderNew from "./components/layout/HeaderNew";

Vue.component("HeaderNew", HeaderNew);
import VueTables from "vue-tables-2";

Vue.use(VueTables.ClientTable);
// import DisableAutocomplete from 'vue-disable-autocomplete';
// Vue.use(DisableAutocomplete);
Vue.config.productionTip = false;

new Vue({
  data: { loading: false },
  router,
  store,
  render: h => h(App)
}).$mount("#app");

router.beforeEach((to, from, next) => {
  //console.log(to)
  if(to.name == 'wizard'){
    if(localStorage.getItem("userProfile")){
      next() 
    }else{
      next('/')
    }
  }else{
    next();
  }
})

router.afterEach((to, from, next) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || 'FP-LMIS E-Learning';
  });
})
