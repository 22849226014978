<template>
  <div v-bind:class="getClass()">
    <HeaderNew />
    <div class="mdgridviewpage-bg">

      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12"></div>
        </div>
      </div>
      <div class="container-fluid m-t-10px">
        <div class="row dashboardchart-container globalconfigcontainer">
          <div class="col-lg-8" style="margin: 0 auto"></div>
        </div>
      </div>
      <ValidationObserver ref="form">
        <form-wizard
          shape="tab"
          class="sandbox_setup"
          @on-complete="onComplete"
        >
          <tab-content
            title="Application Setup"
            icon="1"
            :before-change="validateFirstTab"
            v-on:click="trackEvent('Application Setup')"
          >
            <div class="card m-b-20px">
              <div class="card-header text-decoration-none p-l-16px p-12px">
                Training Module
              </div>
              <div class="collapse show">
                <div class="card-body p-40px">
                  <div class="row">
                    <div class="col-lg-6 mb-1">
                      <div class="form-group row">
                        <label
                          for="inputDashboardName"
                          class="col-sm-5 col-form-label"
                          >Application Name</label
                        >
                        <div class="col-sm-7">
                          <ValidationProvider
                            name="Application Name"
                            rules="required|min:3"
                            v-slot="{ errors }"
                          >
                            <input
                              type="text"
                              v-model="frmModel.appName"
                              class="form-control"
                              id="inputDashboardName"
                              placeholder=""
                            />
                            <span class="text-danger">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 mb-1">
                      <div class="form-group row">
                        <label class="col-sm-5 col-form-label"
                          >Description</label
                        >
                        <div class="col-sm-7">
                          <ValidationProvider
                            name="Application Description"
                            rules="required|min:3"
                            v-slot="{ errors }"
                          >
                            <input
                              type="text"
                              v-model="frmModel.description"
                              class="form-control"
                              id="inputDashboardDescription"
                              placeholder=""
                            />
                            <span class="text-danger">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 hide mb-1">
                      <div class="form-group row">

                        <label class="col-sm-5 col-form-label">Logo</label>
                        <div class="col-sm-2 pr-0">
                          <div class="dropdown logoDropdown">
                            <button
                              class="btn dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img
                                src="../assets/img/logo/kenya.jpg"
                                class="w-56px pl-0"
                              />
                            </button>
                            <div
                              class="dropdown-menu logoDropdownMenu"
                              aria-labelledby="dropdownMenuButton"
                            >

                              <div class="row">
                                <div class="col-lg-4">
                                  <a class="dropdown-item" href="#">
                                    <img
                                      src="../assets/img/logo/DR of congo.jpg"
                                    />
                                  </a>
                                </div>
                                <div class="col-lg-4">
                                  <a class="dropdown-item" href="#">
                                    <img src="../assets/img/logo/ghana.jpg" />
                                  </a>
                                </div>
                                <div class="col-lg-4">
                                  <a class="dropdown-item" href="#">
                                    <img src="../assets/img/logo/ind.jpg" />
                                  </a>
                                </div>
                                <div class="col-lg-4">
                                  <a class="dropdown-item" href="#">
                                    <img src="../assets/img/logo/kenya.jpg" />
                                  </a>
                                </div>
                                <div class="col-lg-4">
                                  <a class="dropdown-item" href="#">
                                    <img src="../assets/img/logo/laos.jpg" />
                                  </a>
                                </div>
                                <div class="col-lg-4">
                                  <a class="dropdown-item" href="#">
                                    <img src="../assets/img/logo/MOH ind.jpg" />
                                  </a>
                                </div>
                                <div class="col-lg-4">
                                  <a class="dropdown-item" href="#">
                                    <img
                                      src="../assets/img/logo/MOH kenya.jpg"
                                    />
                                  </a>
                                </div>
                                <div class="col-lg-4">
                                  <a class="dropdown-item" href="#">
                                    <img src="../assets/img/logo/morocco.jpg" />
                                  </a>
                                </div>
                                <div class="col-lg-4">
                                  <a class="dropdown-item" href="#">
                                    <img src="../assets/img/logo/Nigeria.jpg" />
                                  </a>
                                </div>
                                <div class="col-lg-4">
                                  <a class="dropdown-item" href="#">
                                    <img src="../assets/img/logo/SA.jpg" />
                                  </a>
                                </div>
                                <div class="col-lg-4">
                                  <a class="dropdown-item" href="#">
                                    <img src="../assets/img/logo/tanza.jpg" />
                                  </a>
                                </div>
                                <div class="col-lg-4">
                                  <a class="dropdown-item" href="#">
                                    <img src="../assets/img/logo/zimb.jpg" />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-5 p-t-4px">
                          <input
                            type="file"
                            class="form-control-file"
                            id="exampleFormControlFile5"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 mb-1">
                      <div class="form-group row">
                        <label class="col-sm-5 col-form-label"
                          >Default Language</label
                        >
                        <div class="col-sm-7">
                          <ValidationProvider
                            name="Default Language"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <div class="select-wrapper">
                              <select
                                class="form-control"
                                v-model="frmModel.language"
                              >
                                <option>English</option>
                                <option>French</option>
                                <option>Espanol</option>
                              </select>
                            </div>
                            <span class="text-danger">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 mb-1">
                      <div class="form-group row">
                        <label class="col-sm-5 col-form-label"
                          >Do you want to associate training package to
                          exisiting project
                        </label>
                        <div class="col-sm-7">
                          <div class="select-wrapper">
                            <b-form-select
                              v-model="frmModel.associateTraining"
                              :options="associateTrainingOptions"
                            ></b-form-select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card m-t-40px m-b-20px">
              <div class="card-header text-decoration-none p-12px p-l-16px">
                Theme and Layout Selection
              </div>
              <div class="collapse show">
                <div class="card-body">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-lg-12 border-b-2px-grey">
                        <div class="form-group mb-0 row">
                          <label
                            for="colorSelect"
                            class="col-sm-2 col-form-label vert-center text-center"
                            >Select Color</label
                          >
                          <div class="col-sm-10">
                            <div class="row">
                              <div class="col-lg-3 text-center">
                                <div class="row">
                                  <div class="col-sm-3 vert-center">
                                    <div class="m-t-20px">
                                      <div>
                                        <div class="pure-checkbox">
                                          <input
                                            id="type4"
                                            type="radio"
                                            name="themeColor"
                                            value="#ededed"
                                            v-model="configObj.color"
                                          />
                                          <label for="type4"></label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-sm-9">
                                    <div class="config-icon-container">
                                      <img
                                        src="../assets/img/layouts/purple.png"
                                        class="img img-fluid"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-3 text-center">
                                <div class="row">
                                  <div class="col-sm-3 vert-center">
                                    <div class="m-t-20px">
                                      <div>
                                        <div class="pure-checkbox">
                                          <input
                                            type="radio"
                                            name="themeColor"
                                            id="type1"
                                            value="#212832"
                                            v-model="configObj.color"
                                          />
                                          <label for="type1"></label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-sm-9">
                                    <div class="config-icon-container">
                                      <img
                                        src="../assets/img/layouts/black.png"
                                        class="img img-fluid"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-3 text-center">
                                <div class="row">
                                  <div class="col-sm-3 vert-center">
                                    <div class="m-t-20px">
                                      <div>
                                        <div class="pure-checkbox">
                                          <input
                                            id="type2"
                                            type="radio"
                                            name="themeColor"
                                            value="#66e093"
                                            v-model="configObj.color"
                                          />
                                          <label for="type2"></label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-sm-9">
                                    <div class="config-icon-container">
                                      <img
                                        src="../assets/img/layouts/blue.png"
                                        class="img img-fluid"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-3 text-center">
                                <div class="row">
                                  <div class="col-sm-3 vert-center">
                                    <div class="m-t-20px">
                                      <div>
                                        <div class="pure-checkbox">
                                          <input
                                            id="type3"
                                            type="radio"
                                            name="themeColor"
                                            value="#25984b"
                                            v-model="configObj.color"
                                          />
                                          <label for="type3"></label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-sm-9">
                                    <div class="config-icon-container">
                                      <img
                                        src="../assets/img/layouts/green.png"
                                        class="img img-fluid"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="form-group row">
                          <label class="col-lg-2 col-form-label vert-center"
                            >Select Layout</label
                          >
                          <div class="col-sm-10">
                            <div class="row">
                              <div class="col-lg-3 text-center">
                                <div class="row">
                                  <div class="col-sm-3 vert-center">
                                    <div class="form-group m-t-20px">
                                      <div class="form-check">
                                        <div class="pure-checkbox">
                                          <input
                                            type="radio"
                                            id="layout1"
                                            value="1"
                                            checked
                                            v-model="configObj.layout"
                                          />
                                          <label for="layout1"></label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-sm-9">
                                    <div class="config-icon-container">
                                      <img
                                        src="../assets/img/layouts/1.jpg"
                                        class="img img-fluid"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-3 text-center">
                                <div class="row">
                                  <div class="col-sm-3 vert-center">
                                    <div class="form-group m-t-20px">
                                      <div class="form-check">
                                        <div class="pure-checkbox">
                                          <input
                                            type="radio"
                                            id="layout2"
                                            value="2"
                                            v-model="frmModel.layout"
                                            disabled
                                          />
                                          <label for="layout2"></label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-sm-9">
                                    <div class="config-icon-container">
                                      <img
                                        src="../assets/img/layouts/2.jpg"
                                        class="img img-fluid"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-3 text-center">
                                <div class="row">
                                  <div class="col-sm-3 vert-center">
                                    <div class="form-group m-t-20px">
                                      <div class="form-check">
                                        <div class="pure-checkbox">
                                          <input
                                            type="radio"
                                            id="layout3"
                                            value="3"
                                            v-model="frmModel.layout"
                                            disabled
                                          />
                                          <label for="layout3"></label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-sm-9">
                                    <div class="config-icon-container">
                                      <img
                                        src="../assets/img/layouts/3.jpg"
                                        class="img img-fluid"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-3 text-center">
                                <div class="row">
                                  <div class="col-sm-3 vert-center">
                                    <div class="form-group m-t-20px">
                                      <div class="form-check">
                                        <div class="pure-checkbox">
                                          <input
                                            type="radio"
                                            id="layout4"
                                            value="4"
                                            v-model="frmModel.layout"
                                            disabled
                                          />
                                          <label for="layout4"></label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-sm-9">
                                    <div class="config-icon-container">
                                      <img
                                        src="../assets/img/layouts/4.jpg"
                                        class="img img-fluid"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="container-fluid m-t-20px px-2 d-none">
              <div class="row dashboardchart-container globalconfigcontainer">
                <div
                  class="col-lg-12 p-0"
                  style="
                    padding-left: 0px !important;
                    padding-right: 0px !important;
                  "
                >
                  <div
                    class="card m-t-0px m-b-20px"
                    style="background: transparent"
                  >
                    <div
                      class="card-header p-5px border-b-l-radius-10px border-b-r-radius-10px"
                      id="headingOneAdditionalSettings"
                    >
                      <h2 class="mb-0 mt-0">
                        <button
                          class="btn btn-link w-100 text-left color-white"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOneAdditionalSettings"
                          aria-expanded="false"
                          aria-controls="collapseOneAdditionalSettings"
                        >
                          Additional Settings
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseOneAdditionalSettings"
                      class="collapse"
                      aria-labelledby="headingOneAdditionalSettings"
                    >
                      <div class="card-body p-0 pt-2">
                        <div class="card m-t-0px m-b-20px">
                          <div
                            class="card-header p-5px text-decoration-none p-l-16px p-12px"
                            id="headingTwo"
                          >
                            Content Management
                          </div>
                          <div
                            id="collapseTwo"
                            class="collapse show"
                            aria-labelledby="headingTwo"
                          >
                            <div
                              class="card-body p-0 m-b-0px"
                              style="background-color: transparent !important"
                            >
                              <div
                                class="d-flex"
                                :style="{
                                  backgroundImage: `url(${configObj.landingBgImg})`,
                                  backgroundColor: configObj.landingBgImg
                                    ? ''
                                    : '#7e7877',
                                }"
                                id="wrapper"
                              >
                                <div class="overlay"></div>

                                <div
                                  class="bg-transparent border-right d-none"
                                  id="sidebar-wrapper"
                                >
                                  <div class="sidebar-heading p-0px">
                                    <a
                                      href="#"
                                      data-toggle="modal"
                                      data-target="#uploadAppLogoModal"
                                      class="edit-links"
                                      style="margin-left: 20px; top: 10px"
                                    >
                                      <i
                                        class="fa fa-pencil"
                                        aria-hidden="true"
                                      ></i>
                                      Change Logo
                                    </a>
                                    <p
                                      class="text-center m-0px"
                                      style="margin-top: -28px !important"
                                    >
                                      <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#uploadAppLogoModal"
                                      >
                                        <img
                                          v-if="frmModel.applogo"
                                          :src="frmModel.applogo"
                                          class="img img-fluid brand-logo"
                                        />
                                        <img
                                          v-else
                                          src="../assets/img/home/avenir_health.png"
                                          class="img img-fluid brand-logo"
                                        />
                                      </a>
                                    </p>
                                    <div
                                      class="modal fade"
                                      id="uploadAppLogoModal"
                                      tabindex="-1"
                                      role="dialog"
                                      aria-labelledby="uploadAppLogoModalCenterTitle"
                                      aria-hidden="true"
                                    >
                                      <div
                                        class="modal-dialog modal-dialog-centered"
                                        role="document"
                                      >
                                        <div class="modal-content">
                                          <div
                                            class="modal-header p-10px border-bottom-0 p-l-35px"
                                          >
                                            <h6
                                              class="modal-title color-white"
                                              id="uploadAppLogoModalCenterTitle"
                                            >
                                              Upload Application Logo
                                            </h6>
                                            <button
                                              type="button"
                                              class="close color-white"
                                              data-dismiss="modal"
                                              aria-label="Close"
                                            >
                                              <span
                                                aria-hidden="true"
                                                class="color-white"
                                                >&times;</span
                                              >
                                            </button>
                                          </div>
                                          <div
                                            class="modal-body p-t-5px p-b-0px"
                                          >
                                            <div class="card bg-transparent">
                                              <div
                                                class="card-body bg-transparent p-t-0px p-b-10px"
                                              >
                                                <div class="row">
                                                  <div class="col-lg-12">
                                                    <div class="card">
                                                      <div class="card-body">
                                                        <form>
                                                          <div
                                                            class="form-group row"
                                                          >
                                                            <label
                                                              for="inputPassword"
                                                              class="col-sm-4 col-form-label"
                                                              >Select
                                                              Logo</label
                                                            >
                                                            <div
                                                              class="col-sm-8"
                                                            >
                                                              <input
                                                                type="file"
                                                                class="form-control-file p-t-4px"
                                                                style="
                                                                  font-size: 15px;
                                                                "
                                                                name="applogo"
                                                                accept="image/*"
                                                                @change="
                                                                  onImgChange
                                                                "
                                                              />
                                                              <div
                                                                class="img-preview"
                                                              >
                                                                <img
                                                                  v-if="
                                                                    frmModel.applogo
                                                                  "
                                                                  :src="
                                                                    frmModel.applogo
                                                                  "
                                                                />
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </form>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            class="modal-footer p-10px border-top-0 p-r-35px"
                                          >
                                            <button
                                              type="button"
                                              class="btn btn-secondary bg-dark-grey color-black"
                                              data-dismiss="modal"
                                            >
                                              Save and Continue
                                            </button>

                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row leadershipmsg-container">
                                    <div class="col-lg-12 p-0">
                                      <p
                                        class="color-white text-uppercase m-0px"
                                      >
                                        <span class="border-bottom-white"
                                          >Leadership</span
                                        >
                                      </p>
                                    </div>
                                    <div class="col-lg-12 p-0">
                                      <div class="media m-t-10px">
                                        <a
                                          href="#"
                                          data-toggle="modal"
                                          data-target="#uploadLeadershipLogoModal"
                                        >
                                          <img
                                            v-if="frmModel.leadpic"
                                            :src="frmModel.leadpic"
                                            class="img img-fluid brand-logo"
                                          />
                                          <img
                                            v-else
                                            src="../assets/img/home/user.jpg"
                                            class="mr-3 leaderimg"
                                          />
                                        </a>
                                        <div
                                          class="modal fade"
                                          id="uploadLeadershipLogoModal"
                                          tabindex="-1"
                                          role="dialog"
                                          aria-labelledby="uploadLeadershipLogoModalCenterTitle"
                                          aria-hidden="true"
                                        >
                                          <div
                                            class="modal-dialog modal-dialog-centered"
                                            role="document"
                                          >
                                            <div class="modal-content">
                                              <div
                                                class="modal-header p-10px border-bottom-0 p-l-35px"
                                              >
                                                <h6
                                                  class="modal-title color-white"
                                                  id="uploadLeadershipLogoModalCenterTitle"
                                                >
                                                  Upload Leadership Information
                                                </h6>
                                                <button
                                                  type="button"
                                                  class="close color-white"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                >
                                                  <span
                                                    aria-hidden="true"
                                                    class="color-white"
                                                    >&times;</span
                                                  >
                                                </button>
                                              </div>
                                              <div
                                                class="modal-body p-t-5px p-b-0px"
                                              >
                                                <div
                                                  class="card bg-transparent"
                                                >
                                                  <div
                                                    class="card-body bg-transparent p-t-0px p-b-10px"
                                                  >
                                                    <div class="row">
                                                      <div class="col-lg-12">
                                                        <div
                                                          class="card bg-transparent"
                                                        >

                                                          <div
                                                            class="card-body"
                                                            style="
                                                              padding-bottom: 10px;
                                                            "
                                                          >
                                                            <form>
                                                              <div
                                                                class="form-group row"
                                                                style="
                                                                  background: #fff;
                                                                  border-radius: 10px;
                                                                  padding: 16px;
                                                                "
                                                              >
                                                                <label
                                                                  class="col-sm-4 col-form-label"
                                                                  >Select
                                                                  Image</label
                                                                >
                                                                <div
                                                                  class="col-sm-8"
                                                                >
                                                                  <input
                                                                    type="file"
                                                                    class="form-control-file p-t-4px"
                                                                    style="
                                                                      font-size: 15px;
                                                                    "
                                                                    name="leadpic"
                                                                    accept="image/*"
                                                                    @change="
                                                                      onImgChange
                                                                    "
                                                                  />
                                                                  <img
                                                                    v-if="
                                                                      frmModel.leadpic
                                                                    "
                                                                    :src="
                                                                      frmModel.leadpic
                                                                    "
                                                                    class="mr-3 leaderimg mt-2"
                                                                  />
                                                                </div>
                                                              </div>
                                                              <div
                                                                class="form-group row"
                                                                style="
                                                                  background: #fff;
                                                                  border-radius: 10px;
                                                                  padding: 16px;
                                                                "
                                                              >
                                                                <label
                                                                  class="col-sm-4 col-form-label"
                                                                  >Designation</label
                                                                >
                                                                <div
                                                                  class="col-sm-8"
                                                                >
                                                                  <input
                                                                    type="text"
                                                                    v-model="
                                                                      frmModel.leaddesignation
                                                                    "
                                                                    class="form-control"
                                                                  />
                                                                </div>
                                                              </div>
                                                              <div
                                                                class="form-group row"
                                                                style="
                                                                  background: #fff;
                                                                  border-radius: 10px;
                                                                  padding: 16px;
                                                                  margin-bottom: 0px;
                                                                "
                                                              >
                                                                <label
                                                                  class="col-sm-4 col-form-label"
                                                                  >Description</label
                                                                >
                                                                <div
                                                                  class="col-sm-8"
                                                                >
                                                                  <textarea
                                                                    class="form-control"
                                                                    v-model="
                                                                      frmModel.description
                                                                    "
                                                                    rows="3"
                                                                  ></textarea>
                                                                </div>
                                                              </div>
                                                            </form>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                class="modal-footer p-10px border-top-0 p-r-35px"
                                              >
                                                <button
                                                  type="button"
                                                  class="btn btn-secondary bg-dark-grey color-black"
                                                  data-dismiss="modal"
                                                >
                                                  Save
                                                </button>

                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="media-body">
                                          <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#uploadLeadershipLogoModal"
                                            class="edit-links"
                                            style="margin-left: 20px; top: 10px"
                                          >
                                            <i
                                              class="fa fa-pencil"
                                              aria-hidden="true"
                                            ></i>
                                            Change Image and Content
                                          </a>
                                          <h5
                                            class="m-t-10px leader-designation"
                                            style="margin-top: -10px !important"
                                          >
                                            {{ frmModel.leaddesignation }}
                                          </h5>
                                        </div>
                                      </div>
                                      <p class="leader-description">
                                        {{ frmModel.description }}
                                      </p>
                                      <p
                                        class="text-right leader-description"
                                        contenteditable="true"
                                      >
                                        <span class="border-bottom-white"
                                          >Read More</span
                                        >
                                      </p>
                                    </div>
                                  </div>
                                  <div class="row resources-container">
                                    <div class="col-lg-12 p-0">
                                      <p
                                        class="color-white text-uppercase m-0px"
                                      >
                                        <span class="border-bottom-white"
                                          >Resource</span
                                        >
                                      </p>
                                    </div>
                                    <div class="col-lg-12 p-0">
                                      <div class="media m-t-10px">
                                        <a
                                          href="#"
                                          data-toggle="modal"
                                          data-target="#uploadResourceLogoModal"
                                        >
                                          <img
                                            src="../assets/img/home/track20.png"
                                            class="mr-3 leaderimg resourceimg"
                                            alt="..."
                                          />
                                        </a>
                                        <div class="media-body">
                                          <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#uploadResourceLogoModal"
                                            class="edit-links"
                                            style="margin-left: 20px; top: 10px"
                                          >
                                            <i
                                              class="fa fa-pencil"
                                              aria-hidden="true"
                                            ></i>
                                            Change Logo and Content
                                          </a>
                                          <h5
                                            class="leader-designation"
                                            contenteditable="true"
                                            style="margin-top: -40px !important"
                                          >
                                            Family Planning Effort Index
                                          </h5>
                                        </div>
                                        <div
                                          class="modal fade"
                                          id="uploadResourceLogoModal"
                                          tabindex="-1"
                                          role="dialog"
                                          aria-labelledby="uploadResourceLogoModalCenterTitle"
                                          aria-hidden="true"
                                        >
                                          <div
                                            class="modal-dialog modal-dialog-centered"
                                            role="document"
                                          >
                                            <div class="modal-content">
                                              <div
                                                class="modal-header p-10px border-bottom-0 p-l-35px"
                                              >
                                                <h6
                                                  class="modal-title color-white"
                                                  id="uploadResourceLogoModalCenterTitle"
                                                >
                                                  Upload Resource Information
                                                </h6>
                                                <button
                                                  type="button"
                                                  class="close color-white"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                >
                                                  <span
                                                    aria-hidden="true"
                                                    class="color-white"
                                                    >&times;</span
                                                  >
                                                </button>
                                              </div>
                                              <div
                                                class="modal-body p-t-5px p-b-0px"
                                              >
                                                <div
                                                  class="card bg-transparent"
                                                >
                                                  <div
                                                    class="card-body bg-transparent p-t-0px p-b-10px"
                                                  >
                                                    <div class="row">
                                                      <div class="col-lg-12">
                                                        <div
                                                          class="card bg-transparent"
                                                        >

                                                          <div
                                                            class="card-body bg-transparent"
                                                            style="
                                                              padding-bottom: 10px;
                                                            "
                                                          >
                                                            <form>
                                                              <div
                                                                class="form-group row"
                                                                style="
                                                                  background: #fff;
                                                                  border-radius: 10px;
                                                                  padding: 16px;
                                                                "
                                                              >
                                                                <label
                                                                  for="inputPassword"
                                                                  class="col-sm-4 col-form-label"
                                                                  >Select
                                                                  Image</label
                                                                >
                                                                <div
                                                                  class="col-sm-8"
                                                                >
                                                                  <input
                                                                    type="file"
                                                                    class="form-control-file p-t-4px"
                                                                    style="
                                                                      font-size: 15px;
                                                                    "
                                                                    id="exampleFormControlFile3"
                                                                  />
                                                                  <img
                                                                    src="../assets/img/home/Hopkins.png"
                                                                    class="mr-3 leaderimg mt-2"
                                                                    alt="..."
                                                                  />
                                                                </div>
                                                              </div>
                                                              <div
                                                                class="form-group row"
                                                                style="
                                                                  background: #fff;
                                                                  border-radius: 10px;
                                                                  padding: 16px;
                                                                "
                                                              >
                                                                <label
                                                                  for="inputPassword"
                                                                  class="col-sm-4 col-form-label"
                                                                  >Heading</label
                                                                >
                                                                <div
                                                                  class="col-sm-8"
                                                                >
                                                                  <input
                                                                    type="text"
                                                                    class="form-control"
                                                                    id="inputPassword"
                                                                    placeholder=""
                                                                  />
                                                                </div>
                                                              </div>
                                                              <div
                                                                class="form-group row"
                                                                style="
                                                                  background: #fff;
                                                                  border-radius: 10px;
                                                                  padding: 16px;
                                                                  margin-bottom: 0px;
                                                                "
                                                              >
                                                                <label
                                                                  for="inputPassword"
                                                                  class="col-sm-4 col-form-label"
                                                                  >Description</label
                                                                >
                                                                <div
                                                                  class="col-sm-8"
                                                                >
                                                                  <textarea
                                                                    class="form-control"
                                                                    id="exampleFormControlTextarea1"
                                                                    rows="3"
                                                                  ></textarea>
                                                                </div>
                                                              </div>
                                                            </form>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                class="modal-footer p-10px border-top-0 p-r-35px"
                                              >
                                                <button
                                                  type="button"
                                                  class="btn btn-secondary bg-dark-grey color-black"
                                                  data-dismiss="modal"
                                                >
                                                  Save
                                                </button>

                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <p
                                        class="leader-description"
                                        contenteditable="true"
                                      >
                                        View the data for countries included in
                                        the
                                        <span class="color-blue"
                                          >...View more</span
                                        >
                                      </p>
                                    </div>
                                    <div class="col-lg-12 p-0">
                                      <div class="media m-t-10px">
                                        <a
                                          href="#"
                                          data-toggle="modal"
                                          data-target="#uploadResourceLogoModal"
                                        >
                                          <img
                                            src="../assets/img/home/Hopkins.png"
                                            class="mr-3 leaderimg resourceimg"
                                            alt="..."
                                          />
                                        </a>
                                        <div class="media-body">
                                          <h5
                                            class="leader-designation"
                                            contenteditable="true"
                                          >
                                            The Lives Saved Tool (LiST)
                                          </h5>
                                        </div>
                                      </div>
                                      <p
                                        class="leader-description"
                                        contenteditable="true"
                                      >
                                        An evidence-based tool for estimating
                                        <span class="color-blue"
                                          >...View more</span
                                        >
                                      </p>
                                    </div>
                                  </div>
                                  <a
                                    href="#"
                                    data-toggle="modal"
                                    data-target="#uploadUrlModal"
                                    class="edit-links"
                                    style="margin-left: 20px; top: 0px"
                                  >
                                    <i
                                      class="fa fa-pencil"
                                      aria-hidden="true"
                                    ></i>
                                    Upload URL
                                  </a>
                                  <div class="row socialicons-container m-t-20">
                                    <div
                                      class="col-lg-3 p-0 text-center border-right-white col-sm-3"
                                    >
                                      <a href="javascript:void(0)">
                                        <i
                                          class="fa fa-facebook-square color-white"
                                          aria-hidden="true"
                                        ></i>
                                      </a>
                                      <div
                                        class="modal fade"
                                        id="uploadUrlModal"
                                        tabindex="-1"
                                        role="dialog"
                                        aria-labelledby="uploadUrlModalCenterTitle"
                                        aria-hidden="true"
                                      >
                                        <div
                                          class="modal-dialog modal-dialog-centered"
                                          role="document"
                                        >
                                          <div class="modal-content">
                                            <div
                                              class="modal-header p-10px border-bottom-0 p-l-35px"
                                            >
                                              <h6
                                                class="modal-title color-white"
                                                id="uploadUrlModalCenterTitle"
                                              >
                                                Upload Url
                                              </h6>
                                              <button
                                                type="button"
                                                class="close color-white"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                              >
                                                <span
                                                  aria-hidden="true"
                                                  class="color-white"
                                                  >&times;</span
                                                >
                                              </button>
                                            </div>
                                            <div
                                              class="modal-body p-t-5px p-b-0px"
                                            >
                                              <div class="card bg-transparent">
                                                <div
                                                  class="card-body bg-transparent p-t-0px p-b-10px"
                                                >
                                                  <div class="row">
                                                    <div class="col-lg-12">
                                                      <div class="card">

                                                        <div class="card-body">
                                                          <form>
                                                            <div
                                                              class="form-group row"
                                                            >
                                                              <label
                                                                for="inputFacebook"
                                                                class="col-sm-4 col-form-label"
                                                                >Facebook</label
                                                              >
                                                              <div
                                                                class="col-sm-8"
                                                              >
                                                                <input
                                                                  type="text"
                                                                  v-model="
                                                                    configObj.socialfacebook
                                                                  "
                                                                  class="form-control"
                                                                  id="inputFacebook"
                                                                  placeholder="http://www.example.com"
                                                                />
                                                              </div>
                                                            </div>
                                                            <div
                                                              class="form-group row"
                                                            >
                                                              <label
                                                                for="inputInstagram"
                                                                class="col-sm-4 col-form-label"
                                                                >Instagram</label
                                                              >
                                                              <div
                                                                class="col-sm-8"
                                                              >
                                                                <input
                                                                  type="text"
                                                                  v-model="
                                                                    configObj.socialinsta
                                                                  "
                                                                  class="form-control"
                                                                  id="inputInstagram"
                                                                  placeholder="http://www.example.com"
                                                                />
                                                              </div>
                                                            </div>
                                                            <div
                                                              class="form-group row"
                                                            >
                                                              <label
                                                                for="inputTwitter"
                                                                class="col-sm-4 col-form-label"
                                                                >Twitter</label
                                                              >
                                                              <div
                                                                class="col-sm-8"
                                                              >
                                                                <input
                                                                  type="text"
                                                                  v-model="
                                                                    configObj.socialtwitter
                                                                  "
                                                                  class="form-control"
                                                                  id="inputTwitter"
                                                                  placeholder="http://www.example.com"
                                                                />
                                                              </div>
                                                            </div>
                                                            <div
                                                              class="form-group row"
                                                            >
                                                              <label
                                                                for="inputYoutube"
                                                                class="col-sm-4 col-form-label"
                                                                >Youtube</label
                                                              >
                                                              <div
                                                                class="col-sm-8"
                                                              >
                                                                <input
                                                                  type="text"
                                                                  v-model="
                                                                    configObj.socialyoutube
                                                                  "
                                                                  class="form-control"
                                                                  id="inputYoutube"
                                                                  placeholder="http://www.example.com"
                                                                />
                                                              </div>
                                                            </div>
                                                          </form>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              class="modal-footer p-10px border-top-0 p-r-35px"
                                            >
                                              <button
                                                type="button"
                                                class="btn btn-secondary bg-dark-grey color-black"
                                                data-dismiss="modal"
                                              >
                                                Save
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="col-lg-3 p-0 text-center border-right-white col-sm-3"
                                    >
                                      <a href="javascript:void(0)">
                                        <i
                                          class="fa fa-instagram color-white"
                                          aria-hidden="true"
                                        ></i>
                                      </a>
                                    </div>
                                    <div
                                      class="col-lg-3 p-0 text-center border-right-white col-sm-3"
                                    >
                                      <a href="javascript:void(0)">
                                        <i
                                          class="fa fa-twitter color-white"
                                          aria-hidden="true"
                                        ></i>
                                      </a>
                                    </div>
                                    <div
                                      class="col-lg-3 p-0 text-center col-sm-3"
                                    >
                                      <a href="javascript:void(0)">
                                        <i
                                          class="fa fa-youtube color-white"
                                          aria-hidden="true"
                                        ></i>
                                      </a>
                                    </div>
                                  </div>
                                </div>

                                <div id="page-content-wrapper" class="">
                                  <a
                                    href="#"
                                    data-toggle="modal"
                                    data-target="#uploadMainHeader"
                                    class="edit-links"
                                    style="
                                      text-align: center !important;
                                      width: 100%;
                                      position: absolute;
                                      top: 10%;
                                    "
                                  >
                                    <i
                                      class="fa fa-pencil"
                                      aria-hidden="true"
                                    ></i>
                                    Change Header Logo & Content
                                  </a>
                                  <div
                                    class="modal fade"
                                    id="uploadMainHeader"
                                    tabindex="-1"
                                    role="dialog"
                                    aria-labelledby="uploadResourceLogoModalCenterTitle"
                                    aria-hidden="true"
                                  >
                                    <div
                                      class="modal-dialog modal-dialog-centered"
                                      role="document"
                                    >
                                      <div class="modal-content">
                                        <div
                                          class="modal-header p-10px border-bottom-0 p-l-35px"
                                        >
                                          <h6
                                            class="modal-title color-white"
                                            id="uploadResourceMainContentCenterTitle"
                                          >
                                            Upload Logo & Content
                                          </h6>
                                          <button
                                            type="button"
                                            class="close color-white"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                          >
                                            <span
                                              aria-hidden="true"
                                              class="color-white"
                                              >&times;</span
                                            >
                                          </button>
                                        </div>
                                        <div class="modal-body p-t-5px p-b-0px">
                                          <div class="card bg-transparent">
                                            <div
                                              class="card-body bg-transparent p-t-0px p-b-10px"
                                            >
                                              <div class="row">
                                                <div class="col-lg-12">
                                                  <div
                                                    class="card bg-transparent"
                                                  >
                                                    <div
                                                      class="card-body bg-transparent"
                                                      style="
                                                        padding-bottom: 10px;
                                                      "
                                                    >
                                                      <form>
                                                        <div
                                                          class="form-group row mb-3"
                                                          style="
                                                            background: #fff;
                                                            border-radius: 10px;
                                                            padding: 16px;
                                                            margin-bottom: 0px;
                                                          "
                                                        >
                                                          <label
                                                            class="col-sm-4 col-form-label"
                                                            >Select Logo</label
                                                          >
                                                          <div class="col-sm-8">
                                                            <div
                                                              class="input-group mb-3"
                                                            >
                                                              <div
                                                                class="custom-file"
                                                              >
                                                                <input
                                                                  type="file"
                                                                  class="form-control-file p-t-4px"
                                                                  style="
                                                                    font-size: 15px;
                                                                  "
                                                                  name="landingLogo"
                                                                  id="landingLogo"
                                                                  accept="image/*"
                                                                  @change="
                                                                    onImgChangeconfig
                                                                  "
                                                                />
                                                                <label
                                                                  class="custom-file-label"
                                                                  for="landingLogo"
                                                                  >Choose
                                                                  file</label
                                                                >
                                                              </div>
                                                            </div>
                                                            <div
                                                              class="img-preview"
                                                            >
                                                              <img
                                                                v-if="
                                                                  configObj.landingLogo
                                                                "
                                                                :src="
                                                                  configObj.landingLogo
                                                                "
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div
                                                          class="form-group row"
                                                          style="
                                                            background: #fff;
                                                            border-radius: 10px;
                                                            padding: 16px;
                                                          "
                                                        >
                                                          <label
                                                            class="col-sm-4 col-form-label"
                                                            >Heading</label
                                                          >
                                                          <div class="col-sm-8">
                                                            <input
                                                              type="text"
                                                              class="form-control"
                                                              value="Family Planning Dashboard"
                                                              v-model="
                                                                configObj.landingHeading
                                                              "
                                                            />
                                                          </div>
                                                        </div>

                                                        <div
                                                          class="form-group row"
                                                          style="
                                                            background: #fff;
                                                            border-radius: 10px;
                                                            padding: 16px;
                                                          "
                                                        >
                                                          <label
                                                            class="col-sm-4 col-form-label"
                                                            >Dashboard
                                                            Name</label
                                                          >
                                                          <div class="col-sm-8">
                                                            <input
                                                              type="text"
                                                              class="form-control"
                                                              value="Harmonia"
                                                              v-model="
                                                                configObj.dashName
                                                              "
                                                            />
                                                          </div>
                                                        </div>

                                                        <div
                                                          class="form-group row"
                                                          style="
                                                            background: #fff;
                                                            border-radius: 10px;
                                                            padding: 16px;
                                                          "
                                                        >
                                                          <label
                                                            class="col-sm-4 col-form-label"
                                                            >Dashboard
                                                            Url</label
                                                          >
                                                          <div class="col-sm-8">
                                                            <input
                                                              type="text"
                                                              class="form-control"
                                                              placeholder="http://www.example.com"
                                                              value="http://www.example.com"
                                                              v-model="
                                                                configObj.dashUrl
                                                              "
                                                            />
                                                          </div>
                                                        </div>
                                                      </form>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          class="modal-footer p-10px border-top-0 p-r-35px"
                                        >
                                          <button
                                            type="button"
                                            class="btn btn-light"
                                            data-dismiss="modal"
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div id="header">
                                    <nav
                                      class="navbar navbar-expand-md navbar-default landingpage-nav"
                                    >
                                      <a
                                        class="navbar-brand customfontstylegeneva"
                                        href="#/select-module"
                                      >
                                        <img
                                          :src="configObj.landingLogo"
                                          class="imgFit"
                                        />

                                      </a>
                                      <a
                                        href="#"
                                        class="customfontstylegeneva pl-4"
                                        style="cursor: default"
                                      >
                                        <span
                                          class="title-text"
                                          style="color: #000 !important"
                                          >{{ configObj.landingHeading }}</span
                                        ></a
                                      >
                                      <button
                                        class="navbar-toggler"
                                        type="button"
                                        data-toggle="collapse"
                                        data-target="#collapsibleNavbar"
                                      >
                                        <span
                                          class="navbar-toggler-icon"
                                        ></span>
                                      </button>
                                      <div
                                        class="collapse navbar-collapse"
                                        id="collapsibleNavbar"
                                        style="justify-content: flex-end"
                                        v-if="
                                          this.$route.name != 'home' &&
                                          this.$route.name != 'createaccount' &&
                                          this.$route.name != 'activate'
                                        "
                                      >
                                        <ul class="navbar-nav">
                                          <li class="nav-item">
                                            <a
                                              href="#"
                                              class="nav-link btn btn-headerbutton text-dark"
                                              type="button"
                                              role="button"
                                              style="
                                                padding: 0px 12px;
                                                margin-top: 10%;
                                                color: #000 !important;
                                              "
                                              ><i
                                                class="fa fa-cog"
                                                aria-hidden="true"
                                              ></i>
                                            </a>
                                          </li>
                                          <li class="nav-item">
                                            <a
                                              href="#"
                                              class="nav-link btn btn-headerbutton text-dark"
                                              type="button"
                                              role="button"
                                              style="
                                                padding: 0px 12px;
                                                margin-top: 10%;
                                                color: #000 !important;
                                              "
                                              ><i
                                                class="fa fa-bell"
                                                aria-hidden="true"
                                              ></i>
                                            </a>
                                          </li>
                                          <li class="nav-item">
                                            <a
                                              href="#"
                                              class="nav-link btn btn-headerbutton text-dark"
                                              type="button"
                                              role="button"
                                              style="
                                                padding: 0px 12px;
                                                margin-top: 10%;
                                                color: #000 !important;
                                              "
                                              ><i
                                                class="fa fa-user"
                                                aria-hidden="true"
                                              ></i>
                                            </a>
                                          </li>
                                          <li class="nav-item">
                                            <a
                                              :href="configObj.dashUrl"
                                              class="nav-link btn btn-headerbutton"
                                              target="_blank"
                                              type="button"
                                              role="button"
                                              style="
                                                color: rgb(255, 255, 255);
                                                background-color: rgb(
                                                  29,
                                                  82,
                                                  136
                                                );
                                                border-color: rgb(29, 82, 136);
                                                padding: 0px 12px;
                                                margin-top: 4%;
                                                width: 130px;
                                              "
                                              >{{ configObj.dashName }}</a
                                            >
                                          </li>
                                        </ul>
                                      </div>
                                    </nav>
                                  </div>
                                  <a
                                    href="#"
                                    class="pull-right mr-2"
                                    data-toggle="modal"
                                    data-target="#uploadBackgroundImageModal"
                                  >
                                    <a
                                      href="#"
                                      data-toggle="modal"
                                      data-target="#uploadBackgroundImageModal"
                                      class="edit-links"
                                      style="
                                        margin-left: 20px;
                                        top: 10px;
                                        right: 20px;
                                      "
                                    >
                                      <i
                                        class="fa fa-pencil"
                                        aria-hidden="true"
                                      ></i>
                                      Change Background Image
                                    </a>
                                  </a>
                                  <div
                                    class="modal fade"
                                    id="uploadBackgroundImageModal"
                                    tabindex="-1"
                                    role="dialog"
                                    aria-labelledby="uploadBackgroundImageModalCenterTitle"
                                    aria-hidden="true"
                                  >
                                    <div
                                      class="modal-dialog modal-dialog-centered"
                                      role="document"
                                    >
                                      <div class="modal-content">
                                        <div
                                          class="modal-header p-10px border-bottom-0 p-l-35px"
                                        >
                                          <h6
                                            class="modal-title color-white"
                                            id="uploadBackgroundImageModalCenterTitle"
                                          >
                                            Upload Background Image
                                          </h6>
                                          <button
                                            type="button"
                                            class="close color-white"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                          >
                                            <span
                                              aria-hidden="true"
                                              class="color-white"
                                              >&times;</span
                                            >
                                          </button>
                                        </div>
                                        <div class="modal-body p-t-5px p-b-0px">
                                          <div class="card bg-transparent">
                                            <div
                                              class="card-body bg-transparent p-t-0px p-b-10px"
                                            >
                                              <div class="row">
                                                <div class="col-lg-12">
                                                  <div class="card">
                                                    <div class="card-body">
                                                      <form>
                                                        <div
                                                          class="form-group row"
                                                        >
                                                          <label
                                                            class="col-sm-4 col-form-label"
                                                            >Select Image</label
                                                          >
                                                          <div class="col-sm-8">
                                                            <input
                                                              type="file"
                                                              class="form-control-file p-t-4px"
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                              name="landingBgImg"
                                                              id="landingBgImg"
                                                              accept="image/*"
                                                              @change="
                                                                onImgChangeconfig
                                                              "
                                                            />
                                                            <label
                                                              class="custom-file-label"
                                                              for="landingBgImg"
                                                              >Choose
                                                              file</label
                                                            >
                                                          </div>

                                                          <div
                                                            class="img-preview"
                                                          >
                                                            <img
                                                              v-if="
                                                                configObj.landingBgImg
                                                              "
                                                              :src="
                                                                configObj.landingBgImg
                                                              "
                                                            />
                                                          </div>
                                                        </div>
                                                      </form>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          class="modal-footer p-10px border-top-0 p-r-35px"
                                        >
                                          <button
                                            type="button"
                                            class="btn btn-light"
                                            data-dismiss="modal"
                                          >
                                            Save and Continue
                                          </button>

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="container-fluid landingpage-maincontainer"
                                    style="position: relative"
                                  >
                                    <a
                                      href="#"
                                      data-toggle="modal"
                                      data-target="#uploadMainContentModal"
                                      class="edit-links"
                                      style="
                                        top: 60px;
                                        right: 20px;
                                        text-align: center !important;
                                        width: 100%;
                                      "
                                    >
                                      <i
                                        class="fa fa-pencil"
                                        aria-hidden="true"
                                      ></i>
                                      Change Content
                                    </a>
                                    <div
                                      class="modal fade"
                                      id="uploadMainContentModal"
                                      tabindex="-1"
                                      role="dialog"
                                      aria-labelledby="uploadResourceLogoModalCenterTitle"
                                      aria-hidden="true"
                                    >
                                      <div
                                        class="modal-dialog modal-dialog-centered"
                                        role="document"
                                      >
                                        <div class="modal-content">
                                          <div
                                            class="modal-header p-10px border-bottom-0 p-l-35px"
                                          >
                                            <h6
                                              class="modal-title color-white"
                                              id="uploadResourceMainContentCenterTitle"
                                            >
                                              Upload Content
                                            </h6>
                                            <button
                                              type="button"
                                              class="close color-white"
                                              data-dismiss="modal"
                                              aria-label="Close"
                                            >
                                              <span
                                                aria-hidden="true"
                                                class="color-white"
                                                >&times;</span
                                              >
                                            </button>
                                          </div>
                                          <div
                                            class="modal-body p-t-5px p-b-0px"
                                          >
                                            <div class="card bg-transparent">
                                              <div
                                                class="card-body bg-transparent p-t-0px p-b-10px"
                                              >
                                                <div class="row">
                                                  <div class="col-lg-12">
                                                    <div
                                                      class="card bg-transparent"
                                                    >
                                                      <div
                                                        class="card-body bg-transparent"
                                                        style="
                                                          padding-bottom: 10px;
                                                        "
                                                      >
                                                        <form>
                                                          <div
                                                            class="form-group row"
                                                            style="
                                                              background: #fff;
                                                              border-radius: 10px;
                                                              padding: 16px;
                                                            "
                                                          >
                                                            <label
                                                              class="col-sm-4 col-form-label"
                                                              >Heading</label
                                                            >
                                                            <div
                                                              class="col-sm-8"
                                                            >
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                v-model="
                                                                  configObj.contentHeading
                                                                "
                                                              />
                                                            </div>
                                                          </div>
                                                          <div
                                                            class="form-group row"
                                                            style="
                                                              background: #fff;
                                                              border-radius: 10px;
                                                              padding: 16px;
                                                              margin-bottom: 0px;
                                                            "
                                                          >
                                                            <label
                                                              class="col-sm-4 col-form-label"
                                                              >Description</label
                                                            >
                                                            <div
                                                              class="col-sm-8"
                                                            >
                                                              <textarea
                                                                class="form-control"
                                                                v-model="
                                                                  configObj.contentDescription
                                                                "
                                                              ></textarea>
                                                            </div>
                                                          </div>
                                                        </form>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            class="modal-footer p-10px border-top-0 p-r-35px"
                                          >
                                            <button
                                              type="button"
                                              class="btn btn-light"
                                              data-dismiss="modal"
                                            >
                                              Save
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="text-wrap-center">
                                      <h2>{{ configObj.contentHeading }}</h2>
                                      <h4>
                                        {{ configObj.contentDescription }}
                                      </h4>
                                    </div>
                                    <div class="button-wrap">
                                      <div class="button-div-wrap">
                                        <a
                                          href="#/facilitator-page"
                                          class="btn purpleBg-gradient"
                                        >
                                          <span
                                            class="fa fa-puzzle-piece pull-left"
                                            style="
                                              font-size: 20px;
                                              padding-right: 15px;
                                              color: #fff;
                                            "
                                          ></span
                                          >training planning
                                        </a>
                                        <a
                                          href="#/training-agenda"
                                          class="btn blueBg-gradient"
                                          ><span
                                            class="fa fa-folder-open pull-left"
                                            style="
                                              font-size: 20px;
                                              padding-right: 15px;
                                              color: #fff;
                                            "
                                          ></span
                                          >training material</a
                                        >

                                        <a
                                          href="#/exam-id"
                                          class="btn redBg-gradient"
                                        >
                                          <span
                                            class="fa fa-file-text-o pull-left"
                                            style="
                                              font-size: 20px;
                                              padding-right: 15px;
                                              color: #fff;
                                            "
                                          ></span
                                          >Course Test
                                        </a>
                                        <a
                                          class="btn goldBg-gradient"
                                          href="#/certificate-status"
                                        >
                                          <span
                                            class="fa fa-certificate pull-left"
                                            style="
                                              font-size: 20px;
                                              padding-right: 15px;
                                              color: #fff;
                                            "
                                          ></span
                                          >Certificate</a
                                        >
                                      </div>
                                    </div>
                                  </div>
                                  <div class="floating-chat-icon">
                                    <div class="floatingIcon">
                                      <span
                                        class="fa fa-commenting"
                                        aria-hidden="true"
                                        v-b-tooltip.hover
                                        title="FAQ"
                                      ></span>
                                    </div>
                                  </div>
                                  <div
                                    class="container-fluid footerSection pl-0 pr-0"
                                  >
                                    <div id="footer">
                                      <footer style="position: static">
                                        <div class="row">
                                          <div class="col-md-6">
                                            <div class="social-icon">
                                              <ul>
                                                <li>
                                                  <a href="#"
                                                    ><span
                                                      class="fa fa-twitter-square social-icon-fa"
                                                    ></span
                                                  ></a>
                                                </li>
                                                <li>
                                                  <a href="#"
                                                    ><span
                                                      class="fa fa-facebook-square social-icon-fa"
                                                    ></span
                                                  ></a>
                                                </li>
                                                <li>
                                                  <a href="#"
                                                    ><span
                                                      class="fa fa-youtube-play social-icon-fa"
                                                    ></span
                                                  ></a>
                                                </li>
                                                <li>
                                                  <a href="#"
                                                    ><span
                                                      class="fa fa-instagram social-icon-fa"
                                                    ></span
                                                  ></a>
                                                </li>
                                              </ul>
                                            </div>
                                            <a
                                              href="#"
                                              data-toggle="modal"
                                              data-target="#uploadurl"
                                              class="edit-links"
                                              style="
                                                text-align: center !important;
                                                width: 100%;
                                                position: absolute;
                                                top: 0;
                                                right: 75px;
                                              "
                                            >
                                              <i
                                                class="fa fa-pencil"
                                                aria-hidden="true"
                                              ></i>
                                              Change Social Media Url
                                            </a>
                                            <div
                                              class="modal fade"
                                              id="uploadurl"
                                              tabindex="-1"
                                              role="dialog"
                                              aria-labelledby="uploadResourceLogoModalCenterTitle"
                                              aria-hidden="true"
                                            >
                                              <div
                                                class="modal-dialog modal-dialog-centered"
                                                role="document"
                                              >
                                                <div class="modal-content">
                                                  <div
                                                    class="modal-header p-10px border-bottom-0 p-l-35px"
                                                  >
                                                    <h6
                                                      class="modal-title color-white"
                                                      id="uploadResourceMainContentCenterTitle"
                                                    >
                                                      Upload Url
                                                    </h6>
                                                    <button
                                                      type="button"
                                                      class="close color-white"
                                                      data-dismiss="modal"
                                                      aria-label="Close"
                                                    >
                                                      <span
                                                        aria-hidden="true"
                                                        class="color-white"
                                                        >&times;</span
                                                      >
                                                    </button>
                                                  </div>
                                                  <div
                                                    class="modal-body p-t-5px p-b-0px"
                                                  >
                                                    <div
                                                      class="card bg-transparent"
                                                    >
                                                      <div
                                                        class="card-body bg-transparent p-t-0px p-b-10px"
                                                      >
                                                        <div class="row">
                                                          <div
                                                            class="col-lg-12"
                                                          >
                                                            <div
                                                              class="card bg-transparent"
                                                            >
                                                              <div
                                                                class="card-body bg-transparent"
                                                                style="
                                                                  padding-bottom: 10px;
                                                                "
                                                              >
                                                                <form>
                                                                  <div
                                                                    class="form-group row mb-3"
                                                                    style="
                                                                      background: #fff;
                                                                      border-radius: 10px;
                                                                      padding: 16px;
                                                                      margin-bottom: 0px;
                                                                    "
                                                                  >
                                                                    <label
                                                                      class="col-sm-4 col-form-label"
                                                                      >Twitter</label
                                                                    >
                                                                    <div
                                                                      class="col-sm-8"
                                                                    >
                                                                      <input
                                                                        type="text"
                                                                        class="form-control"
                                                                        placeholder="http://www.example.com"
                                                                        v-model="
                                                                          configObj.socialtwitter
                                                                        "
                                                                      />
                                                                    </div>
                                                                  </div>
                                                                  <div
                                                                    class="form-group row mb-3"
                                                                    style="
                                                                      background: #fff;
                                                                      border-radius: 10px;
                                                                      padding: 16px;
                                                                    "
                                                                  >
                                                                    <label
                                                                      class="col-sm-4 col-form-label"
                                                                      >Facebook</label
                                                                    >
                                                                    <div
                                                                      class="col-sm-8"
                                                                    >
                                                                      <input
                                                                        type="text"
                                                                        class="form-control"
                                                                        placeholder="http://www.example.com"
                                                                        v-model="
                                                                          configObj.socialfacebook
                                                                        "
                                                                      />
                                                                    </div>
                                                                  </div>
                                                                  <div
                                                                    class="form-group row mb-3"
                                                                    style="
                                                                      background: #fff;
                                                                      border-radius: 10px;
                                                                      padding: 16px;
                                                                    "
                                                                  >
                                                                    <label
                                                                      class="col-sm-4 col-form-label"
                                                                      >YouTube</label
                                                                    >
                                                                    <div
                                                                      class="col-sm-8"
                                                                    >
                                                                      <input
                                                                        type="text"
                                                                        class="form-control"
                                                                        placeholder="http://www.example.com"
                                                                        v-model="
                                                                          configObj.socialyoutube
                                                                        "
                                                                      />
                                                                    </div>
                                                                  </div>
                                                                  <div
                                                                    class="form-group row mb-3"
                                                                    style="
                                                                      background: #fff;
                                                                      border-radius: 10px;
                                                                      padding: 16px;
                                                                    "
                                                                  >
                                                                    <label
                                                                      class="col-sm-4 col-form-label"
                                                                      >Instagram</label
                                                                    >
                                                                    <div
                                                                      class="col-sm-8"
                                                                    >
                                                                      <input
                                                                        type="text"
                                                                        class="form-control"
                                                                        placeholder="http://www.example.com"
                                                                        v-model="
                                                                          configObj.socialinsta
                                                                        "
                                                                      />
                                                                    </div>
                                                                  </div>
                                                                </form>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div
                                                    class="modal-footer p-10px border-top-0 p-r-35px"
                                                  >
                                                    <button
                                                      type="button"
                                                      class="btn btn-light"
                                                      data-dismiss="modal"
                                                    >
                                                      Save
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-md-6">
                                            <div class="c_div">
                                              <span class="text-dark"
                                                >Powered By:
                                              </span>
                                              <template
                                                v-if="
                                                  settings.countryName.toLowerCase() ===
                                                  'nepal'
                                                "
                                              >
                                                <img
                                                  src="../assets/img/logo_front_new.png"
                                                  class="img-responsive-sm c_logo aviner_logo"
                                                  alt="Dure Technology"
                                                  style="width: 70px"
                                                />
                                                <img
                                                  src="../assets/img/logo.png"
                                                  class="img-responsive-sm c_logo"
                                                  alt="Dure Technology"
                                                />
                                              </template>
                                              <template v-else>
                                                <img
                                                  src="../assets/img/logo.png"
                                                  class="img-responsive-sm c_logo"
                                                  alt="Dure Technology"
                                                />
                                              </template>
                                            </div>
                                          </div>
                                        </div>
                                      </footer>
                                    </div>
                                  </div>
                                </div>

                              </div>
                              <div class="row m-t-20px m-b-20px">
                                <div class="col-sm-12 text-right">

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </tab-content>
          <tab-content
            title="Data Module Configuration"
            class="uploadDataTab"
            icon="1"
            :before-change="validateSecondTab"
            v-on:click="trackEvent('Data Module Configuration')"
          >
            <div
              class="card-header bgPurple"
              style="border-radius: 10px 10px 0 0"
            >
              <h4 class="color-white f-w-100">
                <span class="border-bottom-white">Data Configuration</span>
              </h4>
            </div>
            <div class="card m-t-0px m-b-20px">

              <div
                class="card-body d-flex upload_data_tab sideNavTab px-0 py-0"
              >
                <div
                  class="nav flex-column nav-pills sidebarNavDiv col-3 pr-0"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <a
                    class="nav-link active pl-3 py-4"
                    id="v-pills-home-tab"
                    data-toggle="pill"
                    href="#v-pills-home"
                    role="tab"
                    aria-controls="v-pills-home"
                    aria-selected="false"
                    v-on:click="trackEvent('Training Planning')"
                    >Training Planning</a
                  >
                  <a
                    class="nav-link pl-3 py-4"
                    id="v-pills-profile-tab"
                    data-toggle="pill"
                    href="#v-pills-profile"
                    role="tab"
                    aria-controls="v-pills-profile"
                    aria-selected="true"
                    v-on:click="trackEvent('Course Test')"
                    >Course Test</a
                  >
                  <a
                    class="nav-link pl-3 py-4"
                    id="trainingFAQTab"
                    data-toggle="pill"
                    href="#trainingFAQ"
                    role="tab"
                    aria-controls="trainingFAQ"
                    aria-selected="false"
                    v-on:click="trackEvent('FAQ')"
                    >FAQ</a
                  >


                </div>
                <div class="tab-content col-9" id="v-pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="v-pills-home"
                    role="tabpanel"
                    aria-labelledby="v-pills-home-tab"
                  >
                    <div class="subTab mt-3">
                      <ul
                        class="nav nav-tabs justify-content-center"
                        id="myTab"
                        role="tablist"
                      >
                        <li class="nav-item px-2">
                          <a
                            class="nav-link active"
                            id="introTab"
                            data-toggle="tab"
                            href="#intro"
                            role="tab"
                            aria-controls="intro"
                            aria-selected="true"
                            v-on:click="trackEvent('Introduction')"
                            >Introduction</a
                          >
                        </li>
                        <li class="nav-item px-2">
                          <a
                            class="nav-link"
                            id="roleDesTab"
                            data-toggle="tab"
                            href="#roleDes"
                            role="tab"
                            aria-controls="roleDes"
                            aria-selected="false"
                            v-on:click="trackEvent('Description')"
                            >Description</a
                          >
                        </li>
                        <li class="nav-item px-2 hide">
                          <a
                            class="nav-link"
                            id="trainProTab"
                            data-toggle="tab"
                            href="#trainPro"
                            role="tab"
                            aria-controls="trainPro"
                            aria-selected="false"
                            v-on:click="trackEvent('Training Process')"
                            >Training Process</a
                          >
                        </li>
                        <li class="nav-item px-2">
                          <a
                            class="nav-link"
                            id="resGrpTab"
                            data-toggle="tab"
                            href="#resGrp"
                            role="tab"
                            aria-controls="resGrp"
                            aria-selected="false"
                            v-on:click="trackEvent('Resource Group')"
                            >Resource Group</a
                          >
                        </li>
                        <li class="nav-item px-2">
                          <a
                            class="nav-link"
                            id="trainingAgendaTab"
                            data-toggle="tab"
                            href="#trainingAgenda"
                            role="tab"
                            aria-controls="trainingAgenda"
                            aria-selected="false"
                            >Training Agenda</a
                          >
                        </li>

                      </ul>
                    </div>

                    <div class="tab-content" id="trainingPlanningTab">
                      <div
                        class="tab-pane fade show active"
                        id="intro"
                        role="tabpanel"
                        aria-labelledby="introtab"
                      >

                        <div class="row">
                          <div class="col-12">
                            <div
                              class="card bg-white mt-3"
                              style="border-radius: 0px"
                            >
                              <div class="card-body">
                                <form>
                                  <div class="row">
                                    <div class="col-6">
                                      <div class="form-group">
                                        <label for="exampleFormControlFile1"
                                          >Upload Image (Maximum 5
                                          images)</label
                                        >
                                        <div class="input-group mb-3">
                                          <div class="custom-file">
                                            <input
                                              type="file"
                                              class="custom-file-input"
                                              accept="image/*"
                                              name="introPhotos"
                                              id="introPhotos"
                                              @change="onImgChange"
                                              style="font-size: 15px"
                                              multiple
                                            />

                                            <label
                                              class="custom-file-label"
                                              for="introPhotos"
                                              >Choose file</label
                                            >
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-6">
                                      <div class="form-group">
                                        <label for="linkText"
                                          >Change Link Text</label
                                        >
                                        <input
                                          type="text"
                                          class="form-control"
                                          value="I am a Facilitator"
                                          id="introLinkText"
                                          v-model="frmModel.introLinkText"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-12">
                                      Uploaded images <br />

                                      <div class="row mt-2">
                                        <div class="d-flex">
                                          <template
                                            v-for="(
                                              img, imgind
                                            ) in frmModel.introPhotos"
                                          >
                                            <div class="col">
                                              <div class="imageCardSection">
                                                <div class="imageDivWrap">
                                                  <div class="img-preview1">
                                                    <img
                                                      v-if="img.photoContent"
                                                      :src="img.photoContent"
                                                    />
                                                  </div>
                                                </div>
                                                <div class="iconDivWrap">
                                                  <div class="dltIcon">
                                                    <i
                                                      class="fa fa-trash text-center"
                                                      aria-hidden="true"
                                                      @click="
                                                        removeImage(imgind)
                                                      "
                                                    ></i>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </template>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row mt-4">
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="textlistEditor"
                                          >Introduction</label
                                        >
                                        <vue-editor
                                          v-model="frmModel.introContent"
                                        ></vue-editor>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>

                            <div
                              class="savebtnDiv float-right my-4"
                              @click="onComplete()"
                            >
                              <button class="btn btn-primary btn-sm">
                                Save & Continue
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="roleDes"
                        role="tabpanel"
                        aria-labelledby="roleDesTab"
                      >
                        <div class="row">
                          <div class="col-12">
                            <div
                              class="card bg-white mt-3"
                              style="border-radius: 0px"
                            >
                              <div class="card-body">
                                <form>
                                  <div class="row">
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="headerText"
                                          >Change Header Text</label
                                        >
                                        <input
                                          type="text"
                                          class="form-control"
                                          value="Facilitator"
                                          id="roleHeader"
                                          v-model="frmModel.roleHeader"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="textlistEditor"
                                          >Add Introduction</label
                                        >
                                        <vue-editor
                                          v-model="frmModel.roleContent"
                                        ></vue-editor>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>

                            <div
                              class="savebtnDiv float-right my-4"
                              @click="onComplete()"
                            >
                              <button class="btn snbtn btn-sm">
                                Save & Continue
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="trainPro"
                        role="tabpanel"
                        aria-labelledby="trainProTab"
                      >
                        <div class="row">
                          <div class="col-12">
                            <div
                              class="card bg-white mt-3"
                              style="border-radius: 0px"
                            >
                              <div class="card-body">
                                <form>
                                  <div class="row">
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="headerText"
                                          >Change Header Text</label
                                        >
                                        <input
                                          type="text"
                                          class="form-control"
                                          value="Facilitator"
                                          id="stepHeader"
                                          v-model="frmModel.stepHeader"
                                          name="stepHeader"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <hr />
                                  <h5
                                    class="mainTextHead bg-light-grey color-grey alert mt-4"
                                  >
                                    Add/Edit Steps
                                  </h5>
                                  <div
                                    v-if="
                                      frmModel.steps &&
                                      frmModel.steps.length > 0
                                    "
                                  >
                                    <div
                                      v-for="(step, stepind) in frmModel.steps"
                                      :key="stepind"
                                    >
                                      <div class="row">
                                        <div class="col-12">
                                          <div class="form-group">
                                            <label for="textlistEditor">
                                              Step {{ stepind + 1 }}</label
                                            >
                                            <div class="row">
                                              <div class="col-9">
                                                <textarea
                                                  class="form-control"
                                                  v-model="step.stepContent"
                                                >
                                                </textarea>
                                              </div>
                                              <div class="col-3">
                                                <div class="select-wrapper">
                                                  <b-select
                                                    :options="stepoptions"
                                                    v-model="step.stepType"
                                                  >
                                                  </b-select>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="row" v-if="stepind == 0">
                                        <div class="col-12">
                                          <div class="float-right">
                                            <button
                                              class="btn addUpdatebtnclass text-white btn-sm"
                                              @click.prevent.stop="
                                                addstepsbtnnew(stepind)
                                              "
                                            >
                                              <i
                                                class="fa fa-plus"
                                                aria-hidden="true"
                                              ></i>
                                              Add More Steps
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="row" v-if="stepind != 0">
                                        <div class="col-12">
                                          <div class="float-right">
                                            <button
                                              class="btn resetbtnclass text-white btn-sm"
                                              @click.prevent.stop="
                                                deleteStep(stepind)
                                              "
                                            >
                                              <i
                                                class="fa fa-trash"
                                                aria-hidden="true"
                                              ></i>
                                              Delete
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>

                            <div
                              class="savebtnDiv float-right my-4"
                              @click="onComplete()"
                            >
                              <button class="btn snbtn btn-sm">
                                Save & Continue
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="resGrp"
                        role="tabpanel"
                        aria-labelledby="resGrpTab"
                      >
                        <div class="row">
                          <div class="col-12">
                            <div
                              class="card bg-white mt-3"
                              style="border-radius: 0px"
                            >
                              <div class="card-body">
                                <div class="row">
                                  <div class="col-12">
                                    <h5
                                      class="mainTextHead bg-light-grey color-grey alert mt-4"
                                    >
                                      Details
                                    </h5>
                                    <table
                                      class="table table-striped table-hover table-bordered detailsTabel"
                                    >
                                      <template
                                        v-for="(
                                          sess, seind
                                        ) in frmModel.sessions"
                                      >
                                        <tr
                                          v-if="seind == 0"
                                          class="sessionHeadingDiv"
                                        >
                                          <th class="col-8">Select Session</th>
                                          <th class="col-4 text-center">
                                            Action
                                          </th>
                                        </tr>
                                        <tr
                                          class="sessionRow"
                                          @click="toggle(sess.sessionId)"
                                          :class="{
                                            opened: opened.includes(
                                              sess.sessionId
                                            ),
                                          }"
                                        >
                                          <td class="col-8">
                                            <p class="selectSecttion">
                                              {{ sess.session }}
                                            </p>
                                          </td>
                                          <td class="col-4">
                                            <div class="iconGroup d-flex">
                                              <i
                                                class="fa fa-plus"
                                                aria-hidden="true"
                                                v-b-tooltip.hover
                                                title="Add"
                                                @click.prevent.stop="onReset()"
                                              ></i>
                                              <i
                                                v-if="sess.sessionId"
                                                class="fa fa-pencil-square-o"
                                                aria-hidden="true"
                                                v-b-tooltip.hover
                                                title="Edit"
                                                @click.prevent.stop="
                                                  updatenewSess(seind)
                                                "
                                              ></i>
                                              <i
                                                class="fa fa-trash"
                                                aria-hidden="true"
                                                v-b-tooltip.hover
                                                title="Delete"
                                                @click.prevent.stop="
                                                  deleteSess(seind)
                                                "
                                              ></i>
                                            </div>
                                          </td>

                                        </tr>
                                        <template
                                          v-for="(
                                            cate, catind
                                          ) in sess.category"
                                          v-if="opened.includes(sess.sessionId)"
                                        >
                                          <tr
                                            :class="{
                                              opened: openedCat.includes(
                                                cate.categoryId
                                              ),
                                            }"
                                            v-if="
                                              opened.includes(sess.sessionId)
                                            "
                                            style="
                                              background: #f7f7f7 !important;
                                            "
                                          >
                                            <td
                                              class="col-8"
                                              @click="
                                                toggleCat(cate.categoryId)
                                              "
                                            >
                                              <p
                                                class="selectSecttion text-dark"
                                              >
                                                Category: {{ cate.category }}
                                              </p>
                                            </td>
                                            <td class="col-4">
                                              <div class="iconGroup d-flex">
                                                <i
                                                  class="fa fa-plus text-dark"
                                                  aria-hidden="true"
                                                  v-b-tooltip.hover
                                                  title="Add"
                                                  @click.prevent.stop="
                                                    addNewSess(seind)
                                                  "
                                                ></i>
                                                <i
                                                  v-if="cate.categoryId"
                                                  class="fa fa-pencil-square-o text-dark"
                                                  aria-hidden="true"
                                                  v-b-tooltip.hover
                                                  title="Edit"
                                                  @click.prevent.stop="
                                                    updatenewSess(seind)
                                                  "
                                                ></i>
                                                <i
                                                  class="fa fa-trash text-dark"
                                                  aria-hidden="true"
                                                  v-b-tooltip.hover
                                                  title="Delete"
                                                  @click.prevent.stop="
                                                    deleteSess(seind, catind)
                                                  "
                                                ></i>
                                              </div>
                                            </td>
                                          </tr>
                                          <template
                                            v-for="(
                                              res, resind
                                            ) in cate.resourse"
                                          >
                                            <tr
                                              v-if="
                                                openedCat.includes(
                                                  cate.categoryId
                                                ) && res.header != ''
                                              "
                                            >
                                              <td class="col-8">
                                                <p
                                                  class="selectSecttion text-dark"
                                                >
                                                  Resource: {{ res.header }}
                                                </p>
                                              </td>
                                              <td class="col-4">
                                                <div class="iconGroup d-flex">
                                                  <i
                                                    class="fa fa-plus text-dark"
                                                    aria-hidden="true"
                                                    v-b-tooltip.hover
                                                    title="Add"
                                                    @click.prevent.stop="
                                                      addNewSess(seind, catind)
                                                    "
                                                  ></i>
                                                  <i
                                                    v-if="res.resourseId"
                                                    class="fa fa-pencil-square-o text-dark"
                                                    aria-hidden="true"
                                                    v-b-tooltip.hover
                                                    title="Edit"
                                                    @click.prevent.stop="
                                                      updatenewSess(
                                                        seind,
                                                        catind
                                                      )
                                                    "
                                                  ></i>
                                                  <i
                                                    class="fa fa-trash text-dark"
                                                    aria-hidden="true"
                                                    v-b-tooltip.hover
                                                    title="Delete"
                                                    @click.prevent.stop="
                                                      deleteSess(
                                                        seind,
                                                        catind,
                                                        resind
                                                      )
                                                    "
                                                  ></i>
                                                </div>
                                              </td>
                                            </tr>
                                          </template>
                                        </template>
                                      </template>
                                    </table>
                                  </div>
                                </div>

                                <form class="" @reset="onReset">
                                  <div class="addSecSection">
                                    <div class="row col-12 hide">
                                      <div class="col-6">
                                        <label>Select Session</label>
                                        <b-select
                                          class="form-control"
                                          :options="sessionList"
                                          v-model="sessionNew1"
                                        >
                                        </b-select>
                                      </div>
                                      <div class="col-6">
                                        <label>Select Category</label>
                                        <b-select
                                          class="form-control"
                                          :options="catList"
                                          v-model="catName1"
                                          @change="loadFormData()"
                                        >
                                        </b-select>
                                      </div>
                                    </div>
                                    <hr />

                                    <div
                                      class="row col-12"
                                      v-for="(sess, sessind) in sessions"
                                      :key="sessind"
                                    >
                                      <div class="col-12 px-0 mt-3">
                                        <div class="form-group">
                                          <label for="textlistEditor"
                                            >Enter Session</label
                                          >
                                          <input
                                            class="form-control"
                                            type="text"
                                            v-model="sess.session"
                                          />
                                        </div>
                                        <div class="form-group">
                                          <label for="textlistEditor"
                                            >Select UserRole</label
                                          >
                                          <div class="select-wrapper">
                                            <multiselect
                                              v-model="multiSelvalue"
                                              :options="userRoleSelect"
                                              :multiple="true"
                                              :close-on-select="false"
                                              :clear-on-select="false"
                                              :preserve-search="true"
                                              placeholder="Select User Role"
                                              label="displayName"
                                              track-by="id"
                                              :preselect-first="true"
                                            >
                                            </multiselect>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-12 px-0 mt-2">
                                        <div id="accordion">
                                          <div
                                            v-for="(
                                              cat, catind
                                            ) in sess.category"
                                            :key="catind"
                                          >
                                            <div class="card mb-2">
                                              <a
                                                class="card-link cardLinkTitle"
                                                data-toggle="collapse"
                                                :href="'#collapsecat-' + catind"
                                              >
                                                <div
                                                  class="card-header categoryRow text-white"
                                                  style="border-radius: 0"
                                                >
                                                  Category {{ catind + 1 }}

                                                  <span
                                                    class="accordionIcon float-right"
                                                  >
                                                    <i
                                                      class="fa fa-chevron-down"
                                                      aria-hidden="true"
                                                    ></i>
                                                  </span>
                                                </div>
                                              </a>
                                              <div
                                                :id="'collapsecat-' + catind"
                                                class="collapse"
                                                data-parent="#accordion"
                                              >
                                                <div class="col-12">
                                                  <div class="col-12 mt-3 px-0">
                                                    <div class="form-group">
                                                      <label
                                                        for="textlistEditor"
                                                        >Enter Category</label
                                                      >
                                                      <input
                                                        type="text"
                                                        class="form-control"
                                                        v-model="cat.category"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <hr class="my-3 mx-2" />
                                                <div
                                                  class="row col-12 resourceDiv px-0 mx-0"
                                                  v-for="(
                                                    res, resind
                                                  ) in cat.resourse"
                                                  :key="resind"
                                                  style="border-radius: 0"
                                                >
                                                  <a
                                                    class="btn btn-block card-header resourceRow text-left mx-0 text-white"
                                                    data-toggle="collapse"
                                                    :href="`#res${resind}`"
                                                    style="border-radius: 0"
                                                    >Resources
                                                    <span
                                                      class="accordionIcon float-right"
                                                    >
                                                      <i
                                                        class="fa fa-chevron-down"
                                                        aria-hidden="true"
                                                      ></i> </span
                                                  ></a>

                                                  <div
                                                    :id="`res${resind}`"
                                                    class="collapse card border"
                                                    style="
                                                      border-top: 0px !important;
                                                      margin-top: -5px;
                                                      border-radius: 0px 0px
                                                        10px 10px;
                                                    "
                                                  >
                                                    <div class="row mx-0 mt-3">
                                                      <div class="col-12">
                                                        <div class="form-group">
                                                          <label
                                                            for="textlistEditor"
                                                            >Resource Header</label
                                                          >
                                                          <input
                                                            type="text"
                                                            class="form-control"
                                                            v-model="res.header"
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div class="row mx-0">
                                                      <div class="col-12">
                                                        <div class="form-group">
                                                          <label
                                                            for="textlistEditor"
                                                            >Add
                                                            Description</label
                                                          >
                                                          <vue-editor
                                                            v-model="
                                                              res.content
                                                            "
                                                          ></vue-editor>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div class="row mx-0">
                                                      <div class="col-12">
                                                        <div class="form-group">
                                                          <label
                                                            for="resourceType"
                                                            >Resource Type</label
                                                          >
                                                          <b-form-select
                                                            v-model="res.resourceType"
                                                            :options="resourceTypeOptions"
                                                            size="sm"
                                                          ></b-form-select>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <hr/>
                                                    <div class="row mx-0 mt-3" v-if="res.resourceType === 'link'">
                                                      <div class="col-12">
                                                        <div class="form-group">
                                                          <label
                                                            for="textlistEditor"
                                                            >Resource Link</label
                                                          >
                                                          <input
                                                            type="text"
                                                            class="form-control"
                                                            v-model="
                                                              res.downloadLink
                                                            "
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <template v-if="res.resourceType === 'file'">
                                                      <div class="row mx-0 mt-3">
                                                        <div class="col-12">
                                                          <div class="form-group">
                                                            <label
                                                              for="exampleFormControlFile1"
                                                              >Upload File</label
                                                            >
                                                            <div
                                                              class="input-group mb-3"
                                                            >
                                                              <div
                                                                class="custom-file"
                                                              >
                                                                <input
                                                                  type="file"
                                                                  class="custom-file-input"
                                                                  name="resourcefile"
                                                                  id="resourcefile"
                                                                  placeholder="Choose a file or drop it here..."
                                                                  drop-placeholder="Drop file here..."
                                                                  @change="
                                                                    onUpload
                                                                  "
                                                                  :disabled="
                                                                    res.downloadLink !=
                                                                    ''
                                                                  "
                                                                />
                                                                <label
                                                                  class="custom-file-label"
                                                                  for="resourcefile"
                                                                  v-if="
                                                                    uploadedFile !=
                                                                    ''
                                                                  "
                                                                  >{{
                                                                    uploadedFile
                                                                  }}</label
                                                                >
                                                                <label
                                                                  class="custom-file-label"
                                                                  for="resourcefile"
                                                                  v-if="
                                                                    uploadedFile ==
                                                                    ''
                                                                  "
                                                                  >Choose
                                                                  file</label
                                                                >
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div class="row mx-0">
                                                        <div class="col-6">
                                                          <div
                                                            class="img-preview"
                                                          >
                                                            <a
                                                              :href="
                                                                res.downloadLink
                                                              "
                                                              class="btn btn-sm"
                                                              :class="
                                                                res.downloadLink
                                                                  ? 'addUpdatebtnclass text-white'
                                                                  : 'bg-grey'
                                                              "
                                                              style="
                                                                color: #505050;
                                                                font-size: 1em;
                                                              "
                                                              >Download
                                                              Uploaded File
                                                            </a>
                                                          </div>
                                                        </div>
                                                        <div class="col-6 text-right">
                                                          <VueLoadingButton
                                                            class="btn addUpdatebtnclass text-white btn-sm button"
                                                            @click.native="
                                                              saveFile(
                                                                sessind,
                                                                catind,
                                                                resind
                                                              )
                                                            "
                                                            :styled="isStyled"
                                                            :loading="
                                                              isLoading
                                                            "
                                                            >Save File
                                                          </VueLoadingButton>
                                                        </div>
                                                      </div>
                                                    </template>
                                                    <template  v-if="res.resourceType === 'questions'">
                                                      <div class="questWrap mt-3">
                                                        <div class="row mx-0">
                                                          <div class="col-12">
                                                            <div class="form-group">
                                                              <label
                                                                for="passingCriteria"
                                                                >Passing Criteria (%)</label
                                                              >
                                                              <input
                                                                type="number"
                                                                class="form-control"
                                                                v-model="res.passingCriteria"
                                                                min="1"
                                                                max="100"
                                                              />
                                                            </div>
                                                          </div>
                                                          <div class="col-12">
                                                            <div class="tablePreview">
                                                              <label
                                                                for="questionTable"
                                                                >Questions Set</label
                                                              >
                                                              <table class="table table-bordered">
                                                                <thead>
                                                                  <tr>
                                                                    <th>
                                                                    Question
                                                                    </th>
                                                                    <th>
                                                                      Actions
                                                                    </th>
                                                                  </tr>
                                                                </thead>
                                                                <tbody>
                                                                  <template v-if="res.questions.length > 0">
                                                                    <tr :key="val" v-for="(key,val) in res.questions">
                                                                      <td>{{key.question}}</td>
                                                                      <td>
                                                                        <div
                                                                          class="iconGroup d-flex"
                                                                        >
                                                                          <i
                                                                           v-if="key.questionId"
                                                                            class="fa fa-pencil-square-o"
                                                                            aria-hidden="true"
                                                                            v-b-tooltip.hover
                                                                            title="Edit"
                                                                             @click.prevent.stop="
                                                                              editQuestionpre(val,res)
                                                                            "
                                                                          ></i>
                                                                          <i
                                                                            class="fa fa-trash"
                                                                            aria-hidden="true"
                                                                            v-b-tooltip.hover
                                                                             @click.prevent.stop="
                                                                              deleteQuestionpre(val,res)
                                                                            "
                                                                            title="Delete"
                                                                          ></i>
                                                                        </div>
                                                                      </td>
                                                                    </tr>
                                                                  </template>
                                                                  <template v-else>
                                                                    <tr>
                                                                      <td colspan="2" class="text-center">No Questions Found!</td>
                                                                    </tr>
                                                                  </template>
                                                                </tbody>
                                                              </table>
                                                            </div>
                                                          </div>
                                                          <div class="col-12 text-right">
                                                            <button
                                                              class="btn btn-sm btn-primary addQ"
                                                              @click.prevent.stop="addNewQuestion"
                                                            >
                                                              Add Question
                                                            </button>
                                                          </div>
                                                        </div>
                                                        <div
                                                          class="addQuestSection"
                                                          v-if="addNewQues"
                                                        >
                                                          <div class="row mx-0">
                                                            <div class="col-12">
                                                              <h5
                                                                class="mainTextHead bg-light-grey color-grey alert mt-3"
                                                              >
                                                                Question
                                                              </h5>
                                                            </div>
                                                          </div>
                                                          <div class="row mx-0">
                                                            <div class="col-12">
                                                              <div
                                                                class="form-check-inline"
                                                              >
                                                                <label
                                                                  class="form-check-label"
                                                                >
                                                                  <input
                                                                    type="radio"
                                                                    class="form-check-input"
                                                                    name="optradio"
                                                                     v-model="preQuestions.questionType"
                                                                  />Single Answer
                                                                </label>
                                                              </div>
                                                              <div
                                                                class="form-check-inline"
                                                              >
                                                                <label
                                                                  class="form-check-label"
                                                                >
                                                                  <input
                                                                    type="radio"
                                                                    class="form-check-input"
                                                                    name="optradio"
                                                                    checked
                                                                    v-model="preQuestions.questionType"
                                                                  />Multiple
                                                                  Choice Answer
                                                                </label>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div class="row mx-0 mt-3">
                                                            <div class="col-12">
                                                              <div
                                                                class="form-group"
                                                              >
                                                                <label for=""
                                                                  >Question
                                                                  Text</label
                                                                >
                                                                <input
                                                                  type="text"
                                                                  class="form-control"
                                                                  v-model="preQuestions.question"
                                                                />
                                                              </div>
                                                            </div>
                                                            <div class="col-12">
                                                              <div
                                                                class="form-group row mx-0"
                                                              >
                                                                <label
                                                                  for="inputPassword"
                                                                  class="col-sm-4 col-form-label"
                                                                  >Select
                                                                  Image</label
                                                                >
                                                                <div
                                                                  class="col-sm-8"
                                                                >
                                                                  <input
                                                                    type="file"
                                                                    class="form-control-file p-t-4px"
                                                                    style="
                                                                      font-size: 15px;
                                                                    "
                                                                    name="uploadImageContent"
                                                                    accept="image/*"
                                                                    @change="onpreQuestionImgChange"
                                                                  />
                                                                  <label
                                                                    class="custom-file-label"
                                                                    for="uploadImageContent"
                                                                    >Choose
                                                                    file</label
                                                                  >
                                                                  <div
                                                                    class="img-preview"
                                                                  >
                                                                    <img
                                                                       v-if="
                                                                          preQuestions.uploadImageContent
                                                                          "
                                                                          :src="
                                                                          preQuestions.uploadImageContent
                                                                          "
                                                                    />
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div class="col-12">
                                                              <h5
                                                                class="mainTextHead alert alert-info mt-3"
                                                              >
                                                                Options
                                                              </h5>
                                                            </div>
                                                            <div class="col-12">
                                                              <div
                                                                class="choiceSettingSection"
                                                              >
                                                                <div
                                                                  id="accordion"
                                                                >
                                                                  <div
                                                                  v-for="(
                                                                  opt, optind
                                                                  ) in prequestionOptions"
                                                                  :key="optind"
                                                                  >
                                                                    <div
                                                                      class="card mb-2"
                                                                    >
                                                                      <div
                                                                        class="card-header"
                                                                      >
                                                                        <a
                                                                          class="card-link"
                                                                          data-toggle="collapse"
                                                                          :href="
                                                                          '#collapse-' + optind
                                                                          "
                                                                        >
                                                                          Option {{ optind + 1 }}
                                                                        </a>
                                                                        <a
                                                                        v-if="optind != 0"
                                                                          class="float-right cursor-pointer"
                                                                          @click.prevent.stop="
                                                                          deletepreOpt(optind)
                                                                          "
                                                                          ><i
                                                                            class="fa fa-trash"
                                                                            aria-hidden="true"
                                                                          ></i
                                                                        ></a>
                                                                      </div>
                                                                      <div
                                                                        :id="'collapse-' + optind"
                                                                        class="collapse show"
                                                                        data-parent="#accordion"
                                                                        style="display:contents"
                                                                      >
                                                                        <div
                                                                          class="card-body"
                                                                        >
                                                                          <div
                                                                            class="row"
                                                                          >
                                                                            <div
                                                                              class="col-4"
                                                                            >
                                                                              <label
                                                                                >Option</label
                                                                              >
                                                                              <div
                                                                                class="form-check"
                                                                              >
                                                                                <label
                                                                                  class="form-check-label"
                                                                                >
                                                                                  <input
                                                                                    type="radio"
                                                                                    class="form-check-input"
                                                                                    name="correctAnswer"
                                                                                    :checked="
                                                                                      opt.correctAnswer
                                                                                  "
                                                                                  @change="
                                                                                      onChangeRadiopre(
                                                                                      $event,
                                                                                      optind
                                                                                      )
                                                                                  "
                                                                                  />Correct
                                                                                  Option
                                                                                </label>
                                                                              </div>
                                                                            </div>
                                                                            <div
                                                                              class="col-8"
                                                                            >
                                                                              <label
                                                                                for=""
                                                                                >Option
                                                                                Label</label
                                                                              >
                                                                              <vue-editor
                                                                              v-model="opt.option"
                                                                              ></vue-editor>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div class="col-12">
                                                              <div class="">
                                                                <button class="btn addUpdatebtnclass btn-sm text-white" @click.prevent.stop="addpreQOpt()">
                                                                  <i class="fa fa-plus" aria-hidden="true" ></i>
                                                                  Add New Option
                                                                </button>
                                                                <button
                                                                  class="btn btn-sm resetbtnclass text-white float-right"
                                                                  @click.prevent.stop="resetQpre()"
                                                                >
                                                                  Cancel
                                                                </button>
                                                                <button
                                                                v-if="editqpre"
                                                                  class="btn btn-sm addUpdatebtnclass updateQ text-white float-right"
                                                                  @click.prevent.stop="addQuestionpre(res)"
                                                                >
                                                                  Update
                                                                </button>
                                                                <button
                                                                v-if="saveqpre"
                                                                  class="btn btn-sm btn-primary addQ float-right"
                                                                  @click.prevent.stop="
                                                                  saveNewQuestionpre(res)
                                                                  "
                                                                >
                                                                  Save
                                                                </button>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </template>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row col-12">
                                    <div class="float-right">
                                      <button
                                        class="btn resetbtnclass btn-sm text-white"
                                        type="reset"
                                      >
                                        <i
                                          class="fa fa-refresh"
                                          aria-hidden="true"
                                        ></i>
                                        Reset
                                      </button>
                                    </div>
                                    <div class="float-right">
                                      <button
                                        class="btn addUpdatebtnclass btn-sm text-white"
                                        @click.prevent.stop="addSess()"
                                      >
                                        <i
                                          class="fa fa-plus"
                                          aria-hidden="true"
                                        ></i>
                                        Save / Update
                                      </button>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div
                              class="savebtnDiv float-right my-4"
                              @click="onComplete()"
                            >
                              <button class="btn btn-primary btn-sm">
                                Save & Continue
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="trainingAgenda"
                        role="tabpanel"
                        aria-labelledby="trainingAgendaTab"
                      >
                        <div class="row">
                          <div class="col-12">
                            <div
                              class="card bg-white mt-3"
                              style="border-radius: 0px"
                            >
                              <div class="card-body">
                                <form>
                                  <div class="section_1">
                                    <div class="row">
                                      <div class="col-12">
                                        <div class="form-group">
                                          <label for="headerText"
                                            >Select Agenda Type</label
                                          >
                                          <fieldset id="group1">
                                            <input
                                              v-model="agenda.agendaType"
                                              type="radio"
                                              value="standard"
                                              name="group1"
                                            />
                                            Standard Agenda
                                            <input
                                              v-model="agenda.agendaType"
                                              type="radio"
                                              value="create"
                                              name="group1"
                                            />
                                            Create your own agenda
                                          </fieldset>
                                        </div>
                                      </div>
                                      <template
                                        v-if="agenda.agendaType == 'standard'"
                                      >
                                        <div class="col-12">
                                          <div class="form-group">
                                            <label for="exampleFormControlFile1"
                                              >Upload File</label
                                            >
                                            <div class="input-group mb-3">
                                              <div class="custom-file">
                                                <input
                                                  type="file"
                                                  class="custom-file-input"
                                                  name="agendafile"
                                                  id="agendafile"
                                                  placeholder="Choose a file or drop it here..."
                                                  drop-placeholder="Drop file here..."
                                                  @change="agendaUpload"
                                                />
                                                <label
                                                  class="custom-file-label"
                                                  for="agendafile"
                                                  v-if="agendaFile != ''"
                                                  >{{ agendaFile }}</label
                                                >
                                                <label
                                                  class="custom-file-label"
                                                  for="agendafile"
                                                  v-if="agendaFile == ''"
                                                  >Choose file</label
                                                >
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-12">
                                          <div class="row">
                                            <div class="col-6">
                                              <VueLoadingButton
                                                class="btn addUpdatebtnclass text-white btn-sm mx-2 float-right button"
                                                @click.native="saveAgendaFile()"
                                                :styled="isStyled"
                                                :loading="isLoading"
                                                >Save File
                                              </VueLoadingButton>
                                            </div>
                                          </div>
                                        </div>
                                      </template>
                                      <template v-else>
                                        <div class="col-12">
                                          <div class="form-group">
                                            <label for="headerText"
                                              >Create Task</label
                                            >
                                            <vue-editor
                                              v-model="agenda.agenda"
                                            ></vue-editor>
                                          </div>
                                        </div>
                                        <div class="col-6">
                                          <div class="form-group">
                                            <label for="headerText"
                                              >Module</label
                                            >
                                            <input
                                              class="form-control"
                                              v-model="agenda.module"
                                            />
                                          </div>
                                        </div>
                                        <div class="col-6">
                                          <div class="form-group">
                                            <label for="headerText"
                                              >Facilitator</label
                                            >
                                            <input
                                              class="form-control"
                                              v-model="agenda.facilatior"
                                            />
                                          </div>
                                        </div>
                                        <div class="col-6">
                                          <div class="form-group">
                                            <label for="headerText"
                                              >Type of session</label
                                            >
                                            <div class="select-wrappe">
                                              <b-select
                                                :options="sessionList"
                                                v-model="agenda.typeOfSession"
                                              >
                                              </b-select>

                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-6">
                                          <div class="form-group">
                                            <label for="headerText"
                                              >Recommended duration</label
                                            >
                                            <input
                                              class="form-control"
                                              type="time"
                                              v-model="
                                                agenda.recommendedDuration
                                              "
                                            />
                                          </div>
                                        </div>
                                        <div class="col-6">
                                          <div class="form-group">
                                            <label for="headerText"
                                              >Goals</label
                                            >

                                            <vue-editor
                                              v-model="agenda.goals"
                                            ></vue-editor>
                                          </div>
                                        </div>
                                        <div class="col-6">
                                          <div class="form-group">
                                            <label for="headerText"
                                              >Requirements</label
                                            >

                                            <vue-editor
                                              v-model="agenda.requirements"
                                            ></vue-editor>
                                          </div>
                                        </div>
                                      </template>
                                    </div>
                                    <div
                                      class="row"
                                      v-if="agenda.agendaType != 'standard'"
                                    >
                                      <div class="col-12">
                                        <div class="addstepsbtn float-right">
                                          <button
                                            class="btn addUpdatebtnclass btn-sm text-white"
                                            @click.prevent.stop="addAgenda()"
                                          >
                                            Create / Update Task
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <hr />
                                  <div
                                    class="row"
                                    v-if="agenda.agendaType != 'standard'"
                                  >
                                    <div class="col-12">
                                      <h5
                                        class="mainTextHead bg-light-grey color-grey alert mt-4"
                                      >
                                        Task
                                      </h5>
                                      <div class="tablePreview">
                                        <table class="table table-bordered">
                                          <thead>
                                            <tr>
                                              <th>#</th>
                                              <th>Facilitator</th>
                                              <th>
                                                Develop your agenda using the
                                                following resources
                                              </th>
                                              <th>Goals</th>
                                              <th>Recommended duration</th>
                                              <th>Module</th>
                                              <th>Requirements</th>
                                              <th>Actions</th>
                                            </tr>
                                          </thead>
                                          <tbody
                                            v-if="frmModel.agenda.length > 0"
                                          >
                                            <tr
                                              v-for="(
                                                agendaVal, i
                                              ) in frmModel.agenda"
                                              :key="i"
                                            >
                                              <td>{{ i + 1 }}</td>
                                              <td v-html="agendaVal.facilatior">

                                              </td>
                                              <td v-html="agendaVal.agenda">

                                              </td>
                                              <td v-html="agendaVal.goals">

                                              </td>
                                              <td>
                                                {{
                                                  agendaVal.recommendedDuration
                                                }}
                                              </td>
                                              <td>{{ agendaVal.module }}</td>
                                              <td
                                                v-html="agendaVal.requirements"
                                              >

                                              </td>
                                              <td>
                                                <div class="iconGroup">
                                                  <i
                                                    v-if="
                                                      agendaVal.masterAgendaId
                                                    "
                                                    class="fa fa-pencil-square-o"
                                                    aria-hidden="true"
                                                    v-b-tooltip.hover
                                                    title="Edit"
                                                    @click.prevent.stop="
                                                      editAgenda(
                                                        agendaVal.masterAgendaId
                                                      )
                                                    "
                                                  ></i>
                                                  <i
                                                    class="fa fa-trash"
                                                    aria-hidden="true"
                                                    v-b-tooltip.hover
                                                    title="Delete"
                                                    @click.prevent.stop="
                                                      deleteAgenda(i)
                                                    "
                                                  ></i>


                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div
                              class="savebtnDiv float-right my-4"
                              @click="onComplete()"
                            >
                              <button class="btn btn-primary btn-sm">
                                Save & Continue
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="trainingFAQ"
                    role="tabpanel"
                    aria-labelledby="trainingFAQTab"
                  >
                    <div class="row">
                      <div class="col-12">
                        <div
                          class="card bg-white mt-3"
                          style="border-radius: 0px"
                        >
                          <div class="card-body">
                            Frequently Asked Questions content
                            <vue-editor
                              v-model="frmModel.faqContent"
                            ></vue-editor>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="savebtnDiv float-right my-4"
                      @click="onComplete()"
                    >
                      <button class="btn btn-primary btn-sm">
                        Save & Continue
                      </button>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="v-pills-profile"
                    role="tabpanel"
                    aria-labelledby="v-pills-profile-tab"
                  >
                    <div class="examWrap my-2">
                      <div class="row">
                        <div class="col-12">
                          <div
                            class="card bg-white mt-3"
                            style="border-radius: 0px"
                          >
                            <div class="card-body">
                              <form>
                                <div class="row">
                                  <div class="col-12">
                                    <h5
                                      class="mainTextHead bg-light-grey color-grey alert mt-3"
                                    >
                                      Exam Rules
                                    </h5>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-12">
                                    <div class="form-group">
                                      <label for="textlistEditor"
                                        >Edit Exam Rules</label
                                      >
                                      <vue-editor
                                        v-model="course.examRules"
                                      ></vue-editor>
                                    </div>
                                  </div>
                                </div>
                                <div class="examSettingSection">
                                  <div class="row">
                                    <div class="col-12">
                                      <h5
                                        class="mainTextHead bg-light-grey color-grey alert mt-3"
                                      >
                                        Exam Settings
                                      </h5>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-6">
                                      <div class="form-group">
                                        <label for="">Pass Criteria</label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="course.passingCriteria"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-6">
                                      <div class="form-group">
                                        <label for="">No. of questions</label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          value="20"
                                          v-model="course.numberOfQuestions"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-6">
                                      <div class="form-group">
                                        <label for="">Time duration</label>
                                        <input
                                          type="time"
                                          class="form-control"
                                          value=""
                                          v-model="course.timeDuration"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-6">
                                      <div class="form-group">
                                        <label for="">Exam Retake</label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          value="3"
                                          v-model="course.examRetake"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-6">
                                      <div class="form-group">
                                        <div class="form-check-inline">
                                          <label class="form-check-label">
                                            <input
                                              type="checkbox"
                                              class="form-check-input"
                                              value=""
                                              v-model="course.shuffle"
                                            />
                                            Shuffle Questions
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="rangeSettingSection">
                                  <div class="row">
                                    <div class="col-12">
                                      <h5
                                        class="mainTextHead bg-light-grey color-grey alert mt-3"
                                      >
                                        Range Settings
                                      </h5>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-6">
                                      <div class="form-group">
                                        <label for="">Excellent</label>
                                        <div class="d-flex">
                                          <input
                                            type="text"
                                            class="form-control w-50"
                                            v-model="course.excellentFrom"
                                          />
                                          <label class="mt-2 mx-2"> To </label>
                                          <input
                                            type="text"
                                            class="form-control w-50"
                                            v-model="course.excellentTo"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-6">
                                      <div class="form-group">
                                        <label for="">Good</label>
                                        <div class="d-flex">
                                          <input
                                            type="text"
                                            class="form-control w-50"
                                            v-model="course.goodFrom"
                                          />
                                          <label class="mt-2 mx-2"> To </label>
                                          <input
                                            type="text"
                                            class="form-control w-50"
                                            v-model="course.goodTo"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-6">
                                      <div class="form-group">
                                        <label for="">Fair</label>
                                        <div class="d-flex">
                                          <input
                                            type="text"
                                            class="form-control w-50"
                                            v-model="course.fairFrom"
                                          />
                                          <label class="mt-2 mx-2"> To </label>
                                          <input
                                            type="text"
                                            class="form-control w-50"
                                            v-model="course.fairTo"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-6">
                                      <div class="form-group">
                                        <label for="">Poor</label>
                                        <div class="d-flex">
                                          <input
                                            type="text"
                                            class="form-control w-50"
                                            v-model="course.poorFrom"
                                          />
                                          <label class="mt-2 mx-2"> To </label>
                                          <input
                                            type="text"
                                            class="form-control w-50"
                                            v-model="course.poorTo"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="securitySettingSection hide">
                                  <div class="row">
                                    <div class="col-12">
                                      <h5
                                        class="mainTextHead bg-light-grey color-grey alert mt-3"
                                      >
                                        Security Settings
                                      </h5>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-6">
                                      <div class="form-group">
                                        <div class="form-check-inline">
                                          <label class="form-check-label">
                                            <input
                                              type="checkbox"
                                              class="form-check-input"
                                              value=""
                                              v-model="course.browserChange"
                                            />
                                            Browser Change
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-6">
                                      <div class="form-group">
                                        <div class="form-check-inline">
                                          <label class="form-check-label">
                                            <input
                                              type="checkbox"
                                              class="form-check-input"
                                              value=""
                                              v-model="course.windowClosed"
                                            />
                                            Window Close
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="questWrap">
                                  <div class="row">
                                    <div class="col-12">
                                      <h5
                                        class="mainTextHead bg-light-grey color-grey alert mt-4"
                                      >
                                        Added Question Preview
                                      </h5>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-12">
                                      <div class="float-right">
                                        <a
                                          class="btn btn-block btn-light btn-sm mx-2 text-left"
                                          data-toggle="collapse"
                                          href="#collapseQuestAdd"
                                          aria-expanded="true"
                                          aria-controls="collapseQuestAdd"
                                          >Question
                                          <span
                                            class="accordionIcon float-right"
                                          >
                                            <i
                                              class="fa fa-chevron-down"
                                              aria-hidden="true"
                                            ></i> </span
                                        ></a>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="collapse addQuestSection show"
                                    id="collapseQuestAdd"
                                  >
                                    <div class="row">
                                      <div class="col-12">
                                        <h5
                                          class="mainTextHead bg-light-grey color-grey alert mt-3"
                                        >
                                          Add Question
                                        </h5>
                                      </div>
                                    </div>
                                    <div class="row">
                                      <div class="col-12">
                                        <div class="form-check-inline">
                                          <label class="form-check-label">
                                            <input
                                              type="radio"
                                              class="form-check-input"
                                              name="optradio"
                                              v-model="questions.questionType"
                                            />Single Answer
                                          </label>
                                        </div>
                                        <div class="form-check-inline">
                                          <label class="form-check-label">
                                            <input
                                              type="radio"
                                              class="form-check-input"
                                              name="optradio"
                                              checked
                                              v-model="questions.questionType"
                                            />Multiple Choice Answer
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <hr />
                                    <div class="row">
                                      <div class="col-12">
                                        <div class="form-group">
                                          <label for="">Question Text</label>
                                          <input
                                            type="text"
                                            class="form-control"
                                            v-model="questions.question"
                                          />
                                        </div>
                                      </div>
                                      <div class="col-12">
                                        <div class="form-group">
                                          <label for="">Question Set</label>
                                          <input
                                            type="text"
                                            class="form-control"
                                            v-model="questions.questionSet"
                                          />
                                        </div>
                                      </div>
                                      <div class="col-12">

                                        <div class="form-group row">
                                          <label
                                            for="inputPassword"
                                            class="col-sm-4 col-form-label"
                                            >Select Image</label
                                          >
                                          <div class="col-sm-8">
                                            <input
                                              type="file"
                                              class="form-control-file p-t-4px"
                                              style="font-size: 15px"
                                              name="uploadImageContent"
                                              accept="image/*"
                                              @change="onQuestionImgChange"
                                            />
                                            <label
                                              class="custom-file-label"
                                              for="uploadImageContent"
                                              >Choose file</label
                                            >
                                            <div class="img-preview">
                                              <img
                                                v-if="
                                                  questions.uploadImageContent
                                                "
                                                :src="
                                                  questions.uploadImageContent
                                                "
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-12">
                                        <h5
                                          class="mainTextHead alert alert-info mt-3"
                                        >
                                          Options
                                        </h5>
                                      </div>
                                      <div class="col-12">
                                        <div class="choiceSettingSection">
                                          <div id="accordion">
                                            <div
                                              v-for="(
                                                opt, optind
                                              ) in questionOptions"
                                              :key="optind"
                                            >
                                              <div class="card mb-2">
                                                <div class="card-header">
                                                  <a
                                                    class="card-link"
                                                    data-toggle="collapse"
                                                    :href="
                                                      '#collapse-' + optind
                                                    "
                                                  >
                                                    Option{{ optind + 1 }}
                                                  </a>
                                                  <a
                                                    v-if="optind != 0"
                                                    class="float-right"
                                                    @click.prevent.stop="
                                                      deleteOpt(optind)
                                                    "
                                                    ><i
                                                      class="fa fa-trash"
                                                      aria-hidden="true"
                                                    ></i
                                                  ></a>
                                                </div>
                                                <div
                                                  :id="'collapse-' + optind"
                                                  class="collapse show"
                                                  data-parent="#accordion"
                                                >
                                                  <div class="card-body">
                                                    <div class="row">
                                                      <div class="col-4">
                                                        <label>Option</label>
                                                        <div class="form-check">
                                                          <label
                                                            class="form-check-label"
                                                          >
                                                            <input
                                                              type="radio"
                                                              class="form-check-input"
                                                              name="correctAnswer"
                                                              :checked="
                                                                opt.correctAnswer
                                                              "
                                                              @change="
                                                                onChangeRadio(
                                                                  $event,
                                                                  optind
                                                                )
                                                              "
                                                            />Correct Option
                                                          </label>
                                                        </div>
                                                      </div>
                                                      <div class="col-8">
                                                        <label for=""
                                                          >Option Label</label
                                                        >
                                                        <vue-editor
                                                          v-model="opt.option"
                                                        ></vue-editor>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                class="row col-12"
                                                v-if="optind == 0"
                                              >
                                                <div class="float-right mt-3">
                                                  <button
                                                    class="btn addUpdatebtnclass btn-sm mt-2 text-white"
                                                    @click.prevent.stop="
                                                      addQOpt()
                                                    "
                                                  >
                                                    <i
                                                      class="fa fa-plus"
                                                      aria-hidden="true"
                                                    ></i>
                                                    Add New Option
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <hr />
                                    <div class="row">
                                      <div class="col-12">
                                        <div class="float-right mt-3">
                                          <button
                                            v-if="editq"
                                            class="btn btn-sm addUpdatebtnclass updateQ text-white"
                                            @click.prevent.stop="addQuestion()"
                                          >
                                            Update
                                          </button>
                                          <button
                                            v-if="saveq"
                                            class="btn btn-sm btn-primary addQ"
                                            @click.prevent.stop="
                                              saveNewQuestion()
                                            "
                                          >
                                            Save
                                          </button>
                                          <button
                                            class="btn btn-sm resetbtnclass text-white"
                                            @click.prevent.stop="resetQ()"
                                          >
                                            Reset
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row mt-4">
                                    <div class="col-12">
                                      <div class="tablePreview">
                                        <table class="table table-bordered">
                                          <thead>
                                            <tr>
                                              <th>#</th>
                                              <th>Question</th>
                                              <th>Question Set</th>
                                              <th>Question Type</th>
                                              <th>Actions</th>
                                            </tr>
                                          </thead>
                                          <tbody
                                            v-if="frmModel.course.length > 0"
                                          >
                                            <tr
                                              v-for="(cr, crind) in frmModel
                                                .course[0].questions"
                                              :key="crind"
                                            >
                                              <td>{{ crind + 1 }}</td>
                                              <td>{{ cr.question }}</td>
                                              <td>{{ cr.questionSet }}</td>
                                              <td>{{ cr.questionType }}</td>
                                              <td>
                                                <div class="iconGroup d-flex">
                                                  <i
                                                    v-if="cr.questionId"
                                                    class="fa fa-pencil-square-o"
                                                    aria-hidden="true"
                                                    v-b-tooltip.hover
                                                    title="Edit"
                                                    @click.prevent.stop="
                                                      editQuestion(crind)
                                                    "
                                                  ></i>
                                                  <i
                                                    class="fa fa-trash"
                                                    aria-hidden="true"
                                                    v-b-tooltip.hover
                                                    @click.prevent.stop="
                                                      deleteQuestion(crind)
                                                    "
                                                    title="Delete"
                                                  ></i>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                          <div
                            class="savebtnDiv float-right my-4"
                            @click="onComplete()"
                          >
                            <button class="btn btn-primary btn-sm">
                              Save & Continue
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              </div>

            </div>
          </tab-content>
        </form-wizard>
      </ValidationObserver>
      <modals-container />
    </div>
  </div>
</template>
<script>
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import ApiServices from "../service/ApiServices";
import swal from "sweetalert2";
import { VueEditor } from "vue2-editor";
import VueLoadingButton from "vue-loading-button";
import Multiselect from "vue-multiselect";
//import { VueEditor, Quill } from "vue2-editor";
export default {
  components: {
    VueEditor,
    VueLoadingButton,
    Multiselect,
  },
  data() {
    return {
      userRoleSelect: [],
      multiSelvalue: [],
      isLoading: false,
      isStyled: false,
      uploadedFile: "",
      agendaFile: "",
      editq: false,
      saveq: true,
      editqpre: false,
      saveqpre: true,
      isvueeditor: false,
      isText: true,
      file1: null,
      editQuestionIndex: null,
      editQuestionIndexpre:null,
      resourcePhotoImg: "",
      dashboardUrl: "api/apps/Avenir-Generic/index.html#/",
      opened: [],
      openedCat: [],
      sessionNew: "",
      sessionNew1: "",
      catName1: "",
      stepoptions: [
        {
          value: "",
          text: "Please Select",
        },
        {
          value: "Resources",
          text: "Resources",
        },
        {
          value: "Agenda",
          text: "Agenda",
        },
      ],
      configObj: {
        landingLogo: "",
        landingHeading: "",
        dashName: "",
        dashUrl: "",
        landingBgImg: "",
        contentHeading: "Welcome to e-learning course on Family Planning",
        contentDescription:
          "Discover training and resources to support high-quality family planning services",
        socialfacebook: "",
        socialinsta: "",
        socialtwitter: "",
        socialyoutube: "",
        layout: "1",
        color: "#212832",
      },
      associateTrainingOptions: [
        {
          value: "true",
          text: "Yes",
        },
        {
          value: "false",
          text: "No",
        },
      ],
      sessions: [
        {
          session: "",
          sessionId: "",
          category: [
            {
              category: "",
              categoryId: "",
              resourse: [
                {
                  photoBlob: "",
                  header: "",
                  content: "",
                  downloadLink: "",
                  downloadFileName: "",
                  resourseId: "",
                  photoContentLength: "",
                  photoContentType: "",
                  questions:[],
                  resourceType: "link",
                  passingCriteria: "50"
                },
              ],
            },
          ],
        },
      ],
      resourceTypeOptions: [
        {
          value: "link",
          text: "Link",
        },
        {
          value: "file",
          text: "File",
        },
        {
          value: "questions",
          text: "Questions",
        }
      ],
      addNewQues: false,
      preQuestions:{
        question:"",
        questionType:null,
        questionSet: "prequestion",
        uploadImageContent: "",
        options: [],
      },
      prequestionOptions:[
        {
          optionId: "",
          option: "",
          correctAnswer: false,
        },
      ],
      agenda: {
        masterAgendaId: "",
        typeOfSession: "",
        agenda: "",
        module: "",
        recommendedDuration: "",
        goals: "",
        topic: null,
        facilatior: "",
        requirements: "",
        colorCode: null,
        downloadLink: null,
        agendaType: "standard",
      },
      course: {
        courseId: "",
        examRules: "",
        passingCriteria: "",
        numberOfQuestions: "",
        timeDuration: "",
        examRetake: "",
        shuffle: true,
        excellentFrom: "",
        excellentTo: "",
        goodFrom: "",
        goodTo: "",
        fairFrom: "",
        fairTo: "",
        poorFrom: "",
        poorTo: "",
        browserChange: "",
        windowClosed: "",
        questions: [],
      },
      questions: {
        questionId: "",
        question: "",
        uploadImageContent: "",
        questionSet: null,
        questionType: null,
        options: [],
      },
      questionOptions: [
        {
          optionId: "",
          option: "",
          correctAnswer: false,
        },
      ],
      resourcefiles: "",
      agendafiles: "",
      frmModel: {
        appName: "",
        description: "",
        language: "English",
        associateTraining: "false",
        introLinkText: "",
        introContent: "",
        introPhotos: [],
        roleHeader: "",
        roleContent: "",
        stepHeader: "",
        steps: [
          {
            stepNumber: null,
            stepContent: "",
            stepType: " ",
          },
        ],
        sessions: [],
        agenda: [],
        course: [],
        faqContent: "",
      },
      date: new Date(),
      years: [],
      settings: settings,
      content: "Some initial content",
    };
  },
  created() {
    ApiServices.theme(this.configObj.color);
  },
  watch: {
    // "catName1" :function (newVal){
    //   this.loadFormData();
    // }
  },
  computed: {
    sessionList: function () {
      let sessArr = this.frmModel.sessions;
      var arr = [];
      var obj = {
        value: "",
        text: "Select Session",
      };
      arr.push(obj);
      //console.log(sessArr)
      sessArr.forEach((sess, ind) => {
        var obj = {
          value: "",
          text: "",
        };
        obj.value = sess.sessionId;
        obj.text = sess.session;
        arr.push(obj);
      });
      return arr;
    },
    catList: function () {
      let sessArr = this.sessions;
      var arr = [];
      sessArr.forEach((sess, ind) => {
        //    if(sess.sessionId == this.sessionNew1){
        //  var obj={value:"",text:"Select Category"};
        //  arr.push(obj);
        sess.category.forEach((cat, ci) => {
          var obj = {
            value: "",
            text: "",
          };
          obj.value = cat.categoryId;
          obj.text = cat.category;
          arr.push(obj);
        });
        //  }
      });
      return arr;
    },
    tableCatList: function () {
      let sessArr = this.frmModel.sessions;
      var arr = [];
      // sessArr.forEach((sess,ind)=>{
      //   if(this.opened.includes(sess.sessionId)){
      //     //var obj={value:"",text:"Select Category"};
      //     //arr.push(obj);
      //     sess.category.forEach((cat, ci) => {
      //       var obj={value:"",text:""};
      //       obj.value=cat.categoryId;
      //       obj.text=cat.category;
      //       arr.push(obj);
      //     });
      //   }
      // })
      sessArr.filter((obj) => {
        if (this.opened.includes(obj.sessionId)) return obj.category;
      });
      return sessArr;
    },
  },
  methods: {
    addNewQuestion() {
      this.addNewQues = true
    },
    onpreQuestionImgChange(event){
    var files = event.target.files[0];
      this.getBase64String(files).then((res1) => {
        this.preQuestions.uploadImageContent = res1;
      });
      console.log(this.preQuestions);
    },
    deletepreOpt(index) {
      console.log(index)
      console.log(this.prequestionOptions[index].optionId);
      ApiServices.RestPostCall("api/avenir/training/deleteOption", {
        optionId: this.prequestionOptions[index].optionId,
      }).then((aveT) => {
        console.log(aveT);
      });
      this.prequestionOptions = this.prequestionOptions.filter((s, i) => i !== index);
    },
    onChangeRadiopre(evt, ind){
     console.log(ind);
      this.prequestionOptions = this.prequestionOptions.map((opt, opti) => {
        if (opti == ind) {
          opt.correctAnswer = true;
        } else {
          opt.correctAnswer = false;
        }
        return opt;
      });
    },
    trackEvent(title) {
      console.log(title)
     this.$gtag.event('tab_view', {
          'value': title
        })
    },
    addpreQOpt(){
    var newoptset = {
        optionId: "",
        option: "",
        correctAnswer: false,
      };
      this.prequestionOptions = this.prequestionOptions.concat(newoptset);
    },
     saveNewQuestionpre(r) {
       console.log(r)
       console.log(this.prequestionOptions)
      this.preQuestions.options =this.prequestionOptions
      r.questions.push(this.preQuestions)

      //this.questions.options = this.questionOptions;
      //this.course.questions = this.course.questions.concat(this.questions);
      //this.frmModel.course[0] = this.course;
      this.preQuestions = {
        questionId: "",
        question: "",
        questionSet: null,
        questionType: null,
        options: [],
      };
      this.prequestionOptions = [
        {
          optionId: "",
          option: "",
          correctAnswer: false,
        },
      ];
      this.addNewQues = false
    },
    addQuestionpre(r) {
      console.log(r)
      this.preQuestions.options = this.prequestionOptions;
      if (r.questions.length > 0) {
        r.questions = r.questions.map((obj, i) => {
          if (this.preQuestions.questionId == obj.questionId) {
            return this.preQuestions;
          } else if (
            this.editQuestionIndexpre != null &&
            i == this.editQuestionIndexpre
          ) {
            return this.preQuestions;
          } else {
            return obj;
          }
        });
      } else {
        r.questions = r.questions.concat(this.preQuestions);
      }
      console.log(r.questions);
      //this.frmModel.course[0] = this.course;
      //console.log(this.course);
      //console.log(this.frmModel.course);
      this.preQuestions = {
        questionId: "",
        question: "",
        questionSet: null,
        questionType: null,
        options: [],
      };
      this.prequestionOptions = [
        {
          optionId: "",
          option: "",
          correctAnswer: false,
        },
      ];
      this.editqpre = false;
      this.saveqpre = true;
      this.addNewQues = false
    },
    resetQpre() {
      this.editqpre = false;
      this.saveqpre = true;
      this.preQuestions = {
        questionId: "",
        question: "",
        questionSet: null,
        questionType: null,
        options: [],
      };
      this.prequestionOptions = [
        {
          optionId: "",
          option: "",
          correctAnswer: false,
        },
      ];
      this.addNewQues = false
    },
     editQuestionpre(ind,r) {
       console.log(ind)
       console.log(r)
      this.editqpre = true;
      this.saveqpre = false;
      this.getQ = r.questions.filter((q, i) => i == ind);
      this.editQuestionIndexpre = ind;
      this.preQuestions = this.getQ[0];
      this.prequestionOptions = this.preQuestions.options;
      if (this.preQuestions.options.length == 0) {
        this.prequestionOptions = [
          {
            optionId: "",
            option: "",
            correctAnswer: false,
          },
        ];
      }
      this.addNewQues = true
    },
    deleteQuestionpre(ind,r) {
      console.log(r.questions[ind].questionId);
      ApiServices.RestPostCall("api/avenir/training/deleteQuestion", {
        questionId: r.questions[ind].questionId,
      }).then((aveT) => {
        console.log(aveT);
      });
       r.questions = r.questions.filter(
        (q, i) => i != ind
      );
    },

    goSearch(sessId) {
      var sessname = this.frmModel.sessions.filter((obj) => {
        return obj.sessionId == sessId;
      });
      if (sessname.length) return sessname[0].session;
      else return "Session not found";
    },
    changeEditor() {
      this.isText = !this.isText;
      console.log(this.isText);
      this.isvueeditor = !this.isvueeditor;
    },
    onQuestionImgChange(event) {
      var files = event.target.files[0];
      this.getBase64String(files).then((res1) => {
        this.questions.uploadImageContent = res1;
      });
      console.log(this.questions);
    },
    onChangeRadio(evt, ind) {
      console.log(ind);
      this.questionOptions = this.questionOptions.map((opt, opti) => {
        if (opti == ind) {
          opt.correctAnswer = true;
        } else {
          opt.correctAnswer = false;
        }
        return opt;
      });
    },
    editQuestion(ind) {
      this.editq = true;
      this.saveq = false;
      this.getQ = this.frmModel.course[0].questions.filter((q, i) => i == ind);
      this.editQuestionIndex = ind;
      this.questions = this.getQ[0];
      this.questionOptions = this.questions.options;
      if (this.questions.options.length == 0) {
        this.questionOptions = [
          {
            optionId: "",
            option: "",
            correctAnswer: false,
          },
        ];
      }
    },
    deleteQuestion(ind) {
      console.log(this.frmModel.course[0].questions[ind].questionId);
      ApiServices.RestPostCall("api/avenir/training/deleteQuestion", {
        questionId: this.frmModel.course[0].questions[ind].questionId,
      }).then((aveT) => {
        console.log(aveT);
      });
      this.frmModel.course[0].questions = this.frmModel.course[0].questions.filter(
        (q, i) => i != ind
      );
    },
    resetQ() {
      this.editq = false;
      this.saveq = true;
      this.questions = {
        questionId: "",
        question: "",
        questionSet: null,
        questionType: null,
        options: [],
      };
      this.questionOptions = [
        {
          optionId: "",
          option: "",
          correctAnswer: false,
        },
      ];
    },
    removeImage(Ind) {
      //console.log(this.frmModel.introPhotos[Ind].photoId)
      ApiServices.RestPostCall("api/avenir/training/deleteIntroPics", {
        photoId: this.frmModel.introPhotos[Ind].photoId,
      }).then((aveT) => {
        console.log(aveT);
      });
      this.frmModel.introPhotos = this.frmModel.introPhotos.filter(
        (img, imgind) => {
          return imgind != Ind;
        }
      );
    },
    addNewSess(sessionId, categoryId = null) {
      if (sessionId != null && categoryId == null) {
        var sess1 = this.frmModel.sessions.map((s, i) => {
          if (i === sessionId) {
            return {
              ...s,
              category: s.category.concat({
                category: "",
                categoryId: "",
                resourse: [
                  {
                    photoBlob: "",
                    header: "",
                    content: "",
                    downloadLink: "",
                    downloadFileName: "",
                    resourseId: "",
                    photoContentLength: "",
                    photoContentType: "",
                    questions:[],
                    resourceType: "link",
                    passingCriteria: "50"
                  },
                ],
              }),
            };
            return s;
          } else {
            return s;
          }
        });
        this.sessions = sess1.filter((obj, i) => {
          return i == sessionId;
        });
      } else {
        var sess1 = this.frmModel.sessions.map((s, i) => {
          if (i == sessionId) {
            s.category = s.category.map((cat, c) => {
              if (c == categoryId) {
                return {
                  ...cat,
                  resourse: cat.resourse.concat({
                    photoBlob: "",
                    header: "",
                    content: "",
                    downloadLink: "",
                    downloadFileName: "",
                    resourseId: "",
                    photoContentLength: "",
                    photoContentType: "",
                    questions:[],
                    resourceType: "link",
                    passingCriteria: "50"
                  }),
                };
                return cat;
              } else {
                return cat;
              }
            });
            return s;
          } else {
            return s;
          }
        });
        this.sessions = sess1.filter((sess, i) => {
          return i == sessionId;
          // if (i == sessionId) {
          //   return sess.category.filter((cat,c) => {
          //     if (c == categoryId) {
          //       return true;
          //     }
          //   });
          // }
        });
      }
    },
    updatenewSess(sessionId, categoryId) {
      this.sessions = this.frmModel.sessions.filter((sess, si) => {
        if (si == sessionId) {
          this.multiSelvalue = sess.userRoles;
          console.log(sess);
          return sess.category.filter((cat, ci) => {
            if (ci == categoryId) {
              return true;
            } else {
              return true;
            }
          });
        }
      });
      //console.log(this.sessions)
    },
    toggle(id) {
      const index = this.opened.indexOf(id);
      if (index > -1) {
        this.opened.splice(index, 1);
        // removing the index
      } else {
        this.opened.push(id);
        // pushing the index
      }
    },
    toggleCat(id) {
      const index = this.openedCat.indexOf(id);
      if (index > -1) {
        this.openedCat.splice(index, 1);
      } else {
        this.openedCat.push(id);
      }
    },
    saveNewQuestion() {
      this.questions.options = this.questionOptions;
      this.course.questions = this.course.questions.concat(this.questions);
      this.frmModel.course[0] = this.course;
      this.questions = {
        questionId: "",
        question: "",
        questionSet: null,
        questionType: null,
        options: [],
      };
      this.questionOptions = [
        {
          optionId: "",
          option: "",
          correctAnswer: false,
        },
      ];
    },
    addQuestion() {
      this.questions.options = this.questionOptions;
      if (this.course.questions.length > 0) {
        this.course.questions = this.course.questions.map((obj, i) => {
          if (this.questions.questionId == obj.questionId) {
            return this.questions;
          } else if (
            this.editQuestionIndex != null &&
            i == this.editQuestionIndex
          ) {
            return this.questions;
          } else {
            return obj;
          }
        });
      } else {
        this.course.questions = this.course.questions.concat(this.questions);
      }
      console.log(this.course.questions);
      this.frmModel.course[0] = this.course;
      console.log(this.course);
      console.log(this.frmModel.course);
      this.questions = {
        questionId: "",
        question: "",
        questionSet: null,
        questionType: null,
        options: [],
      };
      this.questionOptions = [
        {
          optionId: "",
          option: "",
          correctAnswer: false,
        },
      ];
      this.editq = false;
      this.saveq = true;
    },
    deleteOpt(index) {
      console.log(this.questionOptions[index].optionId);
      ApiServices.RestPostCall("api/avenir/training/deleteOption", {
        optionId: this.questionOptions[index].optionId,
      }).then((aveT) => {
        console.log(aveT);
      });
      this.questionOptions = this.questionOptions.filter((s, i) => i !== index);
    },
    addQOpt() {
      var newoptset = {
        optionId: "",
        option: "",
        correctAnswer: false,
      };
      this.questionOptions = this.questionOptions.concat(newoptset);
    },
    loadFormData() {
      var cat1 = this.catName1;
      var session = this.sessionNew1;
      var newss = this.frmModel.sessions.filter((sess) => {
        if (sess.sessionId == session) {
          return sess.category.filter((cat) => {
            if (cat.categoryId == cat1) {
              return true;
            }
          });
        }
      });
      this.sessions = newss;
    },
    onReset() {
      var newSee = [
        {
          session: "",
          sessionId: "",
          category: [
            {
              category: "",
              categoryId: "",
              resourse: [
                {
                  photoBlob: "",
                  header: "",
                  content: "",
                  downloadLink: "",
                  downloadFileName: "",
                  resourseId: "",
                  photoContentLength: "",
                  photoContentType: "",
                  questions:[],
                  resourceType: "link",
                  passingCriteria: "50"
                },
              ],
            },
          ],
        },
      ];
      this.sessions = newSee;
    },
    addAgenda() {
      if (this.agenda.masterAgendaId != "") {
        this.frmModel.agenda = this.frmModel.agenda.map((obj) => {
          if (obj.masterAgendaId == this.agenda.masterAgendaId) {
            return this.agenda;
          } else {
            return obj;
          }
        });
      } else {
        this.frmModel.agenda = this.frmModel.agenda.concat(this.agenda);
      }
      this.agenda = {
        masterAgendaId: "",
        typeOfSession: "",
        agenda: "",
        module: "",
        recommendedDuration: "",
        goals: "",
        topic: null,
        facilatior: "",
        requirements: "",
        colorCode: null,
        downloadLink: null,
        agendaType: "standard",
      };
      console.log(this.frmModel.agenda);
    },
    editAgenda(masterAgendaId) {
      var agenda = this.frmModel.agenda.filter((obj) => {
        return obj.masterAgendaId == masterAgendaId;
      });
      this.agenda = agenda[0];
    },
    deleteAgenda(index) {
      //console.log('hi')
      console.log(this.frmModel.agenda[index].masterAgendaId);
      ApiServices.RestPostCall("api/avenir/training/deleteTrainingAgenda", {
        masterAgendaId: this.frmModel.agenda[index].masterAgendaId,
      }).then((aveT) => {
        console.log(aveT);
      });
      this.frmModel.agenda = this.frmModel.agenda.filter((obj, i) => {
        return i != index;
      });
    },
    deleteSess(sessInd, cateInd = null, resInd = null) {
      //console.log(sessInd,cateInd,resInd);
      //console.log(this.frmModel.sessions)
      if (sessInd && cateInd == null && resInd == null) {
        //console.log(this.frmModel.sessions[sessInd].sessionId)
        ApiServices.RestPostCall("api/avenir/training/deleteSession", {
          sessionId: this.frmModel.sessions[sessInd].sessionId,
        }).then((aveT) => {
          console.log(aveT);
        });
      } else if (resInd == null) {
        console.log(
          this.frmModel.sessions[sessInd].category[cateInd].categoryId
        );
        ApiServices.RestPostCall("api/avenir/training/deleteSessionCategory", {
          categoryId: this.frmModel.sessions[sessInd].category[cateInd]
            .categoryId,
        }).then((aveT) => {
          console.log(aveT);
        });
      } else {
        console.log(
          this.frmModel.sessions[sessInd].category[cateInd].resourse[resInd]
            .resourseId
        );
        ApiServices.RestPostCall("api/avenir/training/deleteCategoryResource", {
          resourceId: this.frmModel.sessions[sessInd].category[cateInd]
            .resourse[resInd].resourseId,
        }).then((aveT) => {
          console.log(aveT);
        });
      }
      this.frmModel.sessions = this.frmModel.sessions.filter((sess, i) => {
        console.log(sessInd, cateInd, resInd);
        if (i == sessInd && cateInd == null && resInd == null) {
          console.log(sess);
          return i != sessInd;
        } else if (resInd == null) {
          return (sess.category = sess.category.filter((cat, j) => {
            return j != cateInd;
          }));
        } else {
          return (sess.category = sess.category.filter((cat, j) => {
            return (cat.resourse = cat.resourse.filter((res, k) => {
              return k != resInd;
            }));
          }));
        }
      });
    },
    addSess() {
      //console.log(this.sessions[0].sessionId)
      //console.log(this.frmModel.sessions)
      var existSess = [];
      if (this.frmModel.sessions.length > 0) {
        existSess = this.sessions.filter((obj) =>
          this.frmModel.sessions.find((o) => o.sessionId === obj.sessionId)
        );
        console.log(existSess)
        if (existSess.length != 0) {
          this.frmModel.sessions = this.frmModel.sessions.map(
            (obj) => existSess.find((o) => o.sessionId === obj.sessionId) || obj
          );
        }
        if (existSess.length == 0) {
          this.frmModel.sessions = this.frmModel.sessions.concat(this.sessions);
        }
      } else {
        this.frmModel.sessions = this.frmModel.sessions.concat(this.sessions);
      }
      this.frmModel.sessions.find((o) => {
        o.sessionId === this.sessions[0].sessionId;
        if (o.sessionId == this.sessions[0].sessionId) {
          console.log(o.sessionId);
          console.log(this.sessions[0].sessionId);
          o.userRoles = this.multiSelvalue;
          //this.questions.options = this.preQuestions;
          //this.course.questions = this.course.questions.concat(this.questions);
          //this.frmModel.course[0] = this.course;


          //this.posQuestions.options = this.posquestionOptions;
          //this.preQuestions.options = this.prequestionOptions;
          //o.category[0].resourse[0].questions.push(this.preQuestions)
           //o.category[0].resourse[0].questions.push(this.posQuestions)
        }
      });
      console.log(this.frmModel.sessions);
    },
    getClass() {
      // if(settings.countryName.toLowerCase() === 'nepal'){
      //   return 'newBlack-theme'
      // }else{
      //   return 'newBlue-theme'
      // }
      if (this.configObj.color === "#212832") {
        return "newBlack-theme";
      } else if (this.configObj.color === "#66e093") {
        return "newBlue-theme";
      } else if (this.configObj.color === "#25984b") {
        return "newGreen-theme";
      } else if (this.configObj.color === "#ededed") {
        return "newPurple-theme";
      }
    },
    random(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    onComplete: function () {
      // this.$root.loading = true;
      this.frmModel.userId = this.loggedInUserProfile.username;
      let users = [];
      if (
        (this.frmModel.users.length > 0 &&
          this.frmModel.users[0].userId == "") ||
        this.frmModel.users.length == 0
      ) {
        users = [
          {
            email: this.loggedInUserProfile.username,
          },
        ];
      } else {
        users = this.frmModel.users;
      }
      let test = {
        ...this.configObj,
        color: this.configObj.color.split("#")[1],
      };
      let test2 = { ...this.frmModel, users: users };
      let formData = new FormData();
      //console.log(JSON.stringify(test2));
      console.log(test2);
      console.log(test);
      //formData.append("resourcefiles ", "");
      formData.append("inputJsonString", JSON.stringify(test2));
      formData.append("config", JSON.stringify(test));
      // console.log(this.frmModel);
      var that = this;
      ApiServices.RestPostMaltipart(
        "api/avenir/training/addTrainingAdmin ",
        formData
      ).then((res) => {
        this.$root.loading = false;
        console.log(res)
        this.avenirTraining = res;
        if (res.status.toLocaleLowerCase() == "success") {
          this.frmModel = this.avenirTraining.trainingApp;
          this.frmModel.sessions = this.avenirTraining.trainingApp.sessions;
          this.frmModel.agenda = this.avenirTraining.trainingApp.agenda;
          if (this.frmModel.course.length > 0)
            this.course = this.frmModel.course[0];
          // console.log("course",this.course)
          this.configObj = this.avenirTraining.config;
          this.configObj.color = "#ededed";
          Swal.fire({
            //title: 'Are you sure, you want to quit?',
            text: "Successfully saved!",
            // confirmButtonColor: "#3085d6",
            icon: "success",
            showCancelButton: false,
            confirmButtonText: "Ok",
            //  cancelButtonText: "Ok"
          });
          // .then(result => {
          //   if (result.value) {
          //     //window.location = this.dashboardUrl;
          //     window.open("https://fplmismohfw.in/", "_blank");
          //   }
          // });
        } else {
          let errorTable = "";
          Swal.fire({
            title: "Alert!",
            // width: "90%",
            icon: "error",
            html: "Something went wrong. Please try again",
            showCloseButton: true,
            //focusConfirm: false,
            confirmButtonText: "Ok",
            // confirmButtonAriaLabel: "Ok",
            showCancelButton: false,
            //cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
            //cancelButtonAriaLabel: 'Thumbs down'
          });
        }
      });
    },
    validateFirstTab: function () {
      //return true;
      return this.$refs.form.validate();
    },
    validateSecondTab: function () {
      return true;
      //return this.$refs.secondTabForm.validate();
    },
    validateThirdTab: function () {
      return true;
      //return this.$refs.thirdTabForm.validate();
    },
    onUpload(e) {
      this.uploadedFile = e.target.files[0].name;
      var files = e.target.files[0];
      this.resourcefiles = files;
    },
    agendaUpload(e) {
      this.agendaFile = e.target.files[0].name;
      var files = e.target.files[0];
      this.agendafiles = files;
    },
    saveAgendaFile() {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("file", this.agendafiles);
      ApiServices.RestPostMaltipart(
        "api/avenir/training/uploadResourse ",
        formData
      ).then((res) => {
        if (res.status.toLocaleLowerCase() == "success") {
          var reslink = res.downloadLink;
          console.log("success");
          this.isLoading = false;
          this.agenda.downloadLink = reslink;
          console.log(this.agenda);
          this.addAgenda();
        }
      });
    },
    saveFile(sessind, catind, resind) {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("file", this.resourcefiles);
      ApiServices.RestPostMaltipart(
        "api/avenir/training/uploadResourse ",
        formData
      ).then((res) => {
        if (res.status.toLocaleLowerCase() == "success") {
          var reslink = res.downloadLink;
          console.log("success");
          this.isLoading = false;
          this.sessions = this.sessions.map((sess, ind) => {
            if (sessind == ind) {
              sess.category.map((ct, ctind) => {
                if (ctind == catind) {
                  ct.resourse.map((res, resin) => {
                    if (resin == resind) {
                      res.downloadLink = reslink;
                      return res;
                    } else {
                      return res;
                    }
                  });
                  return ct;
                } else {
                  return ct;
                }
              });
              return sess;
            } else {
              return sess;
            }
          });
        }
      });
    },
    onFileChange(e, sessind, catind, resind) {
      var files = e.target.files[0];
      //this.resourcefiles = files;
      this.sessions = this.sessions.map((sess, ind) => {
        if (sessind == ind) {
          sess.category.map((ct, ctind) => {
            if (ctind == catind) {
              ct.resourse.map((res, resin) => {
                if (resin == resind) {
                  res.resourcefile = files;
                  return res;
                } else {
                  return res;
                }
              });
              return ct;
            } else {
              return ct;
            }
          });
          return sess;
        } else {
          return sess;
        }
      });
      console.log(this.sessions);
    },
    onImgChange(e) {
      const files = e.target.files;
      var num = files.length + this.frmModel.introPhotos.length;
      if (
        files.length <= 5 &&
        this.frmModel.introPhotos.length <= 5 &&
        num <= 5
      ) {
        const imgSrc = e.target.name;
        files.forEach((fl, key) => {
          this.getBase64String(fl).then((res) => {
            var obj = {
              photoId: "",
              photoContent: "",
            };
            obj.photoContent = res;
            this.frmModel.introPhotos.push(obj);
          });
        });
      } else {
        alert("Only 5 files accepted.");
      }
    },
    onResImgChange(e, sessind, catind, resind) {
      const file = e.target.files[0];
      this.sessions = this.sessions.map((sess, ind) => {
        if (sessind == ind) {
          sess.category.map((ct, ctind) => {
            if (ctind == catind) {
              ct.resourse.map((res, resin) => {
                if (resin == resind) {
                  this.getBase64String(file).then((res1) => {
                    res.photoBlob = res1;
                  });
                  return res;
                } else {
                  return res;
                }
              });
              return ct;
            } else {
              return ct;
            }
          });
          return sess;
        } else {
          return sess;
        }
      });
    },
    onImgChangeconfig(e) {
      const file = e.target.files[0];
      const imgSrc = e.target.name;
      this.getBase64String(file).then((res) => {
        this.configObj[imgSrc] = res;
      });
    },
    getBase64String(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    addstepsbtnnew(stepind) {
      var obj = {
        stepNumber: Number(stepind) + 1,
        stepType: "",
        stepContent: "",
      };
      //this.frmModel.steps = { ...this.frmModel.steps, steps: this.frmModel.steps.concat(obj) };
      this.frmModel.steps = this.frmModel.steps.concat(obj);
    },
    deleteStep(stepind) {
      //console.log(stepind)
      //console.log( this.frmModel.steps)
      //console.log( this.frmModel.steps[stepind].stepId)
      ApiServices.RestPostCall("api/avenir/training/deleteSteps", {
        stepId: this.frmModel.steps[stepind].stepId,
      }).then((aveT) => {
        console.log(aveT);
      });
      this.frmModel.steps = this.frmModel.steps.filter((s, i) => i !== stepind);
    },
  },
  mounted() {
    this.baseUrl = ApiServices.getBaseUrl();
    this.dashboardUrl = this.baseUrl + this.dashboardUrl;
    this.$root.loading = true;
    if (localStorage.getItem("userProfile")) {
      this.loggedInUserProfile = JSON.parse(
        localStorage.getItem("userProfile")
      );
      ApiServices.RestGetCall("/api/avenir/training/getAppDetails", {
        userId: this.loggedInUserProfile.username,
      }).then((aveT) => {
        console.log(aveT);
        this.avenirTraining = aveT;
        if (
          this.avenirTraining &&
          this.avenirTraining.trainingApp &&
          this.avenirTraining.config &&
          this.avenirTraining.trainingApp != "No training app available"
        ) {
          this.$root.loading = false;
          this.frmModel = this.avenirTraining.trainingApp;
          if (this.frmModel.course.length > 0)
            this.course = this.frmModel.course[0];
          this.configObj = this.avenirTraining.config;
          this.configObj.color = "#ededed";
        }
      });
      //this.avenirTraining = JSON.parse(localStorage.getItem("avenirTraining"));
      ApiServices.RestGetCall("api/userRoles", {}).then((aveT) => {
        console.log(aveT);
        this.userRoleSelect = aveT.userRoles;
        console.log(this.userRoleSelect);
      });
    }
    let year = this.date.getFullYear();
    let newyear = year - 10;
    for (var i = newyear; i < year + 1; i++) {
      this.years.push(i);
    }
    this.years.reverse();
  },
};
</script>
<style lang="scss" scoped>
.mdgridviewpage-bg {
  // background-color: #38a1c1;
  background-color: #3471ce;
  // background: url("../assets/img/blue-bg.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  padding: 20px 40px;
}

body.green-theme .mdgridviewpage-bg {
  background-color: #006600;
  background: url("../assets/img/green-bg.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  padding: 20px 40px;
}

.mainform-holder {
  background: #fff;
  -webkit-box-shadow: -2px 0px 5px 0px rgba(204, 204, 204, 1);
  -moz-box-shadow: -2px 0px 5px 0px rgba(204, 204, 204, 1);
  box-shadow: -2px 0px 5px 0px rgba(204, 204, 204, 1);
  border-radius: 10px;
  padding: 60px;
}

.homepage-bg {
  /*background: url("../assets/img/home/Avenir-health-home.jpg") no-repeat;*/
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url("../assets/img/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.socialicons-container {
  position: absolute;
}

/*.config-icon-container {
width: 100px;
height: auto;
}*/

.modRadio .config-icon-container {
  width: 100px;
  height: 100px;
}

#benchmarking .tabs .card-header {
  background-color: rgba(0, 0, 0, 0.03);
}

#sidebar-wrapper {
  position: relative;
}

.modRadio.colorSelect {
  margin-bottom: 30px;
}

.overlay {
  position: absolute;
  top: 48px;
  left: 0;
  height: calc(100% - 90px);
  width: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
}

.edit-links {
  z-index: 14;
  position: relative;
  color: #fff;
  font-size: 14px;
}

.img-preview1 img {
  // height: 50px;
  width: 100%;
}

.img-preview img {
  width: 180px !important;
  margin-top: 20px;
}

.headingText {
  background: transparent;
  border: none;
  font-size: 2.5rem;
  font-weight: 100;
  color: #fff;
  padding: 0px;
}

.landing-desc {
  background: transparent;
  color: #fff;
  border: none;
  width: 100%;
}

.col-form-label {
  font-size: 14px;
}

.vue-form-wizard .wizard-btn {
  display: none !important;
}

.vue-form-wizard .wizard-btn {
  font-weight: 400;
}

.card {
  background-color: #f4f4f4;
}

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-55,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

select {
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  -moz-appearance: none !important;
}

.select-wrapper {
  position: relative;
}

.select-wrapper:after {
  font-family: FontAwesome;
  content: "\f0d7";
  font-size: 16px;
  position: absolute;
  top: 8px;
  right: 14px;
  color: #676767;
  pointer-events: none;
}

.bg-light-purple {
  background-color: #3662a8 !important;
}

.mb-0 > button:after {
  display: none;
}

.mainCard {
  height: 80vh;
  border: 0;
}

.upload_data_tab #v-pills-tab {
  height: 80vh;
  // background: #373a3f !important;
}

.upload_data_tab .nav-link {
  background: #373a3f;
  color: #f4f4f4;
  font-size: 16px;
}

.upload_data_tab .nav-link.active {
  border-radius: 0;
  background-color: #f4f4f4;
  border: 0;
  border-left: 6px solid #fe5868;
  font-weight: 500;
  color: #4c4c4c !important;
}

.upload_data_tab .nav-link:hover {
  background-color: #d4d4d4;
  border-color: transparent;
  border-radius: 0;
  color: #4c4c4c !important;
  border-left: 6px solid #fe5868;
}

.upload_data_tab {
  background: #373a3f;
  border-radius: 0 6px 6px 0;
}

.upload_data_tab .tab-content {
  background-color: #f4f4f4 !important;
  border-radius: 0 6px 6px 0;
}

.upload_data_tab .radioLabelName {
  font-size: 1.2em;
  line-height: 1;
}

.upload_data_tab .iconGroup {
  display: block;
  // position: absolute;
  // right: 20px;
  // top: 15px;
}

.upload_data_tab tbody tr {
  position: relative;
}

.upload_data_tab tr:hover > .iconGroup {
  display: inline-block;
}

.upload_data_tab .iconGroup .fa {
  font-size: 1.2em;
  margin: auto 12px;
  transition: all 0.5s;
  color: #c5c5c5;
  cursor: pointer;
}

.upload_data_tab .iconGroup .fa:hover {
  color: #5e5e5e;
}

.tableCard {
  background-color: #fff;
  border-radius: 0 !important;
}

.subTab {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.subTab .nav-link.active {
  border-left: 0;
  font-weight: 500;
}

.subTab .nav-link {
  background: transparent;
  color: #373a3f;
  font-size: 14px;
  font-weight: 200;
  transform: all 0.5s;
}

.subTab .nav-link:hover {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0;
  color: #373a3f !important;
  border-left: 0;
  font-weight: 500;
}

.formSection {
  height: calc(100vh - 250px);
  overflow: hidden;
  overflow-y: scroll;
}

.border_bottom_grey {
  border-bottom: 2px solid #212832;
}

.addTemp_btn {
}

.upload_data_tab h6 {
  font-size: 0.875rem;
}

.mainRadiobtn {
  font-size: 0.875rem !important;
}

// .upload_data_tab .pure-checkbox input[type="radio"] + label:before{
//   border: 1px solid #333;
//   width: 12px;
//   height: 12px;
//   margin-top: -8px;
// }
// .upload_data_tab .pure-checkbox input[type="radio"] + label{
//   padding-left: 1.4em;
// }
// .upload_data_tab .pure-checkbox input[type="radio"] + label:after{
//   top: 48%;
//   left: 2px;
//   width: 10px;
//   height: 10px;
//   margin-top: -5px;
// }
.upload_data_tab .col-form-label {
  font-size: 0.84rem;
}

.upload_data_tab .form-control {
  color: #7e8084;
  font-weight: 300 !important;
}

.upload_data_tab .btn-success {
  color: #fff;
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}

.landingpage-nav {
  background: #edd0f5 !important;
  border-color: #edd0f5 !important;
}

.newGreen-theme {
  .upload_data_tab {
    background: #1a4728;
    border-radius: 0 6px 6px 0;
  }
  .upload_data_tab #v-pills-tab {
    height: 80vh;
    background: #1a4728 !important;
  }
  .upload_data_tab .nav-link[data-v-596ace85] {
    background: #1a4728;
    color: #f4f4f4;
    font-size: 16px;
  }
  .upload_data_tab .nav-link.active[data-v-596ace85] {
    border-radius: 0;
    background-color: #fff;
    border: 0;
    border-left: 6px solid #fe5868;
    font-weight: 500;
    color: #4c4c4c !important;
  }
  .upload_data_tab .nav-link[data-v-596ace85]:hover {
    background-color: #f4f4f4;
    border-color: transparent;
    border-radius: 0;
    color: #4c4c4c !important;
    border-left: 6px solid #fe5868;
  }
  .upload_data_tab .tab-content[data-v-596ace85] {
    background-color: #ffffff !important;
    border-radius: 0 6px 6px 0;
  }
  .sandbox_setup.sheetsNumber
    .wizard-nav
    li.active
    .wizard-icon-circle.tab_shape
    .wizard-icon-container {
    background-color: #f4f4f4 !important;
  }
  .subTab {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
  }
  .subTab .nav-link.active {
    border-left: 0 !important;
    font-weight: 500 !important;
  }
  .subTab .nav-link {
    background: transparent !important;
    color: #373a3f !important;
    font-size: 14px !important;
    font-weight: 200 !important;
    transform: all 0.5s !important;
  }
  .subTab .nav-link:hover {
    background-color: transparent !important;
    border-color: transparent !important;
    border-radius: 0 !important;
    color: #373a3f !important;
    border-left: 0 !important;
    font-weight: 500 !important;
  }
}

.mainTextHead {
  font-size: 1.1em;
}

#trainingPlanningTab .tab-pane {
  height: calc(100vh - 76px);
  overflow: hidden;
  overflow-y: scroll;
}

.choiceSettingSection #accordion .card .card-header {
  border-radius: 2px;
  font-size: 14px;
  font-weight: 600;
  background: #d4d4d4;
  color: #000 !important;
}

.examWrap {
  height: calc(100vh - 76px);
  overflow: hidden;
  overflow-y: scroll;
}

.input-group {
  position: relative;
  margin-bottom: 0px;
  .form-control {
    border: 1px solid #ebebeb;
    border-left: 0;
    border-radius: 0 50px 50px 0;
  }
}

.input-group-text {
  background-color: #fff !important;
  border: 1px solid #ebebeb;
  border-right: 0;
  border-radius: 50px;
}

.input-group .form-control {
  width: auto;
}

.detailsTabel .iconGroup {
  justify-content: flex-end;
}

.detailsTabel .selectSecttion {
  font-size: 0.79rem;
  margin-bottom: 0;
  text-transform: capitalize;
  cursor: pointer;
}

.detailsTabel .selectSecttion:hover {
  text-decoration: underline;
}

.cardLinkTitle {
  font-weight: 500;
}

.textAreahide {
  display: none !important;
}

// .img_cont {
//   position: relative;
//   width: 50%;
//   max-width: 300px;
// }
// .image {
//   display: block;
//   width: 100%;
//   height: auto;
// }
// .overlay {
//   position: absolute;
//   bottom: 0;
//   background: rgb(0, 0, 0);
//   background: rgba(0, 0, 0, 0.5); /* Black see-through */
//   color: #f1f1f1;
//   width: 100%;
//   transition: .5s ease;
//   opacity:0;
//   color: white;
//   font-size: 20px;
//   padding: 20px;
//   text-align: center;
// }
// .img_cont:hover .overlay {
//   opacity: 1;
// }
.floating-chat-icon {
  position: absolute;
  bottom: 150px;
  right: 27px;
}

.imageCardSection {
  position: relative;
  .imageDivWrap {
    background: #f4f4f4;
    /* padding: 12px 12px; */
    border-radius: 5px;
    width: 90px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .iconDivWrap {
    position: absolute;
    top: 0;
    cursor: pointer;
    // background: rgb(98 98 98 / 40%);
    background: rgba(158, 158, 158, 0.48);
    width: 90px;
    height: 90px;
    border-radius: 5px;
    display: none;
    .dltIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 90px;
      font-size: 20px;
      color: #000;
    }
  }
}

.imageCardSection:hover .iconDivWrap {
  display: block;
}

.vue-loading-button {
  background: #38a556 !important;
  padding: 0.25rem 0.5rem !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
  color: #fff !important;
  margin-bottom: 5px !important;
  margin-right: 5px !important;
  border-radius: 3px !important;
}

.c_div .c_logo {
  width: 200px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
