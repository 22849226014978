<template>
  <div class="body">
    <div class="main_container">
      <training-header :appConfig="appConfig"></training-header>

      <!-- page content -->
      <div class="container-fluid" role="main" style="">
        <div class="text-sectiomn-wrap">
          <div class="row px-4 pt-4">
            <div class="col-md-10 col-sm-10 col-xs-10 mx-auto">
              <div class="backgroundbg-color">
                <div class="heading-title-card">
                  <h2 class="text-white" style="font-weight: 600">
                    Generate and customize your agenda
                  </h2>
                </div>
                <div class="x_panel">
                  <div class="x_content">
                    <div class="form-group row">
                      <label
                      class="control-label col-md-4 col-sm-4 col-xs-12"
                      style="
                      font-weight: 500;
                      font-size: 15px;
                      margin-top: 8px;
                      "
                      >What time do you want to start the session?</label
                      >

                      <div class="col-md-3 col-sm-3 col-xs-12">
                        <div class="input-group">
                          <span class="input-group-addon">
                            <i
                            class="fa fa-clock-o"
                            aria-hidden="true"
                            style="font-size: 1.4em; padding-top: 3px"
                            ></i>
                          </span>
                          <!-- <input
                          type="text"
                          class="form-control timepicker"
                          id="session_sttime"
                          name="session_sttime"
                          placeholder
                          /> -->
                          <vue-timepicker
                          :minute-interval="30"
                          close-on-complete
                          v-model="selectedTime"
                          ></vue-timepicker>
                        </div>
                      </div>
                      <div class="col-md-5 col-sm-5 col-xs-12">
                        <a
                        href
                        class="btn btn-md download-btn text-dark"
                        id="btn_customclick"
                        data-toggle="modal"
                        style="text-decoration: underline"
                        data-target="#myModal"
                        >
                        <i
                        class="fa fa-cloud-download"
                        aria-hidden="true"
                        ></i>
                        Download your agenda
                      </a>
                    </div>
                  </div>
                    <div class="dashboard-widget-content">

                      <table class="table table-bordered" id="example">
                        <thead>
                          <!-- <tr style="background: #dadce8;"> -->
                          <tr>
                            <th>MODULE</th>
                            <th>FACILITATOR</th>
                            <th>FROM</th>
                            <th>TO</th>
                            <th>TOPIC</th>
                            <th>GOALS</th>
                            <th>REQUIREMENTS</th>
                          </tr>
                        </thead>
                        <tbody v-if="masterAgenda">
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>Registration</td>
                            <td></td>
                          </tr>
                          <template v-for="(agenda, i) in masterAgenda">
                            <tr
                              :key="i"
                              v-if="agenda.masterAgendaId == null"
                              :style="{ backgroundColor: agenda.colorCode }"
                            >
                              <td>{{ agenda.module }}</td>
                              <td>{{ agenda.facilatior }}</td>
                              <!-- <td v-if="masterLen % i != 0">{{masterLen}}</td> -->
                              <td v-if="agenda.fromTime">
                                <input
                                  type="text"
                                  id="regfrom"
                                  name="regfrom"
                                  :value="agenda.fromTime"
                                />
                              </td>
                              <td v-else>
                                <input
                                  type="text"
                                  id="regfrom"
                                  name="regfrom"
                                  disabled
                                />
                              </td>
                              <td v-if="agenda.toTime">
                                <input
                                  type="text"
                                  id="regto"
                                  name="regto"
                                  :value="agenda.toTime"
                                />
                              </td>
                              <td v-else>
                                <input
                                  type="text"
                                  id="regto"
                                  name="regto"
                                  disabled
                                />
                              </td>
                              <td v-html="agenda.topic"></td>
                              <td v-html="agenda.goals"></td>
                              <td v-html="agenda.requirements"></td>
                            </tr>
                            <tr :key="i" v-else>
                              <td>{{ agenda.module }}</td>
                              <td>{{ agenda.facilatior }}</td>
                              <!-- <td v-if="masterLen % i != 0">{{masterLen}}</td> -->
                              <td v-if="agenda.fromTime">
                                <input
                                  type="text"
                                  id="regfrom"
                                  name="regfrom"
                                  :value="agenda.fromTime"
                                />
                              </td>
                              <td v-else>
                                <input
                                  type="text"
                                  id="regfrom"
                                  name="regfrom"
                                  disabled
                                />
                              </td>
                              <td v-if="agenda.toTime">
                                <input
                                  type="text"
                                  id="regto"
                                  name="regto"
                                  :value="agenda.toTime"
                                />
                              </td>
                              <td v-else>
                                <input
                                  type="text"
                                  id="regto"
                                  name="regto"
                                  disabled
                                />
                              </td>
                              <td v-html="agenda.agenda"></td>
                              <td v-html="agenda.goals"></td>
                              <td v-html="agenda.requirements"></td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                      <div
                        class="floating-submit-btn w-100"
                        style="text-align: center"
                      >
                        <div class="d-flex justify-content-between">
                          <!-- <button type="submit">Submit form</button> -->

                          <router-link
                            :to="{
                              name: 'facilitator-course',
                              params: {
                                facilitatorObj: facilitatorObj,
                                appConfig: appConfig,
                              },
                            }"
                            class=""
                            style="margin-right: 5%"
                          >
                            <a
                              class="btn yellow-float-btn justify-content-start"
                            >
                              Previous</a
                            >
                          </router-link>
                          <a
                            href="javascript:void(0)"
                            class="btn yellow-float-btn"
                            role="button"
                            style=""
                            @click.prevent.stop="checkTime"
                          >
                            Next
                          </a>

                          <!-- <a href="login.html" class="btn btn-cust1 btn-md btn3d" role="button">Skip to Login</a> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Modal -->
            <div class="modal fade" id="myModal" role="dialog">
              <div class="modal-dialog modal-xl">
                <!-- Modal content-->
                <div class="modal-content">
                  <div class="modal-header">
                    <h4 class="modal-title">Generate your agenda</h4>

                    <button type="button" class="close" data-dismiss="modal">
                      &times;
                    </button>
                  </div>
                  <div class="modal-body">
                    <export-excel :data="items">
                      <button
                        class="btn btn-outline-secondary excel_download_btn"
                        type="button"
                      >
                        Excel
                      </button>
                    </export-excel>
                    <b-table striped bordered hover :items="items">
                      <template #cell(GOALS)="data">
                        <span v-html="data.value"></span>
                      </template>
                      <template #cell(TOPIC)="data">
                        <span v-html="data.value"></span>
                      </template>
                      <template #cell(REQUIREMENTS)="data">
                        <span v-html="data.value"></span>
                      </template>
                    </b-table>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-outline-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /page content -->

      <!-- footer content -->
      <training-footer :appConfig="appConfig"></training-footer>
      <training-faq :facilitatorObj="facilitatorObj"></training-faq>
      <!-- /footer content -->
    </div>
  </div>
</template>
<script>
import trainingHeader from "../components/trainingHeader";
import trainingFooter from "../components/trainingFooter";
import trainingFaq from "./trainingFaq";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import ApiServices from "../service/ApiServices";
import moment from "moment";
export default {
  components: {
    trainingHeader,
    trainingFooter,
    trainingFaq,
    VueTimepicker,
  },
  data() {
    return {
      facilitatorObj: {},
      appConfig: {},
      selectedTime: "",
      masterAgenda: null,
      masterLen: null,
      items: [
        {
          MODULE: "",
          FACILITATOR: "",
          FROM: "",
          TO: "",
          TOPIC: "",
          GOALS: "Registration",
          REQUIREMENTS: ""
        },
      ],
      fields: [
        {
          key: "MODULE",
          label: "MODULE",
          sortable: true,
        },
        {
          key: "FACILITATOR",
          label: "FACILITATOR",
        },
        {
          key: "FROM",
          label: "FROM",
        },
        {
          key: "TO",
          label: "TO",
        },
        {
          key: "TOPIC",
          label: "TOPIC",
        },
        {
          key: "GOALS",
          label: "GOALS",
        },
        {
          key: "REQUIREMENTS",
          label: "REQUIREMENTS",
        },
      ],
      divisor: "",
    };
  },
  methods: {
    getUserAgenda() {
      let selectedAgenda = JSON.parse(localStorage.getItem("selectedAgendas"));
      let masterAgenda = this.$route.params.facilitatorObj.agenda;
      let newAgenda = [];
      this.divisor = Math.round(Math.sqrt(masterAgenda.length));
      let breakAgenda = {
        agenda: "COFFEE BREAK",
        agendaId: null,
        colorCode: "#ffa65b99",
        facilatior: "",
        goals: "",
        masterAgendaId: null,
        module: "",
        recommendedDuration: "",
        requirements: "",
        topic: "COFFEE BREAK",
        topicId: null,
        typeOfSession: "",
        selected: true,
        durationSelected: 15,
      };
      //console.log(this.divisor)
      var count = 0;
      masterAgenda.forEach((agenda, i) => {
        agenda["selected"] = false;
        agenda.fromTime = ""
        agenda.toTime = ""
        selectedAgenda.forEach((selected) => {
          if (agenda.masterAgendaId == selected.masterAgendaId) {
            agenda["selected"] = selected.selected;
            agenda["durationSelected"] = selected.duration;
          }
        });
        if (i % this.divisor == 0 && i != 0) {
          var obj = JSON.parse(JSON.stringify(breakAgenda));
          if (count == 1) {
            obj.agenda = "LUNCH BREAK";
            obj.topic = "LUNCH BREAK";
            obj.colorCode = "#aeceea";
          } else {
            obj.agenda = "COFFEE BREAK";
            obj.topic = "COFFEE BREAK";
          }
          newAgenda.push(obj);
          this.items.push({
            MODULE: obj.module,
            FACILITATOR: obj.facilatior,
            FROM: obj.fromTime,
            TO: obj.toTime,
            TOPIC: obj.agenda,
            GOALS: obj.goals,
            REQUIREMENTS: obj.requirements
          });
          count++;
        }
        newAgenda.push(agenda);
        this.items.push({
          MODULE: agenda.module,
          FACILITATOR: agenda.facilatior,
          FROM: agenda.fromTime,
          TO: agenda.toTime,
          TOPIC: agenda.agenda,
          GOALS: agenda.goals,
          REQUIREMENTS: agenda.requirements
        });
      });
      this.masterAgenda = newAgenda;
      // this.masterAgenda = masterAgenda
      console.log(newAgenda);
    },
    selectedMasterAgenda() {
      this.items = [
        {
          MODULE: "",
          FACILITATOR: "",
          FROM: "",
          TO: "",
          TOPIC: "",
          GOALS: "Registration",
          REQUIREMENTS: ""
        },
      ];
      let startTime = this.selectedTime;
      let fromTime = "",
        toTime = "";
      let j = 0;
      this.masterAgenda.forEach((agenda, i) => {
        if (agenda.selected) {
          j++;
          if (j == 1) {
            fromTime = startTime;
            toTime = moment
              .utc(startTime, "HH:mm")
              .add(agenda.durationSelected, "minutes")
              .format("HH:mm");
          } else {
            fromTime = toTime;
            toTime = moment
              .utc(toTime, "HH:mm")
              .add(agenda.durationSelected, "minutes")
              .format("HH:mm");
          }
          agenda["startTime"] = startTime;
          agenda["fromTime"] = fromTime;
          agenda["toTime"] = toTime;
        }
        this.items.push({
          MODULE: agenda.module,
          FACILITATOR: agenda.facilatior,
          FROM: agenda.fromTime,
          TO: agenda.toTime,
          TOPIC: agenda.agenda,
          GOALS: agenda.goals,
          REQUIREMENTS: agenda.requirements
        });
      });
      console.log(this.masterAgenda);
      this.masterLen = this.masterAgenda.length;
      ApiServices.RestPostCall("api/avenir/training/saveUserAgenda", {
        userAgenda: this.masterAgenda,
      }).then((res) => {
        console.log(res);
      });
      localStorage.setItem("userAgenda", JSON.stringify(this.masterAgenda));
      this.$root.loading = false;
    },
    checkTime() {
      if (this.selectedTime != "") {
        // this.$router.push("/training-agenda");
        this.$router.push({
          name: "course",
          params: {
            facilitatorObj: this.facilitatorObj,
            appConfig: this.appConfig,
          },
        });
      } else {
        Swal.fire({
          text: "Please select time!",
          icon: "warning",
          confirmButtonColor: "#ed2226",
          cancelButtonColor: "#d33",
        });
      }
    },
  },
  mounted() {
    if (this.$route.params.facilitatorObj) {
      this.facilitatorObj = this.$route.params.facilitatorObj;
    } else {
      this.facilitatorObj = JSON.parse(localStorage.getItem("facilitatorObj"));
    }
    if (this.$route.params.appConfig) {
      this.appConfig = this.$route.params.appConfig;
    } else {
      this.appConfig = JSON.parse(localStorage.getItem("appConfig"));
    }

    this.getUserAgenda();
  },
  watch: {
    selectedTime(time) {
      if (time.split(":")[0] != "HH" && time.split(":")[1] != "mm") {
        this.$root.loading = true;
        this.selectedMasterAgenda();
      }
    },
  },
};
</script>
<style scoped>
.x_panel {
  background-color: #fff;
  border: 1px solid #ececec;
  padding: 0;
  position: relative;
}
.x_title {
  border-bottom: 0px solid #e6e9ed;
  padding: 15px 18px;
  background-color: #b0b0b0;
  color: #fff;
  border-radius: 7px 7px 0 0;
  position: relative;
}
.x_content {
  padding: 15px 15px 40px;
}
.download-btn {
  position: absolute;
  right: 18px;
}
.vue__time-picker input.display-time {
  height: 2.83em !important;
}
.modal-title {
  font-size: 18px;
}
.excel_download_btn {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  margin-right: 0.333em;
  padding: 0.5em 1em;
  border: 1px solid #999;
  border-radius: 2px;
  cursor: pointer;
  font-size: 0.88em;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  background-color: #e9e9e9;
  background-image: -webkit-linear-gradient(top, #fff 0%, #e9e9e9 100%);
  background-image: -moz-linear-gradient(top, #fff 0%, #e9e9e9 100%);
  background-image: -ms-linear-gradient(top, #fff 0%, #e9e9e9 100%);
  background-image: -o-linear-gradient(top, #fff 0%, #e9e9e9 100%);
  background-image: linear-gradient(to bottom, #fff 0%, #e9e9e9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='white', EndColorStr='#e9e9e9');
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  outline: none;
}
.excel_download_btn:hover {
  color: #000;
}
.input-group-addon {
  border-radius: 4px 0 0 4px;
  border-right: 0;
}
.floating-submit-btn {
  bottom: -35px;
}
.table thead th {
  vertical-align: bottom;
  /* border-bottom: 2px solid #dee2e6; */
  position: sticky;
  top: 0;
  z-index: 1;
  background: #363636;
  color: #fff;
}
.form-control {
  font-size: 0.8rem;
}
.dashboard-widget-content {
  padding-top: 0;
}
.dashboard-widget-content p {
  margin-bottom: 0;
}
</style>
