<template>
  <div v-bind:class="getClass()">
    <div class="mdgridviewpage-bg">
      <HeaderNew />
      <!-- Page Content -->
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12"></div>
        </div>
      </div>
      <div class="container-fluid m-t-10px">
        <div class="row dashboardchart-container globalconfigcontainer">
          <div class="col-lg-8" style="margin:0 auto;"></div>
        </div>
      </div>
      <ValidationObserver ref="form">
        <form-wizard shape="tab" class="sandbox_setup" @on-complete="onComplete">
          <tab-content title="Application Setup" icon="1" :before-change="validateFirstTab">
            <div class="card m-b-20px">
              <div class="card-header text-decoration-none p-l-16px p-12px">Initial Setup</div>
              <div class="collapse show">
                <div class="card-body p-40px">
                  <div class="row">
                    <div class="col-lg-6 mb-1">
                      <div class="form-group row">
                        <label
                          for="inputDashboardName"
                          class="col-sm-5 col-form-label"
                        >Application Name</label>
                        <div class="col-sm-7">
                          <ValidationProvider
                            name="Application Name"
                            rules="required|min:3"
                            v-slot="{ errors }"
                          >
                            <input
                              type="text"
                              v-model="frmModel.appname"
                              class="form-control"
                              id="inputDashboardName"
                              placeholder
                            />
                            <span class="text-danger">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 mb-1">
                      <div class="form-group row">
                        <label class="col-sm-5 col-form-label">Description</label>
                        <div class="col-sm-7">
                          <ValidationProvider
                            name="Application Description"
                            rules="required|min:3"
                            v-slot="{ errors }"
                          >
                            <input
                              type="text"
                              v-model="frmModel.appdesc"
                              class="form-control"
                              id="inputDashboardDescription"
                              placeholder
                            />
                            <span class="text-danger">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6 hide mb-1">
                      <div class="form-group row">
                        <!-- TODO == need logo list dropdown -->
                        <label class="col-sm-5 col-form-label">Logo</label>
                        <div class="col-sm-2 pr-0">
                          <div class="dropdown logoDropdown">
                            <button
                              class="btn dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="../assets/img/logo/kenya.jpg" class="w-56px pl-0" />
                            </button>
                            <div
                              class="dropdown-menu logoDropdownMenu"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <!-- TODO imgurl -->
                              <div class="row">
                                <div class="col-lg-4">
                                  <a class="dropdown-item" href="#">
                                    <img src="../assets/img/logo/DR of congo.jpg" />
                                  </a>
                                </div>
                                <div class="col-lg-4">
                                  <a class="dropdown-item" href="#">
                                    <img src="../assets/img/logo/ghana.jpg" />
                                  </a>
                                </div>
                                <div class="col-lg-4">
                                  <a class="dropdown-item" href="#">
                                    <img src="../assets/img/logo/ind.jpg" />
                                  </a>
                                </div>
                                <div class="col-lg-4">
                                  <a class="dropdown-item" href="#">
                                    <img src="../assets/img/logo/kenya.jpg" />
                                  </a>
                                </div>
                                <div class="col-lg-4">
                                  <a class="dropdown-item" href="#">
                                    <img src="../assets/img/logo/laos.jpg" />
                                  </a>
                                </div>
                                <div class="col-lg-4">
                                  <a class="dropdown-item" href="#">
                                    <img src="../assets/img/logo/MOH ind.jpg" />
                                  </a>
                                </div>
                                <div class="col-lg-4">
                                  <a class="dropdown-item" href="#">
                                    <img src="../assets/img/logo/MOH kenya.jpg" />
                                  </a>
                                </div>
                                <div class="col-lg-4">
                                  <a class="dropdown-item" href="#">
                                    <img src="../assets/img/logo/morocco.jpg" />
                                  </a>
                                </div>
                                <div class="col-lg-4">
                                  <a class="dropdown-item" href="#">
                                    <img src="../assets/img/logo/Nigeria.jpg" />
                                  </a>
                                </div>
                                <div class="col-lg-4">
                                  <a class="dropdown-item" href="#">
                                    <img src="../assets/img/logo/SA.jpg" />
                                  </a>
                                </div>
                                <div class="col-lg-4">
                                  <a class="dropdown-item" href="#">
                                    <img src="../assets/img/logo/tanza.jpg" />
                                  </a>
                                </div>
                                <div class="col-lg-4">
                                  <a class="dropdown-item" href="#">
                                    <img src="../assets/img/logo/zimb.jpg" />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-5 p-t-4px">
                          <input type="file" class="form-control-file" id="exampleFormControlFile5" />
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 mb-1">
                      <div class="form-group row">
                        <label class="col-sm-5 col-form-label">Initial Year of Data</label>
                        <div class="col-sm-7">
                          <ValidationProvider
                            name="Initial Year"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <div class="select-wrapper">
                              <select class="form-control" v-model="frmModel.initialyear">
                                <option v-for="y in years" :key="y">{{y}}</option>
                              </select>
                            </div>
                            <span class="text-danger">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 mb-1">
                      <div class="form-group row">
                        <label class="col-sm-5 col-form-label">Last Year of Data</label>
                        <div class="col-sm-7">
                          <ValidationProvider name="Last Year" rules="required" v-slot="{ errors }">
                            <div class="select-wrapper">
                              <select class="form-control" v-model="frmModel.lastyear">
                                <option v-for="y in years" :key="y">{{y}}</option>
                              </select>
                            </div>
                            <span class="text-danger">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 mb-1">
                      <div class="form-group row">
                        <label class="col-sm-5 col-form-label">Default Language</label>
                        <div class="col-sm-7">
                          <ValidationProvider
                            name="Default Language"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <div class="select-wrapper">
                              <select class="form-control" v-model="frmModel.language">
                                <option>English</option>
                                <option>French</option>
                                <option>Espanol</option>
                              </select>
                            </div>
                            <span class="text-danger">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card m-t-40px m-b-20px">
              <div
                class="card-header text-decoration-none p-12px p-l-16px"
              >Theme and Layout Selection</div>
              <div class="collapse show">
                <div class="card-body">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-lg-12 border-b-2px-grey">
                        <div class="form-group mb-0 row">
                          <label
                            for="colorSelect"
                            class="col-sm-2 col-form-label vert-center text-center"
                          >Select Color</label>
                          <div class="col-sm-10">
                            <div class="row">
                              <div class="col-lg-3 text-center">
                                <div class="row">
                                  <div class="col-sm-3 vert-center">
                                    <div class="m-t-20px">
                                      <div>
                                        <div class="pure-checkbox">
                                          <input
                                            type="radio"
                                            name="themeColor"
                                            id="type1"
                                            value="#212832"
                                            v-model="frmModel.color"
                                          />
                                          <label for="type1"></label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-sm-9">
                                    <div class="config-icon-container">
                                      <img
                                        src="../assets/img/layouts/black.png"
                                        class="img img-fluid"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-3 text-center">
                                <div class="row">
                                  <div class="col-sm-3 vert-center">
                                    <div class="m-t-20px">
                                      <div>
                                        <div class="pure-checkbox">
                                          <input
                                            id="type2"
                                            type="radio"
                                            name="themeColor"
                                            value="#66e093"
                                            v-model="frmModel.color"
                                          />
                                          <label for="type2"></label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-sm-9">
                                    <div class="config-icon-container">
                                      <img
                                        src="../assets/img/layouts/blue.png"
                                        class="img img-fluid"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-3 text-center">
                                <div class="row">
                                  <div class="col-sm-3 vert-center">
                                    <div class="m-t-20px">
                                      <div>
                                        <div class="pure-checkbox">
                                          <input
                                            id="type3"
                                            type="radio"
                                            name="themeColor"
                                            value="#25984b"
                                            v-model="frmModel.color"
                                          />
                                          <label for="type3"></label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-sm-9">
                                    <div class="config-icon-container">
                                      <img
                                        src="../assets/img/layouts/green.png"
                                        class="img img-fluid"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-3 text-center">
                                <div class="row">
                                  <div class="col-sm-3 vert-center">
                                    <div class="m-t-20px">
                                      <div>
                                        <div class="pure-checkbox">
                                          <input
                                            id="type4"
                                            type="radio"
                                            name="themeColor"
                                            value="#97efe3"
                                            v-model="frmModel.color"
                                            disabled
                                          />
                                          <label for="type4"></label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-sm-9">
                                    <div class="config-icon-container">
                                      <img src="../assets/img/layouts/cs.png" class="img img-fluid" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="form-group row">
                          <label class="col-lg-2 col-form-label vert-center">Select Layout</label>
                          <div class="col-sm-10">
                            <div class="row">
                              <div class="col-lg-3 text-center">
                                <div class="row">
                                  <div class="col-sm-3 vert-center">
                                    <div class="form-group m-t-20px">
                                      <div class="form-check">
                                        <div class="pure-checkbox">
                                          <input type="radio" id="layout1" value="1" checked />
                                          <label for="layout1"></label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-sm-9">
                                    <div class="config-icon-container">
                                      <img src="../assets/img/layouts/1.jpg" class="img img-fluid" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-3 text-center">
                                <div class="row">
                                  <div class="col-sm-3 vert-center">
                                    <div class="form-group m-t-20px">
                                      <div class="form-check">
                                        <div class="pure-checkbox">
                                          <input
                                            type="radio"
                                            id="layout2"
                                            value="2"
                                            v-model="frmModel.layout"
                                            disabled
                                          />
                                          <label for="layout2"></label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-sm-9">
                                    <div class="config-icon-container">
                                      <img src="../assets/img/layouts/2.jpg" class="img img-fluid" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-3 text-center">
                                <div class="row">
                                  <div class="col-sm-3 vert-center">
                                    <div class="form-group m-t-20px">
                                      <div class="form-check">
                                        <div class="pure-checkbox">
                                          <input
                                            type="radio"
                                            id="layout3"
                                            value="3"
                                            v-model="frmModel.layout"
                                            disabled
                                          />
                                          <label for="layout3"></label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-sm-9">
                                    <div class="config-icon-container">
                                      <img src="../assets/img/layouts/3.jpg" class="img img-fluid" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-3 text-center">
                                <div class="row">
                                  <div class="col-sm-3 vert-center">
                                    <div class="form-group m-t-20px">
                                      <div class="form-check">
                                        <div class="pure-checkbox">
                                          <input
                                            type="radio"
                                            id="layout4"
                                            value="4"
                                            v-model="frmModel.layout"
                                            disabled
                                          />
                                          <label for="layout4"></label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-sm-9">
                                    <div class="config-icon-container">
                                      <img src="../assets/img/layouts/4.jpg" class="img img-fluid" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="container-fluid m-t-20px px-2">
              <div class="row dashboardchart-container globalconfigcontainer">
                <div
                  class="col-lg-12 p-0"
                  style="padding-left:0px !important; padding-right:0px !important;"
                >
                  <div class="card m-t-0px m-b-20px" style="background: transparent;">
                    <div
                      class="card-header p-5px border-b-l-radius-10px border-b-r-radius-10px"
                      id="headingOneAdditionalSettings"
                    >
                      <h2 class="mb-0 mt-0">
                        <button
                          class="btn btn-link w-100 text-left color-white"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOneAdditionalSettings"
                          aria-expanded="false"
                          aria-controls="collapseOneAdditionalSettings"
                        >Additional Settings</button>
                      </h2>
                    </div>
                    <div
                      id="collapseOneAdditionalSettings"
                      class="collapse"
                      aria-labelledby="headingOneAdditionalSettings"
                    >
                      <div class="card-body p-0 pt-2">
                        <div class="card m-t-0px m-b-20px">
                          <div
                            class="card-header p-5px text-decoration-none p-l-16px p-12px"
                            id="headingTwo"
                          >Content Management</div>
                          <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo">
                            <div
                              class="card-body p-0 m-b-0px"
                              style="background-color: transparent !important;"
                            >
                              <div class="d-flex homepage-bg" id="wrapper"  :style="{ backgroundImage: `url(${frmModel.backimage})`, backgroundColor: frmModel.backimage ? '' : '#7e7877' }">
                                <div class="overlay"></div>
                                <!-- Sidebar -->
                                <div
                                  class="bg-transparent border-right d-none"
                                  id="sidebar-wrapper"
                                >
                                  <div class="sidebar-heading p-0px">
                                    <a
                                      href="#"
                                      data-toggle="modal"
                                      data-target="#uploadAppLogoModal"
                                      class="edit-links"
                                      style="margin-left: 20px;top:10px;"
                                    >
                                      <i class="fa fa-pencil" aria-hidden="true"></i> Change Logo
                                    </a>
                                    <p
                                      class="text-center m-0px"
                                      style="margin-top: -28px !important;"
                                    >
                                      <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#uploadAppLogoModal"
                                      >
                                        <img
                                          v-if="frmModel.applogo"
                                          :src="frmModel.applogo"
                                          class="img img-fluid brand-logo"
                                        />
                                        <img
                                          v-else
                                          src="../assets/img/home/avenir_health.png"
                                          class="img img-fluid brand-logo"
                                        />
                                      </a>
                                    </p>
                                    <div
                                      class="modal fade"
                                      id="uploadAppLogoModal"
                                      tabindex="-1"
                                      role="dialog"
                                      aria-labelledby="uploadAppLogoModalCenterTitle"
                                      aria-hidden="true"
                                    >
                                      <div
                                        class="modal-dialog modal-dialog-centered"
                                        role="document"
                                      >
                                        <div class="modal-content">
                                          <div class="modal-header p-10px border-bottom-0 p-l-35px">
                                            <h6
                                              class="modal-title color-white"
                                              id="uploadAppLogoModalCenterTitle"
                                            >Upload Application Logo</h6>
                                            <button
                                              type="button"
                                              class="close color-white"
                                              data-dismiss="modal"
                                              aria-label="Close"
                                            >
                                              <span aria-hidden="true" class="color-white">&times;</span>
                                            </button>
                                          </div>
                                          <div class="modal-body p-t-5px p-b-0px">
                                            <div class="card bg-transparent">
                                              <div
                                                class="card-body bg-transparent p-t-0px p-b-10px"
                                              >
                                                <div class="row">
                                                  <div class="col-lg-12">
                                                    <div class="card">
                                                      <div class="card-body">
                                                        <form>
                                                          <div class="form-group row">
                                                            <label
                                                              for="inputPassword"
                                                              class="col-sm-4 col-form-label"
                                                            >Select Logo</label>
                                                            <div class="col-sm-8">
                                                              <input
                                                                type="file"
                                                                class="form-control-file p-t-4px"
                                                                style="font-size: 15px;"
                                                                name="applogo"
                                                                accept="image/*"
                                                                @change="onImgChange"
                                                              />
                                                              <div class="img-preview">
                                                                <img
                                                                  v-if="frmModel.applogo"
                                                                  :src="frmModel.applogo"
                                                                />
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </form>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="modal-footer p-10px border-top-0 p-r-35px">
                                            <button
                                              type="button"
                                              class="btn btn-secondary bg-dark-grey color-black"
                                              data-dismiss="modal"
                                            >Save and Continue</button>
                                            <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row leadershipmsg-container">
                                    <div class="col-lg-12 p-0">
                                      <p class="color-white text-uppercase m-0px">
                                        <span class="border-bottom-white">Leadership</span>
                                      </p>
                                    </div>
                                    <div class="col-lg-12 p-0">
                                      <div class="media m-t-10px">
                                        <a
                                          href="#"
                                          data-toggle="modal"
                                          data-target="#uploadLeadershipLogoModal"
                                        >
                                          <img
                                            v-if="frmModel.leadpic"
                                            :src="frmModel.leadpic"
                                            class="img img-fluid brand-logo"
                                          />
                                          <img
                                            v-else
                                            src="../assets/img/home/user.jpg"
                                            class="mr-3 leaderimg"
                                          />
                                        </a>
                                        <div
                                          class="modal fade"
                                          id="uploadLeadershipLogoModal"
                                          tabindex="-1"
                                          role="dialog"
                                          aria-labelledby="uploadLeadershipLogoModalCenterTitle"
                                          aria-hidden="true"
                                        >
                                          <div
                                            class="modal-dialog modal-dialog-centered"
                                            role="document"
                                          >
                                            <div class="modal-content">
                                              <div
                                                class="modal-header p-10px border-bottom-0 p-l-35px"
                                              >
                                                <h6
                                                  class="modal-title color-white"
                                                  id="uploadLeadershipLogoModalCenterTitle"
                                                >Upload Leadership Information</h6>
                                                <button
                                                  type="button"
                                                  class="close color-white"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                >
                                                  <span
                                                    aria-hidden="true"
                                                    class="color-white"
                                                  >&times;</span>
                                                </button>
                                              </div>
                                              <div class="modal-body p-t-5px p-b-0px">
                                                <div class="card bg-transparent">
                                                  <div
                                                    class="card-body bg-transparent p-t-0px p-b-10px"
                                                  >
                                                    <div class="row">
                                                      <div class="col-lg-12">
                                                        <div class="card bg-transparent">
                                                          <!-- <div class="card-header bg-dark-grey indicatorlinkheader">

                                                          </div>-->
                                                          <div
                                                            class="card-body"
                                                            style="padding-bottom: 10px;"
                                                          >
                                                            <form>
                                                              <div
                                                                class="form-group row"
                                                                style="background: #fff;border-radius: 10px;padding: 16px;"
                                                              >
                                                                <label
                                                                  class="col-sm-4 col-form-label"
                                                                >Select Image</label>

                                                                <div class="col-sm-8">
                                                                  <input
                                                                    type="file"
                                                                    class="form-control-file p-t-4px"
                                                                    style="font-size: 15px;"
                                                                    name="leadpic"
                                                                    accept="image/*"
                                                                    @change="onImgChange"
                                                                  />
                                                                  <img
                                                                    v-if="frmModel.leadpic"
                                                                    :src="frmModel.leadpic"
                                                                    class="mr-3 leaderimg mt-2"
                                                                  />
                                                                </div>
                                                              </div>
                                                              <div
                                                                class="form-group row"
                                                                style="background: #fff;border-radius: 10px;padding: 16px;"
                                                              >
                                                                <label
                                                                  class="col-sm-4 col-form-label"
                                                                >Designation</label>
                                                                <div class="col-sm-8">
                                                                  <input
                                                                    type="text"
                                                                    v-model="frmModel.leaddesignation"
                                                                    class="form-control"
                                                                  />
                                                                </div>
                                                              </div>
                                                              <div
                                                                class="form-group row"
                                                                style="background: #fff;border-radius: 10px;padding: 16px;margin-bottom: 0px;"
                                                              >
                                                                <label
                                                                  class="col-sm-4 col-form-label"
                                                                >Description</label>
                                                                <div class="col-sm-8">
                                                                  <textarea
                                                                    class="form-control"
                                                                    v-model="frmModel.leaddesc"
                                                                    rows="3"
                                                                  ></textarea>
                                                                </div>
                                                              </div>
                                                            </form>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                class="modal-footer p-10px border-top-0 p-r-35px"
                                              >
                                                <button
                                                  type="button"
                                                  class="btn btn-secondary bg-dark-grey color-black"
                                                  data-dismiss="modal"
                                                >Save</button>
                                                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="media-body">
                                          <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#uploadLeadershipLogoModal"
                                            class="edit-links"
                                            style="margin-left: 20px;top:10px;"
                                          >
                                            <i class="fa fa-pencil" aria-hidden="true"></i> Change Image and Content
                                          </a>
                                          <h5
                                            class="m-t-10px leader-designation"
                                            style="margin-top: -10px !important;"
                                          >{{frmModel.leaddesignation}}</h5>
                                        </div>
                                      </div>
                                      <p class="leader-description">{{frmModel.leaddesc}}</p>
                                      <p
                                        class="text-right leader-description"
                                        contenteditable="true"
                                      >
                                        <span class="border-bottom-white">Read More</span>
                                      </p>
                                    </div>
                                  </div>
                                  <div class="row resources-container">
                                    <div class="col-lg-12 p-0">
                                      <p class="color-white text-uppercase m-0px">
                                        <span class="border-bottom-white">Resource</span>
                                      </p>
                                    </div>
                                    <div class="col-lg-12 p-0">
                                      <div class="media m-t-10px">
                                        <a
                                          href="#"
                                          data-toggle="modal"
                                          data-target="#uploadResourceLogoModal"
                                        >
                                          <img
                                            src="../assets/img/home/track20.png"
                                            class="mr-3 leaderimg resourceimg"
                                            alt="..."
                                          />
                                        </a>
                                        <div class="media-body">
                                          <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#uploadResourceLogoModal"
                                            class="edit-links"
                                            style="margin-left: 20px;top:10px;"
                                          >
                                            <i class="fa fa-pencil" aria-hidden="true"></i> Change Logo and Content
                                          </a>
                                          <h5
                                            class="leader-designation"
                                            contenteditable="true"
                                            style="margin-top: -40px !important;"
                                          >Family Planning Effort Index</h5>
                                        </div>
                                        <div
                                          class="modal fade"
                                          id="uploadResourceLogoModal"
                                          tabindex="-1"
                                          role="dialog"
                                          aria-labelledby="uploadResourceLogoModalCenterTitle"
                                          aria-hidden="true"
                                        >
                                          <div
                                            class="modal-dialog modal-dialog-centered"
                                            role="document"
                                          >
                                            <div class="modal-content">
                                              <div
                                                class="modal-header p-10px border-bottom-0 p-l-35px"
                                              >
                                                <h6
                                                  class="modal-title color-white"
                                                  id="uploadResourceLogoModalCenterTitle"
                                                >Upload Resource Information</h6>
                                                <button
                                                  type="button"
                                                  class="close color-white"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                >
                                                  <span
                                                    aria-hidden="true"
                                                    class="color-white"
                                                  >&times;</span>
                                                </button>
                                              </div>
                                              <div class="modal-body p-t-5px p-b-0px">
                                                <div class="card bg-transparent">
                                                  <div
                                                    class="card-body bg-transparent p-t-0px p-b-10px"
                                                  >
                                                    <div class="row">
                                                      <div class="col-lg-12">
                                                        <div class="card bg-transparent">
                                                          <!-- <div class="card-header bg-dark-grey indicatorlinkheader">

                                                          </div>-->
                                                          <div
                                                            class="card-body bg-transparent"
                                                            style="padding-bottom: 10px;"
                                                          >
                                                            <form>
                                                              <div
                                                                class="form-group row"
                                                                style="background: #fff;border-radius: 10px;padding: 16px;"
                                                              >
                                                                <label
                                                                  for="inputPassword"
                                                                  class="col-sm-4 col-form-label"
                                                                >Select Image</label>
                                                                <div class="col-sm-8">
                                                                  <input
                                                                    type="file"
                                                                    class="form-control-file p-t-4px"
                                                                    style="font-size: 15px;"
                                                                    id="exampleFormControlFile3"
                                                                  />
                                                                  <img
                                                                    src="../assets/img/home/Hopkins.png"
                                                                    class="mr-3 leaderimg mt-2"
                                                                    alt="..."
                                                                  />
                                                                </div>
                                                              </div>
                                                              <div
                                                                class="form-group row"
                                                                style="background: #fff;border-radius: 10px;padding: 16px;"
                                                              >
                                                                <label
                                                                  for="inputPassword"
                                                                  class="col-sm-4 col-form-label"
                                                                >Heading</label>
                                                                <div class="col-sm-8">
                                                                  <input
                                                                    type="text"
                                                                    class="form-control"
                                                                    id="inputPassword"
                                                                    placeholder
                                                                  />
                                                                </div>
                                                              </div>
                                                              <div
                                                                class="form-group row"
                                                                style="background: #fff;border-radius: 10px;padding: 16px;margin-bottom: 0px;"
                                                              >
                                                                <label
                                                                  for="inputPassword"
                                                                  class="col-sm-4 col-form-label"
                                                                >Description</label>
                                                                <div class="col-sm-8">
                                                                  <textarea
                                                                    class="form-control"
                                                                    id="exampleFormControlTextarea1"
                                                                    rows="3"
                                                                  ></textarea>
                                                                </div>
                                                              </div>
                                                            </form>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                class="modal-footer p-10px border-top-0 p-r-35px"
                                              >
                                                <button
                                                  type="button"
                                                  class="btn btn-secondary bg-dark-grey color-black"
                                                  data-dismiss="modal"
                                                >Save</button>
                                                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <p class="leader-description" contenteditable="true">
                                        View the data for countries included in the
                                        <span
                                          class="color-blue"
                                        >...View more</span>
                                      </p>
                                    </div>
                                    <div class="col-lg-12 p-0">
                                      <div class="media m-t-10px">
                                        <a
                                          href="#"
                                          data-toggle="modal"
                                          data-target="#uploadResourceLogoModal"
                                        >
                                          <img
                                            src="../assets/img/home/Hopkins.png"
                                            class="mr-3 leaderimg resourceimg"
                                            alt="..."
                                          />
                                        </a>
                                        <div class="media-body">
                                          <h5
                                            class="leader-designation"
                                            contenteditable="true"
                                          >The Lives Saved Tool (LiST)</h5>
                                        </div>
                                      </div>
                                      <p class="leader-description" contenteditable="true">
                                        An evidence-based tool for estimating
                                        <span
                                          class="color-blue"
                                        >...View more</span>
                                      </p>
                                    </div>
                                  </div>
                                  <a
                                    href="#"
                                    data-toggle="modal"
                                    data-target="#uploadUrlModal"
                                    class="edit-links"
                                    style="margin-left: 20px;top:0px;"
                                  >
                                    <i class="fa fa-pencil" aria-hidden="true"></i> Upload URL
                                  </a>
                                  <div class="row socialicons-container m-t-20">
                                    <div
                                      class="col-lg-3 p-0 text-center border-right-white col-sm-3"
                                    >
                                      <a href="javascript:void(0)">
                                        <i
                                          class="fa fa-facebook-square color-white"
                                          aria-hidden="true"
                                        ></i>
                                      </a>
                                      <div
                                        class="modal fade"
                                        id="uploadUrlModal"
                                        tabindex="-1"
                                        role="dialog"
                                        aria-labelledby="uploadUrlModalCenterTitle"
                                        aria-hidden="true"
                                      >
                                        <div
                                          class="modal-dialog modal-dialog-centered"
                                          role="document"
                                        >
                                          <div class="modal-content">
                                            <div
                                              class="modal-header p-10px border-bottom-0 p-l-35px"
                                            >
                                              <h6
                                                class="modal-title color-white"
                                                id="uploadUrlModalCenterTitle"
                                              >Upload Url</h6>
                                              <button
                                                type="button"
                                                class="close color-white"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                              >
                                                <span aria-hidden="true" class="color-white">&times;</span>
                                              </button>
                                            </div>
                                            <div class="modal-body p-t-5px p-b-0px">
                                              <div class="card bg-transparent">
                                                <div
                                                  class="card-body bg-transparent p-t-0px p-b-10px"
                                                >
                                                  <div class="row">
                                                    <div class="col-lg-12">
                                                      <div class="card">
                                                        <!-- <div class="card-header bg-dark-grey indicatorlinkheader">

                                                        </div>-->
                                                        <div class="card-body">
                                                          <form>
                                                            <div class="form-group row">
                                                              <label
                                                                for="inputFacebook"
                                                                class="col-sm-4 col-form-label"
                                                              >Facebook</label>
                                                              <div class="col-sm-8">
                                                                <input
                                                                  type="text"
                                                                  v-model="frmModel.socialfacebook"
                                                                  class="form-control"
                                                                  id="inputFacebook"
                                                                  placeholder="http://www.example.com"
                                                                />
                                                              </div>
                                                            </div>
                                                            <div class="form-group row">
                                                              <label
                                                                for="inputInstagram"
                                                                class="col-sm-4 col-form-label"
                                                              >Instagram</label>
                                                              <div class="col-sm-8">
                                                                <input
                                                                  type="text"
                                                                  v-model="frmModel.socialinsta"
                                                                  class="form-control"
                                                                  id="inputInstagram"
                                                                  placeholder="http://www.example.com"
                                                                />
                                                              </div>
                                                            </div>
                                                            <div class="form-group row">
                                                              <label
                                                                for="inputTwitter"
                                                                class="col-sm-4 col-form-label"
                                                              >Twitter</label>
                                                              <div class="col-sm-8">
                                                                <input
                                                                  type="text"
                                                                  v-model="frmModel.socialtwitter"
                                                                  class="form-control"
                                                                  id="inputTwitter"
                                                                  placeholder="http://www.example.com"
                                                                />
                                                              </div>
                                                            </div>
                                                            <div class="form-group row">
                                                              <label
                                                                for="inputYoutube"
                                                                class="col-sm-4 col-form-label"
                                                              >Youtube</label>
                                                              <div class="col-sm-8">
                                                                <input
                                                                  type="text"
                                                                  v-model="frmModel.socialyoutube"
                                                                  class="form-control"
                                                                  id="inputYoutube"
                                                                  placeholder="http://www.example.com"
                                                                />
                                                              </div>
                                                            </div>
                                                          </form>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="modal-footer p-10px border-top-0 p-r-35px">
                                              <button
                                                type="button"
                                                class="btn btn-secondary bg-dark-grey color-black"
                                                data-dismiss="modal"
                                              >Save</button>
                                              <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="col-lg-3 p-0 text-center border-right-white col-sm-3"
                                    >
                                      <a href="javascript:void(0)">
                                        <i class="fa fa-instagram color-white" aria-hidden="true"></i>
                                      </a>
                                    </div>
                                    <div
                                      class="col-lg-3 p-0 text-center border-right-white col-sm-3"
                                    >
                                      <a href="javascript:void(0)">
                                        <i class="fa fa-twitter color-white" aria-hidden="true"></i>
                                      </a>
                                    </div>
                                    <div class="col-lg-3 p-0 text-center col-sm-3">
                                      <a href="javascript:void(0)">
                                        <i class="fa fa-youtube color-white" aria-hidden="true"></i>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <!-- /#sidebar-wrapper -->
                                <!-- Page Content -->
                                <div id="page-content-wrapper" class>
                                  <!-- <a
                                    href="#"
                                    class="pull-left mr-2"
                                    data-toggle="modal"
                                    data-target="#uploadLogoImageModal"
                                  > -->

                                    <!-- <img
                                      src="../assets/img/logo_front_new.png"
                                      class="img-responsive-sm c_logo aviner_logo1"
                                      alt="Dure Technology"

                                    /> -->
                                    <img
                                      v-if="frmModel.applogo"
                                      :src="frmModel.applogo"
                                      class="img-responsive-sm c_logo aviner_logo1"
                                    />
                                    <img
                                      v-else
                                      src="../assets/img/logo_front_new.png"
                                      class="img-responsive-sm c_logo aviner_logo1"
                                      alt="Dure Technology"
                                    />
                                    <a
                                      href="#"
                                      data-toggle="modal"
                                      data-target="#uploadLogoImageModal"
                                      class="edit-links"
                                      style="margin-left: 20px;top:10px;left:1px;"
                                    >
                                      <i class="fa fa-pencil" aria-hidden="true"></i> Change Logo Image
                                    </a>
                                  <!-- </a> -->
                                  <div
                                    class="modal fade"
                                    id="uploadLogoImageModal"
                                    tabindex="-1"
                                    role="dialog"
                                    aria-labelledby="uploadLogoImageModalCenterTitle"
                                    aria-hidden="true"
                                  >
                                    <div class="modal-dialog modal-dialog-centered" role="document">
                                      <div class="modal-content">
                                        <div class="modal-header p-10px border-bottom-0 p-l-35px">
                                          <h6
                                            class="modal-title color-white"
                                            id="uploadBackgroundImageModalCenterTitle"
                                          >Upload Logo Image</h6>
                                          <button
                                            type="button"
                                            class="close color-white"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                          >
                                            <span aria-hidden="true" class="color-white">&times;</span>
                                          </button>
                                        </div>
                                        <div class="modal-body p-t-5px p-b-0px">
                                          <div class="card bg-transparent">
                                            <div class="card-body bg-transparent p-t-0px p-b-10px">
                                              <div class="row">
                                                <div class="col-lg-12">
                                                  <div class="card">
                                                    <div class="card-body">
                                                      <form>
                                                        <div class="form-group row">
                                                          <label
                                                            class="col-sm-4 col-form-label"
                                                          >Select Image</label>
                                                          <div class="col-sm-8">
                                                            <input
                                                              type="file"
                                                              class="form-control-file p-t-4px"
                                                              style="font-size: 15px;"
                                                              name="applogo"
                                                              accept="image/*"
                                                              @change="onImgChange"
                                                            />
                                                          </div>
                                                          <div class="img-preview">
                                                            <img
                                                            v-if="frmModel.applogo"
                                                            :src="frmModel.applogo"
                                                            />
                                                          </div>
                                                        </div>
                                                      </form>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="modal-footer p-10px border-top-0 p-r-35px">
                                          <button
                                            type="button"
                                            class="btn btn-secondary bg-dark-grey color-black"
                                            data-dismiss="modal"
                                          >Save and Continue</button>
                                          <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <a
                                    href="#"
                                    class="pull-right mr-2"
                                    data-toggle="modal"
                                    data-target="#uploadBackgroundImageModal"
                                  >
                                    <a
                                      href="#"
                                      data-toggle="modal"
                                      data-target="#uploadBackgroundImageModal"
                                      class="edit-links"
                                      style="margin-left: 20px;top:10px;right:20px;"
                                    >
                                      <i class="fa fa-pencil" aria-hidden="true"></i> Change Background Image
                                    </a>
                                  </a>
                                  <div
                                    class="modal fade"
                                    id="uploadBackgroundImageModal"
                                    tabindex="-1"
                                    role="dialog"
                                    aria-labelledby="uploadBackgroundImageModalCenterTitle"
                                    aria-hidden="true"
                                  >
                                    <div class="modal-dialog modal-dialog-centered" role="document">
                                      <div class="modal-content">
                                        <div class="modal-header p-10px border-bottom-0 p-l-35px">
                                          <h6
                                            class="modal-title color-white"
                                            id="uploadBackgroundImageModalCenterTitle"
                                          >Upload Background Image</h6>
                                          <button
                                            type="button"
                                            class="close color-white"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                          >
                                            <span aria-hidden="true" class="color-white">&times;</span>
                                          </button>
                                        </div>
                                        <div class="modal-body p-t-5px p-b-0px">
                                          <div class="card bg-transparent">
                                            <div class="card-body bg-transparent p-t-0px p-b-10px">
                                              <div class="row">
                                                <div class="col-lg-12">
                                                  <div class="card">
                                                    <div class="card-body">
                                                      <form>
                                                        <div class="form-group row">
                                                          <label
                                                            class="col-sm-4 col-form-label"
                                                          >Select Image</label>
                                                          <div class="col-sm-8">
                                                            <input
                                                              type="file"
                                                              class="form-control-file p-t-4px"
                                                              style="font-size: 15px;"
                                                              name="backimage"
                                                              accept="image/*"
                                                              @change="onImgChange"
                                                            />
                                                          </div>
                                                          <div class="img-preview">
                                                            <img
                                                              v-if="frmModel.backimage"
                                                              :src="frmModel.backimage"
                                                            />
                                                          </div>
                                                        </div>
                                                      </form>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="modal-footer p-10px border-top-0 p-r-35px">
                                          <button
                                            type="button"
                                            class="btn btn-secondary bg-dark-grey color-black"
                                            data-dismiss="modal"
                                          >Save and Continue</button>
                                          <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="container-fluid landingpage-maincontainer">
                                    <a
                                      href="#"
                                      data-toggle="modal"
                                      data-target="#uploadMainContentModal"
                                      class="edit-links"
                                      style="top: 60px;right: 20px;text-align: center !important;width: 100%;"
                                    >
                                      <i class="fa fa-pencil" aria-hidden="true"></i> Change Content
                                    </a>
                                    <div
                                      class="modal fade"
                                      id="uploadMainContentModal"
                                      tabindex="-1"
                                      role="dialog"
                                      aria-labelledby="uploadResourceLogoModalCenterTitle"
                                      aria-hidden="true"
                                    >
                                      <div
                                        class="modal-dialog modal-dialog-centered"
                                        role="document"
                                      >
                                        <div class="modal-content">
                                          <div class="modal-header p-10px border-bottom-0 p-l-35px">
                                            <h6
                                              class="modal-title color-white"
                                              id="uploadResourceMainContentCenterTitle"
                                            >Upload Content</h6>
                                            <button
                                              type="button"
                                              class="close color-white"
                                              data-dismiss="modal"
                                              aria-label="Close"
                                            >
                                              <span aria-hidden="true" class="color-white">&times;</span>
                                            </button>
                                          </div>
                                          <div class="modal-body p-t-5px p-b-0px">
                                            <div class="card bg-transparent">
                                              <div
                                                class="card-body bg-transparent p-t-0px p-b-10px"
                                              >
                                                <div class="row">
                                                  <div class="col-lg-12">
                                                    <div class="card bg-transparent">
                                                      <div
                                                        class="card-body bg-transparent"
                                                        style="padding-bottom: 10px;"
                                                      >
                                                        <form>
                                                          <div
                                                            class="form-group row"
                                                            style="background: #fff;border-radius: 10px;padding: 16px;"
                                                          >
                                                            <label
                                                              class="col-sm-4 col-form-label"
                                                            >Heading</label>
                                                            <div class="col-sm-8">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                v-model="frmModel.contentheading"
                                                              />
                                                            </div>
                                                          </div>
                                                          <div
                                                            class="form-group row"
                                                            style="background: #fff;border-radius: 10px;padding: 16px;margin-bottom: 0px;"
                                                          >
                                                            <label
                                                              class="col-sm-4 col-form-label"
                                                            >Description</label>
                                                            <div class="col-sm-8">
                                                              <textarea
                                                                class="form-control"
                                                                v-model="frmModel.contentdesc"
                                                                rows="3"
                                                              ></textarea>
                                                            </div>
                                                          </div>
                                                        </form>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="modal-footer p-10px border-top-0 p-r-35px">
                                            <button
                                              type="button"
                                              class="btn btn-secondary bg-dark-grey color-black"
                                              data-dismiss="modal"
                                            >Save</button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <!-- <input type="text" class="mt-4 color-white f-w-100 text-left w-100 headingText" v-model="frmModel.contentheading" title="Click to edit">
                                    <textarea v-model="frmModel.contentdesc" class="landing-desc" maxlength="450" rows="4" title="Click to edit"></textarea>-->
                                    <h1
                                      class="mt-4 color-white f-w-100 text-left w-100"
                                      contenteditable="true"
                                    >{{frmModel.contentheading}}</h1>
                                    <p
                                      class="color-white"
                                      contenteditable="true"
                                    >{{frmModel.contentdesc}}</p>
                                    <div class="row m-t-40px">
                                      <!-- <div class="col-lg-1 col-sm-6 d-sm-none d-lg-block"></div> -->
                                      <div class="col"   v-if="settings.countryName.toLowerCase() === '' ">
                                        <div
                                          class="card text-center ministerial-dashboard-homepageblock bg-transparent"
                                        >
                                          <div class="card-header uppercase">
                                            Ministerial
                                            <br />Dashboard
                                          </div>
                                          <div class="card-body">
                                            <p class="m-0">
                                              <img
                                                src="../assets/img/home/min-dashboard-homeicon.png"
                                                class="img img-fluid"
                                              />
                                            </p>
                                            <div
                                              class="card-footer"
                                              style="padding-top: 15px !important;"
                                            >
                                              <a
                                                href="#/md-gridview"
                                                class="color-white uppercase"
                                              >View More</a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col" v-if="settings.countryName.toLowerCase() === 'nepal' ">
                                        <div
                                          class="card text-center ministerial-dashboard-homepageblock bg-transparent"
                                        >
                                          <div class="card-header uppercase">
                                            FAMILY PLANNING
                                            <br />SERVICES
                                          </div>
                                          <div class="card-body">
                                            <p class="m-0">
                                              <img
                                                src="../assets/img/home/min-dashboard-homeicon.png"
                                                class="img img-fluid"
                                              />
                                            </p>
                                            <div
                                              class="card-footer"
                                              style="padding-top: 15px !important;"
                                            >
                                              <a
                                                href="#/md-gridview"
                                                class="color-white uppercase"
                                              >View More</a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col" v-if="settings.countryName.toLowerCase() === 'nepal' ">
                                        <div
                                          class="card text-center ad_sexual-dashboard-homepageblock bg-transparent"
                                        >
                                          <div class="card-header uppercase">
                                            ADOLESCENT SEXUAL
                                            <br />REPROD. HEALTH
                                          </div>
                                          <div class="card-body">
                                            <p class="m-0">
                                              <img
                                                src="../assets/img/home/min-dashboard-homeicon.png"
                                                class="img img-fluid"
                                              />
                                            </p>
                                            <div
                                              class="card-footer"
                                              style="padding-top: 15px !important;"
                                            >
                                              <a
                                                href="#/md-gridview"
                                                class="color-white uppercase"
                                              >View More</a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col "  v-if="settings.countryName.toLowerCase() === '' ">
                                        <div
                                          class="card text-center analytical-dashboard-homepageblock bg-transparent"
                                        >
                                          <div class="card-header uppercase">
                                            Analytical
                                            <br />Dashboard
                                          </div>
                                          <div class="card-body">
                                            <p class="m-0">
                                              <img
                                                src="../assets/img/home/ana-dashboard-homeicon.png"
                                                class="img img-fluid"
                                              />
                                            </p>
                                            <div
                                              class="card-footer"
                                              style="padding-top: 15px !important;"
                                            >
                                              <a
                                                href="#/analytical-geo"
                                                class="color-white uppercase"
                                              >View More</a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col" >
                                        <div
                                          class="card text-center interactive-dashboard-homepageblock bg-transparent"
                                        >
                                          <div class="card-header uppercase">
                                            Interactive
                                            <br />Analytics
                                          </div>
                                          <div class="card-body">
                                            <p class="m-0">
                                              <img
                                                src="../assets/img/home/int-dashboard-homeicon.png"
                                                class="img img-fluid"
                                              />
                                            </p>
                                            <div
                                              class="card-footer"
                                              style="padding-top: 15px !important;"
                                            >
                                              <a
                                                href="#/create-interactive-analysis"
                                                class="color-white uppercase"
                                              >View More</a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col" v-if="settings.countryName.toLowerCase() === '' ">
                                        <div
                                          class="card text-center dataquality-dashboard-homepageblock bg-transparent"
                                        >
                                          <div class="card-header uppercase">
                                            Data quality
                                            <br />review
                                          </div>
                                          <div class="card-body">
                                            <p class="m-0">
                                              <img
                                                src="../assets/img/home/dataquality-dashboard-homeicon.png"
                                                class="img img-fluid"
                                              />
                                            </p>
                                            <div
                                              class="card-footer"
                                              style="padding-top: 15px !important;"
                                            >
                                              <a
                                                href="#/data-quality"
                                                class="color-white uppercase"
                                              >View More</a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col">
                                        <div
                                          class="card text-center favorites-dashboard-homepageblock bg-transparent"
                                        >
                                          <div class="card-header uppercase">
                                            Saved
                                            <br />Favorites
                                          </div>
                                          <div class="card-body">
                                            <p class="m-0">
                                              <img
                                                src="../assets/img/home/favorites-dashboard-homeicon.png"
                                                class="img img-fluid"
                                              />
                                            </p>
                                            <div
                                              class="card-footer"
                                              style="padding-top: 15px !important;"
                                            >
                                              <a
                                                href="#/bookmark-chart"
                                                class="color-white uppercase"
                                              >View More</a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <!-- <div class="col-lg-1 col-sm-6 d-sm-none d-lg-block"></div> -->
                                    </div>
                                  </div>
                                </div>

                                <!-- /#page-content-wrapper -->
                              </div>
                              <div class="container-fluid footerSection pl-0 pr-0">
                                <div id="footer">
                                  <footer style="position:static;">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <div class="social-icon">
                                          <ul>
                                            <li>
                                              <a href="#">
                                                <span class="fa fa-twitter-square social-icon-fa"></span>
                                              </a>
                                            </li>
                                            <li>
                                              <a href="#">
                                                <span class="fa fa-facebook-square social-icon-fa"></span>
                                              </a>
                                            </li>
                                            <li>
                                              <a href="#">
                                                <span class="fa fa-youtube-play social-icon-fa"></span>
                                              </a>
                                            </li>
                                            <li>
                                              <a href="#">
                                                <span class="fa fa-instagram social-icon-fa"></span>
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                        <a
                                          href="#"
                                          data-toggle="modal"
                                          data-target="#uploadurl"
                                          class="edit-links"
                                          style="text-align: center !important;width: 100%;position: absolute;top: 0;right: 75px;"
                                        >
                                          <i class="fa fa-pencil" aria-hidden="true"></i> Change Social Media Url
                                        </a>
                                        <div
                                          class="modal fade"
                                          id="uploadurl"
                                          tabindex="-1"
                                          role="dialog"
                                          aria-labelledby="uploadResourceLogoModalCenterTitle"
                                          aria-hidden="true"
                                        >
                                          <div
                                            class="modal-dialog modal-dialog-centered"
                                            role="document"
                                          >
                                            <div class="modal-content">
                                              <div
                                                class="modal-header p-10px border-bottom-0 p-l-35px"
                                              >
                                                <h6
                                                  class="modal-title color-white"
                                                  id="uploadResourceMainContentCenterTitle"
                                                >Upload Url</h6>
                                                <button
                                                  type="button"
                                                  class="close color-white"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                >
                                                  <span
                                                    aria-hidden="true"
                                                    class="color-white"
                                                  >&times;</span>
                                                </button>
                                              </div>
                                              <div class="modal-body p-t-5px p-b-0px">
                                                <div class="card bg-transparent">
                                                  <div
                                                    class="card-body bg-transparent p-t-0px p-b-10px"
                                                  >
                                                    <div class="row">
                                                      <div class="col-lg-12">
                                                        <div class="card bg-transparent">
                                                          <div
                                                            class="card-body bg-transparent"
                                                            style="padding-bottom: 10px;"
                                                          >
                                                            <form>
                                                              <div
                                                                class="form-group row mb-3"
                                                                style="background: #fff;border-radius: 10px;padding: 16px;margin-bottom: 0px;"
                                                              >
                                                                <label
                                                                  class="col-sm-4 col-form-label"
                                                                >Twitter</label>
                                                                <div class="col-sm-8">
                                                                  <input
                                                                    type="text"
                                                                    class="form-control"
                                                                    placeholder="http://www.example.com"
                                                                  />
                                                                </div>
                                                              </div>
                                                              <div
                                                                class="form-group row mb-3"
                                                                style="background: #fff;border-radius: 10px;padding: 16px;"
                                                              >
                                                                <label
                                                                  class="col-sm-4 col-form-label"
                                                                >Facebook</label>
                                                                <div class="col-sm-8">
                                                                  <input
                                                                    type="text"
                                                                    class="form-control"
                                                                    placeholder="http://www.example.com"
                                                                  />
                                                                </div>
                                                              </div>
                                                              <div
                                                                class="form-group row mb-3"
                                                                style="background: #fff;border-radius: 10px;padding: 16px;"
                                                              >
                                                                <label
                                                                  class="col-sm-4 col-form-label"
                                                                >YouTube</label>
                                                                <div class="col-sm-8">
                                                                  <input
                                                                    type="text"
                                                                    class="form-control"
                                                                    placeholder="http://www.example.com"
                                                                  />
                                                                </div>
                                                              </div>
                                                              <div
                                                                class="form-group row mb-3"
                                                                style="background: #fff;border-radius: 10px;padding: 16px;"
                                                              >
                                                                <label
                                                                  class="col-sm-4 col-form-label"
                                                                >Instagram</label>
                                                                <div class="col-sm-8">
                                                                  <input
                                                                    type="text"
                                                                    class="form-control"
                                                                    placeholder="http://www.example.com"
                                                                  />
                                                                </div>
                                                              </div>
                                                            </form>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                class="modal-footer p-10px border-top-0 p-r-35px"
                                              >
                                                <button
                                                  type="button"
                                                  class="btn btn-light"
                                                  data-dismiss="modal"
                                                >Save</button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-6">
                                        <div class="c_div">
                                          <span class="text-dark">Powered By:</span>
                                          <!-- <template
                                            class="d-none"
                                            v-if="settings.countryName.toLowerCase() === 'nepal' "
                                          >
                                            <img
                                              src="../assets/img/logo_front_new.png"
                                              class="img-responsive-sm c_logo aviner_logo"
                                              alt="Dure Technology"
                                              style="width:70px;"
                                            />
                                            <img
                                              src="../assets/img/logo.png"
                                              class="img-responsive-sm c_logo"
                                              alt="Dure Technology"
                                            />
                                          </template> -->
                                          <template>
                                            <img
                                              src="../assets/img/logo.png"
                                              class="img-responsive-sm c_logo"
                                              alt="Dure Technology"
                                            />
                                          </template>
                                        </div>
                                      </div>
                                    </div>
                                  </footer>
                                </div>
                              </div>
                              <div class="row m-t-20px m-b-20px">
                                <div class="col-sm-12 text-right">
                                  <!-- #111 -->
                                  <!-- <button type="submit" class="btn btn-primary black-btn mr-3" >Save</button> -->
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </tab-content>
          <tab-content title="Module Selection" icon="1" :before-change="validateSecondTab">
            <div class="card m-t-0px m-b-20px">
              <div class="card-header text-decoration-none">Select Your Module</div>
              <div class="collapse show">
                <div class="card-body p-40px">
                  <div class="row m-t-20px appconfing-selectmodulecontainer">
                    <div class="col-lg-3 text-center">
                      <p
                        v-if="settings.countryName.toLowerCase() === 'nepal' "
                      >Family Planning Services</p>
                      <p v-else>Ministerial Dashboard</p>
                      <div class="config-icon-container bg-light-red">
                        <img
                          src="../assets/img/home/min-dashboard-icon-white.png"
                          class="img img-fluid"
                        />
                      </div>
                      <div class="form-group m-t-20px">
                        <div class="form-check">
                          <div class="pure-checkbox">
                            <input
                              type="checkbox"
                              id="ministral-chk"
                              value="ministerial"
                              v-model="frmModel.modules"
                              disabled
                            />
                            <label for="ministral-chk"></label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 text-center">
                      <p
                        v-if="settings.countryName.toLowerCase() === 'nepal' "
                      >Adolescent Sexual Reproductive Health</p>
                      <p v-else>Analytical Dashboard</p>
                      <div class="config-icon-container bg-light-green">
                        <img
                          src="../assets/img/home/ana-dashboard-icon-white.png"
                          class="img img-fluid"
                        />
                      </div>
                      <div class="form-group m-t-20px">
                        <div class="form-check">
                          <div class="pure-checkbox">
                            <input
                              type="checkbox"
                              id="analytical-chk"
                              value="analytical"
                              v-model="frmModel.modules"
                              disabled
                            />
                            <label for="analytical-chk"></label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 text-center">
                      <p>Interactive Analytics</p>
                      <div class="config-icon-container bg-light-yellow">
                        <img src="../assets/img/home/int-icon-white.png" class="img img-fluid" />
                      </div>
                      <div class="form-group m-t-20px">
                        <div class="form-check">
                          <div class="pure-checkbox">
                            <input
                              type="checkbox"
                              id="interactive-chk"
                              value="interactive"
                              v-model="frmModel.modules"
                              disabled
                            />
                            <label for="interactive-chk"></label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 text-center">
                      <p>Saved Favorites</p>
                      <div class="config-icon-container bg-light-purple">
                        <img src="../assets/img/home/int-icon-white.png" class="img img-fluid" />
                      </div>
                      <div class="form-group m-t-20px">
                        <div class="form-check">
                          <div class="pure-checkbox">
                            <input
                              type="checkbox"
                              id="interactive-chk"
                              value="interactive"
                              v-model="frmModel.modules"
                              disabled
                            />
                            <label for="interactive-chk"></label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="settings.countryName.toLowerCase() === '' "
                      class="col-lg-3 text-center"
                    >
                      <p>Data Quality Review</p>
                      <div class="config-icon-container bg-light-blue">
                        <img
                          src="../assets/img/home/dataquality-icon-white.png"
                          class="img img-fluid"
                        />
                      </div>
                      <div class="form-group m-t-20px">
                        <div class="form-check">
                          <div class="pure-checkbox">
                            <input
                              type="checkbox"
                              id="dqr-chk"
                              value="dataqualityreview"
                              v-model="frmModel.modules"
                              disabled
                            />
                            <label for="dqr-chk"></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </tab-content>
          <tab-content
            title="Data Upload"
            class="uploadDataTab"
            icon="1"
            :before-change="validateThirdTab"
          >
            <h4 class="color-white f-w-100">
              <span class="border-bottom-white">Upload Your Data</span>
            </h4>
            <div class="card m-t-0px m-b-20px mt-4">
              <!-- <div class="card-header text-decoration-none" id style="border-bottom: 2px solid rgba(0,0,0,.1);">Upload Your Data</div> -->
              <div class="collapse show d-none">
                <div class="card-body p-40px">
                  <div class="panel-group" id="accordion">
                    <div class="panel panel-default m-b-20px">
                      <div class="row">
                        <div class="col-lg-1 vert-center">
                          <div class="form-check pure-checkbox">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="UploadDatasetSandbox"
                              id="UploadDataset"
                              value="sample"
                              v-model="frmModel.type"
                              @click="visible = !visible"
                              checked
                            />
                            <label class="form-check-label" for="UploadDataset"></label>
                          </div>
                        </div>
                        <div class="col-lg-11">
                          <a data-toggle="collapse">
                            <div class="panel-heading upload-data-heading">
                              <div class="panel-title">
                                <p
                                  class="img-container"
                                  style="width: 70px;display: inline-block;margin-bottom: 0px;text-align: center;"
                                >
                                  <img
                                    src="../assets/img/sample-data.png"
                                    style="max-width: 65px; max-height:35px;"
                                  />
                                </p>
                                <span style="color: #212121;">Use Sample Data</span>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default m-b-20px">
                      <div class="row">
                        <div class="col-lg-1 vert-center">
                          <div class="form-check pure-checkbox">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="UploadDatasetSandbox"
                              id="UploadDataset2"
                              value="upload"
                              v-model="frmModel.type"
                              :class="visible ? null : 'collapsed'"
                              :aria-expanded="visible ? 'true' : 'false'"
                              aria-controls="collapse-4"
                              @click="visible = !visible"
                            />
                            <label class="form-check-label" for="UploadDataset2"></label>
                          </div>
                        </div>
                        <div class="col-lg-11">
                          <a data-toggle="collapse" data-parent="#accordion" href="#collapseTwo">
                            <div class="panel-heading upload-data-heading">
                              <div class="panel-title">
                                <p
                                  class="img-container"
                                  style="width: 70px;display: inline-block;margin-bottom: 0px;text-align: center;"
                                >
                                  <img
                                    src="../assets/img/upload-data.png"
                                    style="max-width: 65px;max-height:35px;"
                                  />
                                </p>
                                <span style="color: #212121;">Upload Your Data</span>
                              </div>
                            </div>
                          </a>
                          <!-- <div id="collapseTwo" class="panel-collapse collapse"> -->
                          <b-collapse id="collapse-4" v-model="visible" class="mt-2">
                            <div
                              class="panel-body"
                              style="border: 1px solid #ddd;border-radius: 5px;border-top-left-radius: 0px;border-top-right-radius: 0px;"
                            >
                              <div class="input-group image-preview bg-white">
                                <input
                                  type="text"
                                  class="form-control image-preview-filename col-10"
                                  placeholder="Excel File Only"
                                  disabled="disabled"
                                  v-model="uploadedExcelName"
                                />
                                <span class="input-group-btn">
                                  <!-- image-preview-clear button -->
                                  <button
                                    type="button"
                                    class="btn btn-default image-preview-clear"
                                    style="display:none;"
                                  >
                                    <span class="glyphicon glyphicon-remove"></span> Clear
                                  </button>
                                  <!-- image-preview-input -->
                                  <div
                                    class="btn btn-default image-preview-input"
                                    style="padding: .52em 1em;"
                                  >
                                    <span class="glyphicon glyphicon-folder-open"></span>
                                    <span class="image-preview-input-title">Browse</span>

                                    <input
                                      type="file"
                                      name="file-upload"
                                      placeholder="Excel File Only"
                                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                      @change="onFileChange"
                                      ref="inputfile"
                                    />
                                    <!-- rename it -->
                                  </div>
                                </span>

                                <!-- <div class="form-ctas form-ctas-upload clearfix">
                                <button type="submit" class="btn">Upload </button>
                                <p class="forgot-password"><a href="#">Download Sample Data Template</a></p>
                                </div>-->
                              </div>
                              <div class="row" style="width: 80%;margin: 30px auto 40px;">
                                <div class="col-lg-8">
                                  <div class="form-group row mb-0">
                                    <span>{{frmModel.uploadedExcelName}}</span>
                                  </div>
                                </div>
                                <div class="col-lg-4">
                                  <div class="form-group row mb-0">
                                    <div class="col-sm-12 text-right pl-0">
                                      <a
                                        class="color-black"
                                        :href="downloadTemplateUrl"
                                        style="text-decoration: underline;font-size: 14px;margin-top: 10px;"
                                        target="_blank"
                                      >
                                        <i class="fa fa-arrow-down" style="margin-right: 15px;"></i>Download Sample Data Template
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </b-collapse>
                          <!-- </div> -->
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default m-b-20px">
                      <div class="row">
                        <div class="col-lg-1 vert-center">
                          <div class="form-check pure-checkbox">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="UploadDatasetSandbox"
                              id="UploadDataset3"
                              value="option3"
                              disabled
                            />
                            <label class="form-check-label" for="UploadDataset3"></label>
                          </div>
                        </div>
                        <div class="col-lg-11">
                          <a data-toggle="collapse">
                            <div class="panel-heading upload-data-heading">
                              <div class="panel-title">
                                <p
                                  class="img-container"
                                  style="width: 70px;display: inline-block;margin-bottom: 0px;text-align: center;"
                                >
                                  <img
                                    src="../assets/img/request-systems.png"
                                    style="max-width: 65px;max-height:35px;"
                                  />
                                </p>
                                <span
                                  style="color: #212121;"
                                >Request for Integration with Exisiting Systems</span>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row m-t-0px text-right"></div>
                </div>
              </div>

              <!-- new upload data structure starts -->
              <div class="card-body d-flex upload_data_tab px-0 py-0">
                <div
                  class="nav flex-column nav-pills col-3 pr-0"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <a
                    class="nav-link pl-3 py-4 disabled"
                    id="v-pills-home-tab"
                    data-toggle="pill"
                    href="#v-pills-home"
                    role="tab"
                    aria-controls="v-pills-home"
                    aria-selected="false"
                  >Use Sample Data</a>

                  <a
                    class="nav-link active pl-3 py-4"
                    id="v-pills-profile-tab"
                    data-toggle="pill"
                    href="#v-pills-profile"
                    role="tab"
                    aria-controls="v-pills-profile"
                    aria-selected="true"
                  >Data Upload</a>

                  <a
                    class="nav-link pl-3 py-4 disabled"
                    id="v-pills-messages-tab"
                    data-toggle="pill"
                    href="#v-pills-messages"
                    role="tab"
                    aria-controls="v-pills-messages"
                    aria-selected="false"
                  >Request for Integration with Exisiting Systems</a>
                  <!-- <a class="nav-link" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings" role="tab" aria-controls="v-pills-settings" aria-selected="false">Settings</a> -->
                </div>
                <div class="tab-content col-9" id="v-pills-tabContent">
                  <div
                    class="tab-pane fade"
                    id="v-pills-home"
                    role="tabpanel"
                    aria-labelledby="v-pills-home-tab"
                  >...</div>

                  <div
                    class="tab-pane fade show active py-4"
                    id="v-pills-profile"
                    role="tabpanel"
                    aria-labelledby="v-pills-profile-tab"
                  >
                    <div class="row">
                      <div class="col-6">
                        <div class="form-check pure-checkbox">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="uploadData"
                            id="static_data"
                            checked="checked"
                            v-model="selected"
                            value="staticData"
                          />
                          <label
                            class="form-check-label radioLabelName mainRadiobtn"
                            for="static_data"
                          >Static Data Upload</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-check pure-checkbox">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="uploadData"
                            id="dynamic_data"
                            v-model="selected"
                            value="dynamicData"
                          />
                          <label
                            class="form-check-label radioLabelName mainRadiobtn"
                            for="dynamic_data"
                          >Dynamic Data Upload</label>
                        </div>
                      </div>
                    </div>
                    <hr />

                    <template v-if="selected === 'staticData'">
                      <div class="staticProcess mt-4 pt-3" v-show="selected === 'staticData'">
                        <div class="row" style="width: 100%;">
                          <div class="col-lg-12">
                            <div class="panel-body">
                              <div class="form-group d-flex mb-0 float-right">
                                <div class="pl-0">
                                  <a
                                    class="color-black"
                                    :href="downloadTemplateUrl"
                                    style="text-decoration: underline;font-size: 14px;margin-top: 10px;"
                                    target="_blank"
                                  >
                                    <i class="fa fa-arrow-down" style="margin-right: 15px;"></i>Download Sample Data
                                  </a>
                                </div>
                                <div class="pl-5 hide">
                                  <a
                                    class="color-black"
                                    style="text-decoration: underline;font-size: 14px;margin-top: 10px;"
                                    target="_blank"
                                  >
                                    <i class="fa fa-eye" style="margin-right: 15px;"></i>View Sample Data
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <div class="panel-body bg-white mt-3" style>
                              <!-- <label>Select a file</label> -->
                              <div class="input-group image-preview mx-4" style="width:100%;">
                                <input
                                  type="text"
                                  class="form-control image-preview-filename col-10"
                                  placeholder="Excel File Only"
                                  disabled="disabled"
                                  v-model="uploadedExcelName"
                                  style="border: 1px solid #ccc; border-right: 0;"
                                />
                                <span class="input-group-btn">
                                  <!-- image-preview-clear button -->
                                  <button
                                    type="button"
                                    class="btn btn-default image-preview-clear"
                                    style="display:none;"
                                  >
                                    <span class="glyphicon glyphicon-remove"></span> Clear
                                  </button>
                                  <!-- image-preview-input -->
                                  <div
                                    class="btn btn-default bg-white image-preview-input"
                                    style="padding: .52em 1em; border-color: #ccc !important;"
                                  >
                                    <span class="glyphicon glyphicon-folder-open"></span>
                                    <span class="image-preview-input-title">Browse</span>

                                    <input
                                      type="file"
                                      name="file-upload"
                                      placeholder="Excel File Only"
                                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                      @change="onFileChange"
                                      ref="inputfile"
                                    />
                                    <!-- rename it -->
                                  </div>
                                </span>

                                <!-- <div class="form-ctas form-ctas-upload clearfix">
                                      <button type="submit" class="btn">Upload </button>
                                      <p class="forgot-password"><a href="#">Download Sample Data Template</a></p>
                                </div>-->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>

                    <template v-if="listOfTemplates.length > 0 && selected === 'dynamicData'">
                      <div class="listingTable">
                        <div class="row">
                          <div class="col-12">
                            <div class="row">
                              <div class="col-6">
                                <h6 class="section_title mt-3">List of Data Templates</h6>
                              </div>
                              <div class="col-6">
                                <button
                                  class="btn btn-sm float-right addTemp_btn"
                                  @click.prevent.stop="addNewTemplate"
                                >
                                  <span class="border_bottom_grey">
                                    <i class="fa fa-plus" aria-hidden="true"></i> Add Data Template
                                  </span>
                                </button>
                              </div>
                            </div>
                            <div class="card tableCard">
                              <div class="card-body px-5">
                                <table class="table table-hover mb-0">
                                  <thead>
                                    <tr class="row">
                                      <th class="col-1" style="border-top: 0;">#</th>
                                      <th class="col-4" style="border-top: 0;">Template Name</th>
                                      <th class="col-2" style="border-top: 0;">Last Updated</th>
                                      <th class="col-2" style="border-top: 0;">Username</th>
                                      <th class="col-3" style="border-top: 0;">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr class="row" v-for="template,i in templatesList" :key="i">
                                      <td class="col-1" style="border-top: 0;">{{template.index}}</td>
                                      <td class="col-4" style="border-top: 0;">{{template.name}}</td>
                                      <td
                                        class="col-2"
                                        style="border-top: 0;"
                                      >{{template.lastUpdated }}</td>
                                      <td
                                        class="col-2"
                                        style="border-top: 0;"
                                      >{{template.userName }}</td>
                                      <td class="col-3" style="border-top: 0;">
                                        <div class="iconGroup">
                                          <i
                                            class="fa fa-pencil-square-o"
                                            aria-hidden="true"
                                            v-b-tooltip.hover
                                            title="Edit"
                                            v-show="selected === 'dynamicData'"
                                            @click.prevent.stop="editTemplate(template.templateId)"
                                          ></i>
                                          <i
                                            class="fa fa-upload"
                                            aria-hidden="true"
                                            v-b-tooltip.hover
                                            title="Upload"
                                            v-show="selected === 'dynamicData'"
                                            @click.prevent.stop="uploadData(template.templateId)"
                                          ></i>
                                          <i
                                            class="fa fa-trash"
                                            aria-hidden="true"
                                            v-b-tooltip.hover
                                            title="Delete"
                                            v-show="selected === 'dynamicData'"
                                            @click.prevent.stop="deleteData(template.templateId)"
                                          ></i>
                                          <!-- <i
                                            class="fa fa-download"
                                            aria-hidden="true"
                                            v-b-tooltip.hover
                                            title="Download"
                                          ></i>-->
                                          <!-- <i
                                            class="fa fa-eye"
                                            aria-hidden="true"
                                            v-b-tooltip.hover
                                            title="View"
                                            v-show="selected === 'dynamicData'"
                                          ></i>-->
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <b-pagination
                                  v-model="currentPage"
                                  :total-rows="rows"
                                  :per-page="perPage"
                                  first-number
                                  last-number
                                  first-text="First"
                                  prev-text="Prev"
                                  next-text="Next"
                                  last-text="Last"
                                  align="center"
                                ></b-pagination>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="staticProcess mt-4 pt-3"
                        v-if="selected === 'dynamicData' && isUpload"
                        style="border-top: 1px solid rgba(0,0,0,.1);"
                      >
                        <div class="row">
                          <div class="col-12">
                            Selected Template ::
                            <strong>{{uploadTemplateName}}</strong>
                          </div>
                          <div class="col-12">
                            <div class="panel-body bg-white mt-3" style>
                              <!-- <label>Select a file</label> -->
                              <div class="input-group image-preview mx-4" style="width:100%;">
                                <input
                                  type="text"
                                  class="form-control image-preview-filename col-10"
                                  placeholder="Excel File Only"
                                  disabled="disabled"
                                  v-model="uploadedExcelName"
                                  style="border: 1px solid #ccc; border-right: 0;"
                                />
                                <span class="input-group-btn">
                                  <!-- image-preview-clear button -->
                                  <button
                                    type="button"
                                    class="btn btn-default image-preview-clear"
                                    style="display:none;"
                                  >
                                    <span class="glyphicon glyphicon-remove"></span> Clear
                                  </button>
                                  <!-- image-preview-input -->
                                  <div
                                    class="btn btn-default bg-white image-preview-input"
                                    style="padding: .52em 1em; border-color: #ccc !important;"
                                  >
                                    <span class="glyphicon glyphicon-folder-open"></span>
                                    <span class="image-preview-input-title">Browse</span>

                                    <input
                                      type="file"
                                      name="file-upload"
                                      placeholder="Excel File Only"
                                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                      @change="onFileChange"
                                      ref="inputfile"
                                    />
                                    <!-- rename it -->
                                  </div>
                                </span>

                                <!-- <div class="form-ctas form-ctas-upload clearfix">
                                      <button type="submit" class="btn">Upload </button>
                                      <p class="forgot-password"><a href="#">Download Sample Data Template</a></p>
                                </div>-->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-if="listOfTemplates.length === 0 && selected === 'dynamicData'">
                      <div class="row text-center">
                        <div class="col">No Templates Saved!</div>
                      </div>
                    </template>
                    <!-- dynamic data upload section starts -->
                    <template v-if="isAddNewTemplate">
                      <section
                        class="dynamicDataProcess mt-4 pt-3"
                        v-show="selected === 'dynamicData'"
                        style="border-top: 1px solid rgba(0,0,0,.1);"
                      >
                        <div class="row">
                          <div class="col-6">
                            <h6>{{isEdit ? 'Edit' : 'Add'}} data template</h6>
                          </div>
                          <div class="col-6">
                            <p class="float-right text-danger">
                              <small>* feilds are mandatory</small>
                            </p>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <form>
                              <div class="form-group row">
                                <label for="temp_name" class="col-sm-3 col-form-label">
                                  Template name
                                  <sup class="text-danger">*</sup>
                                </label>
                                <div class="col-sm-7">
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="temp_name"
                                    v-model="templateDetails.templateName"
                                  />
                                </div>
                                <div class="col-sm-2"></div>
                              </div>
                              <div class="form-group row">
                                <label for="descp" class="col-sm-3 col-form-label">
                                  Description
                                  <sup class="text-danger">*</sup>
                                </label>
                                <div class="col-sm-7">
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="descp"
                                    v-model="templateDetails.templateDesc"
                                  />
                                </div>
                                <div class="col-sm-2"></div>
                              </div>
                              <div class="form-group row">
                                <label for="file_type" class="col-sm-3 col-form-label">
                                  Select your file type
                                  <sup class="text-danger">*</sup>
                                </label>
                                <div class="col-sm-7">
                                  <div class="select-wrapper">
                                    <select
                                      class="form-control"
                                      id="file_type"
                                      v-model="templateDetails.fileType"
                                    >
                                      <option value disabled>Select your file type</option>
                                      <option value="xlsx">xlsx</option>
                                      <option value="csv">csv</option>
                                      <option value="xls">xls</option>
                                      <option value="json">json</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-sm-2"></div>
                              </div>
                              <div class="form-group row">
                                <label for="noSheet" class="col-sm-3 col-form-label">
                                  Number of sheets to read
                                  <sup class="text-danger">*</sup>
                                </label>
                                <div class="col-sm-7">
                                  <div class="select-wrapper">
                                    <select
                                      class="form-control"
                                      id="noSheet"
                                      v-model="templateDetails.numSheetsToRead"
                                    >
                                      <option value disabled>Select number of sheets</option>
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                      <option value="4">4</option>
                                      <option value="5">5</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-sm-2" v-if="!isProceed">
                                  <button
                                    class="btn btn-success btn-sm btn-block mt-1"
                                    :disabled="!(templateDetails.templateName && templateDetails.templateDesc && templateDetails.fileType && templateDetails.numSheetsToRead)"
                                    @click.prevent.stop="proceed"
                                  >Proceed</button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </section>
                      <transition name="slide-fade">
                        <section
                          class="dynamic_questWrap"
                          v-if="isProceed && sheets.length > 0 && selected === 'dynamicData'"
                        >
                          <form-wizard
                            shape="tab"
                            class="sandbox_setup sheetsNumber mb-0"
                            @on-complete="submitTemplate"
                            finish-button-text="Save"
                          >
                            <tab-content
                              :title="'Add Sheet'+(index+1)"
                              icon="1"
                              v-for="sheet,index in sheets"
                              :key="index"
                            >
                              <div class="subTab">
                                <ul class="nav nav-pills">
                                  <li class="nav-item">
                                    <a class="nav-link active" href="#sheetTab">Sheet</a>
                                  </li>
                                  <li class="nav-item">
                                    <a class="nav-link" href="#parentLocTab">Parent location</a>
                                  </li>
                                  <li class="nav-item">
                                    <a class="nav-link" href="#childLocTab">Location</a>
                                  </li>
                                  <li class="nav-item">
                                    <a class="nav-link" href="#category">Category</a>
                                  </li>
                                  <li class="nav-item">
                                    <a class="nav-link" href="#dataElement">Data element</a>
                                  </li>
                                  <li class="nav-item">
                                    <a class="nav-link" href="#gde">Data element group</a>
                                  </li>
                                  <li class="nav-item">
                                    <a class="nav-link" href="#period">Period</a>
                                  </li>
                                </ul>
                              </div>

                              <section class="formSection">
                                <div id="sheetTab">
                                  <div class="row mt-3">
                                    <div class="col-12">
                                      <h6 class="formTitle my-2 mb-3">
                                        <span class="sr_num">1.</span> Sheet
                                      </h6>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-12">
                                      <form>
                                        <div class="form-group row">
                                          <label
                                            :for="'sheet_name'+index"
                                            class="col-sm-3 col-form-label"
                                          >Sheet name</label>
                                          <div class="col-sm-9">
                                            <input
                                              type="text"
                                              class="form-control"
                                              :id="'sheet_name'+index"
                                              v-model="sheet.sheetName"
                                            />
                                          </div>
                                          <!-- <div class="col-sm-2"></div> -->
                                        </div>

                                        <div class="form-group row">
                                          <label
                                            :for="'startFromRow'+index"
                                            class="col-sm-3 col-form-label"
                                          >
                                            <sup>
                                              <i
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                                v-b-popover.hover.top="'I am popover directive content!'"
                                                title="Popover Title"
                                              ></i>
                                            </sup>
                                            Start from row
                                          </label>
                                          <div class="col-sm-9">
                                            <input
                                              type="number"
                                              class="form-control"
                                              :id="'startFromRow'+index"
                                              v-model="sheet.readFrom"
                                            />
                                          </div>
                                          <!-- <div class="col-sm-2"></div> -->
                                        </div>
                                        <div class="form-group row">
                                          <label
                                            :for="'row_pos_sheet'+index"
                                            class="col-sm-3 col-form-label"
                                          >
                                            <sup>
                                              <i
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                                v-b-popover.hover.top="'I am popover directive content!'"
                                                title="Popover Title"
                                              ></i>
                                            </sup>
                                            Value Row Position
                                          </label>
                                          <div class="col-sm-3">
                                            <input
                                              type="number"
                                              class="form-control"
                                              :id="'row_pos_sheet'+index"
                                              v-model="sheet.valueRowPosition"
                                            />
                                          </div>
                                          <label
                                            :for="'col_pos_sheet'+index"
                                            class="col-sm-3 col-form-label"
                                          >
                                            <sup>
                                              <i
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                                v-b-popover.hover.top="'I am popover directive content!'"
                                                title="Popover Title"
                                              ></i>
                                            </sup>
                                            Value Column Position
                                          </label>
                                          <div class="col-sm-3">
                                            <input
                                              type="number"
                                              class="form-control"
                                              :id="'col_pos_sheet'+index"
                                              v-model="sheet.valueColPosition"
                                            />
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label
                                            :for="'serviceName'+index"
                                            class="col-sm-3 col-form-label"
                                          >
                                            <sup>
                                              <i
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                                v-b-popover.hover.top="'I am popover directive content!'"
                                                title="Popover Title"
                                              ></i>
                                            </sup>
                                            Service Name
                                          </label>
                                          <div class="col-sm-9">
                                            <input
                                              type="text"
                                              class="form-control"
                                              :id="'serviceName'+index"
                                              v-model="sheet.serviceName"
                                            />
                                          </div>
                                          <!-- <div class="col-sm-2"></div> -->
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                                <hr />
                                <div id="parentLocTab">
                                  <div class="row mt-3">
                                    <div class="col-12">
                                      <h6 class="formTitle my-2 mb-3">
                                        <span class="sr_num">2.</span> Parent Location Details
                                      </h6>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-12 px-2">
                                      <form>
                                        <!-- <div class="form-group row">
                                          <label for="loc_detail" class="col-sm-3 col-form-label">
                                            <sup>
                                              <i
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                                v-b-popover.hover.top="'I am popover directive content!'"
                                                title="Popover Title"
                                              ></i>
                                            </sup>

                                            Add location details
                                          </label>
                                          <div class="col-sm-7">
                                            <div class="select-wrapper">
                                              <select class="form-control" id="loc_detail">
                                                <option>Add data template configuration</option>
                                              </select>
                                            </div>
                                          </div>
                                          <div class="col-sm-2"></div>
                                        </div>-->
                                        <div class="form-group row">
                                          <!-- <label :for="'loc_detail'+index" class="col-sm-3 col-form-label d-none">
                                            <sup>
                                              <i
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                                v-b-popover.hover.top="'I am popover directive content!'"
                                                title="Popover Title"
                                              ></i>
                                            </sup>

                                            Add location details
                                          </label>-->
                                          <div class="col-sm-12">
                                            <div class="row">
                                              <label
                                                :for="'locName'+index"
                                                class="col-sm-3 col-form-label"
                                              >
                                                <sup>
                                                  <i
                                                    class="fa fa-info-circle"
                                                    aria-hidden="true"
                                                    v-b-popover.hover.top="'I am popover directive content!'"
                                                    title="Popover Title"
                                                  ></i>
                                                </sup>
                                                Location Name
                                              </label>
                                              <div class="col-sm-9">
                                                <input
                                                  class="form-control"
                                                  :id="'locName'+index"
                                                  v-model="sheet.baseOrg"
                                                />
                                              </div>
                                            </div>
                                            <div class="row my-3">
                                              <label
                                                :for="'processType'+index"
                                                class="col-sm-3 col-form-label"
                                              >Process type</label>
                                              <div class="col-sm-9">
                                                <div class="d-flex mt-2">
                                                  <div class="form-check pure-checkbox pl-0">
                                                    <input
                                                      class="form-check-input"
                                                      type="radio"
                                                      name="p_type_2"
                                                      :id="'vertical1'+index"
                                                      value="true"
                                                      v-model="sheet.level1Vertical"
                                                    />
                                                    <label
                                                      class="form-check-label radioLabelName mainRadiobtn"
                                                      :for="'vertical1'+index"
                                                    >Vertical</label>
                                                  </div>
                                                  <div class="form-check pure-checkbox">
                                                    <input
                                                      class="form-check-input"
                                                      type="radio"
                                                      name="p_type_2"
                                                      :id="'horizontal1'+index"
                                                      value="false"
                                                      v-model="sheet.level1Vertical"
                                                    />
                                                    <label
                                                      class="form-check-label radioLabelName mainRadiobtn"
                                                      :for="'horizontal1'+index"
                                                    >Horizontal</label>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="row">
                                              <label
                                                :for="'row_pos_loc'+index"
                                                class="col-sm-3 col-form-label"
                                              >
                                                <sup>
                                                  <i
                                                    class="fa fa-info-circle"
                                                    aria-hidden="true"
                                                    v-b-popover.hover.top="'I am popover directive content!'"
                                                    title="Popover Title"
                                                  ></i>
                                                </sup>
                                                Row Position
                                              </label>
                                              <div class="col-sm-3">
                                                <input
                                                  type="number"
                                                  class="form-control"
                                                  :id="'row_pos_loc'+index"
                                                  v-model="sheet.parentOrgRowPosition"
                                                />
                                              </div>
                                              <label
                                                :for="'col_pos_loc'+index"
                                                class="col-sm-3 col-form-label"
                                              >
                                                <sup>
                                                  <i
                                                    class="fa fa-info-circle"
                                                    aria-hidden="true"
                                                    v-b-popover.hover.top="'I am popover directive content!'"
                                                    title="Popover Title"
                                                  ></i>
                                                </sup>
                                                Column Position
                                              </label>
                                              <div class="col-sm-3">
                                                <input
                                                  type="number"
                                                  class="form-control"
                                                  :id="'col_pos_loc'+index"
                                                  v-model="sheet.parentOrgColPosition"
                                                />
                                              </div>
                                            </div>
                                            <div class="row my-3">
                                              <label for class="col-sm-3 col-form-label">
                                                <sup>
                                                  <i
                                                    class="fa fa-info-circle"
                                                    aria-hidden="true"
                                                    v-b-popover.hover.top="'I am popover directive content!'"
                                                    title="Popover Title"
                                                  ></i>
                                                </sup>
                                                Records
                                              </label>
                                              <div
                                                class="col-sm-3"
                                                style="padding-right:0 !important;"
                                              >
                                                <div class="d-flex mt-2">
                                                  <div class="form-check pure-checkbox pl-0">
                                                    <input
                                                      class="form-check-input"
                                                      type="radio"
                                                      name="record_2"
                                                      :id="'read_all1'+index"
                                                      value="read_all"
                                                      v-model="sheet.parentRecords"
                                                    />
                                                    <label
                                                      class="form-check-label radioLabelName mainRadiobtn"
                                                      :for="'read_all1'+index"
                                                    >Read all</label>
                                                  </div>
                                                  <div class="form-check pure-checkbox">
                                                    <input
                                                      class="form-check-input"
                                                      type="radio"
                                                      name="record_2"
                                                      :id="'ignore1'+index"
                                                      value="ignore"
                                                      v-model="sheet.parentRecords"
                                                    />
                                                    <label
                                                      class="form-check-label radioLabelName mainRadiobtn"
                                                      :for="'ignore1'+index"
                                                    >Ignore</label>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                class="col-sm-3"
                                                style
                                                v-if="sheet.parentRecords === 'ignore'"
                                              >
                                                <div class="form-control d-flex">
                                                  <label
                                                    :for="'col_feild_parent'+index"
                                                    class="col-form-label pt-1"
                                                  >Col</label>
                                                  <input
                                                    class="form-control ml-2"
                                                    :id="'col_feild_parent'+index"
                                                    v-model="sheet.baseOrgIgnoreRows"
                                                    style="height:26px;"
                                                  />
                                                </div>
                                              </div>

                                              <div
                                                class="col-sm-3"
                                                style
                                                v-if="sheet.parentRecords === 'ignore'"
                                              >
                                                <div class="form-control d-flex">
                                                  <label
                                                    :for="'row_feild_parent'+index"
                                                    class="col-form-label pt-1"
                                                  >Row</label>
                                                  <input
                                                    class="form-control ml-2"
                                                    :id="'row_feild_parent'+index"
                                                    v-model="sheet.baseOrgIgnoreCols"
                                                    style="height:26px;"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                                <hr />
                                <div id="childLocTab">
                                  <div class="row mt-3">
                                    <div class="col-12">
                                      <h6 class="formTitle my-2 mb-3">
                                        <span class="sr_num">3.</span> Location Details
                                      </h6>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-12 px-2">
                                      <form>
                                        <!-- <div class="form-group row">
                                          <label :for="'loc_detail'+index" class="col-sm-3 col-form-label">
                                            <sup>
                                              <i
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                                v-b-popover.hover.top="'I am popover directive content!'"
                                                title="Popover Title"
                                              ></i>
                                            </sup>

                                            Add location details
                                          </label>
                                          <div class="col-sm-7">
                                            <div class="select-wrapper">
                                              <select class="form-control" :id="'loc_detail'+index">
                                                <option>Add data template configuration</option>
                                              </select>
                                            </div>
                                          </div>
                                          <div class="col-sm-2"></div>
                                        </div>-->
                                        <div class="form-group row">
                                          <!-- <div class="col-sm-3"></div> -->
                                          <div class="col-sm-12">
                                            <div class="row">
                                              <label
                                                :for="'locNameChild'+index"
                                                class="col-sm-3 col-form-label"
                                              >
                                                <sup>
                                                  <i
                                                    class="fa fa-info-circle"
                                                    aria-hidden="true"
                                                    v-b-popover.hover.top="'I am popover directive content!'"
                                                    title="Popover Title"
                                                  ></i>
                                                </sup>
                                                Location Name
                                              </label>
                                              <div class="col-sm-9">
                                                <input
                                                  class="form-control"
                                                  :id="'locNameChild'+index"
                                                  v-model="sheet.childLevelOrg"
                                                />
                                              </div>
                                            </div>
                                            <div class="row my-3">
                                              <label
                                                :for="'processType'+index"
                                                class="col-sm-3 col-form-label"
                                              >Process type</label>
                                              <div class="col-sm-9">
                                                <div class="d-flex mt-2">
                                                  <div class="form-check pure-checkbox pl-0">
                                                    <input
                                                      class="form-check-input"
                                                      type="radio"
                                                      name="p_type_3"
                                                      :id="'vertical2'+index"
                                                      value="true"
                                                      v-model="sheet.childOrgVertical"
                                                    />
                                                    <label
                                                      class="form-check-label radioLabelName mainRadiobtn"
                                                      :for="'vertical2'+index"
                                                    >Vertical</label>
                                                  </div>
                                                  <div class="form-check pure-checkbox">
                                                    <input
                                                      class="form-check-input"
                                                      type="radio"
                                                      name="p_type_3"
                                                      :id="'horizontal2'+index"
                                                      value="false"
                                                      v-model="sheet.childOrgVertical"
                                                    />
                                                    <label
                                                      class="form-check-label radioLabelName mainRadiobtn"
                                                      :for="'horizontal2'+index"
                                                    >Horizontal</label>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="row">
                                              <label
                                                :for="'row_pos'+index"
                                                class="col-sm-3 col-form-label"
                                              >
                                                <sup>
                                                  <i
                                                    class="fa fa-info-circle"
                                                    aria-hidden="true"
                                                    v-b-popover.hover.top="'I am popover directive content!'"
                                                    title="Popover Title"
                                                  ></i>
                                                </sup>
                                                Row Position
                                              </label>
                                              <div class="col-sm-3">
                                                <input
                                                  type="number"
                                                  class="form-control"
                                                  :id="'row_pos'+index"
                                                  v-model="sheet.childOrgRowPosition"
                                                />
                                              </div>
                                              <label
                                                :for="'col_pos'+index"
                                                class="col-sm-3 col-form-label"
                                              >
                                                <sup>
                                                  <i
                                                    class="fa fa-info-circle"
                                                    aria-hidden="true"
                                                    v-b-popover.hover.top="'I am popover directive content!'"
                                                    title="Popover Title"
                                                  ></i>
                                                </sup>
                                                Column Position
                                              </label>
                                              <div class="col-sm-3">
                                                <input
                                                  type="number"
                                                  class="form-control"
                                                  :id="'col_pos'+index"
                                                  v-model="sheet.childOrgColPosition"
                                                />
                                              </div>
                                            </div>
                                            <div class="row my-3">
                                              <label
                                                :for="'records'+index"
                                                class="col-sm-3 col-form-label"
                                              >
                                                <sup>
                                                  <i
                                                    class="fa fa-info-circle"
                                                    aria-hidden="true"
                                                    v-b-popover.hover.top="'I am popover directive content!'"
                                                    title="Popover Title"
                                                  ></i>
                                                </sup>
                                                Records
                                              </label>
                                              <div
                                                class="col-sm-3"
                                                style="padding-right:0 !important;"
                                              >
                                                <div class="d-flex mt-2">
                                                  <div class="form-check pure-checkbox pl-0">
                                                    <input
                                                      class="form-check-input"
                                                      type="radio"
                                                      name="record_3"
                                                      :id="'read_all2'+index"
                                                      value="read_all"
                                                      v-model="sheet.childRecords"
                                                    />
                                                    <label
                                                      class="form-check-label radioLabelName mainRadiobtn"
                                                      :for="'read_all2'+index"
                                                    >Read all</label>
                                                  </div>
                                                  <div class="form-check pure-checkbox">
                                                    <input
                                                      class="form-check-input"
                                                      type="radio"
                                                      name="record_3"
                                                      :id="'ignore2'+index"
                                                      value="ignore"
                                                      v-model="sheet.childRecords"
                                                    />
                                                    <label
                                                      class="form-check-label radioLabelName mainRadiobtn"
                                                      :for="'ignore2'+index"
                                                    >Ignore</label>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                class="col-sm-3"
                                                style
                                                v-if="sheet.childRecords === 'ignore'"
                                              >
                                                <div class="form-control d-flex">
                                                  <label
                                                    :for="'col_feild_child'+index"
                                                    class="col-form-label pt-1"
                                                  >Col</label>
                                                  <input
                                                    class="form-control ml-2"
                                                    :id="'col_feild_child'+index"
                                                    v-model="sheet.childOrgIgnoreRows"
                                                    style="height:26px;"
                                                  />
                                                </div>
                                              </div>

                                              <div
                                                class="col-sm-3"
                                                style
                                                v-if="sheet.childRecords === 'ignore'"
                                              >
                                                <div class="form-control d-flex">
                                                  <label
                                                    :for="'row_feild_child'+index"
                                                    class="col-form-label pt-1"
                                                  >Row</label>
                                                  <input
                                                    class="form-control ml-2"
                                                    :id="'row_feild_child'+index"
                                                    v-model="sheet.childOrgIgnoreCols"
                                                    style="height:26px;"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                                <hr />
                                <div id="category">
                                  <div class="row mt-3">
                                    <div class="col-12">
                                      <h6 class="formTitle my-2 mb-3">
                                        <span class="sr_num">4.</span> Category Details
                                      </h6>
                                    </div>
                                  </div>

                                  <div class="row">

                                    <div class="col-12">
                                      <form>
                                        <div class="form-group row" style="padding-left: 10px;">
                                          <b>Primary Category Details:</b>
                                        </div>
                                        <div class="form-group row">
                                          <label
                                            :for="'catg_name'+index"
                                            class="col-sm-3 col-form-label"
                                          >
                                            <sup>
                                              <i
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                                v-b-popover.hover.top="'I am popover directive content!'"
                                                title="Popover Title"
                                              ></i>
                                            </sup>
                                            Primary Category Name
                                          </label>
                                          <div class="col-sm-9">
                                            <input
                                              class="form-control"
                                              :id="'catg_name'+index"
                                              v-model="sheet.categoryPrimaryName"
                                            />
                                          </div>
                                          <!-- <div class="col-sm-2"></div> -->
                                        </div>

                                        <div class="form-group row my-3">
                                          <label
                                            :for="'processType'+index"
                                            class="col-sm-3 col-form-label"
                                          >
                                            <sup>
                                              <i
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                                v-b-popover.hover.top="'I am popover directive content!'"
                                                title="Popover Title"
                                              ></i>
                                            </sup>
                                            Process type
                                          </label>
                                          <div class="col-sm-9">
                                            <div class="d-flex mt-2">
                                              <div class="form-check pure-checkbox pl-0">
                                                <input
                                                  class="form-check-input"
                                                  type="radio"
                                                  name="pp_type_4"
                                                  :id="'vertical3'+index"
                                                  value="true"
                                                  v-model="sheet.categoryPrimaryVertical"
                                                />
                                                <label
                                                  class="form-check-label radioLabelName mainRadiobtn"
                                                  :for="'vertical3'+index"
                                                >Vertical</label>
                                              </div>
                                              <div class="form-check pure-checkbox">
                                                <input
                                                  class="form-check-input"
                                                  type="radio"
                                                  name="pp_type_4"
                                                  :id="'horizontal3'+index"
                                                  value="false"
                                                  v-model="sheet.categoryPrimaryVertical"
                                                />
                                                <label
                                                  class="form-check-label radioLabelName mainRadiobtn"
                                                  :for="'horizontal3'+index"
                                                >Horizontal</label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label
                                            :for="'row_pos_category'+index"
                                            class="col-sm-3 col-form-label"
                                          >
                                            <sup>
                                              <i
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                                v-b-popover.hover.top="'I am popover directive content!'"
                                                title="Popover Title"
                                              ></i>
                                            </sup>
                                            Row Position
                                          </label>
                                          <div class="col-sm-3">
                                            <input
                                              type="number"
                                              class="form-control"
                                              :id="'row_pos_category'+index"
                                              v-model="sheet.categoryPrimaryRowPosition"
                                            />
                                          </div>
                                          <label
                                            :for="'col_pos_category'+index"
                                            class="col-sm-3 col-form-label"
                                          >
                                            <sup>
                                              <i
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                                v-b-popover.hover.top="'I am popover directive content!'"
                                                title="Popover Title"
                                              ></i>
                                            </sup>
                                            Column Position
                                          </label>
                                          <div class="col-sm-3">
                                            <input
                                              type="number"
                                              class="form-control"
                                              :id="'col_pos_category'+index"
                                              v-model="sheet.categoryPrimaryColPosition"
                                            />
                                          </div>
                                        </div>
                                        <div class="form-group row" style="padding-left: 10px;">
                                          <b>Secondary Category Details:</b>
                                        </div>
                                        <div class="form-group row">
                                          <label
                                            :for="'catg_name'+index"
                                            class="col-sm-3 col-form-label"
                                          >
                                            <sup>
                                              <i
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                                v-b-popover.hover.top="'I am popover directive content!'"
                                                title="Popover Title"
                                              ></i>
                                            </sup>
                                            Category Name
                                          </label>
                                          <div class="col-sm-9">
                                            <input
                                              class="form-control"
                                              :id="'catg_name'+index"
                                              v-model="sheet.categoryName"
                                            />
                                          </div>
                                          <!-- <div class="col-sm-2"></div> -->
                                        </div>

                                        <div class="form-group row my-3">
                                          <label
                                            :for="'processType'+index"
                                            class="col-sm-3 col-form-label"
                                          >
                                            <sup>
                                              <i
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                                v-b-popover.hover.top="'I am popover directive content!'"
                                                title="Popover Title"
                                              ></i>
                                            </sup>
                                            Process type
                                          </label>
                                          <div class="col-sm-9">
                                            <div class="d-flex mt-2">
                                              <div class="form-check pure-checkbox pl-0">
                                                <input
                                                  class="form-check-input"
                                                  type="radio"
                                                  name="p_type_4"
                                                  :id="'vertical3'+index"
                                                  value="true"
                                                  v-model="sheet.categoryVertical"
                                                />
                                                <label
                                                  class="form-check-label radioLabelName mainRadiobtn"
                                                  :for="'vertical3'+index"
                                                >Vertical</label>
                                              </div>
                                              <div class="form-check pure-checkbox">
                                                <input
                                                  class="form-check-input"
                                                  type="radio"
                                                  name="p_type_4"
                                                  :id="'horizontal3'+index"
                                                  value="false"
                                                  v-model="sheet.categoryVertical"
                                                />
                                                <label
                                                  class="form-check-label radioLabelName mainRadiobtn"
                                                  :for="'horizontal3'+index"
                                                >Horizontal</label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label
                                            :for="'row_pos_category'+index"
                                            class="col-sm-3 col-form-label"
                                          >
                                            <sup>
                                              <i
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                                v-b-popover.hover.top="'I am popover directive content!'"
                                                title="Popover Title"
                                              ></i>
                                            </sup>
                                            Row Position
                                          </label>
                                          <div class="col-sm-3">
                                            <input
                                              type="number"
                                              class="form-control"
                                              :id="'row_pos_category'+index"
                                              v-model="sheet.categoryRowPosition"
                                            />
                                          </div>
                                          <label
                                            :for="'col_pos_category'+index"
                                            class="col-sm-3 col-form-label"
                                          >
                                            <sup>
                                              <i
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                                v-b-popover.hover.top="'I am popover directive content!'"
                                                title="Popover Title"
                                              ></i>
                                            </sup>
                                            Column Position
                                          </label>
                                          <div class="col-sm-3">
                                            <input
                                              type="number"
                                              class="form-control"
                                              :id="'col_pos_category'+index"
                                              v-model="sheet.categoryColPosition"
                                            />
                                          </div>
                                        </div>
                                        <div class="form-group row my-3">
                                          <label for class="col-sm-3 col-form-label">
                                            <sup>
                                              <i
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                                v-b-popover.hover.top="'I am popover directive content!'"
                                                title="Popover Title"
                                              ></i>
                                            </sup>
                                            Records
                                          </label>
                                          <div class="col-sm-3" style="padding-right:0 !important;">
                                            <div class="d-flex mt-2">
                                              <div class="form-check pure-checkbox pl-0">
                                                <input
                                                  class="form-check-input"
                                                  type="radio"
                                                  name="record_4"
                                                  :id="'read_all3'+index"
                                                  value="read_all"
                                                  v-model="sheet.categoryRecords"
                                                />
                                                <label
                                                  class="form-check-label radioLabelName mainRadiobtn"
                                                  :for="'read_all3'+index"
                                                >Read all</label>
                                              </div>
                                              <div class="form-check pure-checkbox">
                                                <input
                                                  class="form-check-input"
                                                  type="radio"
                                                  name="record_4"
                                                  :id="'ignore3'+index"
                                                  value="ignore"
                                                  v-model="sheet.categoryRecords"
                                                />
                                                <label
                                                  class="form-check-label radioLabelName mainRadiobtn"
                                                  :for="'ignore3'+index"
                                                >Ignore</label>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            class="col-sm-3"
                                            style
                                            v-if="sheet.categoryRecords === 'ignore'"
                                          >
                                            <div class="form-control d-flex">
                                              <label
                                                :for="'col_feild_category'+index"
                                                class="col-form-label pt-1"
                                              >Col</label>
                                              <input
                                                class="form-control ml-2"
                                                :id="'col_feild_category'+index"
                                                v-model="sheet.categoryIgnoreCols"
                                                style="height:26px;"
                                              />
                                            </div>
                                          </div>

                                          <div
                                            class="col-sm-3"
                                            style
                                            v-if="sheet.categoryRecords === 'ignore'"
                                          >
                                            <div class="form-control d-flex">
                                              <label
                                                :for="'row_feild_category'+index"
                                                class="col-form-label pt-1"
                                              >Row</label>
                                              <input
                                                class="form-control ml-2"
                                                :id="'row_feild_category'+index"
                                                v-model="sheet.categoryIgnoreRows"
                                                style="height:26px;"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label
                                            :for="'ccdd'+index"
                                            class="col-sm-3 col-form-label"
                                          >
                                            <sup>
                                              <i
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                                v-b-popover.hover.top="'I am popover directive content!'"
                                                title="Popover Title"
                                              ></i>
                                            </sup>
                                            Category combo data dimension
                                          </label>
                                          <div class="col-sm-5">
                                            <div class>
                                              <b-form-select
                                                v-model="sheet.catComboDimensionType"
                                                :options="dimensionTypeList"
                                                class="form-control"
                                              ></b-form-select>
                                            </div>
                                          </div>
                                          <div class="col-sm-4"></div>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                                <hr />
                                <div id="dataElement">
                                  <div class="row mt-3">
                                    <div class="col-12">
                                      <h6 class="formTitle my-2 mb-3">
                                        <span class="sr_num">5.</span> Data Element Details
                                      </h6>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-12">
                                      <form>
                                        <div class="form-group row my-3">
                                          <label
                                            :for="'processType'+index"
                                            class="col-sm-3 col-form-label"
                                          >
                                            <sup>
                                              <i
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                                v-b-popover.hover.top="'I am popover directive content!'"
                                                title="Popover Title"
                                              ></i>
                                            </sup>
                                            Process type
                                          </label>
                                          <div class="col-sm-9">
                                            <div class="d-flex mt-2">
                                              <div class="form-check pure-checkbox pl-0">
                                                <input
                                                  class="form-check-input"
                                                  type="radio"
                                                  name="p_type_5"
                                                  :id="'vertical4'+index"
                                                  value="true"
                                                  v-model="sheet.dataElementVertical"
                                                />
                                                <label
                                                  class="form-check-label radioLabelName mainRadiobtn"
                                                  :for="'vertical4'+index"
                                                >Vertical</label>
                                              </div>
                                              <div class="form-check pure-checkbox">
                                                <input
                                                  class="form-check-input"
                                                  type="radio"
                                                  name="p_type_5"
                                                  :id="'horizontal4'+index"
                                                  value="false"
                                                  v-model="sheet.dataElementVertical"
                                                />
                                                <label
                                                  class="form-check-label radioLabelName mainRadiobtn"
                                                  :for="'horizontal4'+index"
                                                >Horizontal</label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label
                                            :for="'row_pos_dataEle'+index"
                                            class="col-sm-3 col-form-label"
                                          >
                                            <sup>
                                              <i
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                                v-b-popover.hover.top="'I am popover directive content!'"
                                                title="Popover Title"
                                              ></i>
                                            </sup>
                                            Row Position
                                          </label>
                                          <div class="col-sm-3">
                                            <input
                                              type="number"
                                              class="form-control"
                                              :id="'row_pos_dataEle'+index"
                                              v-model="sheet.dataElementRowPosition"
                                            />
                                          </div>
                                          <label
                                            :for="'col_pos_dataEle'+index"
                                            class="col-sm-3 col-form-label"
                                          >
                                            <sup>
                                              <i
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                                v-b-popover.hover.top="'I am popover directive content!'"
                                                title="Popover Title"
                                              ></i>
                                            </sup>
                                            Column Position
                                          </label>
                                          <div class="col-sm-3">
                                            <input
                                              type="number"
                                              class="form-control"
                                              :id="'col_pos_dataEle'+index"
                                              v-model="sheet.dataElementColPosition"
                                            />
                                          </div>
                                        </div>
                                        <div class="form-group row my-3">
                                          <label for class="col-sm-3 col-form-label">
                                            <sup>
                                              <i
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                                v-b-popover.hover.top="'I am popover directive content!'"
                                                title="Popover Title"
                                              ></i>
                                            </sup>
                                            Records
                                          </label>
                                          <div class="col-sm-3" style="padding-right:0 !important;">
                                            <div class="d-flex mt-2">
                                              <div class="form-check pure-checkbox pl-0">
                                                <input
                                                  class="form-check-input"
                                                  type="radio"
                                                  name="record_5"
                                                  :id="'read_all4'+index"
                                                  value="read_all"
                                                  v-model="sheet.dataElementRecords"
                                                />
                                                <label
                                                  class="form-check-label radioLabelName mainRadiobtn"
                                                  :for="'read_all4'+index"
                                                >Read all</label>
                                              </div>
                                              <div class="form-check pure-checkbox">
                                                <input
                                                  class="form-check-input"
                                                  type="radio"
                                                  name="record_5"
                                                  :id="'ignore4'+index"
                                                  value="ignore"
                                                  v-model="sheet.dataElementRecords"
                                                />
                                                <label
                                                  class="form-check-label radioLabelName mainRadiobtn"
                                                  :for="'ignore4'+index"
                                                >Ignore</label>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            class="col-sm-3"
                                            style
                                            v-if="sheet.dataElementRecords === 'ignore'"
                                          >
                                            <div class="form-control d-flex">
                                              <label
                                                :for="'col_feild_dataElement'+index"
                                                class="col-form-label pt-1"
                                              >Col</label>
                                              <input
                                                class="form-control ml-2"
                                                :id="'col_feild_dataElement'+index"
                                                v-model="sheet.dataElementIgnoreCols"
                                                style="height:26px;"
                                              />
                                            </div>
                                          </div>

                                          <div
                                            class="col-sm-3"
                                            style
                                            v-if="sheet.dataElementRecords === 'ignore'"
                                          >
                                            <div class="form-control d-flex">
                                              <label
                                                :for="'row_feild_dataElement'+index"
                                                class="col-form-label pt-1"
                                              >Row</label>
                                              <input
                                                class="form-control ml-2"
                                                :id="'row_feild_dataElement'+index"
                                                v-model="sheet.dataElementIgnoreRows"
                                                style="height:26px;"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label
                                            :for="'domain'+index"
                                            class="col-sm-3 col-form-label"
                                          >
                                            <sup>
                                              <i
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                                v-b-popover.hover.top="'I am popover directive content!'"
                                                title="Popover Title"
                                              ></i>
                                            </sup>
                                            Domain
                                          </label>
                                          <div class="col-sm-5">
                                            <div class>
                                              <b-form-select
                                                v-model="sheet.dataElementDomainType"
                                                :options="domainTypeList"
                                                class="form-control"
                                              ></b-form-select>
                                            </div>
                                          </div>
                                          <div class="col-sm-4"></div>
                                        </div>
                                        <div class="form-group row">
                                          <label
                                            :for="'aggType'+index"
                                            class="col-sm-3 col-form-label"
                                          >
                                            <sup>
                                              <i
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                                v-b-popover.hover.top="'I am popover directive content!'"
                                                title="Popover Title"
                                              ></i>
                                            </sup>
                                            Aggregation type
                                          </label>
                                          <div class="col-sm-5">
                                            <div class>
                                              <b-form-select
                                                v-model="sheet.dataElementAggregationType"
                                                :options="aggregationTypeList"
                                                class="form-control"
                                              ></b-form-select>
                                            </div>
                                          </div>
                                          <div class="col-sm-4"></div>
                                        </div>
                                        <div class="form-group row">
                                          <label
                                            :for="'valueType'+index"
                                            class="col-sm-3 col-form-label"
                                          >
                                            <sup>
                                              <i
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                                v-b-popover.hover.top="'I am popover directive content!'"
                                                title="Popover Title"
                                              ></i>
                                            </sup>
                                            Value type
                                          </label>
                                          <div class="col-sm-5">
                                            <div class>
                                              <b-form-select
                                                v-model="sheet.dataElementValueType"
                                                :options="valueTypeList"
                                                class="form-control"
                                              ></b-form-select>
                                            </div>
                                          </div>
                                          <div class="col-sm-4"></div>
                                        </div>
                                        <div class="form-group row">
                                          <label for class="col-sm-3 col-form-label">
                                            <sup>
                                              <i
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                                v-b-popover.hover.top="'I am popover directive content!'"
                                                title="Popover Title"
                                              ></i>
                                            </sup>
                                            Cumulative data
                                          </label>
                                          <div class="col-sm-5">
                                            <b-form-select
                                              v-model="sheet.cumulative"
                                              :options="cumulativeList"
                                              class="form-control"
                                            ></b-form-select>
                                          </div>
                                          <div class="col-sm-4"></div>
                                        </div>
                                        <transition name="slide-fade">
                                          <div class="row" v-if="sheet.cumulative">
                                            <div class="col-sm-3"></div>
                                            <div class="col-sm-4">
                                              <div class="d-flex mt-2">
                                                <div class="form-check pure-checkbox pl-0">
                                                  <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="ccData"
                                                    :id="'cum_all'+index"
                                                    :value="true"
                                                    v-model="sheet.allCumulative"
                                                  />
                                                  <label
                                                    class="form-check-label radioLabelName mainRadiobtn"
                                                    :for="'cum_all'+index"
                                                  >All cumulative</label>
                                                </div>
                                                <div class="form-check pure-checkbox">
                                                  <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="ccData"
                                                    :id="'if_spec'+index"
                                                    :value="false"
                                                    v-model="sheet.allCumulative"
                                                  />
                                                  <label
                                                    class="form-check-label radioLabelName mainRadiobtn"
                                                    :for="'if_spec'+index"
                                                  >If specific</label>
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              class="col-sm-3"
                                              v-if="!sheet.allCumulative && sheet.cumulitives.length > 0"
                                            >
                                              <div
                                                class="d-flex mb-1"
                                                v-for="list,listInd in sheet.cumulitives"
                                                :key="listInd"
                                              >
                                                <input
                                                  class="form-control"
                                                  :id="'col_feild'+index+listInd"
                                                  placeholder="Add data element name"
                                                  v-model="list.value"
                                                />
                                                <div class="col-sm-2" v-if="listInd != 0">
                                                  <div class="d-flex">
                                                    <button
                                                      class="btn btn-primary btn-sm mt-1"
                                                      @click.prevent.stop="removeNewSpecific(index,listInd)"
                                                    >
                                                      <i class="fa fa-minus" aria-hidden="true"></i>
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              class="col-sm-2"
                                              v-if="!sheet.allCumulative && sheet.cumulitives.length > 0"
                                            >
                                              <div class="d-flex">
                                                <button
                                                  class="btn btn-primary btn-sm mt-1"
                                                  @click.prevent.stop="addNewSpecific(index)"
                                                >
                                                  <i class="fa fa-plus" aria-hidden="true"></i> Add New
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </transition>
                                        <div class="form-group row">
                                          <label for class="col-sm-3 col-form-label">
                                            <sup>
                                              <i
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                                v-b-popover.hover.top="'I am popover directive content!'"
                                                title="Popover Title"
                                              ></i>
                                            </sup>
                                            Elements without category
                                          </label>
                                          <div
                                            class="col-sm-5 mb-2"
                                            v-if="sheet.dataEltsNoCats.length > 0"
                                          >
                                            <div
                                              class="d-flex mb-1"
                                              v-for="elm,elemInd in sheet.dataEltsNoCats"
                                              :key="elemInd"
                                            >
                                              <input
                                                class="form-control"
                                                :id="'elems'+index+elemInd"
                                                placeholder="Add data element name"
                                                v-model="elm.value"
                                              />

                                              <div class="col-sm-2" v-if="elemInd != 0">
                                                <div class="d-flex">
                                                  <button
                                                    class="btn btn-primary btn-sm mt-1"
                                                    @click.prevent.stop="removeElem(index,elemInd)"
                                                  >
                                                    <i class="fa fa-minus" aria-hidden="true"></i>
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-sm-4">
                                            <div class="d-flex">
                                              <button
                                                class="btn btn-primary btn-sm mt-1"
                                                @click.prevent.stop="addNewelem(index)"
                                              >
                                                <i class="fa fa-plus" aria-hidden="true"></i> Add New
                                              </button>
                                            </div>
                                          </div>
                                          <!-- <div class="col-sm-4"></div> -->
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                                <hr />
                                <div id="gde">
                                  <div class="row mt-3">
                                    <div class="col-12">
                                      <h6 class="formTitle my-2 mb-3">
                                        <span class="sr_num">6.</span> Data element group Details
                                      </h6>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-12">
                                      <form>
                                        <div class="form-group row">
                                          <label
                                            :for="'deg_name'+index"
                                            class="col-sm-3 col-form-label"
                                          >
                                            <sup>
                                              <i
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                                v-b-popover.hover.top="'I am popover directive content!'"
                                                title="Popover Title"
                                              ></i>
                                            </sup>
                                            Data element group Name
                                          </label>
                                          <div class="col-sm-9">
                                            <input
                                              class="form-control"
                                              :id="'deg_name'+index"
                                              v-model="sheet.dataGroup"
                                            />
                                          </div>
                                          <!-- <div class="col-sm-2"></div> -->
                                        </div>

                                        <div class="form-group row my-3">
                                          <label
                                            :for="'processTypeDEGrp'+index"
                                            class="col-sm-3 col-form-label"
                                          >
                                            <sup>
                                              <i
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                                v-b-popover.hover.top="'I am popover directive content!'"
                                                title="Popover Title"
                                              ></i>
                                            </sup>
                                            Process type
                                          </label>
                                          <div class="col-sm-9">
                                            <div class="d-flex mt-2">
                                              <div class="form-check pure-checkbox pl-0">
                                                <input
                                                  class="form-check-input"
                                                  type="radio"
                                                  name="p_type_6"
                                                  :id="'vertical5'+index"
                                                  value="true"
                                                  v-model="sheet.dataElementGrpVertical"
                                                />
                                                <label
                                                  class="form-check-label radioLabelName mainRadiobtn"
                                                  :for="'vertical5'+index"
                                                >Vertical</label>
                                              </div>
                                              <div class="form-check pure-checkbox">
                                                <input
                                                  class="form-check-input"
                                                  type="radio"
                                                  name="p_type_6"
                                                  :id="'horizontal5'+index"
                                                  value="false"
                                                  v-model="sheet.dataElementGrpVertical"
                                                />
                                                <label
                                                  class="form-check-label radioLabelName mainRadiobtn"
                                                  :for="'horizontal5'+index"
                                                >Horizontal</label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label
                                            :for="'row_pos_dataGrp'+index"
                                            class="col-sm-3 col-form-label"
                                          >
                                            <sup>
                                              <i
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                                v-b-popover.hover.top="'I am popover directive content!'"
                                                title="Popover Title"
                                              ></i>
                                            </sup>
                                            Row Position
                                          </label>
                                          <div class="col-sm-3">
                                            <input
                                              type="number"
                                              class="form-control"
                                              :id="'row_pos_dataGrp'+index"
                                              v-model="sheet.dataeltGrpRowPosition"
                                            />
                                          </div>
                                          <label
                                            :for="'col_pos_dataGrp'+index"
                                            class="col-sm-3 col-form-label"
                                          >
                                            <sup>
                                              <i
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                                v-b-popover.hover.top="'I am popover directive content!'"
                                                title="Popover Title"
                                              ></i>
                                            </sup>
                                            Column Position
                                          </label>
                                          <div class="col-sm-3">
                                            <input
                                              type="number"
                                              class="form-control"
                                              :id="'col_pos_dataGrp'+index"
                                              v-model="sheet.dataeltGrpColPosition"
                                            />
                                          </div>
                                        </div>
                                        <div class="form-group row my-3">
                                          <label for class="col-sm-3 col-form-label">
                                            <sup>
                                              <i
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                                v-b-popover.hover.top="'I am popover directive content!'"
                                                title="Popover Title"
                                              ></i>
                                            </sup>
                                            Records
                                          </label>
                                          <div class="col-sm-3" style="padding-right:0 !important;">
                                            <div class="d-flex mt-2">
                                              <div class="form-check pure-checkbox pl-0">
                                                <input
                                                  class="form-check-input"
                                                  type="radio"
                                                  name="record_6"
                                                  :id="'read_all5'+index"
                                                  value="read_all"
                                                  v-model="sheet.dataElementGrpRecords"
                                                />
                                                <label
                                                  class="form-check-label radioLabelName mainRadiobtn"
                                                  :for="'read_all5'+index"
                                                >Read all</label>
                                              </div>
                                              <div class="form-check pure-checkbox">
                                                <input
                                                  class="form-check-input"
                                                  type="radio"
                                                  name="record_6"
                                                  :id="'ignore5'+index"
                                                  value="ignore"
                                                  v-model="sheet.dataElementGrpRecords"
                                                />
                                                <label
                                                  class="form-check-label radioLabelName mainRadiobtn"
                                                  :for="'ignore5'+index"
                                                >Ignore</label>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            class="col-sm-3"
                                            style
                                            v-if="sheet.dataElementGrpRecords === 'ignore'"
                                          >
                                            <div class="form-control d-flex">
                                              <label
                                                :for="'col_feild_dataElementGrp'+index"
                                                class="col-form-label pt-1"
                                              >Col</label>
                                              <input
                                                class="form-control ml-2"
                                                :id="'col_feild_dataElementGrp'+index"
                                                v-model="sheet.dataElementGrpIgnoreCols"
                                                style="height:26px;"
                                              />
                                            </div>
                                          </div>

                                          <div
                                            class="col-sm-3"
                                            style
                                            v-if="sheet.dataElementGrpRecords === 'ignore'"
                                          >
                                            <div class="form-control d-flex">
                                              <label
                                                :for="'row_feild_dataElementGrp'+index"
                                                class="col-form-label pt-1"
                                              >Row</label>
                                              <input
                                                class="form-control ml-2"
                                                :id="'row_feild_dataElementGrp'+index"
                                                v-model="sheet.dataElementGrpIgnoreRows"
                                                style="height:26px;"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                                <hr />
                                <div id="period">
                                  <div class="row mt-3">
                                    <div class="col-12">
                                      <h6 class="fformTitle my-2 mb-3">
                                        <span class="sr_num">7.</span> Period Details
                                      </h6>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-12">
                                      <form>
                                        <div class="form-group row">
                                          <label
                                            :for="'period_type'+index"
                                            class="col-sm-3 col-form-label"
                                          >
                                            <sup>
                                              <i
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                                v-b-popover.hover.top="'I am popover directive content!'"
                                                title="Popover Title"
                                              ></i>
                                            </sup>
                                            Period Type
                                          </label>
                                          <div class="col-sm-3">
                                            <div class>
                                              <b-form-select
                                                v-model="sheet.periodType"
                                                :options="periodTypeList"
                                                class="form-control"
                                              ></b-form-select>
                                            </div>
                                          </div>
                                          <label
                                            :for="'period_freq'+index"
                                            class="col-sm-3 col-form-label hide"
                                          >
                                            <sup>
                                              <i
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                                v-b-popover.hover.top="'I am popover directive content!'"
                                                title="Popover Title"
                                              ></i>
                                            </sup>
                                            Period Frequency
                                          </label>
                                          <div class="col-sm-3 hide">
                                            <b-form-select
                                              v-model="sheet.periodFrequency"
                                              :options="periodFrequencyList"
                                              class="form-control"
                                            ></b-form-select>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label
                                            :for="'calender_type'+index"
                                            class="col-sm-3 col-form-label"
                                          >
                                            <sup>
                                              <i
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                                v-b-popover.hover.top="'I am popover directive content!'"
                                                title="Popover Title"
                                              ></i>
                                            </sup>
                                            Calender Type
                                          </label>
                                          <div class="col-sm-5">
                                            <div class>
                                              <b-form-select
                                                v-model="sheet.calenderType"
                                                :options="calenderTypeList"
                                                class="form-control"
                                              ></b-form-select>
                                            </div>
                                          </div>

                                          <div class="col-sm-4"></div>
                                        </div>

                                        <div class="form-group row my-3">
                                          <label
                                            :for="'processType'+index"
                                            class="col-sm-3 col-form-label"
                                          >
                                            <sup>
                                              <i
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                                v-b-popover.hover.top="'I am popover directive content!'"
                                                title="Popover Title"
                                              ></i>
                                            </sup>
                                            Process type
                                          </label>
                                          <div class="col-sm-9">
                                            <div class="d-flex mt-2">
                                              <div class="form-check pure-checkbox pl-0">
                                                <input
                                                  class="form-check-input"
                                                  type="radio"
                                                  name="p_type_7"
                                                  :id="'vertical6'+index"
                                                  value="true"
                                                  v-model="sheet.periodVertical"
                                                />
                                                <label
                                                  class="form-check-label radioLabelName mainRadiobtn"
                                                  :for="'vertical6'+index"
                                                >Vertical</label>
                                              </div>
                                              <div class="form-check pure-checkbox">
                                                <input
                                                  class="form-check-input"
                                                  type="radio"
                                                  name="p_type_7"
                                                  :id="'horizontal6'+index"
                                                  value="false"
                                                  v-model="sheet.periodVertical"
                                                />
                                                <label
                                                  class="form-check-label radioLabelName mainRadiobtn"
                                                  :for="'horizontal6'+index"
                                                >Horizontal</label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label
                                            :for="'row_pos_periodType'+index"
                                            class="col-sm-3 col-form-label"
                                          >
                                            <sup>
                                              <i
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                                v-b-popover.hover.top="'I am popover directive content!'"
                                                title="Popover Title"
                                              ></i>
                                            </sup>
                                            Row Position
                                          </label>
                                          <div class="col-sm-3">
                                            <input
                                              type="number"
                                              class="form-control"
                                              :id="'row_pos_periodType'+index"
                                              v-model="sheet.periodRowPosition"
                                            />
                                          </div>
                                          <label
                                            :for="'col_pos_periodType'+index"
                                            class="col-sm-3 col-form-label"
                                          >
                                            <sup>
                                              <i
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                                v-b-popover.hover.top="'I am popover directive content!'"
                                                title="Popover Title"
                                              ></i>
                                            </sup>
                                            Column Position
                                          </label>
                                          <div class="col-sm-3">
                                            <input
                                              type="number"
                                              class="form-control"
                                              :id="'col_pos_periodType'+index"
                                              v-model="sheet.periodColPosition"
                                            />
                                          </div>
                                        </div>
                                        <div class="form-group row my-3">
                                          <label for class="col-sm-3 col-form-label">
                                            <sup>
                                              <i
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                                v-b-popover.hover.top="'I am popover directive content!'"
                                                title="Popover Title"
                                              ></i>
                                            </sup>
                                            Records
                                          </label>
                                          <div class="col-sm-3" style="padding-right:0 !important;">
                                            <div class="d-flex mt-2">
                                              <div class="form-check pure-checkbox pl-0">
                                                <input
                                                  class="form-check-input"
                                                  type="radio"
                                                  name="record_7"
                                                  :id="'read_all6'+index"
                                                  value="read_all"
                                                  v-model="sheet.periodRecords"
                                                />
                                                <label
                                                  class="form-check-label radioLabelName mainRadiobtn"
                                                  :for="'read_all6'+index"
                                                >Read all</label>
                                              </div>
                                              <div class="form-check pure-checkbox">
                                                <input
                                                  class="form-check-input"
                                                  type="radio"
                                                  name="record_7"
                                                  :id="'ignore6'+index"
                                                  value="ignore"
                                                  v-model="sheet.periodRecords"
                                                />
                                                <label
                                                  class="form-check-label radioLabelName mainRadiobtn"
                                                  :for="'ignore6'+index"
                                                >Ignore</label>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            class="col-sm-3"
                                            style
                                            v-if="sheet.periodRecords === 'ignore'"
                                          >
                                            <div class="form-control d-flex">
                                              <label
                                                :for="'col_feild_period'+index"
                                                class="col-form-label pt-1"
                                              >Col</label>
                                              <input
                                                class="form-control ml-2"
                                                :id="'col_feild_period'+index"
                                                v-model="sheet.periodIgnoreCols"
                                                style="height:26px;"
                                              />
                                            </div>
                                          </div>

                                          <div
                                            class="col-sm-3"
                                            style
                                            v-if="sheet.periodRecords === 'ignore'"
                                          >
                                            <div class="form-control d-flex">
                                              <label
                                                :for="'row_feild_period'+index"
                                                class="col-form-label pt-1"
                                              >Row</label>
                                              <input
                                                class="form-control ml-2"
                                                :id="'row_feild_period'+index"
                                                v-model="sheet.periodIgnoreRows"
                                                style="height:26px;"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </tab-content>
                          </form-wizard>
                        </section>
                      </transition>
                    </template>
                    <!-- dynamic data upload section ends -->
                  </div>

                  <div
                    class="tab-pane fade"
                    id="v-pills-messages"
                    role="tabpanel"
                    aria-labelledby="v-pills-messages-tab"
                  >...</div>
                  <!-- <div class="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">...</div> -->
                </div>
              </div>
              <!-- new upload data structure ends -->
            </div>
          </tab-content>
        </form-wizard>
      </ValidationObserver>
      <modals-container />
    </div>
  </div>
</template>

<script>
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import ApiServices from "../service/ApiServices";
import moment from "moment";
import swal from "sweetalert2";
export default {
  data() {
    return {
      frmModel: {
        appname: "",
        appdesc: "",
        initialyear: "2010",
        lastyear: "2012",
        language: "English",
        color: "#212832",
        layout: "1",
        modules: [],
        type: "sample",
        applogo: "",
        leadpic: "",
        leaddesignation: "Director for the Centre of M&E and Advocacy",
        leaddesc:
          "Dr. Xyz Jorge joined Avenir Health in 2009. She currently leads activities that focus on monitoring of health programs...",
        contentheading: "Avenir Health",
        contentdesc:
          "Avenir Health, formerly Futures Institute, was founded in 2006 as a global health organization that works to enhance social and economic development by providing tools and technical assistance in policy, planning, resource allocation and evaluation. In particular, we focus on developing and implementing demographic, epidemiological and costing models for long-range planning to assist with setting goals, strategies, and objectives.",
        backimage: "",
        landingPageLogo: "",
        country: "",
        countryuid: "",
        userid: "",
        username: "",
        useruid: "",
        authparam: "",
        socialfacebook: "",
        socialinsta: "",
        socialtwitter: "",
        socialyoutube: "",
        //level: "",
        //sublevel: "",
        uploadedExcelName: ""
      },
      uploadedExcelName: "",
      uploadedExcel: "",
      isFirstTabValid: false,
      loggedInUserProfile: null,
      downloadTemplateUrl:
        "https://fppnepal.duredemos.com/uploadedfiles/fpp(Nepal).xlsx",
      visible: false,
      date: new Date(),
      years: [],
      settings: settings,
      baseUrl: "http://localhost:8080/dhis/",
      dashboardUrl: "api/apps/Avenir-Generic/index.html#/",
      selected: "staticData",

      templateDetails: {
        // id: 937,
        // templateId: "gpkFxNSwRtx",
        templateName: "",
        templateDesc: "",
        fileType: "",
        numSheetsToRead: "" //internal
      },
      isProceed: false,
      cumulativeList: [
        { text: "Yes", value: true },
        { text: "No", value: false }
      ],
      dimensionTypeList: [
        { text: "Please select dimension type", value: null, disabled: true },
        { text: "DISAGGREGATION", value: "disaggregation" },
        { text: "ATTRIBUTE", value: "attribut" }
      ],
      domainTypeList: [
        { text: "Please select domain type", value: null, disabled: true },
        { text: "AGGREGATE", value: "aggregate" },
        { text: "TRACKER", value: "attribut" }
      ],
      aggregationTypeList: [
        { text: "Please select aggregation type", value: null, disabled: true },
        { text: "SUM", value: "SUM" },
        { text: "AVERAGE", value: "AVERAGE" },
        { text: "AVERAGE SUM ORG UNIT", value: "AVERAGE_SUM_ORG_UNIT" },
        { text: "LAST", value: "LAST" },
        { text: "LAST AVERAGE ORG UNIT", value: "LAST_AVERAGE_ORG_UNIT" },
        { text: "FIRST", value: "FIRST" },
        { text: "FIRST AVERAGE ORG UNIT", value: "FIRST_AVERAGE_ORG_UNIT" },
        { text: "COUNT", value: "COUNT" },
        { text: "STDDEV", value: "STDDEV" },
        { text: "VARIANCE", value: "VARIANCE" },
        { text: "MIN", value: "MIN" },
        { text: "MAX", value: "MAX" },
        { text: "NONE", value: "NONE" },
        { text: "CUSTOM", value: "CUSTOM" },
        { text: "DEFAULT", value: "DEFAULT" }
      ],
      valueTypeList: [
        { text: "Please select value type", value: null, disabled: true },
        { text: "TEXT", value: "TEXT" },
        { text: "LONG TEXT", value: "LONG_TEXT" },
        { text: "LETTER", value: "LETTER" },
        { text: "PHONE NUMBER", value: "PHONE_NUMBER" },
        { text: "EMAIL", value: "EMAIL" },
        { text: "BOOLEAN", value: "BOOLEAN" },
        { text: "TRUE ONLY", value: "TRUE_ONLY" },
        { text: "DATE", value: "DATE" },
        { text: "DATETIME", value: "DATETIME" },
        { text: "TIME", value: "TIME" },
        { text: "NUMBER", value: "NUMBER" },
        { text: "UNIT INTERVAL", value: "UNIT_INTERVAL" },
        { text: "PERCENTAGE", value: "PERCENTAGE" },
        { text: "INTEGER", value: "INTEGER" },
        { text: "INTEGER POSITIVE", value: "INTEGER_POSITIVE" },
        { text: "INTEGER NEGATIVE", value: "INTEGER_NEGATIVE" },
        { text: "INTEGER ZERO OR POSITIVE", value: "INTEGER_ZERO_OR_POSITIVE" },
        { text: "TRACKER ASSOCIATE", value: "TRACKER_ASSOCIATE" },
        { text: "USERNAME", value: "USERNAME" },
        { text: "COORDINATE", value: "COORDINATE" },
        { text: "ORGANISATION UNIT", value: "ORGANISATION_UNIT" },
        { text: "AGE", value: "AGE" },
        { text: "URL", value: "URL" },
        { text: "FILE RESOURCE", value: "FILE_RESOURCE" },
        { text: "IMAGE", value: "IMAGE" }
      ],
      periodTypeList: [
        { text: "Please select period type", value: null, disabled: true },
        { text: "Daily", value: "Daily" },
        { text: "Weekly", value: "Weekly" },
        { text: "Weekly Wednesday", value: "WeeklyWednesday" },
        { text: "Weekly Thursday", value: "WeeklyThursday" },
        { text: "Weekly Saturday", value: "WeeklySaturday" },
        { text: "Weekly Sunday", value: "WeeklySunday" },
        { text: "Bi Weekly", value: "BiWeekly" },
        { text: "Monthly", value: "Monthly" },
        { text: "Bi Monthly", value: "BiMonthly" },
        { text: "Quarterly", value: "Quarterly" },
        { text: "Six Monthly", value: "SixMonthly" },
        { text: "Six Monthly April", value: "SixMonthlyApril" },
        { text: "Six Monthly Nov", value: "SixMonthlyNov" },
        { text: "Yearly", value: "Yearly" },
        { text: "Financial April", value: "FinancialApril" },
        { text: "Financial July", value: "FinancialJuly" },
        { text: "Financial Oct", value: "FinancialOct" },
        { text: "Financial Nov", value: "FinancialNov" }
      ],
      periodFrequencyList: [
        {
          text: "Please select period frequency type",
          value: null,
          disabled: true
        }
      ],
      calenderTypeList: [
        // {text:"Please select calender type", value: null, disabled: true}
        {
          text: "International Fixed Calendar",
          value: "International Fixed Calendar"
        }
      ],
      sheetDetails: {
        sheetName: "",
        readFrom: "",
        valueRowPosition: "",
        valueColPosition: "",
        serviceName: "",
        baseOrg: "",
        level1Vertical: "true", //true/false
        parentOrgRowPosition: "",
        parentOrgColPosition: "",
        parentRecords: "read_all", //internal
        baseOrgIgnoreRows: "",
        baseOrgIgnoreCols: "",
        level1SingleCell: "", //add in UI true/false

        childLevelOrg: "",
        childOrgVertical: "true",
        childOrgRowPosition: "",
        childOrgColPosition: "",
        childRecords: "read_all", //internal
        childOrgIgnoreRows: "",
        childOrgIgnoreCols: "",
        level2SingleCell: "",

        childLevelOrg1: "",
        childOrgVertical1: "true",
        childOrgRowPosition1: "",
        childOrgColPosition1: "",
        childRecords1: "read_all", //internal
        childOrgIgnoreRows1: "",
        childOrgIgnoreCols1: "",
        level2SingleCell1: "",

        childLevelOrg2: "",
        childOrgVertical2: "true",
        childOrgRowPosition2: "",
        childOrgColPosition2: "",
        childRecords2: "read_all", //internal
        childOrgIgnoreRows2: "",
        childOrgIgnoreCols2: "",
        level2SingleCell2: "",

        childLevelOrg3: "",
        childOrgVertical3: "true",
        childOrgRowPosition3: "",
        childOrgColPosition3: "",
        childRecords3: "read_all", //internal
        childOrgIgnoreRows3: "",
        childOrgIgnoreCols3: "",
        level2SingleCell3: "",

        categoryName: "",
        categoryVertical: "true", //true/false
        categoryRowPosition: "",
        categoryColPosition: "",
        categoryRecords: "read_all", //International
        categoryIgnoreCols: "",
        categoryIgnoreRows: "",
        catComboDimensionType: "",
        //primary category fields
        categoryPrimaryColPosition:"",
        categoryPrimaryRowPosition:"",
        categoryPrimaryName:"",
        categoryPrimary:"",
        categoryPrimaryVertical:"true",

        dataElementVertical: "true", //true/false
        dataElementRowPosition: "",
        dataElementColPosition: "",
        dataElementRecords: "read_all", //International
        dataElementIgnoreCols: "",
        dataElementIgnoreRows: "",
        dataElementDomainType: "",
        dataElementAggregationType: "",
        dataElementValueType: "",
        cumulative: false,
        allCumulative: "true", //true/false
        cumulitives: [{ value: "" }],
        dataEltsNoCats: [{ value: "" }],

        dataGroup: "",
        dataElementGrpVertical: "true",
        dataeltGrpRowPosition: "",
        dataeltGrpColPosition: "",
        dataElementGrpRecords: "read_all", //internal
        dataElementGrpIgnoreRows: "",
        dataElementGrpIgnoreCols: "",

        periodType: "",
        periodFrequency: "",
        calenderType: "International Fixed Calendar",
        periodVertical: "true",
        periodRowPosition: "",
        periodColPosition: "",
        periodRecords: "read_all", //internal
        periodIgnoreCols: "",
        periodIgnoreRows: "",
        periodSingleCell: ""
      },
      allSavedTemplates: [],
      listOfTemplates: [],
      isUpload: false,
      uploadTemplateID: null,
      isEdit: false,
      perPage: 3,
      currentPage: 1,
      isAddNewTemplate: false,
      sheets: [],
      uploadTemplateName: ""
    };
  },
  created() {
    ApiServices.theme(this.frmModel.color);
  },
  watch: {
    "templateDetails.numSheetsToRead": function(newValue, oldValue) {
      if (!this.isEdit) {
        this.sheets = [];
        this.$nextTick(() => {
          for (let i = 0; i < newValue; i++) {
            let details = JSON.parse(JSON.stringify(this.sheetDetails));
            this.sheets.push(details);
          }
        });
      }
    },
    selected(newValue) {
      this.isEdit = false;
      this.resetDynamicForm();
      if (newValue === "staticData") {
        this.isUpload = false;
      }
    }
  },
  computed: {
    templatesList() {
      return this.listOfTemplates.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
    rows() {
      return this.listOfTemplates.length;
    }
  },
  methods: {
    addNewSpecific(index) {
      // console.log(index)
      // console.log(this.sheets)
      this.sheets = this.sheets.map((s, i) => {
        if (i === index) {
          return { ...s, cumulitives: s.cumulitives.concat({ value: "" }) };
        } else {
          return s;
        }
      });
    },
    removeNewSpecific(index, elemInd) {
      this.sheets = this.sheets.map((s, i) => {
        if (i === index) {
          var newS = s.cumulitives.filter((s, i) => i !== elemInd);
          return { ...s, cumulitives: newS };
        } else {
          return s;
        }
      });
    },
    removeElem(index, elemInd) {
      this.sheets = this.sheets.map((s, i) => {
        if (i === index) {
          // console.log("s.dataEltsNoCats",s.dataEltsNoCats);
          // console.log(index);
          // console.log(elemInd);
          var newS = s.dataEltsNoCats.filter((s, i) => i !== elemInd);
          // console.log("newS",newS);
          return { ...s, dataEltsNoCats: newS };
        } else {
          return s;
        }
      });
    },
    addNewelem(index) {
      // console.log(index)
      // console.log(this.sheets)
      this.sheets = this.sheets.map((s, i) => {
        if (i === index) {
          // console.log(s.dataEltsNoCats);
          return {
            ...s,
            dataEltsNoCats: s.dataEltsNoCats.concat({ value: "" })
          };
        } else {
          return s;
        }
      });
    },
    resetDynamicForm() {
      this.templateDetails = {
        templateName: "",
        templateDesc: "",
        fileType: "",
        numSheetsToRead: ""
      };
      this.editTemplateSheets = [];
      this.isProceed = false;
    },
    addNewTemplate() {
      this.isAddNewTemplate = true;
      this.isUpload = false;
      this.isEdit = false;
      this.resetDynamicForm();
    },
    editTemplate(templateId) {
      // console.log(templateId)
      this.isEdit = true;
      this.isAddNewTemplate = true;
      this.isUpload = false;
      let templates = JSON.parse(JSON.stringify(this.allSavedTemplates)).filter(
        t => t.templateId === templateId
      );
      // console.log(templates)
      // console.log(templates[0])
      this.templateDetails = {
        templateName: templates[0].templateName
          ? templates[0].templateName
          : "",
        templateDesc: templates[0].templateDesc
          ? templates[0].templateDesc
          : "",
        fileType: templates[0].fileType ? templates[0].fileType : "",
        numSheetsToRead: templates[0].numSheetsToRead
          ? templates[0].numSheetsToRead
          : ""
      };

      this.sheets = templates.map((t, index) => {
        let updatedObj = Object.assign(
          JSON.parse(JSON.stringify(this.sheetDetails)),
          t
        );
        // console.log(t.dataEltsNoCats);
        // console.log(t.cumulitives);
        // console.log(updatedObj.cumulitives);
        // console.log(updatedObj.dataEltsNoCats);
        if (!updatedObj.allCumulative) {
          updatedObj.cumulitives =
            updatedObj.cumulitives && updatedObj.cumulitives.length
              ? updatedObj.cumulitives.map(c => ({ value: c }))
              : [];
        }
        if (updatedObj.dataEltsNoCats.length > 0) {
          updatedObj.dataEltsNoCats =
            updatedObj.dataEltsNoCats && updatedObj.dataEltsNoCats.length
              ? updatedObj.dataEltsNoCats.map(c => ({ value: c }))
              : [];
        }
        // console.log(updatedObj);
        return JSON.parse(JSON.stringify(updatedObj));
      });
      // console.log(this.sheets)
      this.isProceed = true;
    },
    uploadData(templateId) {
      this.isUpload = true;
      this.isAddNewTemplate = false;
      this.uploadTemplateID = templateId;
      this.uploadTemplateName = this.templatesList.find(
        t => t.templateId === templateId
      ).name;
    },
    deleteData(templateId) {
      this.$root.loading = true;
      ApiServices.RestPostCall(
        "api/avenir/uploadData/deleteUploadTemplate?templateId=" + templateId
      ).then(response => {
        let isTemplate = this.listOfTemplates.find(
          lt => lt.templateId === templateId
        );
        this.listOfTemplates.splice(isTemplate.index - 1, 1);
        this.$root.loading = false;
        this.getTemplates();
        if (this.isEdit) {
          this.isEdit = false;
          this.isAddNewTemplate = false;
        }
      });
    },
    submitTemplate() {
      this.$root.loading = true;
      // console.log("submit template called", this.sheets)
      let sheets = this.sheets.map(s => ({
        ...s,
        cumulitives:
          s.cumulitives && s.cumulitives.length
            ? s.cumulitives.map(c => c.value)
            : [],
        dataEltsNoCats:
          s.dataEltsNoCats && s.dataEltsNoCats.length
            ? s.dataEltsNoCats.map(c => c.value)
            : [],
        ...this.templateDetails
      }));
      // console.log(sheets);
      ApiServices.RestPostCall(
        "api/avenir/uploadData/updateUploadTemplate",
        sheets
      ).then(response => {
        // console.log("response",response)
        this.$root.loading = false;
        this.isProceed = false;
        this.getTemplates();
        this.templateDetails = {
          // id: 937,
          // templateId: "gpkFxNSwRtx",
          templateName: "",
          templateDesc: "",
          fileType: "",
          numSheetsToRead: ""
        };
        if (this.isEdit) {
          this.isEdit = false;
          this.isAddNewTemplate = false;
        }
        if (this.isUpload) {
          this.isUpload = false;
          this.uploadTemplateName = "";
        }
      });
    },
    getTemplates() {
      ApiServices.RestGetCall(
        "api/avenir/uploadData/getAllUploadTemplates"
      ).then(response => {
        // console.log("all templates",response)
        this.allSavedTemplates = response.uploadTemplates;
        let index = 0;
        if (this.listOfTemplates.length > 0) {
          let maxIndex = this.listOfTemplates.map(t => t.index);
          index = Math.max(...maxIndex);
        }
        response.uploadTemplates.forEach((t, i) => {
          let isTemplate = this.listOfTemplates.find(
            lt => lt.templateId === t.templateId
          );
          if (!isTemplate) {
            index += 1;
            this.listOfTemplates.push({
              index,
              name: t.templateName,
              templateId: t.templateId,
              lastUpdated: moment.utc(t.lastUpdated).format("MM/DD/YYYY"),
              userName: t.userName
            });
          }
        });
      });
    },
    proceed() {
      this.isProceed = true;
      // console.log(this.templateDetails);
    },
    getClass() {
      // if(settings.countryName.toLowerCase() === 'nepal'){
      //   return 'newBlack-theme'
      // }else{
      //   return 'newBlue-theme'
      // }
      if (this.frmModel.color === "#212832") {
        return "newBlack-theme";
      } else if (this.frmModel.color === "#66e093") {
        return "newBlue-theme";
      } else if (this.frmModel.color === "#25984b") {
        return "newGreen-theme";
      }
    },
    random(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    onComplete: function() {
      //console.log(this.$refs.inputfile);
      //debugger;
      this.$root.loading = true;
      this.frmModel.userid = this.loggedInUserProfile.userid;
      this.frmModel.username = this.loggedInUserProfile.username;
      this.frmModel.useruid = this.loggedInUserProfile.useruid;
      this.frmModel.authparam = this.loggedInUserProfile.authparam;

      let formData = new FormData();
      formData.append("file", this.uploadedExcel);
      // to be remove later
      console.log(this.frmModel);
      let test = {
        ...this.frmModel,
        color: this.frmModel.color.split("#")[1]
      };
      if (this.selected === "dynamicData") {
        test.templateId = this.uploadTemplateID;
      }
      formData.append("inputjson", JSON.stringify(test));
      // console.log(JSON.stringify(test));
      /*for (var value of formData.values()) {
        console.log(value);
      }*/
      //return;
      //let headers = {'Content-Type': 'multipart/form-data',Authorization: 'Basic YWRtaW46ZGlzdHJpY3Q='};
      var that = this;
      ApiServices.RestPostMaltipart(
        "api/avenir/uploadData/read",
        formData
      ).then(res => {
        this.$root.loading = false;

        if (res.status.toLocaleLowerCase() == "success") {
          Swal.fire({
            //title: 'Are you sure, you want to quit?',
            text: "Successfully saved!",
            confirmButtonColor: "#3085d6",
            icon: "success",
            showCancelButton: true,
            confirmButtonText: "Go to Dashboard",
            cancelButtonText: "Ok"
          }).then(result => {
            if (result.value) {
              //window.location = this.dashboardUrl;
              window.open(this.dashboardUrl, "_blank");
            }
          });
        } else {
          /*this.$swal({
            icon: 'error',
            text: res.message
            });*/
          let errorTable = "";
          if (res.data.length > 1) {
            errorTable = `<table class="table table-striped">
                <thead>
                    <tr>
                        <th>Excel Sheet Row No</th>
                        <th style="text-align: left;">Error Details</th>
                    </tr>
                </thead>
                <tbody>`;
            for (var i in res.data) {
              //console.log(res.data[i])

              for (var j in res.data[i].errors) {
                if (j == 0) {
                  errorTable += `<tr><td>` + res.data[i].rowno + `</td>`;
                } else {
                  errorTable += `<tr><td></td>`;
                }

                errorTable +=
                  `<td style="text-align: left; text-transform: capitalize;">` +
                  res.data[i].errors[j] +
                  `</td></tr>`;
              }
            }
            errorTable += `</tbody></table>`;
          } else {
            errorTable = `<p>` + res.data[0] + `</p>`;
          }

          this.$swal.fire({
            title: "Alert!",
            width: "90%",
            icon: "error",
            html: errorTable,
            showCloseButton: true,
            //focusConfirm: false,
            confirmButtonText: "Ok",
            confirmButtonAriaLabel: "Ok",
            showCancelButton: false
            //cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
            //cancelButtonAriaLabel: 'Thumbs down'
          });
        }

        that.$refs.inputfile.value = null;
        this.frmModel.uploadedExcelName = "";
        this.uploadedExcelName = "";
        this.uploadedExcel = "";
        this.uploadTemplateID = null;
        if (this.isUpload) {
          this.isUpload = false;
          this.uploadTemplateName = "";
        }
      });
    },
    validateFirstTab: function() {
      return this.$refs.form.validate();
    },
    validateSecondTab: function() {
      return true;
      //return this.$refs.secondTabForm.validate();
    },
    validateThirdTab: function() {
      return true;
      //return this.$refs.thirdTabForm.validate();
    },
    onFileChange(file) {
      this.frmModel.uploadedExcelName = file.target.files[0].name;
      this.uploadedExcelName = file.target.files[0].name;
      this.uploadedExcel = file.target.files[0];
    },
    onImgChange(e) {
      const file = e.target.files[0];
      const imgSrc = e.target.name;
      console.log(imgSrc);
      this.getBase64String(file).then(res => {
      //  console.log(res);
        this.frmModel[imgSrc] = res;
      });
      console.log(this.frmModel[imgSrc]);
      console.log(this.frmModel.landingPageLogo);
    },
    getBase64String(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }
  },
  mounted() {
    this.baseUrl = ApiServices.getBaseUrl();
    this.getTemplates();
    this.dashboardUrl = this.baseUrl + this.dashboardUrl;
    //this.$root.loading = true;
    if (localStorage.getItem("userProfile")) {
      this.loggedInUserProfile = JSON.parse(
        localStorage.getItem("userProfile")
      );
      if (this.loggedInUserProfile.config) {
        this.frmModel = this.loggedInUserProfile.config;
        this.frmModel.backimage =  this.frmModel.landingPageBGImg
        this.frmModel.applogo =  this.frmModel.landingPageLogo
        this.frmModel.modules = true;
        this.frmModel.modules == "" ? [] : this.frmModel.modules;
        this.visible =
          this.loggedInUserProfile.config.type == "upload" ? true : false;
        this.frmModel.initialyear =
          this.frmModel.initialyear == "" ? "2011" : this.frmModel.initialyear;
        this.frmModel.lastyear =
          this.frmModel.lastyear == "" ? "2013" : this.frmModel.lastyear;
        this.frmModel.language =
          this.frmModel.language == "" ? "English" : this.frmModel.language;
        this.frmModel.color = this.frmModel.color;
      }
    }
    // console.log(this.loggedInUserProfile);

    let year = this.date.getFullYear();
    let newyear = year - 10;

    for (var i = newyear; i < year + 1; i++) {
      this.years.push(i);
    }
    this.years.reverse();
  }
};
</script>

<style lang="scss" scoped>
.mdgridviewpage-bg {
  // background-color: #38a1c1;
  background-color: #3471ce;
  // background: url("../assets/img/blue-bg.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  padding: 20px 40px;
}

body.green-theme .mdgridviewpage-bg {
  background-color: #006600;
  background: url("../assets/img/green-bg.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  padding: 20px 40px;
}

.mainform-holder {
  background: #fff;
  -webkit-box-shadow: -2px 0 5px 0 rgba(204, 204, 204, 1);
  -moz-box-shadow: -2px 0 5px 0 rgba(204, 204, 204, 1);
  box-shadow: -2px 0 5px 0 rgba(204, 204, 204, 1);
  border-radius: 10px;
  padding: 60px;
}

.homepage-bg {
  /*background: url("../assets/img/home/Avenir-health-home.jpg") no-repeat;*/
  // background-image: linear-gradient(
  //     0deg,
  //     rgba(0, 0, 0, 0.5),
  //     rgba(0, 0, 0, 0.5)
  //   ),
  //   url("../assets/img/home/avenirbg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.socialicons-container {
  position: absolute;
}
/*.config-icon-container {
  width: 100px;
  height: auto;
}*/

.modRadio .config-icon-container {
  width: 100px;
  height: 100px;
}

#benchmarking .tabs .card-header {
  background-color: rgba(0, 0, 0, 0.03);
}

#sidebar-wrapper {
  position: relative;
}

.modRadio.colorSelect {
  margin-bottom: 30px;
}

.overlay {
  position: absolute;
  top: 45px;
  left: 0;
  height: calc(100% - 90px);
  width: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
}

.edit-links {
  z-index: 14;
  position: relative;
  color: #fff;
  font-size: 14px;
}
.img-preview img {
  width: 180px !important;
  margin-top: 20px;
}
.headingText {
  background: transparent;
  border: none;
  font-size: 2.5rem;
  font-weight: 100;
  font-color: #ffffff;
  padding: 0;
}

.landing-desc {
  background: transparent;
  color: #fff;
  border: none;
  width: 100%;
}
.col-form-label {
  font-size: 14px;
}
.vue-form-wizard .wizard-btn {
  font-weight: 400;
}
.card {
  background-color: #f4f4f4;
}
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-55,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  padding-right: 15px !important;
  padding-left: 15px !important;
}
select {
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  -moz-appearance: none !important;
}
.select-wrapper {
  position: relative;
}

.select-wrapper:after {
  font-family: FontAwesome;
  content: "\f0d7";
  font-size: 16px;
  position: absolute;
  top: 8px;
  right: 14px;
  color: #676767;
  pointer-events: none;
}
.bg-light-purple {
  background-color: #3662a8 !important;
}
.mb-0 > button:after {
  display: none;
}
.mainCard {
  height: 80vh;
  border: 0;
}
.upload_data_tab #v-pills-tab {
  height: 80vh;
  background: #373a3f !important;
}
.upload_data_tab .nav-link {
  background: #373a3f;
  color: #f4f4f4;
  font-size: 16px;
}
.upload_data_tab .nav-link.active {
  border-radius: 0;
  background-color: #f4f4f4;
  border: 0;
  border-left: 6px solid #fe5868;
  font-weight: 500;
  color: #4c4c4c !important;
}
.upload_data_tab .nav-link:hover {
  background-color: #d4d4d4;
  border-color: transparent;
  border-radius: 0;
  color: #4c4c4c !important;
  border-left: 6px solid #fe5868;
}
.upload_data_tab {
  background: #373a3f;
  border-radius: 0 6px 6px 0;
}
.upload_data_tab .tab-content {
  background-color: #f4f4f4 !important;
  border-radius: 0 6px 6px 0;
}
.upload_data_tab .radioLabelName {
  font-size: 1.2em;
  line-height: 1;
}
.upload_data_tab .iconGroup {
  display: block;
  // position: absolute;
  // right: 20px;
  // top: 15px;
}
.upload_data_tab tbody tr {
  position: relative;
}
.upload_data_tab tr:hover > .iconGroup {
  display: inline-block;
}
.upload_data_tab .iconGroup .fa {
  font-size: 1.2em;
  margin: auto 12px;
  transition: all 0.5s;
  color: #c5c5c5;
  cursor: pointer;
}
.upload_data_tab .iconGroup .fa:hover {
  color: #5e5e5e;
}
.tableCard {
  background-color: #fff;
  border-radius: 0 !important;
}
.subTab {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.subTab .nav-link.active {
  border-left: 0;
  font-weight: 500;
}
.subTab .nav-link {
  background: transparent;
  color: #373a3f;
  font-size: 14px;
  font-weight: 200;
  transform: all 0.5s;
}
.subTab .nav-link:hover {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0;
  color: #373a3f !important;
  border-left: 0;
  font-weight: 500;
}
.formSection {
  height: calc(100vh - 250px);
  overflow: hidden;
  overflow-y: scroll;
}
.border_bottom_grey {
  border-bottom: 2px solid #212832;
}
.addTemp_btn {
}
.upload_data_tab h6 {
  font-size: 0.875rem;
}
.mainRadiobtn {
  font-size: 0.875rem !important;
}
// .upload_data_tab .pure-checkbox input[type="radio"] + label:before{
//   border: 1px solid #333;
//   width: 12px;
//   height: 12px;
//   margin-top: -8px;
// }
// .upload_data_tab .pure-checkbox input[type="radio"] + label{
//   padding-left: 1.4em;
// }
// .upload_data_tab .pure-checkbox input[type="radio"] + label:after{
//   top: 48%;
//   left: 2px;
//   width: 10px;
//   height: 10px;
//   margin-top: -5px;
// }
.upload_data_tab .col-form-label {
  font-size: 0.84rem;
}
.upload_data_tab .form-control {
  // color: #7e8084;
  color: #434445;
  font-weight: 300 !important;
}
.upload_data_tab .btn-success {
  color: #fff;
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}
.newGreen-theme {
  .upload_data_tab {
    background: #1a4728;
    border-radius: 0 6px 6px 0;
  }
  .upload_data_tab #v-pills-tab {
    height: 80vh;
    background: #1a4728 !important;
  }
  .upload_data_tab .nav-link[data-v-596ace85] {
    background: #1a4728;
    color: #f4f4f4;
    font-size: 16px;
  }
  .upload_data_tab .nav-link.active[data-v-596ace85] {
    border-radius: 0;
    background-color: #fff;
    border: 0;
    border-left: 6px solid #fe5868;
    font-weight: 500;
    color: #4c4c4c !important;
  }
  .upload_data_tab .nav-link[data-v-596ace85]:hover {
    background-color: #f4f4f4;
    border-color: transparent;
    border-radius: 0;
    color: #4c4c4c !important;
    border-left: 6px solid #fe5868;
  }
  .upload_data_tab .tab-content[data-v-596ace85] {
    background-color: #ffffff !important;
    border-radius: 0 6px 6px 0;
  }
  .sandbox_setup.sheetsNumber
    .wizard-nav
    li.active
    .wizard-icon-circle.tab_shape
    .wizard-icon-container {
    background-color: #f4f4f4 !important;
  }
  .subTab {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
  }
  .subTab .nav-link.active {
    border-left: 0 !important;
    font-weight: 500 !important;
  }
  .subTab .nav-link {
    background: transparent !important;
    color: #373a3f !important;
    font-size: 14px !important;
    font-weight: 200 !important;
    transform: all 0.5s !important;
  }
  .subTab .nav-link:hover {
    background-color: transparent !important;
    border-color: transparent !important;
    border-radius: 0 !important;
    color: #373a3f !important;
    border-left: 0 !important;
    font-weight: 500 !important;
  }
}
.footerSection #footer footer {
  background-color: #fff !important;
}
.homepage-bg .modal-content{
  background-color: #828282 !important;
}
.homepage-bg .modal-content .modal-footer button{
  color:#fff !important;
}
</style>
