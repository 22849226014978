<template>
<div>
<training-header :appConfig="configObj"></training-header> 
  <div class="mdgridviewpage-bg">
    
    <div class="container py-5">
      <div class="row">
        <div class="col-md-12">
          <h5>Feedback Type</h5>  
        <div>
          <input type="radio" value="Comments" name="type" @click="feebackType('Comments')">Comments
          <input type="radio" value="Suggestions" name="type" @click="feebackType('Suggestions')">Suggestions
          <input type="radio" value="Questions" name="type"  @click="feebackType('Questions')">Questions
        </div>
        <h5>Describe your Feedback</h5>
        <textarea v-model="feedbackText"></textarea >
        <br>
        <button @click="callApi">Submit Button</button>
        </div>
      </div>
    </div>
    
  </div>
  <training-footer :appConfig="configObj"></training-footer>
</div>
</template>
    <script>
import ApiServices from "../service/ApiServices";
import trainingHeader from '../components/trainingHeader';
import trainingFooter from '../components/trainingFooter';
export default {
  data() {
    return {
      configObj:{},
      userProfile:'',
      feedbackType:'',
      feedbackText:''
    };
    
  },
  components: {
        trainingHeader,
        trainingFooter
        
    },
  methods: {
    callApi(){
      this.userProfile = JSON.parse(localStorage.getItem("userProfile"));
      console.log(this.userProfile.username)
      console.log(this.feedbackType)
      console.log(this.feedbackText)
      console.log(this.$route.params.facilitatorObj.appId)
      ApiServices.RestPostCall("api/avenir/training/sendFeedback", {
              "feedbackType":this.feedbackType,
              "feedback":this.feedbackText,
              "emailId":this.userProfile.username,
              "appId":this.$route.params.facilitatorObj.appId
              }).then(aveT => {
                console.log(aveT)
              });
    },
    feebackType(value){
      this.feedbackType =value
     console.log(this.feedbackType)
    }
  },
  mounted() {
   console.log(this.$route.params.facilitatorObj.appId)
  },
};
</script>
