<template>
  <div v-if="isVisible" class="loader-container">
    <div class="loader">
      <div class="loader__bar"></div>
      <div class="loader__bar"></div>
      <div class="loader__bar"></div>
      <div class="loader__bar"></div>
      <div class="loader__bar"></div>
      <div class="loader__ball"></div>
    </div>
  </div>
</template>

<script>
export default {
    name: "Loader",
    props: {
        isVisible: {type: Boolean, required: true}
    },
}
</script>
