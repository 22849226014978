<template>
  <div>
    <div class="mdgridviewpage-bg-login position-relative">
      <div class="row px-0">
        <div class="col-lg-12">
          <div class="header-login d-flex justify-content-between">
            <div class="d-flex justify-content-center align-items-center">
              <div class="d-flex justify-content-center">
                <img
                  src="../assets/img/goi_icon.png"
                  style="width: 30%!important;"
                />
              </div>
              <div>
                Ministry of Health &amp; Family Welfare, Government of India
              </div>
            </div>
            <div class="d-flex justify-content-center align-items-center w-25">
              <div
                class="d-flex justify-content-center align-items-center w-25 mx-2"
              >
                <img
                  src="../assets/img/jori_no_border.png"
                  class="py-3"
                  style="width: 100%!important;"
                />
              </div>
              <div
                class="d-flex justify-content-center align-items-center w-25 mx-2"
              >
                <img
                  src="../assets/img/nhm_white_text.png"
                  class="py-3"
                  style="width: 100%!important;"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="container-fluid"
        style="
    height: calc(100vh - 129px);
    overflow: auto;
"
      >
        <div
          class="row dashboardchart-container globalconfigcontainer loginForm h-100 px-5 pt-4"
        >
          <div class="col-4">
            <h4 class="formTitle-login">
              E-Learning Portal For FP-LMIS
            </h4>
            <div v-if="$route.name === 'homeMod'">
              <div
                class="text-center formTitle-login"
                style="color: #fff; font-size: 24px"
              >
                Login
              </div>
              <ValidationObserver v-slot="{ handleSubmit }">
                <div class="px-0">
                  <form
                    class="d-flex"
                    @submit.prevent="handleSubmit(onSubmitLogin)"
                  >
                    <div class="row col-lg-12" style="margin: 0 auto">
                      <div class="mainform-holder-login w-100">
                        <div class="">
                          <div class="form-group">
                            <div for="inputContactEmail" class="pb-1">
                              Email
                            </div>
                            <div class="">
                              <ValidationProvider
                                name="E-mail"
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <input
                                  v-model="email"
                                  type="text"
                                  class="form-control"
                                  id="inputContactEmail"
                                  placeholder=""
                                />
                                <span class="text-danger1-login">{{ errors[0] }}</span>
                              </ValidationProvider>
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <div class="form-group">
                            <div for="inputContactEmailPassword" class="pb-1">
                              Password
                            </div>
                            <div class="">
                              <ValidationProvider
                                name="Password"
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <div class="input-group mb-0">
                                  <input
                                    v-model="password"
                                    type="password"
                                    class="form-control"
                                    id="inputContactEmailPassword"
                                    placeholder=""
                                  />
                                  <div class="input-group-append">
                                    <span
                                      @click="showPass"
                                      class="input-group-text fa"
                                      v-bind:class="[
                                        passActive ? 'fa-eye' : 'fa-eye-slash',
                                      ]"
                                      style="height: 2.3rem"
                                    ></span>
                                  </div>
                                </div>
                                <span class="text-danger1-login">{{ errors[0] }}</span>
                              </ValidationProvider>
                            </div>
                          </div>
                        </div>
                        <div>
                          <button
                            type="submit"
                            class="btn purple-button-login mx-0 w-100 mb-3 mt-2"
                          >
                            Login
                          </button>
                        </div>
                        <div class="pt-1">
                          <div class="form-group text-left">
                            <a
                              class="text-dark-white-login"
                              @click="GotoCreateAccount()"
                              >Request for Registration</a
                            >
                          </div>
                        </div>
                        <div class="d-flex justify-content-between">
                          <div class="mt-2">
                            <div class="form-group mb-0">
                              <div class=" m-t-20px">
                                <a
                                  class="text-dark-white-login"
                                  @click="goToActivateAccount"
                                  >Activate Account</a
                                >
                              </div>
                            </div>
                          </div>
                          <div class="mt-2">
                            <div class="form-group mb-0">
                              <div class="m-t-20px">
                                <a
                                  class="text-dark-white-login"
                                  @click="$bvModal.show('forgotPasswordModal')"
                                  v-b-modal.modal-center
                                  >Forgot Password</a
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="mt-3">
                          <div class="row">
                            <div class="col-12 px-1">
                              <div class="col-6 d-none">
                                <div class="form-group row mb-0">
                                  <div class="col-sm-12 text-left m-t-20px">
                                    <form>
                                      <div class="form-check">
                                        <label
                                          class="form-check-label m-t-20px"
                                          for="check1"
                                        >
                                          <input
                                            type="checkbox"
                                            class="form-check-input"
                                            id="check1"
                                            name="option1"
                                            value="something"
                                          />
                                          Remember Me
                                        </label>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </ValidationObserver>
            </div>
            <div v-if="$route.name === 'activate'">
              <div
                class="text-center formTitle-login"
                style="color: #fff; font-size: 24px"
              >
                Activate
              </div>
              <ValidationObserver v-slot="{ handleSubmit }">
                <div class="">
                  <form
                    @submit.prevent="handleSubmit(onSubmitActivate)"
                    class=""
                  >
                    <div class="row col-lg-12" style="margin: 0 auto">
                      <div class="mainform-holder-login w-100">
                        <div class="">
                          <div class="form-group">
                            <div for="inputContactEmail" class="pb-1">
                              Email
                            </div>
                            <div class="">
                              <ValidationProvider
                                name="E-mail"
                                rules="required|email"
                                v-slot="{ errors }"
                              >
                                <input
                                  v-model="email"
                                  type="email"
                                  class="form-control"
                                  id="inputContactEmail"
                                  placeholder=""
                                />
                                <span class="text-danger1-login">{{ errors[0] }}</span>
                              </ValidationProvider>
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <div class="form-group">
                            <div for="inputToken" class="pb-1">Token</div>
                            <div class="">
                              <ValidationProvider
                                name="Token"
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <input
                                  v-model="token"
                                  type="text"
                                  class="form-control"
                                  id="inputToken"
                                  placeholder=""
                                />
                                <span class="text-danger1-login">{{ errors[0] }}</span>
                              </ValidationProvider>
                            </div>
                          </div>
                        </div>
                        <div class="mt-3">
                          <div class="">
                            <div class=""></div>
                            <div class="">
                              <div class="">
                                <div class="form-group">
                                  <div class="">
                                    <button
                                      type="submit"
                                      class="btn purple-button-login w-100 mb-3 mt-2"
                                    >
                                      Activate Account
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <div class="">
                            <div class="">
                              <a class="text-dark-white-login" @click="GotoSignin()"
                                >Login</a
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </ValidationObserver>
            </div>
            <div v-if="$route.name === 'createaccount'">
              <ValidationObserver v-slot="{ handleSubmit }">
                <div class="collapse show" aria-labelledby="headingOne">
                  <div class="">
                    <form
                      @submit.prevent="handleSubmit(onSubmitCreate)"
                      class=""
                    >
                      <div class="" style="">
                        <div class="mainform-holder-login w-100">
                          <div class="">
                            <div class="form-group pb-0 mb-0">
                              <label class="col-form-label  p-0">Name</label>
                              <div class="d-flex">
                                <div class="" style="width: 50%">
                                  <ValidationProvider
                                    name="Firstname"
                                    rules="required"
                                    v-slot="{ errors }"
                                  >
                                    <input
                                      v-model.trim="frmAccount['firstname']"
                                      type="text"
                                      class="form-control"
                                      placeholder="First"
                                    />
                                    <span class="text-danger1-login">{{
                                      errors[0]
                                    }}</span>
                                  </ValidationProvider>
                                </div>
                                <div class="" style="width: 50%">
                                  <ValidationProvider
                                    name="Last Name"
                                    rules="required"
                                    v-slot="{ errors }"
                                  >
                                    <input
                                      v-model.trim="frmAccount['lastname']"
                                      type="text"
                                      class="form-control"
                                      placeholder="Last"
                                    />
                                    <span class="text-danger1-login">{{
                                      errors[0]
                                    }}</span>
                                  </ValidationProvider>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="d-flex">
                            <div class="form-group pb-0 mb-0" style="width: 50%">
                              <!-- <div class="col-sm-4"></div> -->
                              <label class="col-form-label  p-0"
                                >Nationality</label
                              >
                              <div class="">
                                <div class="select-wrapper">
                                  <select
                                    class="form-control"
                                    id="country"
                                    name="country"
                                    v-model="nationality"
                                    @change="getNation"
                                    :disabled="ndisabled == 0"
                                  >
                                    <option value="">Select Nationality</option>
                                    <option
                                      v-for="c in countries"
                                      :key="c.Code + '-' + c.Name"
                                      :value="c.Code + '-' + c.Name"
                                    >
                                      {{ c.Name }}
                                    </option>
                                  </select>
                                  <span
                                    v-if="isActive"
                                    v-bind:class="{
                                      active: isActive,
                                      'text-danger1-login': hasError,
                                    }"
                                    class="text-danger1-login"
                                    >This field is required</span
                                  >
                                </div>
                              </div>
                            </div>
                             <div class="" style="width: 50%">
                              <div class="form-group pb-0 mb-0">
                                <!-- <div class="col-sm-4"></div> -->
                                <label class="col-form-label  p-0"
                                  >User Role</label
                                >
                                <div class="">
                                  <div class="select-wrapper">
                                    <select
                                      class="form-control"
                                      v-model="userRole"
                                      @change="verifiUserRole"
                                    >
                                      <option value="">Select UserRole</option>
                                      <option
                                        v-for="userRoles in selectUserRole"
                                        :key="userRoles.id"
                                      >
                                        {{ userRoles.displayName }}
                                      </option>
                                    </select>
                                    <span
                                      v-if="userroleisActive"
                                      v-bind:class="{
                                        active: userroleisActive,
                                        'text-danger1-login': userroleishasError,
                                      }"
                                      class="text-danger1-login"
                                      >This field is required</span
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="">
                            <div class="">
                              <div class="form-group pb-0 mb-0">
                                <label class=" col-form-label  p-0"
                                  >Mobile</label
                                >
                                <div class="" v-if="odisabled == 0">
                                  <ValidationProvider
                                    name="User Name"
                                    rules="required"
                                    v-slot="{ errors }"
                                  >
                                    <input
                                      v-model.trim="frmAccount['mobile']"
                                      type="number"
                                      class="form-control"
                                      placeholder=""
                                    />
                                    <span class="text-danger1-login">{{
                                      errors[0]
                                    }}</span>
                                  </ValidationProvider>
                                </div>
                                <div class="" v-else>
                                  <VuePhoneNumberInput
                                    color="#dc3545"
                                    class=""
                                    v-model="phoneNumber"
                                    :default-country-code="
                                      nationality.split('-')[0]
                                    "
                                    @update="results = JSON.stringify($event)"
                                  >
                                  <span
                                    v-if="
                                      results && !JSON.parse(results).isValid
                                    "
                                    class="text-danger1-login"
                                    >Please enter valid number</span
                                  >
                                  </VuePhoneNumberInput>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-12 hide">
                            <div class="form-group pb-0 mb-0">
                              <!-- <div class="col-sm-4"></div> -->
                              <label
                                class="col-sm-3 col-form-label  p-0 text-right"
                                >Select Session</label
                              >
                              <div class="col-sm-9">
                                <div class="select-wrapper">
                                  <!-- <multiselect
                                    v-model="selectSes.session"
                                    :options="selectSes"
                                    :multiple="true"
                                    :close-on-select="false"
                                    :clear-on-select="false"
                                    :preserve-search="true"
                                    placeholder="Select Session"
                                    label="session"
                                    track-by="sessionId"
                                    :preselect-first="true"
                                  >
                                  </multiselect> -->
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="">
                            <div class="form-group pb-0 mb-0">
                              <label
                                for="inputContactEmail"
                                class="col-form-label p-0"
                                >Email</label
                              >
                              <div class="">
                                <ValidationProvider
                                  name="Email"
                                  rules="required|email"
                                  v-slot="{ errors }"
                                >
                                  <input
                                    v-model="frmAccount['email']"
                                    type="email"
                                    class="form-control"
                                    placeholder=""
                                    autocomplete="new-nemail"
                                  />
                                  <span class="text-danger1-login">{{
                                    errors[0]
                                  }}</span>
                                </ValidationProvider>
                              </div>
                            </div>
                          </div>
                          <div class="">
                            <div class="form-group pb-0 mb-0">
                              <label class="col-form-label p-0">Password</label>
                              <div class="d-flex">
                                <div class="" style="width: 50%">
                                  <ValidationProvider
                                    name="Password"
                                    rules="required|verify_password|min:8|max:16"
                                    v-slot="{ errors }"
                                    vid="confirmation"
                                  >
                                    <div class="input-group pb-0 mb-0">
                                      <input
                                        v-model="frmAccount['password']"
                                        type="password"
                                        class="form-control"
                                        placeholder="Password"
                                        id="inputContactEmailPassword"
                                        autocomplete="new-paaaassword"
                                      />
                                      <div class="input-group-append">
                                        <span
                                          @click="showPass"
                                          class="input-group-text fa fa-eye"
                                          style="
    height: 2.3rem;
"
                                        ></span>
                                      </div>
                                    </div>
                                    <span class="text-danger1-login">{{
                                      errors[0]
                                    }}</span>
                                  </ValidationProvider>
                                </div>
                                <div class="" style="width: 50%">
                                  <ValidationProvider
                                    name="Confirm Password"
                                    rules="required|confirmed:confirmation"
                                    v-slot="{ errors }"
                                  >
                                    <input
                                      v-model="frmAccount['confirm_pass']"
                                      type="password"
                                      class="form-control"
                                      placeholder="Confirm Password"
                                    />
                                    <span class="text-danger1-login">{{
                                      errors[0]
                                    }}</span>
                                  </ValidationProvider>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="mt-1">
                            <div class="">
                              <div class=""></div>
                              <div class="">
                                <div class="form-group mb-0">
                                  <div class="text-left" style="">
                                    <button
                                      type="submit"
                                      class="btn purple-button-login mx-0 w-100"
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="">
                            <div class="d-flex justify-content-between">
                              <div class="mt-2">
                                <div class="form-group mb-0">
                                  <div class="m-t-10px" style="">
                                    <a
                                      class="text-dark-white-login"
                                      @click="GotoSignin()"
                                      >Login</a
                                    >
                                  </div>
                                </div>
                              </div>
                              <div class="mt-2">
                                <div class="form-group mb-0">
                                  <div class="m-t-10px" style="">
                                    <a
                                      class="text-dark-white-login"
                                      @click="goToActivateAccount"
                                      >Activate Account</a
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </ValidationObserver>
            </div>
          </div>
          <div class="col-8">
            <img src="../assets/img/lpatop_view.png" class="w-100 px-3" />
          </div>
        </div>
      </div>
      <div class="row fixed-bottom">
        <div class="col-lg-12">
          <div class="footer-login py-2">
            Copywrite @ Ministry of Health &amp; Family Welfare. Goverment of
            India
          </div>
        </div>
      </div>
    </div>

    <b-modal id="forgotPasswordModal" centered>
      <template v-slot:modal-title> Forgot Password </template>
      <template v-slot:default>
        <ValidationObserver ref="frmForgotPassword">
          <div class="col-lg-12">
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">* E-mail</label>
              <div class="col-sm-8">
                <ValidationProvider
                  name="Email"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <input
                    v-model="forgotEmail"
                    type="email"
                    class="form-control"
                    placeholder="Enter Email"
                  />
                  <span class="text-danger1-login">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </ValidationObserver>
      </template>
      <template v-slot:modal-footer>
        <button
          type="button"
          class="btn purple-button-login w-auto justify-content-center"
          @click="$bvModal.hide('forgotPasswordModal')"
        >
          Close
        </button>
        <button
          type="button"
          class="btn purple-button-login w-auto justify-content-center"
          @click="forgotPassword()"
        >
          Save
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import ApiServices from "../service/ApiServices";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import Multiselect from "vue-multiselect";

extend("required", {
  ...required,
  message: "This field is required",
});

extend("verify_password", {
  message: `The {_field_} must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character`,
  validate: (value) => {
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    return strongRegex.test(value);
  },
});

export default {
  components: {
    VuePhoneNumberInput,
    Multiselect,
  },
  data() {
    return {
      passActive: true,
      email: "",
      password: "",
      forgotEmail: "",
      settings: settings,
      baseUrl: "http://localhost:8080/dhis/",
      dashboardUrl: "api/apps/Avenir-Generic/Avenir_Generic/index.html#/",
      email: "",
      token: "",
      settings: settings,
      frmAccount: {
        firstname: "",
        lastname: "",
        email: "",
        mobile: "",
        password: "",
        confirm_pass: "",
        nationality: "",
        userRole: "",
        status: "New",
      },
      otherNationality: "",
      nationality: "",
      userRole: "",
      selectUserRole: [],
      selectSes: [],
      selSes: "",
      UserRoleSelect: "",
      settings: settings,
      countries: [
        { Code: "AF", Name: "Afghanistan" },
        { Code: "AX", Name: "\u00c5land Islands" },
        { Code: "AL", Name: "Albania" },
        { Code: "DZ", Name: "Algeria" },
        { Code: "AS", Name: "American Samoa" },
        { Code: "AD", Name: "Andorra" },
        { Code: "AO", Name: "Angola" },
        { Code: "AI", Name: "Anguilla" },
        { Code: "AQ", Name: "Antarctica" },
        { Code: "AG", Name: "Antigua and Barbuda" },
        { Code: "AR", Name: "Argentina" },
        { Code: "AM", Name: "Armenia" },
        { Code: "AW", Name: "Aruba" },
        { Code: "AU", Name: "Australia" },
        { Code: "AT", Name: "Austria" },
        { Code: "AZ", Name: "Azerbaijan" },
        { Code: "BS", Name: "Bahamas" },
        { Code: "BH", Name: "Bahrain" },
        { Code: "BD", Name: "Bangladesh" },
        { Code: "BB", Name: "Barbados" },
        { Code: "BY", Name: "Belarus" },
        { Code: "BE", Name: "Belgium" },
        { Code: "BZ", Name: "Belize" },
        { Code: "BJ", Name: "Benin" },
        { Code: "BM", Name: "Bermuda" },
        { Code: "BT", Name: "Bhutan" },
        { Code: "BO", Name: "Bolivia, Plurinational State of" },
        { Code: "BQ", Name: "Bonaire, Sint Eustatius and Saba" },
        { Code: "BA", Name: "Bosnia and Herzegovina" },
        { Code: "BW", Name: "Botswana" },
        { Code: "BV", Name: "Bouvet Island" },
        { Code: "BR", Name: "Brazil" },
        { Code: "IO", Name: "British Indian Ocean Territory" },
        { Code: "BN", Name: "Brunei Darussalam" },
        { Code: "BG", Name: "Bulgaria" },
        { Code: "BF", Name: "Burkina Faso" },
        { Code: "BI", Name: "Burundi" },
        { Code: "KH", Name: "Cambodia" },
        { Code: "CM", Name: "Cameroon" },
        { Code: "CA", Name: "Canada" },
        { Code: "CV", Name: "Cape Verde" },
        { Code: "KY", Name: "Cayman Islands" },
        { Code: "CF", Name: "Central African Republic" },
        { Code: "TD", Name: "Chad" },
        { Code: "CL", Name: "Chile" },
        { Code: "CN", Name: "China" },
        { Code: "CX", Name: "Christmas Island" },
        { Code: "CC", Name: "Cocos (Keeling) Islands" },
        { Code: "CO", Name: "Colombia" },
        { Code: "KM", Name: "Comoros" },
        { Code: "CG", Name: "Congo" },
        { Code: "CD", Name: "Congo, the Democratic Republic of the" },
        { Code: "CK", Name: "Cook Islands" },
        { Code: "CR", Name: "Costa Rica" },
        { Code: "CI", Name: "C\u00f4te d'Ivoire" },
        { Code: "HR", Name: "Croatia" },
        { Code: "CU", Name: "Cuba" },
        { Code: "CW", Name: "Cura\u00e7ao" },
        { Code: "CY", Name: "Cyprus" },
        { Code: "CZ", Name: "Czech Republic" },
        { Code: "DK", Name: "Denmark" },
        { Code: "DJ", Name: "Djibouti" },
        { Code: "DM", Name: "Dominica" },
        { Code: "DO", Name: "Dominican Republic" },
        { Code: "EC", Name: "Ecuador" },
        { Code: "EG", Name: "Egypt" },
        { Code: "SV", Name: "El Salvador" },
        { Code: "GQ", Name: "Equatorial Guinea" },
        { Code: "ER", Name: "Eritrea" },
        { Code: "EE", Name: "Estonia" },
        { Code: "ET", Name: "Ethiopia" },
        { Code: "FK", Name: "Falkland Islands (Malvinas)" },
        { Code: "FO", Name: "Faroe Islands" },
        { Code: "FJ", Name: "Fiji" },
        { Code: "FI", Name: "Finland" },
        { Code: "FR", Name: "France" },
        { Code: "GF", Name: "French Guiana" },
        { Code: "PF", Name: "French Polynesia" },
        { Code: "TF", Name: "French Southern Territories" },
        { Code: "GA", Name: "Gabon" },
        { Code: "GM", Name: "Gambia" },
        { Code: "GE", Name: "Georgia" },
        { Code: "DE", Name: "Germany" },
        { Code: "GH", Name: "Ghana" },
        { Code: "GI", Name: "Gibraltar" },
        { Code: "GR", Name: "Greece" },
        { Code: "GL", Name: "Greenland" },
        { Code: "GD", Name: "Grenada" },
        { Code: "GP", Name: "Guadeloupe" },
        { Code: "GU", Name: "Guam" },
        { Code: "GT", Name: "Guatemala" },
        { Code: "GG", Name: "Guernsey" },
        { Code: "GN", Name: "Guinea" },
        { Code: "GW", Name: "Guinea-Bissau" },
        { Code: "GY", Name: "Guyana" },
        { Code: "HT", Name: "Haiti" },
        { Code: "HM", Name: "Heard Island and McDonald Islands" },
        { Code: "VA", Name: "Holy See (Vatican City State)" },
        { Code: "HN", Name: "Honduras" },
        { Code: "HK", Name: "Hong Kong" },
        { Code: "HU", Name: "Hungary" },
        { Code: "IS", Name: "Iceland" },
        { Code: "IN", Name: "India" },
        { Code: "ID", Name: "Indonesia" },
        { Code: "IR", Name: "Iran, Islamic Republic of" },
        { Code: "IQ", Name: "Iraq" },
        { Code: "IE", Name: "Ireland" },
        { Code: "IM", Name: "Isle of Man" },
        { Code: "IL", Name: "Israel" },
        { Code: "IT", Name: "Italy" },
        { Code: "JM", Name: "Jamaica" },
        { Code: "JP", Name: "Japan" },
        { Code: "JE", Name: "Jersey" },
        { Code: "JO", Name: "Jordan" },
        { Code: "KZ", Name: "Kazakhstan" },
        { Code: "KE", Name: "Kenya" },
        { Code: "KI", Name: "Kiribati" },
        { Code: "KP", Name: "Korea, Democratic People's Republic of" },
        { Code: "KR", Name: "Korea, Republic of" },
        { Code: "KW", Name: "Kuwait" },
        { Code: "KG", Name: "Kyrgyzstan" },
        { Code: "LA", Name: "Lao People's Democratic Republic" },
        { Code: "LV", Name: "Latvia" },
        { Code: "LB", Name: "Lebanon" },
        { Code: "LS", Name: "Lesotho" },
        { Code: "LR", Name: "Liberia" },
        { Code: "LY", Name: "Libya" },
        { Code: "LI", Name: "Liechtenstein" },
        { Code: "LT", Name: "Lithuania" },
        { Code: "LU", Name: "Luxembourg" },
        { Code: "MO", Name: "Macao" },
        { Code: "MK", Name: "Macedonia, the Former Yugoslav Republic of" },
        { Code: "MG", Name: "Madagascar" },
        { Code: "MW", Name: "Malawi" },
        { Code: "MY", Name: "Malaysia" },
        { Code: "MV", Name: "Maldives" },
        { Code: "ML", Name: "Mali" },
        { Code: "MT", Name: "Malta" },
        { Code: "MH", Name: "Marshall Islands" },
        { Code: "MQ", Name: "Martinique" },
        { Code: "MR", Name: "Mauritania" },
        { Code: "MU", Name: "Mauritius" },
        { Code: "YT", Name: "Mayotte" },
        { Code: "MX", Name: "Mexico" },
        { Code: "FM", Name: "Micronesia, Federated States of" },
        { Code: "MD", Name: "Moldova, Republic of" },
        { Code: "MC", Name: "Monaco" },
        { Code: "MN", Name: "Mongolia" },
        { Code: "ME", Name: "Montenegro" },
        { Code: "MS", Name: "Montserrat" },
        { Code: "MA", Name: "Morocco" },
        { Code: "MZ", Name: "Mozambique" },
        { Code: "MM", Name: "Myanmar" },
        { Code: "NA", Name: "Namibia" },
        { Code: "NR", Name: "Nauru" },
        { Code: "NP", Name: "Nepal" },
        { Code: "NL", Name: "Netherlands" },
        { Code: "NC", Name: "New Caledonia" },
        { Code: "NZ", Name: "New Zealand" },
        { Code: "NI", Name: "Nicaragua" },
        { Code: "NE", Name: "Niger" },
        { Code: "NG", Name: "Nigeria" },
        { Code: "NU", Name: "Niue" },
        { Code: "NF", Name: "Norfolk Island" },
        { Code: "MP", Name: "Northern Mariana Islands" },
        { Code: "NO", Name: "Norway" },
        { Code: "OM", Name: "Oman" },
        { Code: "PK", Name: "Pakistan" },
        { Code: "PW", Name: "Palau" },
        { Code: "PS", Name: "Palestine, State of" },
        { Code: "PA", Name: "Panama" },
        { Code: "PG", Name: "Papua New Guinea" },
        { Code: "PY", Name: "Paraguay" },
        { Code: "PE", Name: "Peru" },
        { Code: "PH", Name: "Philippines" },
        { Code: "PN", Name: "Pitcairn" },
        { Code: "PL", Name: "Poland" },
        { Code: "PT", Name: "Portugal" },
        { Code: "PR", Name: "Puerto Rico" },
        { Code: "QA", Name: "Qatar" },
        { Code: "RE", Name: "R\u00e9union" },
        { Code: "RO", Name: "Romania" },
        { Code: "RU", Name: "Russian Federation" },
        { Code: "RW", Name: "Rwanda" },
        { Code: "BL", Name: "Saint Barth\u00e9lemy" },
        { Code: "SH", Name: "Saint Helena, Ascension and Tristan da Cunha" },
        { Code: "KN", Name: "Saint Kitts and Nevis" },
        { Code: "LC", Name: "Saint Lucia" },
        { Code: "MF", Name: "Saint Martin (French part)" },
        { Code: "PM", Name: "Saint Pierre and Miquelon" },
        { Code: "VC", Name: "Saint Vincent and the Grenadines" },
        { Code: "WS", Name: "Samoa" },
        { Code: "SM", Name: "San Marino" },
        { Code: "ST", Name: "Sao Tome and Principe" },
        { Code: "SA", Name: "Saudi Arabia" },
        { Code: "SN", Name: "Senegal" },
        { Code: "RS", Name: "Serbia" },
        { Code: "SC", Name: "Seychelles" },
        { Code: "SL", Name: "Sierra Leone" },
        { Code: "SG", Name: "Singapore" },
        { Code: "SX", Name: "Sint Maarten (Dutch part)" },
        { Code: "SK", Name: "Slovakia" },
        { Code: "SI", Name: "Slovenia" },
        { Code: "SB", Name: "Solomon Islands" },
        { Code: "SO", Name: "Somalia" },
        { Code: "ZA", Name: "South Africa" },
        { Code: "GS", Name: "South Georgia and the South Sandwich Islands" },
        { Code: "SS", Name: "South Sudan" },
        { Code: "ES", Name: "Spain" },
        { Code: "LK", Name: "Sri Lanka" },
        { Code: "SD", Name: "Sudan" },
        { Code: "SR", Name: "Suriname" },
        { Code: "SJ", Name: "Svalbard and Jan Mayen" },
        { Code: "SZ", Name: "Swaziland" },
        { Code: "SE", Name: "Sweden" },
        { Code: "CH", Name: "Switzerland" },
        { Code: "SY", Name: "Syrian Arab Republic" },
        { Code: "TW", Name: "Taiwan, Province of China" },
        { Code: "TJ", Name: "Tajikistan" },
        { Code: "TZ", Name: "Tanzania, United Republic of" },
        { Code: "TH", Name: "Thailand" },
        { Code: "TL", Name: "Timor-Leste" },
        { Code: "TG", Name: "Togo" },
        { Code: "TK", Name: "Tokelau" },
        { Code: "TO", Name: "Tonga" },
        { Code: "TT", Name: "Trinidad and Tobago" },
        { Code: "TN", Name: "Tunisia" },
        { Code: "TR", Name: "Turkey" },
        { Code: "TM", Name: "Turkmenistan" },
        { Code: "TC", Name: "Turks and Caicos Islands" },
        { Code: "TV", Name: "Tuvalu" },
        { Code: "UG", Name: "Uganda" },
        { Code: "UA", Name: "Ukraine" },
        { Code: "AE", Name: "United Arab Emirates" },
        { Code: "GB", Name: "United Kingdom" },
        { Code: "US", Name: "United States" },
        { Code: "UM", Name: "United States Minor Outlying Islands" },
        { Code: "UY", Name: "Uruguay" },
        { Code: "UZ", Name: "Uzbekistan" },
        { Code: "VU", Name: "Vanuatu" },
        { Code: "VE", Name: "Venezuela, Bolivarian Republic of" },
        { Code: "VN", Name: "Viet Nam" },
        { Code: "VG", Name: "Virgin Islands, British" },
        { Code: "VI", Name: "Virgin Islands, U.S." },
        { Code: "WF", Name: "Wallis and Futuna" },
        { Code: "EH", Name: "Western Sahara" },
        { Code: "YE", Name: "Yemen" },
        { Code: "ZM", Name: "Zambia" },
        { Code: "ZW", Name: "Zimbabwe" },
      ],
      odisabled: 0,
      ndisabled: 1,
      isActive: false,
      hasError: false,
      results: null,
      userroleisActive: false,
      userroleishasError: false,
      sesisActive: false,
      sesishasError: false,
      phoneNumber: "",
    };
  },
  methods: {
    showPass() {
        console.log(this.passActive);
      this.passActive = !this.passActive;
      let passwordField = document.querySelector("#inputContactEmailPassword");
      if (passwordField.getAttribute("type") === "password") {
        passwordField.setAttribute("type", "text");
      } else {
        passwordField.setAttribute("type", "password");
      }
    },
    GotoCreateAccount() {
      this.$router.push("/create-account");
    },
    goToActivateAccount() {
      this.$router.push("/activate");
    },
    onSubmitLogin() {
      this.$root.loading = true;
      this.baseUrl = ApiServices.getBaseUrl();
      this.dashboardUrl = this.baseUrl + this.dashboardUrl;
      //let headers = {Authorization: 'Basic YWRtaW46ZGlzdHJpY3Q='};
      let authorizationToken =
        "Basic " + btoa(this.email + ":" + this.password);
      localStorage.setItem("authToken", authorizationToken);
      ApiServices.RestPostCall("api/avenir/login", {
        username: this.email,
        password: this.password,
      })
        .then((res) => {
          console.log(res);
          this.$root.loading = false;
          if (res.status.toLocaleLowerCase() == "success") {
            // ApiServices.RestGetCall('/api/avenir/training/getAppDetails',{'userId':this.email}).then(aveT => {
            //   console.log(aveT);
            // localStorage.setItem("avenirTraining",JSON.stringify(aveT));
            localStorage.setItem("userProfile", JSON.stringify(res));
            localStorage.setItem("loggedInUserId", res.useruid);
            //console.log(JSON.parse(localStorage.getItem("userProfile")));
            //this.$router.push("/wizard");
            if (settings.countryName.toLowerCase() === "nepal") {
              if (settings.userRole.includes(res.userrole)) {
                this.$router.push("/select-plan-nepal");
              } else {
                window.open(this.dashboardUrl, "_blank");
              }
            } else if (
              res.userroleid === "65042" ||
              res.userroleid === "64975"
            ) {
              this.$router.push("/training-dashboard");
            } else {
              this.$router.push("/select-module");
            }
            //  });
          } else {
            this.$swal({
              icon: "error",
              text: res.message,
            });
            // logout forcefully
            ApiServices.RestGetCall(
              "dhis-web-commons-security/logout.action",
              {}
            ).then((res) => {
              console.log(res);
            });
          }
        })
        .catch((error) => {
          this.$root.loading = false;
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            //console.log(error.response.headers);
            this.$swal({
              text: "incorrect username/password",
            });
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          // logout forcefully
          ApiServices.RestGetCall(
            "dhis-web-commons-security/logout.action",
            {}
          ).then((res) => {
            console.log(res);
          });
          //console.log(error.config);
        });
    },
    forgotPassword() {
      if (this.$refs.frmForgotPassword.validate()) {
        this.$root.loading = true;
        console.log(this.forgotEmail);
        //this.forgotEmail = "";
        ApiServices.RestPostCall("api/avenir/subscribe/password/forgot", {
          email: this.forgotEmail,
        }).then((res) => {
          console.log(res);
          this.$swal({
            text: res.message,
          });
          this.$bvModal.hide("forgotPasswordModal");
          this.$root.loading = false;
        });
        //this.$bvModal.hide('forgotPasswordModal')
      }
    },
    onSubmitActivate() {
      this.$root.loading = true;
      //let headers = {Authorization: 'Basic YWRtaW46ZGlzdHJpY3Q='};
      ApiServices.RestGetCall(
        "api/avenir/subscribe/authorize",
        {
          email: this.email,
          token: this.token,
        },
        "systemBasicAuth"
      ).then((response) => {
        this.$root.loading = false;
        console.log(response);
        if (response.status.toLocaleLowerCase() == "success") {
          this.email = this.token = "";
          this.$swal({
            text: response.message,
          }).then(() => {
            this.$router.push("/");
          });
        } else {
          this.$swal({
            text: response.message,
          });
        }
      });
    },
    GotoSignin() {
      this.$router.push("/");
    },
    getNation() {
      if (this.nationality == "") {
        this.isActive = true;
        this.hasError = true;
        this.odisabled = 0;
      } else {
        this.odisabled = 1;
        this.isActive = false;
        this.hasError = false;
      }
    },
    getOther() {
      if (this.otherNationality == "") {
        this.ndisabled = 1;
        this.isActive = true;
        this.hasError = true;
      } else {
        this.ndisabled = 0;
        this.isActive = false;
        this.hasError = false;
      }
    },
    verifiUserRole() {
      if (this.userRole == "") {
        this.userroleisActive = true;
        this.userroleishasError = true;
      } else {
        this.userroleisActive = false;
        this.userroleishasError = false;
      }
    },
    verifiSelSes() {
      if (this.userRole == "") {
        this.sesisActive = true;
        this.sesishasError = true;
      } else {
        this.sesisActive = false;
        this.sesishasError = false;
      }
    },
    getUserRole() {
      ApiServices.RestGetCall("api/userRoles.json")
        .then((Response) => {
          console.log(Response);
          this.selectUserRole = Response.userRoles;
        })
        .catch((error) => {
          this.$root.loading = false;
          this.$swal({
            text: error,
          });
        });
    },
    getTrainSes() {
      ApiServices.RestGetCall("api/avenir/training/getTrainingSessions")
        .then((Response) => {
          console.log(Response);
          this.selectSes = Response.sessions;
        })
        .catch((error) => {
          this.$root.loading = false;
          this.$swal({
            text: error,
          });
        });
    },
    GotoSignin() {
      this.$router.push("/");
    },
    onSubmitCreate() {
      //this.$root.loading = true;
      if (!this.isActive) {
        if (this.otherNationality != "") {
          this.frmAccount["nationality"] = this.otherNationality;
        } else {
          this.frmAccount["nationality"] = this.nationality;
        }
        if (this.otherNationality == "") {
          this.phoneNumber = JSON.parse(this.results)["formatInternational"];
          this.frmAccount["mobile"] = this.phoneNumber;
        }
        if (this.userRole != "") {
          this.frmAccount["userRole"] = this.userRole;
        }
        console.log(this.frmAccount, this.nationality);
        let headers = { Authorization: "Basic YWRtaW46ZGlzdHJpY3Q=" };
        ApiServices.RestPostCall("api/avenir/register/request", this.frmAccount)
          .then((Response) => {
            console.log(Response);
            this.$root.loading = false;
            if (Response.status && Response.status.toLowerCase() == "success") {
              this.$swal({
                text: Response.message,
              }).then(() => {
                this.$router.push("/activate");
              });
            } else {
              this.$swal({
                text: Response.message,
              });
            }
          })
          .catch((error) => {
            this.$root.loading = false;
            this.$swal({
              text: error,
            });
          });
      }
    },
  },
  mounted() {
    this.getUserRole();
    this.getTrainSes();
  },
};
</script>

<style>
.mdgridviewpage-bg-login {
  background-color: #ededed;
  background: url("../assets/img/dots_pattern.png");
  background-position: top;
  height: 100vh;
  overflow: hidden;
}
.header-login {
  background-color: #00000030 !important;
  color: white;
  font-size: 18px !important;
  font-weight: 500;
}
.footer-login {
  background-color: #ffffff4d !important;
  color: #000;
  text-align: center;
}
.formTitle-login {
  color: #fff;
  font-size: 36px;
  letter-spacing: 2px;
  letter-spacing: 2px;
  font-weight: 700;
}
.mainform-holder-login {
  color: #fff;
}
.purple-button-login {
  padding: 6px 35px;
  background: #543b54 !important;
  border: 1px solid #543b54 !important;
  border-radius: 6px !important;
  color: #fff !important;
  font-weight: 600;
  text-transform: uppercase;
}
.text-dark-white-login,
.text-dark-white-login:hover {
  color: #fff;
  border-bottom: #fff;
  text-decoration: underline;
  cursor: pointer;
}
.text-danger1-login {
  color: #000 !important;
}
</style>
