<template>
  <div>
    <!-- <training-header></training-header> -->

    <div class="mdgridviewpage-bg">
      <div class="container-fluid">
        <div class="row" style="display:block; width:100%">
          <!-- <div class="col-12" v-if="userProfile.userrole == 'AvenirAppAdmin'">
                <a href="#" @click="logout" class="nav-link float-right mb-4" type="button" role="button" style="font-size:16px; color:#000;"><i class="fa fa-sign-out" aria-hidden="true" ></i> Logout</a>
          </div>-->
          <div class="col-12">
            <ul class="navbar-nav ml-auto mt-2 mt-lg-0 topnavbarlist">
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="fa fa-user"></i> User
                </a>
                <div class="dropdown-menu innerdropdownmenu" aria-labelledby="navbarDropdown">
                  <a class="dropdown-item" href="#/profile">Profile</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" @click="logout">Log out</a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="container-fluid body maindivWrap">
        <div class="row">
          <div class="col-lg-8" style="margin:0 auto;">
            <div class="card">
              <div
                class="card-header"
                style="background-color:rgba(221, 221, 221, 0.91) !important;"
              >
                <div class="row d-flex">
                  <div class="col-md-8 mx-auto">
                    <div class="row">
                      <div class="col-sm-4">
                        <!-- <img src="../assets/img/logo_front_new.png" class="imgFit float-right pr-3" style="padding-top: 3%;">  -->
                        <img
                          src="../assets/img/Nepal_logo.png"
                          class="imgFit float-right"
                          style="padding-top: 3%; height:65px;"
                        />
                      </div>
                      <div
                        class="col-sm-8 perfect-center float-left"
                        style="justify-content: left;"
                      >
                        <h4 style="margin-bottom: 0;font-weight:400;">
                          <span style="font-weight:600;">Family Planning</span> Dashboard
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body p-5">
                <div class="row">
                  <div class="col-md-12">
                    <h4
                      class="top_text mt-4"
                      style="text-align: center; font-weight: 500;"
                    >Welcome {{userProfile.firstname}}</h4>
                    <p
                      class="text-center py-3"
                      style="font-size:14px;"
                    >This tool allows Family Welfare Division Nepal to monitor the family planning data submitted by Private sectors and the NGOs throughout the country. It also allows the division to track the performance of different FP services over time.</p>
                    <h5 class="text-center">Get Started</h5>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="flex-container2">
                      <!-- <a class="btn bg-light-red btn-div" href="#/training-dashboard">
                                        <div class="inside_content">
                                            <span class="fa fa-address-book-o text-white"></span>
                                            <p class="text-white">Avenir Training</p>
                                        </div>
                      </a>-->
                      <a
                        class="btn btn-div"
                        href="#/wizard"
                        style="margin-left: 30px; background-color:#3471CE;"
                        v-if="userProfile.userrole == 'AvenirAppAdmin'"
                      >
                        <div class="inside_content">
                          <img src="../assets/img/conf_icon.png" class="mr-2" style="width:25px;" />
                          <p class="text-white">Configure Dashboard</p>
                        </div>
                      </a>
                      <a
                        class="btn btn-div"
                        :href="dashboardUrl"
                        target="_blank"
                        style="margin-left: 30px; background-color:#3471CE;"
                        v-else
                      >
                        <div class="inside_content">
                          <img src="../assets/img/conf_icon.png" class="mr-2" style="width:25px;" />
                          <p class="text-white">Access Dashboard</p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <training-footer></training-footer>
  </div>
</template>
<script>
import trainingHeader from "../components/trainingHeader";
import trainingFooter from "../components/trainingFooter";
import ApiServices from "../service/ApiServices";
export default {
  components: {
    trainingHeader,
    trainingFooter
  },
  data() {
    return {
      userProfile: "",
      baseUrl: "http://localhost:8080/dhis/",
      dashboardUrl: "api/apps/Avenir-Generic/index.html#/"
    };
  },
  methods: {
    logout() {
      //dhis-web-commons-security/logout.action
      localStorage.removeItem("userProfile");
      localStorage.removeItem("loggedInUserId");
      ApiServices.RestGetCall(
        "dhis-web-commons-security/logout.action",
        {}
      ).then(res => {
        console.log(res);
      });
      //window.open('http://intraavenir.duredemos.com/service/dhis-web-commons-security/logout.action', '_blank');
      this.$router.push("/");
    }
  },
  mounted() {
    this.userProfile = JSON.parse(localStorage.getItem("userProfile"));
    this.baseUrl = ApiServices.getBaseUrl();
    this.dashboardUrl = this.baseUrl + this.dashboardUrl;
  }
};
</script>
<style scoped>
.mdgridviewpage-bg {
  background-color: #38a1c1;
  background: url("../assets/img/sign_bg.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  padding: 20px 40px;
}
.maindivWrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.flex-container2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
}

.flex-container2 > .btn-div {
  color: white;
  width: 230px;
  height: 65px;
}
.btn-success:hover {
  color: #fff;
  background-color: #449d44 !important;
  border-color: #398439 !important;
}
.inside_content {
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-direction: column; */
  width: 100%;
  height: 100%;
}
.inside_content span {
  font-size: 26px;
  padding-top: 2px;
}
.inside_content p {
  color: #fff;
  /* margin-top: 5px; */
  font-size: 16px;
  margin-bottom: 0px;
}
.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.btn {
  transition: all 0.5s;
}
.btn:hover {
  background-color: #ababab !important;
  border-color: #ababab !important;
}
.card-body {
  background-color: rgba(255, 255, 255, 0.78) !important ;
  padding-top: 10px !important;
  padding-bottom: 60px !important;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  /* border-top-left-radius: 10px;
        border-top-right-radius: 10px; */
  /* box-shadow: 0px 0px 3px rgba(53,53,53,0.26); */
}

.card {
  background-color: transparent;
  box-shadow: 0px 0px 0px 0px 4px rgb(186, 186, 186) !important;
  border-radius: 10px;
  border: 0px !important;
}
.card-header {
  border-bottom: 0px;
  border-radius: 10px 10px 0 0 !important;
  padding: 8px 12px !important;
}
.navbar-brand,
.navbar-nav > li > a,
.site_title {
  color: #343a40 !important;
  font-size: 16px;
  text-transform: capitalize;
  line-height: 24px;
}
</style>