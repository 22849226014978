var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"body"},[_c('div',{staticClass:"main_container"},[_c('training-header',{attrs:{"appConfig":_vm.appConfig}}),_c('div',{staticClass:"container-fluid",attrs:{"role":"main"}},[_c('div',{staticClass:"text-sectiomn-wrap"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-10 mx-auto"},[_c('div',{staticClass:"backgroundbg-color"},[_c('div',{staticClass:"heading-title-card"},[_c('h2',{staticClass:"text-white",staticStyle:{"font-weight":"600"}},[_vm._v(" "+_vm._s(_vm.facilitatorObj.roleHeader)+" ")])]),_c('div',{staticClass:"x_panel"},[_c('div',{staticClass:"x_content position-relative"},[_c('div',{staticClass:"dashboard-widget-content",domProps:{"innerHTML":_vm._s(_vm.facilitatorObj.roleContent)}}),_c('div',{staticClass:"floating-submit-btn w-100",staticStyle:{"text-align":"center"}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('router-link',{staticStyle:{"margin-right":"5%"},attrs:{"to":{
                          name: 'facilitator-page',
                          params: {
                            facilitatorObj: _vm.facilitatorObj,
                            appConfig: _vm.appConfig,
                          },
                        }}},[_c('a',{staticClass:"btn yellow-float-btn justify-content-start"},[_vm._v(" Previous ")])]),_c('router-link',{staticStyle:{"color":"#813582"},attrs:{"to":{
                          name: 'facilitator-course',
                          params: {
                            facilitatorObj: _vm.facilitatorObj,
                            appConfig: _vm.appConfig,
                          },
                        }}},[_c('a',{staticClass:"btn yellow-float-btn"},[_vm._v(" Next ")])])],1)])])])])])])])]),_c('training-footer',{attrs:{"appConfig":_vm.appConfig}}),_c('training-faq',{attrs:{"facilitatorObj":_vm.facilitatorObj}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }