<template>
  <div v-if="facilitatorObj">
    <div class="floating-chat-icon" data-toggle="modal" data-target="#faqModal">
      <div class="floatingIcon">
        <span
          class="fa fa-question-circle-o"
          aria-hidden="true"
          v-b-tooltip.hover
          title="FAQ"
        ></span>
      </div>
    </div>

    <div class="modal fade" id="faqModal">
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">Frequently Asked Questions</h4>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <!-- Modal body -->
          <div class="modal-body">
            <div v-html="facilitatorObj.faqContent"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import trainingHeader from "../components/trainingHeader";
import trainingFooter from "../components/trainingFooter";
import ApiServices from "../service/ApiServices";
export default {
  data() {
    return {
      facilitatorObj: {},
      appConfig: {},
    };
  },
  components: {
    trainingHeader,
    trainingFooter,
  },
  mounted() {
    if (this.$route.params.facilitatorObj) {
      this.facilitatorObj = this.$route.params.facilitatorObj;
    } else {
      this.facilitatorObj = JSON.parse(localStorage.getItem("facilitatorObj"));
    }
    if (this.$route.params.appConfig) {
      this.appConfig = this.$route.params.appConfig;
    } else {
      this.appConfig = JSON.parse(localStorage.getItem("appConfig"));
    }
  },
};
</script>
<style lang="scss" scoped>
#faqModal {
  .modal-header {
    background-color: #ffc816;
    .modal-title {
      font-family: Montserrat-Black;
      font-size: 1.2rem;
      color: #42413c;
      margin-bottom: 0;
    }
  }
  .close {
    // color: #fff;
    text-decoration: none;
    font-size: 2.3rem;
  }
}
// #faqModal {
//   .modal-header {
//     background-color: #813582 !important;
//     color: #fff !important;
//   }
//   .close {
//     color: #fff;
//     text-decoration: none;
//     font-size: 2.3rem;
//   }
// }
</style>
